@charset "UTF-8";
@font-face {
  font-family: Cairo;
  font-style: normal;
  src: local("Cairo Regular"), local("Cairo-Regular"), url("../sass/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Cairo;
  font-style: normal;
  src: local("Cairo Bold"), local("Cairo-Bold"), url("../sass/fonts/Cairo-Bold.ttf");
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: lato;
  font-style: normal;
  font-weight: 300;
  src: local("lato Light"), local("lato-Light"), url("../sass/fonts/lato/Lato-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: lato;
  font-style: normal;
  font-weight: 400;
  src: local("lato Regular"), local("lato-Regular"), url("../sass/fonts/lato/Lato-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: lato;
  font-style: normal;
  font-weight: 500;
  src: local("lato Medium"), local("lato-Medium"), url("../sass/fonts/lato/Lato-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: lato;
  font-style: normal;
  font-weight: 600;
  src: local("lato Semibold"), local("lato-Semibold"), url("../sass/fonts/lato/Lato-Semibold.ttf");
  font-display: swap;
}

@font-face {
  font-family: lato;
  font-style: normal;
  font-weight: 700;
  src: local("lato Bold"), local("lato-Bold"), url("../sass/fonts/lato/Lato-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../sass/fonts/Montserrat/Montserrat-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"), url("../sass/fonts/Montserrat/Montserrat-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("../sass/fonts/Montserrat/Montserrat-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("../sass/fonts/Montserrat/Montserrat-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../sass/fonts/Montserrat/Montserrat-Bold.ttf");
  font-display: swap;
}

.ant-upload-picture-card-wrapper, .ant-upload.ant-upload-select-picture-card > .ant-upload, .ant-tooltip .ant-tooltip-inner, header.user-header .notify-holder .notify-count, header.user-header .profile-holder, header.user-header .profile-holder::after, header.main-header .profile-holder, header.main-header .profile-holder::after, .notify-menu-wrapper > li:last-of-type, .admin-modal-wrapper .ant-modal-content .ant-modal-footer, .dashboard-wrapper .ant-table-wrapper .ant-pagination, .home-content .work-wrapper .content .steps-wrapper .step-holder .content h5 span, .contact-section-wrapper .contact-holder .infinty-info-holder > div, .contact-section-wrapper .contact-holder .infinty-info-holder > div .icon-holder, .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content h5 span {
  display: flex;
  align-items: center;
  justify-content: center;
}

header.user-header, header.grid-action-header, header.main-header, header.main-header .links-wrapper, header.main-header ul, header.main-header.res-header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/************LINKS ********************/
/************ triangle ********************/
/************MEDIA QUERY ********************/
/*****************prfix*************/
/*****************input placeholder*************/
/***************** slide imgs *************/
/************ spacing ********************/
.mr-0 {
  margin-right: 0px;
}

body.rtl .mr-0 {
  margin-left: 0px;
  margin-right: 0;
}

.ml-0 {
  margin-left: 0px;
}

body.rtl .ml-0 {
  margin-right: 0px;
  margin-left: 0;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.pr-0 {
  padding-right: 0px;
}

body.rtl .pr-0 {
  padding-left: 0px;
  padding-right: 0;
}

.pl-0 {
  padding-left: 0px;
}

body.rtl .pl-0 {
  padding-right: 0px;
  padding-left: 0;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.m-0 {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.p-0 {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.mr-5 {
  margin-right: 5px;
}

body.rtl .mr-5 {
  margin-left: 5px;
  margin-right: 0;
}

.ml-5 {
  margin-left: 5px;
}

body.rtl .ml-5 {
  margin-right: 5px;
  margin-left: 0;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.pr-5 {
  padding-right: 5px;
}

body.rtl .pr-5 {
  padding-left: 5px;
  padding-right: 0;
}

.pl-5 {
  padding-left: 5px;
}

body.rtl .pl-5 {
  padding-right: 5px;
  padding-left: 0;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.m-5 {
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.p-5 {
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.mr-10 {
  margin-right: 10px;
}

body.rtl .mr-10 {
  margin-left: 10px;
  margin-right: 0;
}

.ml-10 {
  margin-left: 10px;
}

body.rtl .ml-10 {
  margin-right: 10px;
  margin-left: 0;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pr-10 {
  padding-right: 10px;
}

body.rtl .pr-10 {
  padding-left: 10px;
  padding-right: 0;
}

.pl-10 {
  padding-left: 10px;
}

body.rtl .pl-10 {
  padding-right: 10px;
  padding-left: 0;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.m-10 {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.p-10 {
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.mr-15 {
  margin-right: 15px;
}

body.rtl .mr-15 {
  margin-left: 15px;
  margin-right: 0;
}

.ml-15 {
  margin-left: 15px;
}

body.rtl .ml-15 {
  margin-right: 15px;
  margin-left: 0;
}

.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.pr-15 {
  padding-right: 15px;
}

body.rtl .pr-15 {
  padding-left: 15px;
  padding-right: 0;
}

.pl-15 {
  padding-left: 15px;
}

body.rtl .pl-15 {
  padding-right: 15px;
  padding-left: 0;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.m-15 {
  margin-top: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
}

.p-15 {
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.mr-20 {
  margin-right: 20px;
}

body.rtl .mr-20 {
  margin-left: 20px;
  margin-right: 0;
}

.ml-20 {
  margin-left: 20px;
}

body.rtl .ml-20 {
  margin-right: 20px;
  margin-left: 0;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.pr-20 {
  padding-right: 20px;
}

body.rtl .pr-20 {
  padding-left: 20px;
  padding-right: 0;
}

.pl-20 {
  padding-left: 20px;
}

body.rtl .pl-20 {
  padding-right: 20px;
  padding-left: 0;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.m-20 {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.p-20 {
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.mr-25 {
  margin-right: 25px;
}

body.rtl .mr-25 {
  margin-left: 25px;
  margin-right: 0;
}

.ml-25 {
  margin-left: 25px;
}

body.rtl .ml-25 {
  margin-right: 25px;
  margin-left: 0;
}

.mx-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.pr-25 {
  padding-right: 25px;
}

body.rtl .pr-25 {
  padding-left: 25px;
  padding-right: 0;
}

.pl-25 {
  padding-left: 25px;
}

body.rtl .pl-25 {
  padding-right: 25px;
  padding-left: 0;
}

.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.m-25 {
  margin-top: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
  margin-left: 25px;
}

.p-25 {
  padding-top: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
}

.mr-30 {
  margin-right: 30px;
}

body.rtl .mr-30 {
  margin-left: 30px;
  margin-right: 0;
}

.ml-30 {
  margin-left: 30px;
}

body.rtl .ml-30 {
  margin-right: 30px;
  margin-left: 0;
}

.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.pr-30 {
  padding-right: 30px;
}

body.rtl .pr-30 {
  padding-left: 30px;
  padding-right: 0;
}

.pl-30 {
  padding-left: 30px;
}

body.rtl .pl-30 {
  padding-right: 30px;
  padding-left: 0;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.m-30 {
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
}

.p-30 {
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
}

.mr-35 {
  margin-right: 35px;
}

body.rtl .mr-35 {
  margin-left: 35px;
  margin-right: 0;
}

.ml-35 {
  margin-left: 35px;
}

body.rtl .ml-35 {
  margin-right: 35px;
  margin-left: 0;
}

.mx-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.pr-35 {
  padding-right: 35px;
}

body.rtl .pr-35 {
  padding-left: 35px;
  padding-right: 0;
}

.pl-35 {
  padding-left: 35px;
}

body.rtl .pl-35 {
  padding-right: 35px;
  padding-left: 0;
}

.px-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.m-35 {
  margin-top: 35px;
  margin-right: 35px;
  margin-bottom: 35px;
  margin-left: 35px;
}

.p-35 {
  padding-top: 35px;
  padding-right: 35px;
  padding-bottom: 35px;
  padding-left: 35px;
}

.mr-40 {
  margin-right: 40px;
}

body.rtl .mr-40 {
  margin-left: 40px;
  margin-right: 0;
}

.ml-40 {
  margin-left: 40px;
}

body.rtl .ml-40 {
  margin-right: 40px;
  margin-left: 0;
}

.mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.pr-40 {
  padding-right: 40px;
}

body.rtl .pr-40 {
  padding-left: 40px;
  padding-right: 0;
}

.pl-40 {
  padding-left: 40px;
}

body.rtl .pl-40 {
  padding-right: 40px;
  padding-left: 0;
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.m-40 {
  margin-top: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  margin-left: 40px;
}

.p-40 {
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
}

.mr-45 {
  margin-right: 45px;
}

body.rtl .mr-45 {
  margin-left: 45px;
  margin-right: 0;
}

.ml-45 {
  margin-left: 45px;
}

body.rtl .ml-45 {
  margin-right: 45px;
  margin-left: 0;
}

.mx-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.pr-45 {
  padding-right: 45px;
}

body.rtl .pr-45 {
  padding-left: 45px;
  padding-right: 0;
}

.pl-45 {
  padding-left: 45px;
}

body.rtl .pl-45 {
  padding-right: 45px;
  padding-left: 0;
}

.px-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.m-45 {
  margin-top: 45px;
  margin-right: 45px;
  margin-bottom: 45px;
  margin-left: 45px;
}

.p-45 {
  padding-top: 45px;
  padding-right: 45px;
  padding-bottom: 45px;
  padding-left: 45px;
}

.mr-50 {
  margin-right: 50px;
}

body.rtl .mr-50 {
  margin-left: 50px;
  margin-right: 0;
}

.ml-50 {
  margin-left: 50px;
}

body.rtl .ml-50 {
  margin-right: 50px;
  margin-left: 0;
}

.mx-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.pr-50 {
  padding-right: 50px;
}

body.rtl .pr-50 {
  padding-left: 50px;
  padding-right: 0;
}

.pl-50 {
  padding-left: 50px;
}

body.rtl .pl-50 {
  padding-right: 50px;
  padding-left: 0;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.m-50 {
  margin-top: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  margin-left: 50px;
}

.p-50 {
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
}

.delay-0-half {
  transition-delay: 0.5s !important;
}

.delay-1 {
  transition-delay: 1s !important;
}

.delay-1-half {
  transition-delay: 1.5s !important;
}

.delay-2 {
  transition-delay: 2s !important;
}

.delay-2-half {
  transition-delay: 2.5s !important;
}

.delay-3 {
  transition-delay: 3s !important;
}

.delay-3-half {
  transition-delay: 3.5s !important;
}

.delay-4 {
  transition-delay: 4s !important;
}

.delay-4-half {
  transition-delay: 4.5s !important;
}

.delay-5 {
  transition-delay: 5s !important;
}

.delay-5-half {
  transition-delay: 5.5s !important;
}

.delay-6 {
  transition-delay: 6s !important;
}

.delay-6-half {
  transition-delay: 6.5s !important;
}

.delay-7 {
  transition-delay: 7s !important;
}

.delay-7-half {
  transition-delay: 7.5s !important;
}

.delay-8 {
  transition-delay: 8s !important;
}

.delay-8-half {
  transition-delay: 8.5s !important;
}

.delay-9 {
  transition-delay: 9s !important;
}

.delay-9-half {
  transition-delay: 9.5s !important;
}

.delay-10 {
  transition-delay: 10s !important;
}

.delay-10-half {
  transition-delay: 10.5s !important;
}

.delay-11 {
  transition-delay: 11s !important;
}

.pb-24 {
  padding-bottom: 1.5rem !important;
}

.pt-24 {
  padding-top: 1.5rem !important;
}

.py-24 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.d-none {
  display: none;
}

.h-90 {
  height: 90%;
}

.float-unset {
  float: unset !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline-none {
  text-decoration: none !important;
}

.p-relative {
  position: relative !important;
}

.cursor-p {
  cursor: pointer;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.h-auto {
  height: auto !important;
}

.text-nowrap {
  white-space: nowrap;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

.w-100 {
  width: 100% !important;
}

.w_90 {
  width: 90%;
}

.w_47 {
  width: 47%;
}

.w-80 {
  width: 80%;
}

.text_center {
  text-align: center;
}

.text-right, .home-content .work-wrapper .content .steps-wrapper .step-holder, .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder {
  text-align: right !important;
}

body.rtl .text-right, body.rtl .home-content .work-wrapper .content .steps-wrapper .step-holder, .home-content .work-wrapper .content .steps-wrapper body.rtl .step-holder, body.rtl .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder, .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps body.rtl .step-holder {
  text-align: left !important;
}

.text-left, .home-content .hero-wrapper > div:first-of-type .content, .home-content .statistics-wrapper .content > div, .home-content .work-wrapper .content .steps-wrapper .step-holder .content, .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content {
  text-align: left !important;
}

body.rtl .text-left, body.rtl .home-content .hero-wrapper > div:first-of-type .content, .home-content .hero-wrapper > div:first-of-type body.rtl .content, body.rtl .home-content .statistics-wrapper .content > div, .home-content .statistics-wrapper body.rtl .content > div, body.rtl .home-content .work-wrapper .content .steps-wrapper .step-holder .content, .home-content .work-wrapper .content .steps-wrapper .step-holder body.rtl .content, body.rtl .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content, .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder body.rtl .content {
  text-align: right !important;
}

.border_none {
  border: none !important;
}

.fit-cover {
  object-fit: cover;
}

.visibility-hidden {
  visibility: hidden !important;
}

.ant-pagination-options-quick-jumper input {
  display: none;
}

.mt-70 {
  margin-top: 70px !important;
}

.col-direction {
  flex-direction: column;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.align-baseLine {
  align-items: baseline;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.font-18 {
  font-size: 18px;
}

.font-28 {
  font-size: 28px;
}

.bold {
  font-weight: bold;
}

.font-14 {
  font-size: 14px;
}

.font-17 {
  font-size: 17px;
}

.gray-33 {
  color: #333333;
}

.w-50-100 {
  width: 50%;
}

@media only screen and (max-width: 769px) {
  .w-50-100 {
    width: 100% !important;
  }
}

.mx-7 {
  margin: 0 7px;
}

.mx-20 {
  margin: 0 23px;
}

.m-auto {
  margin: auto;
}

.py-20 {
  padding: 20px 0;
}

.p-25 {
  padding: 25px;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pr {
  padding-right: 10px !important;
}

body.rtl .pr {
  padding-right: 0 !important;
  padding-left: 10px !important;
}

.my-40 {
  margin: 40px 0;
}

.text-center {
  text-align: center;
}

/*
  Conditionally apply styles based upon child count
*/
.child-equal-wrapper {
  display: flex;
}

.child-equal-wrapper > div {
  flex: 1;
}

.child-equal-wrapper div:first-child:nth-last-child(1),
.child-equal-wrapper div:first-child:nth-last-child(1) ~ div {
  width: 100%;
}

.child-equal-wrapper div:first-child:nth-last-child(2),
.child-equal-wrapper div:first-child:nth-last-child(2) ~ div {
  width: 50%;
}

.child-equal-wrapper div:first-child:nth-last-child(3),
.child-equal-wrapper div:first-child:nth-last-child(3) ~ div {
  width: 33.33333%;
}

.child-equal-wrapper div:first-child:nth-last-child(4),
.child-equal-wrapper div:first-child:nth-last-child(4) ~ div {
  width: 25%;
}

.child-equal-wrapper div:first-child:nth-last-child(5),
.child-equal-wrapper div:first-child:nth-last-child(5) ~ div {
  width: 20%;
}

.child-equal-wrapper div:first-child:nth-last-child(6),
.child-equal-wrapper div:first-child:nth-last-child(6) ~ div {
  width: 16.66667%;
}

.child-equal-wrapper div:first-child:nth-last-child(7),
.child-equal-wrapper div:first-child:nth-last-child(7) ~ div {
  width: 14.28571%;
}

.child-equal-wrapper div:first-child:nth-last-child(8),
.child-equal-wrapper div:first-child:nth-last-child(8) ~ div {
  width: 12.5%;
}

.child-equal-wrapper > div {
  height: 50px;
}

.justify-space-between {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
/*******  created 26/3/2019 ************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: middle;
  font-family: Montserrat, Cairo, sans-serif;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  font-weight: normal;
}

body,
html {
  font-size: 16px;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:focus {
  outline: none;
}

input:focus {
  outline: none !important;
}

a:active,
a:hover {
  outline: 0;
  text-decoration: none;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar,
div::-webkit-scrollbar,
section::-webkit-scrollbarb,
main::-webkit-scrollbar,
ul::-webkit-scrollbar,
.ant-layout::-webkit-scrollbar {
  background-color: #f4f4f4 !important;
  width: 7px !important;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
section::-webkit-scrollbar-thumb,
main::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb,
.ant-layout::-webkit-scrollbar-thumb {
  background-color: #0793C7 !important;
}

.ant-form-item-with-help {
  margin-bottom: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:default,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

.slide-right {
  opacity: 0;
  transform: translateX(-50px);
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

.slide-left {
  opacity: 0;
  transform: translateX(50px);
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

.slide-top {
  opacity: 0;
  transform: translateY(-50px);
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

.slide-bottom {
  opacity: 0;
  transform: translateY(50px);
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

.rotate-y {
  transform-style: preserve-3d;
  transform: rotateY(90deg);
  transition: all ease-in-out 0.3s;
}

.animation-active .slide-right {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
  transition: all ease-in-out 0.4s;
}

.animation-active .slide-left {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
  transition: all ease-in-out 0.4s;
}

.animation-active .slide-top {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition: all ease-in-out 0.4s;
}

.animation-active .slide-bottom {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition: all ease-in-out 0.4s;
}

.animation-active .rotate-y {
  transform: rotateY(0);
}

.animation-active .charger-img-holder > div {
  top: 0 !important;
}

.animation-active .rotate-moving {
  animation-name: chargingMove;
  animation-duration: .7s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

body.rtl .animation-active .rotate-moving {
  animation-name: chargingMoveRtl;
}

@media only screen and (max-width: 769px) {
  .ant-menu-inline > .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

/***********************headeings*********************/
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  color: #4b4b4b;
  line-height: normal;
}

h1 {
  font-size: 2.1875rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5625rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 0.875rem;
  font-weight: normal;
}

/*************TEXT******************/
.black-text {
  color: #000;
}

.white-text {
  color: #FFF !important;
}

.dark-blue-text {
  color: #077aa3;
}

.primary {
  color: #0793C7;
}

.primary-2 {
  color: #077aa3;
}

.primary-3 {
  color: #249D45;
}

.primary-4 {
  color: #80D698;
}

.secondary {
  color: #2db2bb;
}

.secondary-2 {
  color: #c30c00;
}

.secondary-3 {
  color: #df5950;
}

.gray {
  color: #DADCE0;
}

.gray-2 {
  color: #CBCBCB;
}

.gray-3 {
  color: #f9f9fc;
}

.gray-4 {
  color: #7e7e7e;
}

.gray-5 {
  color: #656565;
}

.gray-6 {
  color: #4b4b4b;
}

.gray-7 {
  color: #242424;
}

/*disabled link*/
.isdisabled, .primary_link:disabled, .secondary_link:disabled, .Grayscale_link:disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

/*backgrounds*/
.bg-white {
  background-color: #FFF;
}

.bg-primary {
  background-color: #0793C7;
}

.bg-primary-2 {
  background-color: #077aa3;
}

.bg-primary-3 {
  background-color: #249D45;
}

.bg-primary-4 {
  background-color: #80D698;
}

.bg-secondary {
  background-color: #2db2bb;
}

.bg-secondary-2 {
  background-color: #c30c00;
}

.bg-secondary-3 {
  background-color: #df5950;
}

.bg-gray {
  background-color: #DADCE0;
}

.bg-gray-2 {
  background-color: #CBCBCB;
}

.bg-gray-3 {
  background-color: #f9f9fc;
}

.bg-gray-4 {
  background-color: #7e7e7e;
}

.bg-gray-5 {
  background-color: #656565;
}

.bg-gray-6 {
  background-color: #4b4b4b;
}

.bg-gray-7 {
  background-color: #242424;
}

.primary-fill, .primary-outline, .secondary-fill, .secondary-outline, .grayscale-fill, .grayscale-outline, .grayscale-light-fill, .grayscale-light-outline, .warning-fill, .warning-outline, .info-fill, .info-outline, .gradient-fill, .gradient-light-fill {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 33px !important;
  font-size: 13px;
  font-weight: 500;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 4px;
  white-space: nowrap;
  text-decoration: none;
}

.sm-btn.primary-fill, .sm-btn.primary-outline, .sm-btn.secondary-fill, .sm-btn.secondary-outline, .sm-btn.grayscale-fill, .sm-btn.grayscale-outline, .sm-btn.grayscale-light-fill, .sm-btn.grayscale-light-outline, .sm-btn.warning-fill, .sm-btn.warning-outline, .sm-btn.info-fill, .sm-btn.info-outline, .sm-btn.gradient-fill, .sm-btn.gradient-light-fill {
  min-height: 24px !important;
  padding: 0 18px;
  font-size: 14px;
  font-weight: normal;
}

.rounded.primary-fill, .rounded.primary-outline, .rounded.secondary-fill, .rounded.secondary-outline, .rounded.grayscale-fill, .rounded.grayscale-outline, .rounded.grayscale-light-fill, .rounded.grayscale-light-outline, .rounded.warning-fill, .rounded.warning-outline, .rounded.info-fill, .rounded.info-outline, .rounded.gradient-fill, .rounded.gradient-light-fill {
  border-radius: 20px;
}

.light-green.primary-fill, .light-green.primary-outline, .light-green.secondary-fill, .light-green.secondary-outline, .light-green.grayscale-fill, .light-green.grayscale-outline, .light-green.grayscale-light-fill, .light-green.grayscale-light-outline, .light-green.warning-fill, .light-green.warning-outline, .light-green.info-fill, .light-green.info-outline, .light-green.gradient-fill, .light-green.gradient-light-fill {
  color: #008539 !important;
  border: 1px solid #008539 !important;
}

.light-green.primary-fill:hover, .light-green.primary-outline:hover, .light-green.secondary-fill:hover, .light-green.secondary-outline:hover, .light-green.grayscale-fill:hover, .light-green.grayscale-outline:hover, .light-green.grayscale-light-fill:hover, .light-green.grayscale-light-outline:hover, .light-green.warning-fill:hover, .light-green.warning-outline:hover, .light-green.info-fill:hover, .light-green.info-outline:hover, .light-green.gradient-fill:hover, .light-green.gradient-light-fill:hover {
  background-color: #008539 !important;
  color: white !important;
}

.light-green-bg.primary-fill, .light-green-bg.primary-outline, .light-green-bg.secondary-fill, .light-green-bg.secondary-outline, .light-green-bg.grayscale-fill, .light-green-bg.grayscale-outline, .light-green-bg.grayscale-light-fill, .light-green-bg.grayscale-light-outline, .light-green-bg.warning-fill, .light-green-bg.warning-outline, .light-green-bg.info-fill, .light-green-bg.info-outline, .light-green-bg.gradient-fill, .light-green-bg.gradient-light-fill {
  background-color: #008539 !important;
}

.light-green-bg.primary-fill:focus, .light-green-bg.primary-outline:focus, .light-green-bg.secondary-fill:focus, .light-green-bg.secondary-outline:focus, .light-green-bg.grayscale-fill:focus, .light-green-bg.grayscale-outline:focus, .light-green-bg.grayscale-light-fill:focus, .light-green-bg.grayscale-light-outline:focus, .light-green-bg.warning-fill:focus, .light-green-bg.warning-outline:focus, .light-green-bg.info-fill:focus, .light-green-bg.info-outline:focus, .light-green-bg.gradient-fill:focus, .light-green-bg.gradient-light-fill:focus {
  color: #fff;
}

.lg-btn.primary-fill, .lg-btn.primary-outline, .lg-btn.secondary-fill, .lg-btn.secondary-outline, .lg-btn.grayscale-fill, .lg-btn.grayscale-outline, .lg-btn.grayscale-light-fill, .lg-btn.grayscale-light-outline, .lg-btn.warning-fill, .lg-btn.warning-outline, .lg-btn.info-fill, .lg-btn.info-outline, .lg-btn.gradient-fill, .lg-btn.gradient-light-fill {
  min-height: 38px !important;
  padding: 0 3.125rem;
  font-size: 16px;
  font-weight: normal;
}

.xlg-btn.primary-fill, .xlg-btn.primary-outline, .xlg-btn.secondary-fill, .xlg-btn.secondary-outline, .xlg-btn.grayscale-fill, .xlg-btn.grayscale-outline, .xlg-btn.grayscale-light-fill, .xlg-btn.grayscale-light-outline, .xlg-btn.warning-fill, .xlg-btn.warning-outline, .xlg-btn.info-fill, .xlg-btn.info-outline, .xlg-btn.gradient-fill, .xlg-btn.gradient-light-fill {
  min-height: 47px !important;
  padding: 0.75rem 3.25rem;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.43px;
  line-height: 1.06;
}

@media only screen and (max-width: 577px) {
  .xlg-btn.primary-fill, .xlg-btn.primary-outline, .xlg-btn.secondary-fill, .xlg-btn.secondary-outline, .xlg-btn.grayscale-fill, .xlg-btn.grayscale-outline, .xlg-btn.grayscale-light-fill, .xlg-btn.grayscale-light-outline, .xlg-btn.warning-fill, .xlg-btn.warning-outline, .xlg-btn.info-fill, .xlg-btn.info-outline, .xlg-btn.gradient-fill, .xlg-btn.gradient-light-fill {
    min-height: 40px !important;
    padding: 0.75rem 1.25rem;
    font-size: 15px;
  }
}

.primary-fill:focus, .primary-outline:focus, .secondary-fill:focus, .secondary-outline:focus, .grayscale-fill:focus, .grayscale-outline:focus, .grayscale-light-fill:focus, .grayscale-light-outline:focus, .warning-fill:focus, .warning-outline:focus, .info-fill:focus, .info-outline:focus, .gradient-fill:focus, .gradient-light-fill:focus {
  outline: none !important;
}

.sharpe.primary-fill, .sharpe.primary-outline, .sharpe.secondary-fill, .sharpe.secondary-outline, .sharpe.grayscale-fill, .sharpe.grayscale-outline, .sharpe.grayscale-light-fill, .sharpe.grayscale-light-outline, .sharpe.warning-fill, .sharpe.warning-outline, .sharpe.info-fill, .sharpe.info-outline, .sharpe.gradient-fill, .sharpe.gradient-light-fill {
  border-radius: 6px;
}

.primary-fill {
  background: #0793C7;
  color: #FFF;
}

.primary-fill:focus {
  background: #0793C7;
  color: #FFF;
}

.primary-fill:active {
  background: #0793C7;
  color: #FFF;
}

.primary-fill:hover {
  background: #0793C7;
  color: #FFF;
  border-color: #0793C7;
}

.primary-outline {
  background-color: #fff;
  color: #0793C7;
  border: 1px solid #0793C7;
  border-radius: 4px;
}

.primary-outline:hover {
  color: #fff;
  background-color: #0793C7;
  border-color: #0793C7;
}

.secondary-fill {
  background: #2db2bb;
  color: #FFF;
}

.secondary-fill:focus {
  background: #2db2bb;
  color: #FFF;
}

.secondary-fill:active {
  background: #2db2bb;
  color: #FFF;
}

.secondary-fill:hover {
  background: #2db2bb;
  color: #FFF;
  border-color: #2db2bb;
}

.secondary-outline {
  background-color: #fff;
  color: #2db2bb;
  border: 1px solid #2db2bb;
  border-radius: 4px;
}

.secondary-outline:hover {
  color: #fff;
  background-color: #2db2bb;
  border-color: #2db2bb;
}

.grayscale-fill {
  background: #4b4b4b;
  color: #FFF;
}

.grayscale-fill:focus {
  background: #4b4b4b;
  color: #FFF;
}

.grayscale-fill:active {
  background: #4b4b4b;
  color: #FFF;
}

.grayscale-fill:hover {
  background: #FFF;
  color: #4b4b4b;
  border: 1px solid #4b4b4b;
}

.grayscale-outline {
  background-color: #fff;
  color: #4b4b4b;
  border: 1px solid #4b4b4b;
  border-radius: 4px;
}

.grayscale-outline:hover {
  color: #fff;
  background-color: #4b4b4b;
  border-color: #4b4b4b;
}

.grayscale-light-fill {
  background: #f9f9fc;
  color: #FFF;
}

.grayscale-light-fill:focus {
  background: #f9f9fc;
  color: #FFF;
}

.grayscale-light-fill:active {
  background: #f9f9fc;
  color: #FFF;
}

.grayscale-light-fill:hover {
  background: #f9f9fc;
  color: #FFF;
  border-color: #f9f9fc;
}

.grayscale-light-outline {
  background-color: #fff;
  color: #f9f9fc;
  border: 1px solid #f9f9fc;
  border-radius: 4px;
}

.grayscale-light-outline:hover {
  color: #fff;
  background-color: #f9f9fc;
  border-color: #f9f9fc;
}

.warning-fill {
  background: #d83025;
  color: #FFF;
}

.warning-fill:focus {
  background: #d83025;
  color: #FFF;
}

.warning-fill:active {
  background: #d83025;
  color: #FFF;
}

.warning-fill:hover {
  background: #d83025;
  color: #FFF;
  border-color: #d83025;
}

.warning-outline {
  background-color: #fff;
  color: #d83025;
  border: 1px solid #d83025;
  border-radius: 4px;
}

.warning-outline:hover {
  color: #fff;
  background-color: #d83025;
  border-color: #d83025;
}

.info-fill {
  background: #d4b425;
  color: #FFF;
}

.info-fill:focus {
  background: #d4b425;
  color: #FFF;
}

.info-fill:active {
  background: #d4b425;
  color: #FFF;
}

.info-fill:hover {
  background: #d4b425;
  color: #FFF;
  border-color: #d4b425;
}

.info-outline {
  background-color: #fff;
  color: #d4b425;
  border: 1px solid #d4b425;
  border-radius: 4px;
}

.info-outline:hover {
  color: #fff;
  background-color: #d4b425;
  border-color: #d4b425;
}

.gradient-fill {
  background: linear-gradient(to left, #008539, #008539 3%);
  color: #FFF;
}

.gradient-fill:focus {
  background: linear-gradient(to left, #008539, #008539 3%);
  color: #FFF;
}

.gradient-fill:active {
  background: linear-gradient(to left, #008539, #008539 3%);
  color: #FFF;
}

.gradient-fill:hover {
  background: linear-gradient(to left, #008539, #008539 3%);
  color: #FFF;
  border-color: linear-gradient(to left, #008539, #008539 3%);
}

.gradient-light-fill {
  background: linear-gradient(89deg, #008539 1%, #5db92c 100%);
  color: #FFF;
}

.gradient-light-fill:focus {
  background: linear-gradient(89deg, #008539 1%, #5db92c 100%);
  color: #FFF;
}

.gradient-light-fill:active {
  background: linear-gradient(89deg, #008539 1%, #5db92c 100%);
  color: #FFF;
}

.gradient-light-fill:hover {
  background: linear-gradient(89deg, #008539 1%, #5db92c 100%);
  color: #FFF;
  border-color: linear-gradient(89deg, #008539 1%, #5db92c 100%);
}

.remove-icon-btn {
  display: flex;
  align-items: center;
  color: #c30c00 !important;
  background: transparent !important;
  border: 0px;
  font-size: 15px;
  font-weight: 700;
  padding: 0;
}

.ant-dropdown.has-close-icon .ant-dropdown-menu-item.warning {
  color: #d83025;
}

.ant-dropdown.has-close-icon .anticon-close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: -10px;
  right: -10px;
  width: 28px;
  height: 28px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #2db2bb;
  color: #FFF;
}

.ant-dropdown.has-close-icon .anticon-close svg {
  width: 14px;
  height: 14px;
}

.ant-dropdown.has-close-icon .ant-dropdown-menu {
  top: -20px;
  right: 10px;
  padding: 0;
}

.ant-dropdown.has-close-icon .ant-dropdown-menu li {
  color: #383838;
  font-size: 14px;
  line-height: 1.36;
  background-color: #fff !important;
  padding: 9px 33px 9px 15px;
}

body.rtl .ant-dropdown.has-close-icon .anticon-close {
  right: auto;
  left: -10px;
}

body.rtl .ant-dropdown.has-close-icon .ant-dropdown-menu {
  right: auto;
  left: 10px;
}

body.rtl .ant-dropdown.has-close-icon li {
  padding: 9px 15px 9px 33px;
}

.search-dropdown-holder ul > li {
  height: 45px;
  color: #414141;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 14px;
}

/******************links***************/
/*primary_link*/
.primary_link {
  color: #0793C7;
  display: inline-block;
  transition: 0.3s;
  font-size: 0.9375rem;
  text-transform: capitalize;
}

.primary_link:hover {
  text-decoration: underline;
}

.primary_link:active, .primary_link:focus {
  text-decoration: none;
}

.primary_link:disabled {
  color: red !important;
}

.primary_link:hover {
  color: #077aa3;
}

/*secondary_link*/
.secondary_link {
  color: #2db2bb;
  display: inline-block;
  transition: 0.3s;
  font-size: 0.9375rem;
  text-transform: capitalize;
}

.secondary_link:hover {
  text-decoration: underline;
}

.secondary_link:active, .secondary_link:focus {
  text-decoration: none;
}

.secondary_link:disabled {
  color: red !important;
}

.secondary_link:hover {
  color: #c30c00;
}

/*Grayscale_link*/
.Grayscale_link {
  color: #4b4b4b;
  display: inline-block;
  transition: 0.3s;
  font-size: 0.9375rem;
  text-transform: capitalize;
}

.Grayscale_link:hover {
  text-decoration: underline;
}

.Grayscale_link:active, .Grayscale_link:focus {
  text-decoration: none;
}

.Grayscale_link:disabled {
  color: red !important;
}

.Grayscale_link:hover {
  color: #000;
}

.ant-radio-group-outline .ant-radio-wrapper {
  color: #4b4b4b;
  font-size: 15px;
  font-weight: bold;
}

.ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner {
  width: 18px;
  height: 18px;
  border-width: 2px;
  border-color: #242424;
  color: #242424;
  box-shadow: none !important;
}

.ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner:after {
  background-color: #0793C7;
}

.ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  border-color: #0793C7;
}

.ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-checked + span {
  color: #0793C7;
}

body.rtl .ant-radio-group-outline .ant-radio-wrapper {
  direction: rtl;
}

body.rtl .ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner:after {
  transform: scale(1);
  opacity: 1;
}

.ant-radio-group-outline .ant-radio-wrapper:hover {
  color: #0793C7;
}

.ant-radio-group-outline .ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #0793C7;
}

.ant-radio-group-outline .ant-radio-wrapper:hover .ant-radio-inner:after {
  transform: scale(1);
  opacity: 1;
}

.ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-disabled .ant-radio-inner {
  background-color: #FFF;
}

.ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-disabled:hover .ant-radio-inner:after {
  opacity: 0;
}

.ant-checkbox-wrapper {
  color: #4b4b4b;
  font-size: 15px;
  font-weight: bold;
}

.ant-checkbox-wrapper .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-width: 2px;
  border-color: #242424;
  box-shadow: none !important;
}

.ant-checkbox-wrapper .ant-checkbox-inner.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  border-color: #0793C7;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color: #0793C7;
  border-color: #0793C7;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #0793C7;
}

.ant-checkbox-wrapper:hover .ant-checkbox + span {
  color: #0793C7;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled .ant-checkbox-inner {
  background-color: #FFF;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled:hover .ant-checkbox + span {
  color: rgba(0, 0, 0, 0.25);
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled:hover .ant-radio-inner:after {
  opacity: 0;
}

.ant-checkbox-wrapper.remove-checkbox .ant-checkbox-inner {
  border-radius: 3px;
  border: solid 2px #e2e2e2;
}

.ant-checkbox-wrapper.remove-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #c30c00;
  border-color: #c30c00;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #0793C7;
}

.ant-switch {
  min-width: 50px !important;
  height: 16px !important;
  margin-left: 5px !important;
  background-color: #e5e5e5;
}

.ant-switch::after {
  width: 25px;
  height: 25px;
  top: -6px;
  margin-left: -5px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #FAFAFA;
}

.ant-switch.ant-switch-checked {
  background-color: #80D698;
}

.ant-switch.ant-switch-checked::after {
  margin-left: 5px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #0793C7;
}

.switch-input-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.switch-input-holder span {
  font-size: 14px;
  font-weight: bold;
  color: #5a5a5a;
}

.switch-input-holder .ant-form-item {
  display: flex;
  align-items: center;
}

.switch-input-holder .ant-form-item .ant-form-item-label {
  position: static;
}

.switch-input-holder .ant-form-item .ant-form-item-label label {
  margin-right: 15px;
  font-size: 14px;
  min-width: 50px;
  text-align: left;
}

body.rtl .switch-input-holder .ant-form-item .ant-form-item-label label {
  margin-left: 15px;
  margin-right: 0;
  text-align: right;
}

.upload-input-wrapper .ant-form-item-label {
  top: 19px !important;
  left: 30px !important;
  pointer-events: none;
}

body.rtl .upload-input-wrapper .ant-form-item-label {
  right: 30px !important;
  left: auto !important;
}

.upload-input-wrapper .ant-form-item-label label {
  font-size: 17px !important;
  color: #989898 !important;
  pointer-events: none;
}

.upload-input-wrapper .ant-form-item-children > span {
  display: flex;
  flex-direction: column-reverse;
}

body.rtl .upload-input-wrapper .ant-upload-list-item-actions {
  right: 0;
  left: auto;
  transform: translate(-50%, -50%);
}

.upload-input-wrapper .ant-upload {
  width: 100% !important;
  border: none !important;
  background: none !important;
  height: auto !important;
}

.upload-input-wrapper .ant-upload button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 55px;
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #dadce0 !important;
  padding-right: 0 !important;
}

body.rtl .upload-input-wrapper .ant-upload button {
  padding-right: 10px !important;
}

.upload-input-wrapper .ant-upload button i {
  font-size: 22px;
  padding: 6px;
  background: #e0e0e0;
  border-radius: 50%;
  color: #000000;
}

.upload-input-wrapper .ant-upload-list .ant-upload-list-item {
  padding: 0;
}

.upload-input-wrapper .ant-upload-list .ant-upload-list-item-info {
  display: flex;
  align-items: center;
}

.upload-input-wrapper .ant-upload-list .ant-upload-list-item-info:after {
  top: 8px;
  right: 8px;
}

.upload-input-wrapper .ant-upload-list-item-uploading-text {
  display: none;
}

.upload-input-wrapper .ant-upload-list-item-name {
  font-size: 0;
}

.ant-input-number {
  border: none;
  border-bottom: 1px solid #dadce0;
  border-radius: 0;
  width: 100%;
  margin-top: 20px;
}

.ant-input-number.ant-input-number-focused {
  border-bottom: 1px solid #0793C7;
  outline: none !important;
  box-shadow: none !important;
}

.ant-input-number:hover {
  border-bottom: 1px solid #dadce0;
}

.ant-input-number ::-webkit-input-placeholder {
  color: #bcbcbc;
  font-size: 16px;
}

.ant-input-number .ant-input-number-input {
  padding: 0 !important;
}

body.rtl .ant-input-number .ant-input-number-input {
  text-align: right !important;
}

.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1 !important;
  border: none !important;
}

body.rtl .ant-input-number .ant-input-number-handler-wrap {
  right: unset !important;
  left: 0;
}

.ant-input-number .ant-input-number-handler-down {
  border: none !important;
}

.counter-input-holder {
  display: flex;
  flex-direction: column;
}

.counter-input-holder .counter-header {
  font-size: 14px;
  font-weight: bold;
  color: #5a5a5a;
  margin-bottom: 5px;
}

.counter-input-holder .ant-form-item {
  display: flex !important;
}

.counter-input-holder .ant-form-item .ant-input-number {
  min-height: 55px;
  min-width: 100px;
}

.counter-input-holder .ant-input-number-input {
  height: 100%;
  padding-top: 18px;
  text-align: left;
}

body.rtl .counter-input-holder .ant-input-number-input {
  text-align: right;
}

.counter-input-holder .ant-input-number-input-wrap {
  height: 100%;
  font-size: 16px;
  color: #242424;
}

.counter-input-holder .ant-input-number-handler-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 30px;
  padding-bottom: 8px;
  top: auto;
  bottom: 0;
  right: 0;
  border: 0;
  opacity: 1 !important;
}

body.rtl .counter-input-holder .ant-input-number-handler-wrap {
  left: 0;
  right: auto;
}

.counter-input-holder .ant-input-number-handler-wrap span {
  position: relative;
  height: 16px !important;
  width: 16px;
  font-size: 16px;
  border: 0;
  margin-right: 8px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #dadce0;
  border-radius: 2px;
}

.counter-input-holder .ant-input-number-handler-wrap span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.counter-input-holder .ant-input-number-handler-wrap span.ant-input-number-handler-up::after {
  content: "\002B";
}

.counter-input-holder .ant-input-number-handler-wrap span.ant-input-number-handler-down::after {
  content: "\2212";
}

.counter-input-holder .ant-input-number-handler-wrap span:last-of-type {
  margin-right: 0;
}

body.rtl .counter-input-holder .ant-input-number-handler-wrap span:last-of-type {
  margin-right: 20px;
  margin-left: 0;
}

.counter-input-holder .ant-input-number-handler-wrap span i {
  display: none !important;
}

.ant-input {
  height: 47px;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: transparent !important;
  padding: 0 16px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.7px;
  color: #000000;
}

.ant-input:-moz-placeholder {
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: normal;
  color: #00000080;
}

.ant-input::-moz-placeholder {
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: normal;
  color: #00000080;
}

.ant-input:-ms-input-placeholder {
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: normal;
  color: #00000080;
}

.ant-input::-webkit-input-placeholder {
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: normal;
  color: #00000080;
}

.ant-input:hover {
  outline: none;
}

.ant-input:focus {
  border-color: #0793C7;
  box-shadow: none;
  transition: 0.3s;
}

.ant-form-item {
  position: relative;
  outline: none;
}

.ant-form-item:focus-within label {
  color: #0793C7 !important;
}

.ant-form-item .ant-form-item-label {
  position: relative;
  line-height: unset;
  top: -6px;
  left: 0px;
  z-index: 1;
  transition: all ease 0.3s;
}

.ant-form-item .ant-form-item-label label {
  font-size: 16px;
  color: #7e7e7e;
  font-weight: normal;
}

.ant-form-item .ant-form-item-label label::before, .ant-form-item .ant-form-item-label label::after {
  display: none;
}

body.rtl .ant-form-item .ant-form-item-label {
  left: auto;
  right: 0px;
}

.ant-form-item .ant-form-item-control {
  outline: none;
}

.ant-form-item.ant-form-item-with-help {
  margin-bottom: 15px;
  color: #123456;
}

.ant-form-item.ant-form-item-with-help .ant-form-item-label label {
  color: #d83025 !important;
}

.ant-input-affix-wrapper,
.ant-form-item-control-wrapper {
  outline: none;
}

.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover,
.has-error .ant-input,
.has-error .ant-input:hover {
  border-bottom: solid 1px #d83025 !important;
  box-shadow: none !important;
}

.ant-form-item-control.has-error .ant-form-explain {
  font-size: 12px;
  font-weight: bold;
  color: #d83025;
  padding: 2px 0px 0 0px;
  text-align: left;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-right-width: 1px !important;
}

.ant-input[disabled] {
  background-color: #f2f2f2;
}

.ant-select .ant-select-selection {
  height: 38px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #242424;
  box-shadow: none;
  margin: 0;
}

.ant-select .ant-select-selection .ant-select-selection__rendered {
  margin: 0;
  padding: 10px;
  line-height: 20px;
  top: -2px;
}

.ant-select .ant-select-selection .ant-select-selection__placeholder {
  font-size: 16px;
  color: #a3a3a3;
  font-weight: 500;
}

.ant-select.ant-select-focused .ant-select-selection {
  box-shadow: none;
  transition: 0.3s;
}

.ant-select.ant-select-auto-complete .ant-select-selection {
  border-bottom: solid 1px #dadce0;
}

.ant-select.ant-select-auto-complete .ant-input {
  border: 0 !important;
  padding: 0 !important;
}

.ant-select.ant-select-auto-complete .ant-select-selection__placeholder {
  margin: 0;
}

.ant-input-search {
  height: 35px;
}

.ant-input-search .ant-input {
  height: 35px;
  padding-top: 10px !important;
  background-color: rgba(255, 255, 255, 0.47);
}

.ant-input-search .ant-input:-moz-placeholder {
  font-size: 14px;
  color: #949494;
}

.ant-input-search .ant-input::-moz-placeholder {
  font-size: 14px;
  color: #949494;
}

.ant-input-search .ant-input:-ms-input-placeholder {
  font-size: 14px;
  color: #949494;
}

.ant-input-search .ant-input::-webkit-input-placeholder {
  font-size: 14px;
  color: #949494;
}

.ant-input-search .ant-input-suffix i {
  font-size: 18px;
  color: #0793C7;
}

.ant-upload.ant-upload-select-picture-card {
  display: flex;
  border-radius: 50% !important;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
}

.ant-upload.ant-upload-select-picture-card img {
  height: 100%;
  border-radius: 50%;
}

.ant-upload.ant-upload-select-picture-card.ant-upload-disabled {
  opacity: 0.3;
}

body.rtl .ant-upload.ant-upload-select-picture-card {
  display: flex;
}

.ant-select-dropdown .ant-select-dropdown-menu {
  /* Track */
  /* Handle */
}

.ant-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar {
  width: 8px;
}

.ant-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.ant-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar-thumb {
  background: #747474;
  border-radius: 5px;
}

.ant-tooltip .ant-tooltip-inner {
  min-height: 23px;
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  background-color: #000000d9;
}

.ant-pagination li:hover {
  border-color: #0793C7;
}

.ant-pagination li:hover a {
  color: #0793C7 !important;
  border-color: #0793C7 !important;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #0793C7 !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #0793C7;
}

.ant-pagination .ant-pagination-jump-prev {
  order: -1;
  color: #000000;
}

.ant-pagination .ant-pagination-jump-next {
  order: 999;
  color: #000000;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
  display: none;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link-icon,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-link-icon {
  opacity: 1;
}

.ant-pagination .ant-pagination-jump-prev .anticon svg,
.ant-pagination .ant-pagination-jump-next .anticon svg {
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  font-weight: bold;
}

.ant-pagination .ant-pagination-jump-prev:hover .anticon svg,
.ant-pagination .ant-pagination-jump-next:hover .anticon svg {
  color: #0793C7 !important;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-bottom: 1px solid #0793C7 !important;
  transition: 0.3s;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #0793C7;
  border-bottom: 1px solid #0793C7 !important;
  transition: 0.3s;
}

.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #d83025;
  border-bottom: 1px solid #d83025 !important;
  transition: 0.3s;
}

.has-error .ant-select-selection {
  border-color: #d83025 !important;
  border-width: 2px !important;
}

.phone-input-holder .ant-form-item-label {
  height: fit-content;
  position: absolute;
  left: 60px;
}

.phone-input-holder .dlt-phone {
  display: none;
}

.phone-input-holder.has-delete .ant-form-item-children {
  display: flex;
  align-items: center;
}

.phone-input-holder.has-delete .dlt-phone {
  display: flex;
  cursor: pointer;
  margin-right: -20px;
}

.search-wrapper .ant-input-search .ant-input {
  min-width: 250px;
  padding-top: 0 !important;
  border-radius: 0;
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid #b6b6b6;
  padding: 0 4px !important;
}

.search-wrapper .ant-input-search .ant-input:focus, .search-wrapper .ant-input-search .ant-input:hover {
  border: 0 !important;
  border-bottom: 1px solid #0793C7 !important;
}

.search-wrapper .ant-input-search .ant-input:focus + .ant-input-suffix i, .search-wrapper .ant-input-search .ant-input:hover + .ant-input-suffix i {
  color: #0793C7;
  transform: scale(1.1) rotate(2deg);
}

.search-wrapper .ant-input-search .ant-input-suffix {
  right: 0;
}

.search-wrapper .ant-input-search .ant-input-suffix i {
  transition: all ease-in-out 0.3s;
  color: #b6b6b6;
}

.comment-textArea {
  border: solid 0.5px #cacaca !important;
  padding: 4px 11px !important;
}

body.rtl .comment-textArea {
  text-align: right;
}

.comment-textArea.ant-input:focus {
  border: solid 0.5px #cacaca !important;
}

.tel-wrapper {
  margin-bottom: 13px;
}

.tel-wrapper .react-tel-input {
  display: flex;
  align-items: stretch;
  flex-direction: row-reverse;
  height: 45px;
  outline: 0;
}

.tel-wrapper .react-tel-input .form-control {
  box-shadow: none;
  min-height: 47px !important;
  height: 47px;
}

.tel-wrapper .react-tel-input .flag-dropdown {
  position: static;
  width: 50px;
  height: 30px;
  margin-right: -53px;
  margin-top: 8px;
  z-index: 4;
  display: flex;
  border: none;
  background: transparent;
}

body.rtl .tel-wrapper .react-tel-input .flag-dropdown {
  margin-right: 0 !important;
  margin-left: -53px !important;
}

.tel-wrapper .react-tel-input .flag-dropdown:hover {
  background: transparent !important;
}

.tel-wrapper .react-tel-input .flag-dropdown.open {
  background: transparent !important;
}

.tel-wrapper .react-tel-input .form-control.open {
  z-index: unset !important;
}

.tel-wrapper .country-list {
  width: 100% !important;
  top: 40px;
}

.tel-wrapper .country-list .search {
  display: flex;
  align-items: center;
  padding-bottom: 0;
}

.tel-wrapper .country-list .search .search-emoji {
  display: none;
}

.tel-wrapper .country-list .country {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px !important;
  height: 35px;
}

.tel-wrapper .country-list .country .flag {
  margin-left: 10px;
}

body.rtl .tel-wrapper .country-list .country .flag {
  margin-left: 0;
  margin-right: 10px;
}

.react-tel-input input[type=text],
.react-tel-input input[type=tel] {
  width: 100% !important;
  min-height: 55px;
  padding-left: 60px !important;
}

.react-tel-input {
  min-height: 55px;
}

.react-tel-input .flag-dropdown {
  min-width: 50px;
}

.react-tel-input .selected-flag {
  padding: 0 0 0 11px;
}

.react-tel-input .flag-dropdown:hover .selected-flag {
  background-color: #eaeaea !important;
}

.react-tel-input .flag-dropdown.open-dropdown .selected-flag {
  background: #eaeaea !important;
  border-radius: 3px 0 0 0;
}

body.rtl .react-tel-input input[type=text],
body.rtl .react-tel-input input[type=tel] {
  padding-right: 60px !important;
}

.react-tel-input .flag-dropdown.open-dropdown {
  background: #eaeaea !important;
  border-radius: 3px 0 0 0;
}

.ant-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lottie-spinner {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  transform: scale(0.8);
}

.croping-modal .ant-modal-close .ant-modal-close-x {
  width: 28px;
  height: 56px;
  font-size: 16px;
  line-height: 34px;
}

.croping-modal.rounded .cropper-view-box,
.croping-modal.rounded .cropper-move {
  border-radius: 50%;
}

.croping-modal.rounded .ant-modal-close {
  top: 0px !important;
}

.croping-modal.rounded .ant-modal-close .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.pac-item {
  text-align: left !important;
}

body.rtl .pac-item {
  text-align: right !important;
}

body {
  scrollbar-width: thin;
  scrollbar-color: #0A4C95 #C2D2E4 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  -webkit-background-color: #888 !important;
  border-radius: 1ex;
  -webkit-border-radius: 1ex;
  box-shadow: 0px 1px 2px #888;
  -webkit-box-shadow: 0px 1px 2px #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

header.user-header {
  position: sticky;
  top: 0;
  z-index: 150;
  border-bottom: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  min-height: 60px;
  padding-left: 55px;
  padding-right: calc( 14px + 20px);
}

body.rtl header.user-header {
  padding-left: calc(14px + 20px);
  padding-right: 55px;
}

header.user-header p {
  font-size: 0.8125rem;
  font-weight: bold;
  letter-spacing: 1.3px;
  color: #0793C7;
}

body.rtl header.user-header p {
  letter-spacing: normal;
}

header.user-header .ant-input-search {
  position: absolute;
  background: #fff;
  width: 708px;
  height: 47px;
  border-radius: 25px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06);
  left: 30px;
}

body.rtl header.user-header .ant-input-search {
  right: 30px;
  left: unset;
}

header.user-header .ant-input-search.ant-input-affix-wrapper .ant-input {
  border-bottom: none !important;
}

header.user-header .ant-input-search.ant-input-affix-wrapper .ant-input:hover, header.user-header .ant-input-search.ant-input-affix-wrapper .ant-input:focus, header.user-header .ant-input-search.ant-input-affix-wrapper .ant-input:active {
  border-bottom: none !important;
}

header.user-header .ant-input-search .ant-input {
  padding: 0 15px !important;
}

header.user-header .ant-input-search .ant-input-suffix {
  right: 15px;
}

body.rtl header.user-header .ant-input-search .ant-input-suffix {
  right: auto;
  left: 15px;
}

@media only screen and (max-width: 769px) {
  header.user-header .ant-input-search {
    width: 150px;
  }
}

header.user-header .notify-holder {
  margin: 0 20px;
  cursor: pointer;
  position: relative;
}

header.user-header .notify-holder .notify-count {
  box-sizing: content-box;
  position: absolute;
  top: -6px;
  right: -6px;
  width: 17px;
  height: 17px;
  border-radius: 12px;
  border: solid 1.5px #f5f5f5;
  background-color: #0793C7;
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
}

body.rtl header.user-header .notify-holder .notify-count {
  left: -6px;
  right: auto;
}

header.user-header .profile-holder {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #e5e5e5;
  box-sizing: content-box;
  cursor: pointer;
}

header.user-header .profile-holder::after {
  content: "\2193";
  font-size: 10px;
  font-weight: 400;
  width: 20px;
  height: 17px;
  border-radius: 0 50% 50% 0;
  background-color: #e5e5e5;
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
}

body.rtl header.user-header .profile-holder::after {
  right: auto;
  left: -20px;
  border-radius: 50% 0 0 50%;
}

header.user-header .profile-holder img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

header.user-header .lang-btn {
  font-size: 16px;
  color: #077aa3;
  margin: 0 15px;
  cursor: pointer;
}

header.user-header .ant-divider {
  height: 35px;
  margin: 0;
}

header.grid-action-header {
  height: 66px;
  background-color: #f5f5f5;
  padding-left: 26px;
  padding-right: 14px;
}

body.rtl header.grid-action-header {
  padding-right: 26px;
  padding-left: 14px;
}

header.grid-action-header .action-btns {
  margin-left: 20px;
}

header.grid-action-header .action-btns > span {
  display: inline-flex;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 20px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

header.grid-action-header .action-btns > span:last-of-type {
  margin-right: 0;
}

body.rtl header.grid-action-header .action-btns > span:last-of-type {
  margin-left: 0;
}

header.grid-action-header .action-btns > span[disabled] {
  pointer-events: none;
}

header.grid-action-header .action-btns > span.duplicate-btn {
  background-image: url(../../assets/duplicate-icon-active.svg);
}

header.grid-action-header .action-btns > span.duplicate-btn[disabled] {
  background-image: url(../../assets/duplicate-icon.svg);
}

header.grid-action-header .action-btns > span.delete-btn {
  background-image: url(../../assets/delete-icon-active.svg);
}

header.grid-action-header .action-btns > span.delete-btn[disabled] {
  background-image: url(../../assets/delete-icon.svg);
}

header.grid-action-header .action-btns > span.archive-btn {
  background-image: url(../../assets/archive-icon-active.svg);
}

header.grid-action-header .action-btns > span.archive-btn[disabled] {
  background-image: url(../../assets/archive-icon.svg);
}

header.grid-action-header .action-btns > span.edit-btn {
  background-image: url(../../assets/edit-icon-active.svg);
}

header.grid-action-header .action-btns > span.edit-btn[disabled] {
  background-image: url(../../assets/edit-icon.svg);
}

body.rtl header.grid-action-header .action-btns > span {
  margin-left: 0px;
  margin-right: 20px;
}

header.grid-action-header .cascade-select {
  margin-left: 20px;
}

header.grid-action-header .cascade-select .ant-select-selection {
  height: 30px;
  width: 200px;
  padding: 5px !important;
  background: transparent;
}

header.grid-action-header .cascade-select .ant-select-selection__rendered {
  height: 100%;
  display: flex;
  align-items: center;
}

header.main-header {
  height: 70px;
  min-height: 70px;
  background-color: #ffffff;
  padding: 15px 35px;
  position: sticky;
  top: 0;
  z-index: 60;
  transition: background-color ease-in-out 0.4s;
}

header.main-header .logo {
  cursor: pointer;
}

header.main-header .dark-logo {
  display: block;
}

header.main-header .white-logo {
  display: none;
}

header.main-header .white-global {
  display: none;
}

header.main-header.active {
  transition: background-color ease-in-out 0.4s;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(48, 48, 51, 0.74);
}

header.main-header.active .dark-logo {
  display: none;
}

header.main-header.active .lang-btn {
  color: #ffffff;
}

header.main-header.active .white-logo {
  display: block;
}

header.main-header.active .white-global {
  display: inline-block;
}

header.main-header.active .white-global + img {
  display: none;
}

header.main-header.active ul > li a {
  color: #ffffff;
}

header.main-header .lang-btn {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.07;
  letter-spacing: 0.38px;
  color: #008539;
  outline: none !important;
  font-family: Cairo;
}

body.rtl header.main-header .lang-btn {
  font-family: Pridi;
  margin-left: auto;
}

@media only screen and (max-width: 1201px) {
  header.main-header .lang-btn {
    margin-top: -40px;
  }
}

header.main-header .links-wrapper button {
  font-size: 18px;
  font-weight: normal;
}

header.main-header ul > li {
  margin-right: 35px;
}

body.rtl header.main-header ul > li {
  margin-right: 0;
  margin-left: 35px;
}

header.main-header ul > li a {
  font-family: Pridi;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.22;
  color: #242430;
}

header.main-header ul > li a.active {
  font-weight: bold !important;
}

header.main-header.transparent-header {
  position: fixed;
  right: 0;
  left: 0;
  background-color: transparent;
}

header.main-header.transparent-header .dark-logo {
  display: none;
}

header.main-header.transparent-header .lang-btn {
  color: #ffffff;
}

header.main-header.transparent-header .white-logo {
  display: block;
}

header.main-header.transparent-header .white-global {
  display: inline-block;
}

header.main-header.transparent-header .white-global + img {
  display: none;
}

header.main-header.transparent-header ul > li a {
  color: #ffffff;
}

header.main-header.transparent-header.active {
  transition: background-color ease-in-out 0.4s;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(48, 48, 51, 0.74);
}

header.main-header .profile-holder {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: content-box;
  cursor: pointer;
  margin-right: 20px;
}

body.rtl header.main-header .profile-holder {
  margin-left: 20px;
  margin-right: 0;
}

header.main-header .profile-holder::after {
  content: url(../../resources/images/website/icon/play-button.svg);
  font-size: 10px;
  font-weight: 400;
  width: 15.1px;
  height: 8.8px;
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
}

body.rtl header.main-header .profile-holder::after {
  right: auto;
  left: -20px;
  border-radius: 50% 0 0 50%;
}

header.main-header .profile-holder.has-notifications::before {
  content: "";
  font-size: 10px;
  font-weight: 400;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #db4a41;
  position: absolute;
  top: 5px;
  right: -5px;
  transform: translateY(-50%);
}

body.rtl header.main-header .profile-holder.has-notifications::before {
  right: auto;
  left: -5px;
  border-radius: 50%;
}

header.main-header .profile-holder img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

header.main-header.res-header {
  padding: 10px 20px;
}

header.main-header.res-header .lang-btn {
  color: #008539 !important;
}

header.main-header.res-header .white-global {
  display: none;
}

header.main-header.res-header .white-global + img {
  display: inline-block;
}

header.main-header.res-header.active .btn2 .icon, header.main-header.res-header.transparent-header .btn2 .icon {
  background-color: #FFF;
}

header.main-header.res-header.active .btn2 .icon::after, header.main-header.res-header.active .btn2 .icon::before, header.main-header.res-header.transparent-header .btn2 .icon::after, header.main-header.res-header.transparent-header .btn2 .icon::before {
  background-color: #FFF;
}

header.main-header.res-header.active .btn2 .user-img-holder + .menu-icon-holder .icon, header.main-header.res-header.transparent-header .btn2 .user-img-holder + .menu-icon-holder .icon {
  background-color: #008539;
}

header.main-header.res-header.active .btn2 .user-img-holder + .menu-icon-holder .icon::after, header.main-header.res-header.active .btn2 .user-img-holder + .menu-icon-holder .icon::before, header.main-header.res-header.transparent-header .btn2 .user-img-holder + .menu-icon-holder .icon::after, header.main-header.res-header.transparent-header .btn2 .user-img-holder + .menu-icon-holder .icon::before {
  background-color: #008539;
}

header.main-header.res-header .logo img {
  width: 80%;
}

header.main-header.res-header ul > li {
  margin-right: 35px;
}

body.rtl header.main-header.res-header ul > li {
  margin-right: 0;
  margin-left: 35px;
}

header.main-header.res-header ul > li a {
  font-family: Pridi;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.22;
  color: #242430;
}

header.main-header.res-header .profile-info-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
}

header.main-header.res-header .profile-info-holder .img-holder {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

header.main-header.res-header .profile-info-holder .img-holder img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

header.main-header.res-header .profile-info-holder .details-holder {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

body.rtl header.main-header.res-header .profile-info-holder .details-holder {
  margin-right: 12px;
  margin-left: 0;
}

header.main-header.res-header .profile-info-holder .details-holder .name {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

header.main-header.res-header .profile-info-holder .details-holder a {
  font-size: 14px;
  font-weight: normal;
  line-height: 2.43;
  color: #008539;
  margin-right: 20px;
}

header.main-header.res-header .profile-info-holder .details-holder a:last-child {
  margin-right: 0;
}

header.main-header.res-header .profile-info-holder .notify-holder {
  position: relative;
  margin-right: 10px;
  align-self: start;
}

body.rtl header.main-header.res-header .profile-info-holder .notify-holder {
  margin-left: 10px;
  margin-right: 0;
}

body.rtl header.main-header.res-header .profile-info-holder .notify-holder:after {
  right: auto;
  left: 2px;
}

header.main-header.res-header .profile-info-holder .notify-holder:after {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #db4a41;
  position: absolute;
  top: 4px;
  right: 2px;
  border-radius: 50%;
}

header.main-header.res-header .links-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  align-items: flex-start;
  justify-content: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 80%;
  margin-left: auto;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.6);
  background-color: #f6f8f9;
  padding-bottom: 130px;
  transform: translateX(110%);
  transition: all 0.5s ease-in-out;
  z-index: 555;
}

body.rtl header.main-header.res-header .links-wrapper {
  transform: translateX(-110%);
  margin-right: auto;
  margin-left: unset;
  align-items: center;
}

body.rtl header.main-header.res-header .links-wrapper + .overlay {
  transform: translateX(100%);
}

header.main-header.res-header .links-wrapper + .overlay {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(92, 92, 92, 0.7);
  transition: all 0.5s ease-in-out;
  transform: translateX(-100%);
}

header.main-header.res-header .links-wrapper ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

header.main-header.res-header .links-wrapper ul > li {
  min-height: 50px;
}

header.main-header.res-header .links-wrapper ul > li a {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

header.main-header.res-header .links-wrapper ul > li a.active {
  font-weight: bold !important;
}

header.main-header.res-header .links-wrapper ul + button,
header.main-header.res-header .links-wrapper ul + a {
  width: 130px;
  min-height: 40px !important;
  border-radius: 29px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  bottom: -60px;
}

header.main-header.res-header .btn2 {
  width: 25px;
  height: 30px;
  top: 100px;
  left: 120px;
  transition-duration: 0.5s;
  z-index: 556;
  cursor: pointer;
}

header.main-header.res-header .btn2 .icon {
  transition-duration: 0.5s;
  position: absolute;
  height: 3px;
  width: 24px;
  top: 30px;
  background-color: #0793C7;
}

header.main-header.res-header .btn2 .icon:before {
  transition-duration: 0.5s;
  position: absolute;
  width: 24px;
  height: 3px;
  background-color: #0793C7;
  content: "";
  top: -8px;
}

header.main-header.res-header .btn2 .icon:after {
  transition-duration: 0.5s;
  position: absolute;
  width: 24px;
  height: 3px;
  background-color: #0793C7;
  content: "";
  top: 8px;
}

header.main-header.res-header .btn2 .user-img-holder {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  top: -5px;
  left: -30px;
  transition: opacity ease-in-out 0.4s;
}

body.rtl header.main-header.res-header .btn2 .user-img-holder {
  right: -30px;
  left: auto;
}

header.main-header.res-header .btn2 .user-img-holder img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

header.main-header.res-header .btn2 .user-img-holder + .menu-icon-holder {
  position: relative;
  height: 26px;
  width: 26px;
  background: #FFF;
  top: -28px;
  border-radius: 50%;
  left: -5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body.rtl header.main-header.res-header .btn2 .user-img-holder + .menu-icon-holder {
  right: -5px;
  left: auto;
}

body.rtl header.main-header.res-header .btn2 .user-img-holder + .menu-icon-holder .icon {
  right: 1px;
  left: auto;
}

header.main-header.res-header .btn2 .user-img-holder + .menu-icon-holder .icon {
  top: 11px;
  left: 1px;
  transform: scale(0.6);
}

header.main-header.res-header.open .btn2 .icon {
  transition-duration: 0.5s;
  background: transparent;
}

header.main-header.res-header.open .btn2 .icon:before {
  transform: rotateZ(45deg) scaleX(1.25) translate(6px, 4px);
  background-color: #555555;
}

header.main-header.res-header.open .btn2 .icon:after {
  transform: rotateZ(-45deg) scaleX(1.25) translate(5px, -4px);
  background-color: #555555;
}

header.main-header.res-header.open .user-img-holder {
  opacity: 0;
}

header.main-header.res-header.open .user-img-holder + .menu-icon-holder {
  box-shadow: none;
  background: transparent;
}

header.main-header.res-header.open .links-wrapper {
  transform: translateX(0);
  transition: all 0.5s ease-in-out;
}

header.main-header.res-header.open .links-wrapper + .overlay {
  transform: translateX(0);
  transition: all 0.5s ease-in-out;
}

body.rtl header.main-header.res-header.open .links-wrapper {
  transform: translateX(0);
}

body.rtl header.main-header.res-header.open .links-wrapper + .overlay {
  transform: translateX(0);
}

.user-menu-wrapper {
  padding: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  min-width: 170px;
  position: relative;
}

.user-menu-wrapper.web-dropdown::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border: 1em solid black;
  border-color: transparent transparent white white;
  right: 44px;
  top: -12px;
  transform: rotate(135deg);
  box-shadow: -2px 3px 3px -2px rgba(0, 0, 0, 0.16);
  z-index: 1;
}

body.rtl .user-menu-wrapper.web-dropdown::before {
  left: 22px;
  right: unset !important;
}

.user-menu-wrapper > li {
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 12px 32px 12px 23px;
  z-index: 2;
  position: relative;
}

.user-menu-wrapper > li a {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: bold;
  color: #565656;
}

.user-menu-wrapper > li:first-of-type {
  border: 1px solid #f5f5f5;
}

.user-menu-wrapper > li:first-of-type span {
  font-size: 9px;
  font-weight: bold;
  color: #565656;
  letter-spacing: 0.18px;
}

.user-menu-wrapper > li .link-d {
  font-size: 18px;
  line-height: 1.89;
  color: #5f5f5f;
  padding-inline-start: 13.5px;
}

.user-menu-wrapper > li .logout {
  color: #db4a41;
  line-height: 1.89;
  font-size: 18px;
  padding-inline-start: 13px;
}

.user-menu-wrapper > li:hover {
  background-color: #f0f3f8;
}

.user-menu-wrapper .profile-img {
  width: 54px;
  height: 54px;
  min-width: 54px;
  max-width: 54px;
  min-height: 54px;
  max-height: 54px;
  border-radius: 50%;
}

.user-menu-wrapper .profile-link-holder {
  padding-inline-start: 23px;
}

.user-menu-wrapper .profile-button {
  background-image: linear-gradient(to right, #5CAE30 16%, #86D958 115%);
  width: 109px;
  height: 25px;
  border-radius: 13px;
  color: white;
}

.user-menu-wrapper .user-name {
  font-size: 18px;
  line-height: 1.89;
  color: #000000;
}

.user-menu-wrapper .notification-count {
  width: 21px !important;
  height: 21px !important;
}

.notify-menu-wrapper {
  padding: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d6d6d6;
}

.notify-menu-wrapper header {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 18px;
  color: #ffffff;
  background-color: #0793C7;
}

.notify-menu-wrapper > li:not(:last-of-type) {
  min-height: 85px;
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 30px;
  background-color: rgba(57, 103, 169, 0.09) !important;
  border-bottom: 1px solid #d6d6d6;
}

.notify-menu-wrapper > li:not(:last-of-type) a {
  padding: 0;
  margin: 0;
  position: relative;
}

.notify-menu-wrapper > li:not(:last-of-type) a h6 {
  font-size: 14px;
  font-weight: bold;
  color: #242424;
}

.notify-menu-wrapper > li:not(:last-of-type) a span {
  font-size: 10px;
  font-weight: bold;
  color: #565656;
}

.notify-menu-wrapper > li:not(:last-of-type) a::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #0793C7;
  border-radius: 50%;
  position: absolute;
  left: -20px;
}

body.rtl .notify-menu-wrapper > li:not(:last-of-type) a::before {
  left: auto;
  right: -20px;
}

.notify-menu-wrapper > li:not(:last-of-type).seen {
  background-color: #ffffff !important;
}

.notify-menu-wrapper > li:not(:last-of-type).seen a::before {
  display: none;
}

body.rtl .notify-menu-wrapper > li:not(:last-of-type) {
  padding: 12px 30px 12px 12px;
}

.notify-menu-wrapper > li:last-of-type {
  min-height: 40px;
}

.notify-menu-wrapper > li:last-of-type a {
  padding: 0;
  margin: 0;
}

.notify-menu-wrapper > li:last-of-type a span {
  font-size: 12px;
  font-weight: bold;
  color: #565656;
}

.notify-menu-wrapper > li:last-of-type:hover {
  background-color: transparent;
}

.menu-root {
  left: 1023px !important;
  top: 73px !important;
}

body.rtl .menu-root {
  right: 1023px !important;
  top: 73px !important;
  left: unset !important;
}

aside.dashboard-menu {
  background-color: #0793C7;
  overflow: visible !important;
}

aside.dashboard-menu .anticon.trigger {
  position: absolute;
  z-index: 555;
  right: -28px;
  top: 21px;
}

body.rtl aside.dashboard-menu .anticon.trigger {
  left: -28px;
  right: auto;
}

aside.dashboard-menu .ant-menu {
  background: transparent !important;
  box-shadow: none !important;
}

aside.dashboard-menu .ant-menu > li {
  border-bottom: 1px solid #FFF;
  background: transparent;
  color: #ffffff;
  min-height: 77px;
  margin-bottom: 0 !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

aside.dashboard-menu .ant-menu > li a {
  color: #ffffff;
}

aside.dashboard-menu .ant-menu > li .ant-menu-submenu-title {
  min-height: 77px;
  line-height: 77px;
  margin: 0;
}

body.rtl aside.dashboard-menu .ant-menu > li .ant-menu-submenu-title {
  padding-right: 16px;
}

aside.dashboard-menu .ant-menu > li:last-of-type {
  border-bottom: none;
}

aside.dashboard-menu .ant-menu > li.ant-menu-submenu-open {
  background-color: #0793c738 !important;
  color: #0793C7 !important;
}

aside.dashboard-menu .ant-menu > li.ant-menu-submenu-open {
  padding-bottom: 30px;
}

aside.dashboard-menu .ant-menu > li.ant-menu-submenu-open .ant-menu-submenu-title {
  min-height: 50px;
  line-height: 50px;
}

aside.dashboard-menu .ant-menu > li.ant-menu-submenu-open.ant-menu-submenu-vertical {
  padding-bottom: 0;
}

aside.dashboard-menu .ant-menu .ant-menu-item {
  margin-top: 0;
}

aside.dashboard-menu .ant-menu .ant-menu-item.ant-menu-item-selected {
  background-color: #0793c738;
  position: relative;
}

aside.dashboard-menu .ant-menu .ant-menu-item.ant-menu-item-selected::after {
  contain: "";
  position: absolute;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: #FFF;
}

body.rtl aside.dashboard-menu .ant-menu .ant-menu-item.ant-menu-item-selected::after {
  right: 0;
  left: auto;
}

aside.dashboard-menu .ant-menu.ant-menu-vertical > li {
  color: #FFF;
}

aside.dashboard-menu .ant-menu.ant-menu-sub > li {
  height: 35px !important;
  min-height: 35px;
  line-height: 35px !important;
  border-bottom: none;
}

aside.dashboard-menu-light {
  overflow: visible !important;
  z-index: 551;
}

aside.dashboard-menu-light .student-icons {
  margin-right: 8px;
}

body.rtl aside.dashboard-menu-light .student-icons {
  margin-right: 0;
  margin-left: 8px;
}

@media only screen and (max-width: 641px) {
  body.rtl aside.dashboard-menu-light .student-icons {
    margin-right: 8px !important;
  }
}

aside.dashboard-menu-light .student-icons::before {
  color: #474747;
}

aside.dashboard-menu-light .student-icons.active::before {
  color: #0793C7;
}

aside.dashboard-menu-light .anticon.trigger {
  position: absolute;
  z-index: 555;
  right: -40px;
  top: 21px;
  font-size: 20px;
  color: #0793C7;
}

@media only screen and (max-width: 769px) {
  aside.dashboard-menu-light .anticon.trigger {
    display: none;
  }
}

body.rtl aside.dashboard-menu-light .anticon.trigger {
  left: -28px;
  right: auto;
  transform: rotate(180deg);
}

aside.dashboard-menu-light .ant-layout-sider-children > ul {
  border: 0;
}

aside.dashboard-menu-light .ant-layout-sider-children > ul li a {
  display: flex;
  align-items: center;
}

aside.dashboard-menu-light .ant-layout-sider-children > ul li .anticon {
  font-size: 20px;
}

aside.dashboard-menu-light .ant-layout-sider-children > ul li span {
  font-size: 16px;
  font-weight: 500;
  color: #474747;
}

aside.dashboard-menu-light .ant-layout-sider-children > ul.ant-menu-inline-collapsed li.ant-menu-item span {
  display: none;
}

aside.dashboard-menu-light .ant-layout-sider-children > ul.ant-menu-inline-collapsed .ant-menu-submenu-title span > span {
  display: none;
}

aside.dashboard-menu-light + .ant-layout > header {
  background-color: #F5F5F5;
}

aside .logo {
  text-align: center;
  height: 60px;
  padding-top: 15px;
  border-bottom: 1px solid #f9f9fc;
}

aside .user-profile-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

aside .user-profile-card .user-profile-card__avatar {
  width: 88px;
  height: 88px;
  border-radius: 50%;
}

aside .user-profile-card .user-profile-card__info {
  margin-top: 20px;
  font-family: Pridi;
  text-align: center;
}

aside .user-profile-card .user-profile-card__info h5 {
  color: #0793C7;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

aside .user-profile-card .user-profile-card__info p {
  font-size: 13px;
  line-height: 1.23;
  font-weight: normal;
  color: #6f6f6f;
}

.ant-menu-submenu-title:hover span i {
  color: #2DBB54 !important;
}

.ant-menu-submenu-popup .ant-menu-sub .ant-menu-item i {
  padding-inline-end: 5px;
}

.ant-table-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mid-grid .ant-table-body {
  height: 400px;
  overflow: auto;
}

.dashboard-wrapper .more-icon-holder i {
  font-size: 28px;
  cursor: pointer;
  color: #818385;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th.ant-table-selection-column {
  padding: 15px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) {
  width: 25%;
  text-align: center;
  line-height: 1.31;
  padding-right: 15px !important;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter {
  display: block;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter::after {
  content: '';
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div {
  justify-content: flex-end !important;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  content: url(../../resources/images/station/fuel.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  content: url(../../resources/images/station/car.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  content: url(../../resources/images/station/car-out.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div:after {
  content: url(../../resources/images/station/flag.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td .no-charger-out {
  color: #474747;
  font-size: 16px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:first-of-type {
  padding: 15px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(2) {
  text-align: left !important;
  color: #000000;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(2) {
  text-align: right !important;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(3) {
  color: #008539;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(4) {
  color: #c30707;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:not(:first-of-type) {
  width: 25%;
  text-align: center;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th.ant-table-selection-column {
  padding: 15px;
  display: none;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:last-of-type {
  padding: 0 45px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) {
  width: 24%;
  text-align: center;
  line-height: 1.31;
  padding-right: 15px !important;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter {
  display: block;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter::after {
  content: '';
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div, .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div {
  justify-content: flex-end;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  content: url(../../resources/images/customers/user.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  content: url(../../resources/images/customers/email.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  content: url(../../resources/images/customers/phone.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:first-of-type {
  padding: 15px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td.ant-table-selection-column {
  display: none;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(2), .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(3) {
  text-align: left !important;
  color: #000000;
}

body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(2), body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(3) {
  text-align: right !important;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:not(:first-of-type) {
  width: 24%;
  text-align: center;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .customer-holder {
  display: flex;
  align-items: center;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .customer-holder .img-holder {
  width: 35px;
  height: 35px;
  max-width: 35px;
  max-height: 35px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50%;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .customer-holder .img-holder img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder {
  color: #008539;
  text-transform: capitalize;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.inactive {
  color: #c30707;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.delivered {
  color: #2d6fbb;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.inprogress {
  color: #bbbb2d;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.requested {
  color: #2db2bb;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:last-of-type {
  width: 5%;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:last-of-type div::after {
  display: none;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  content: url(../../resources/images/customers/user.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  content: url(../../resources/images/customers/email.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  content: url(../../resources/images/customers/phone.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div:after {
  content: url(../../resources/images/customers/credit-card.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-body tr > td:last-of-type {
  width: 5%;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th.ant-table-selection-column {
  padding: 15px;
  display: none;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:last-of-type {
  padding: 0 45px;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) {
  width: 19%;
  text-align: center;
  line-height: 1.31;
  padding-right: 15px !important;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter {
  display: block;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter::after {
  content: '';
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  content: url(../../resources/images/customers/email.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  content: url(../../resources/images/customers/phone.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td:first-of-type {
  padding: 15px;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td.ant-table-selection-column {
  display: none;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td:not(:first-of-type) {
  width: 19%;
  text-align: center;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder {
  color: #008539;
  text-transform: capitalize;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.inactive {
  color: #c30707;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.delivered {
  color: #2d6fbb;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.inprogress {
  color: #bbbb2d;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.requested {
  color: #2db2bb;
}

.masterdata-wrapper .ant-table .ant-table-scroll .ant-table-thead tr > th:nth-child(1) {
  width: 2%;
}

.masterdata-wrapper .ant-table .ant-table-scroll .ant-table-thead tr > th:nth-child(2), .masterdata-wrapper .ant-table .ant-table-scroll .ant-table-thead tr > th:nth-child(3) {
  display: inline-block;
  width: 49%;
  max-width: 49%;
}

.masterdata-wrapper .ant-table .ant-table-scroll .ant-table-tbody tr > td:nth-child(1) {
  width: 2%;
}

.masterdata-wrapper .ant-table .ant-table-scroll .ant-table-tbody tr > td:nth-child(2), .masterdata-wrapper .ant-table .ant-table-scroll .ant-table-tbody tr > td:nth-child(3) {
  display: inline-block;
  width: 49%;
  max-width: 49%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  display: none;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) {
  padding: 12px 30px !important;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll tr > td:nth-child(2) {
  padding: 12px 30px !important;
}

.admin-modal-wrapper .ant-modal {
  width: 90% !important;
  max-width: 90%;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 641px) {
  .admin-modal-wrapper .ant-modal {
    width: 100% !important;
    max-width: 100%;
  }
}

.admin-modal-wrapper .ant-modal-content {
  height: 92%;
  width: 90%;
  margin: auto;
  top: 4%;
}

.admin-modal-wrapper .ant-modal-content .ant-modal-close-x {
  color: #d83025;
}

.admin-modal-wrapper .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 1.125rem;
  font-weight: bold;
  color: #077aa3;
}

body.rtl .admin-modal-wrapper .ant-modal-content .ant-modal-header {
  text-align: right !important;
}

.admin-modal-wrapper .ant-modal-content .ant-modal-body {
  border-radius: 0;
  height: calc(100% - 50px - 90px);
  overflow: auto;
  padding: 0;
}

.admin-modal-wrapper .ant-modal-content .ant-modal-footer {
  justify-content: flex-end;
}

.admin-modal-wrapper .ant-form {
  height: 100%;
}

.admin-modal-wrapper .Personal-info-wrapper {
  height: auto;
  overflow: auto;
  padding-top: 0 !important;
}

body.rtl .admin-modal-wrapper .Personal-info-wrapper {
  padding: 0px 50px;
}

.admin-modal-wrapper .Personal-info-wrapper h2 {
  font-weight: 400;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse {
  background-color: transparent;
  border: 0;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header {
  border: 0;
  box-shadow: none;
  background: transparent;
  font-size: 11px;
  font-weight: bold;
  color: #077aa3;
  width: fit-content;
  padding-left: 0;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header .ant-collapse-item {
  border: 0;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header p {
  width: fit-content;
  font-size: 11px;
  font-weight: bold;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header .ant-collapse-arrow {
  left: auto;
  right: 0;
  font-size: 9px;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-item {
  border: 0;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-content {
  border: 0px;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-content-box {
  padding: 0;
}

body.rtl .admin-modal-wrapper .Personal-info-wrapper .ant-switch {
  margin-right: 15px;
}

.admin-modal-wrapper .rules-wrapper {
  background-color: #f8f8f8;
  height: 100% !important;
  min-height: 450px;
}

body.rtl .admin-modal-wrapper .rules-wrapper {
  padding: 2%;
}

.admin-modal-wrapper .rules-wrapper h6 {
  color: #008539;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
}

.admin-modal-wrapper .rules-wrapper .remove-icon-btn {
  font-size: 11px;
  font-weight: normal;
  color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper {
  height: 300px;
  border: solid 0.8px #cacaca;
  background-color: #ffffff;
  padding: 10px 14px;
  overflow: auto;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper .content {
  display: flex;
  flex-direction: column;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper .content > .ant-checkbox-wrapper {
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  font-size: 15px;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper .content .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper .content .ant-checkbox-group > .ant-checkbox-wrapper {
  margin-bottom: 5px;
  color: #404040;
  font-size: 15px;
  font-weight: normal;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper {
  height: 220px;
  border: solid 0.8px #cacaca;
  background-color: #ffffff;
  padding: 10px 14px;
  overflow: auto;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content {
  display: flex;
  flex-direction: column;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-indeterminate .ant-checkbox-inner::after,
.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color: #d83025;
  border-color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-wrapper:hover .ant-checkbox + span {
  color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content > .ant-checkbox-wrapper {
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  font-size: 15px;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-group > .ant-checkbox-wrapper {
  margin-bottom: 5px;
  color: #404040;
  font-size: 15px;
  font-weight: normal;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d83025;
  border-color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .admins-preview-placeholder {
  text-align: center;
  font-size: 12px;
  color: #a4a4a4;
}

.admin-modal-wrapper .rules-wrapper .ant-form-item .ant-form-item-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.admin-modal-wrapper .rules-wrapper .ant-form-item .ant-form-item-label label {
  pointer-events: none;
}

.admin-modal-wrapper .rules-wrapper .action-btn-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: 5px 30px;
  cursor: pointer;
  border-top: 1px solid #cacaca;
}

body.rtl .admin-modal-wrapper .rules-wrapper .action-btn-holder {
  flex-direction: row-reverse;
}

.admin-modal-wrapper .rules-wrapper .action-btn-holder p {
  font-size: 16px;
  font-weight: 500;
  color: #474747;
}

.admin-modal-wrapper .rules-wrapper .action-btn-holder .add-btn {
  color: #008539;
}

.admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered ul > li:not(:last-of-type) {
  height: 0;
  margin-top: 0;
  line-height: 0;
  width: 0;
  opacity: 0;
  display: none;
}

.admin-modal-wrapper .rules-wrapper .error_holder {
  position: relative;
}

.admin-modal-wrapper .rules-wrapper .error_holder .ant-form-explain {
  position: absolute;
  top: -15px;
}

.admin-modal-wrapper .remove_holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-modal-wrapper .remove_holder h6 {
  padding-bottom: 0;
}

.admin-modal-wrapper .popup-medium {
  height: 70%;
  width: 50% !important;
}

.admin-modal-wrapper .select-admin-dropdown .ant-form-item-label label {
  font-size: 15px;
  font-weight: 400;
  color: #c4c4c4;
}

.modal-global-style .ant-modal-header {
  padding: 26px 55px;
}

.modal-global-style .ant-modal-title {
  font-size: 1.5625rem;
  font-weight: bold;
  color: #008539;
  font-weight: 600;
}

body.rtl .modal-global-style .ant-modal-close {
  right: unset !important;
}

.modal-global-style .ant-modal-close .ant-modal-close-x {
  color: #d83025;
}

.modal-global-style .ant-modal-footer {
  border: none !important;
}

.modal-global-style .ant-modal-footer button + button {
  margin-left: 12px;
}

body.rtl .modal-global-style .ant-modal-footer button + button {
  margin-left: 0 !important;
  margin-right: 32px;
}

.modal-global-style .ant-modal-header {
  border: none !important;
}

.modal-global-style .ant-modal-header .anticon {
  color: #c30707;
}

body.rtl .modal-global-style .ant-modal-header {
  text-align: right !important;
}

.role-holder .ant-modal-body h2 {
  font-weight: normal;
}

.ant-transfer .ant-transfer-list-search {
  padding-top: 0 !important;
  height: 32px;
}

.preview-licenses-modal {
  display: flex;
  align-items: center;
}

.preview-licenses-modal .ant-modal {
  top: 0;
}

.preview-licenses-modal .ant-modal-body {
  padding: 30px;
}

.preview-licenses-modal .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #d83025;
  font-size: 18px;
}

.add-admin-holder .ant-modal-header {
  padding-bottom: 10px;
}

.demo-wrapper {
  width: 85%;
  margin: auto;
}

.demo-wrapper > section {
  margin-top: 25px;
}

.demo-wrapper > section > h3 {
  margin-bottom: 20px;
}

.demo-wrapper .color-palette-wrapper .content {
  display: flex;
}

.demo-wrapper .color-palette-wrapper .content .panel {
  display: flex;
  width: 100%;
  border: 0;
  box-shadow: none;
}

.demo-wrapper .color-palette-wrapper .content .panel .sample {
  width: 190px;
  margin-left: 25px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.demo-wrapper .color-palette-wrapper .content .panel .sample:first-child {
  margin-left: 0px;
}

.demo-wrapper .color-palette-wrapper .color-text {
  min-height: 90px;
  background: #52565e;
  color: #fff;
  border-radius: 0 0 4px 4px;
  padding: 5px;
}

.demo-wrapper .color-palette-wrapper .color-text h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

.demo-wrapper .color-palette-wrapper .color-text p {
  display: flex;
  flex-wrap: wrap;
}

.demo-wrapper .color-palette-wrapper .color-text p span {
  cursor: pointer;
  font-size: 12px;
}

.demo-wrapper .color-palette-wrapper .color-swatch-split {
  display: flex;
  height: 150px;
}

.demo-wrapper .color-palette-wrapper .color-swatch-split > div:first-of-type {
  border-radius: 4px 0 0 0;
  width: 100%;
}

.demo-wrapper .color-palette-wrapper .color-swatch-split > div:last-of-type {
  border-radius: 0 4px 0 0;
}

.demo-wrapper .color-palette-wrapper .color-swatch-split-2 {
  width: 45px;
}

.assignment-card {
  display: flex;
  margin: 0 5px;
  cursor: pointer;
}

.assignment-card .assignment-card-details {
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  display: flex;
  margin-inline-end: 13px;
  padding: 8px 14px 8px 12px;
}

.assignment-card .assignment-card-details__teacher-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  min-height: 45px;
  min-width: 45px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 50%;
  padding: 5px;
  box-shadow: none;
  border: solid 0.5px #707070;
}

.assignment-card .assignment-card-details__teacher-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  object-fit: contain;
}

@media only screen and (max-width: 577px) {
  .assignment-card .assignment-card-details__teacher-img {
    display: none;
  }
}

.assignment-card .teacher-summary {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-inline-start: 5px;
}

.assignment-card .teacher-summary .assignment-card-details__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.assignment-card .teacher-summary .assignment-card-details__info .assignment-card-details__info--action {
  display: flex;
  align-items: center;
}

.assignment-card .teacher-summary .assignment-card-details__info .assignment-card-details__info--action h4 {
  font-size: 14px;
  font-weight: normal;
  line-height: 2.14;
  color: #868ea4;
  margin-inline-end: 10px;
}

.assignment-card .teacher-summary .assignment-card-details__info .assignment-card-details__info--action img {
  margin-right: 15px;
}

body.rtl .assignment-card .teacher-summary .assignment-card-details__info .assignment-card-details__info--action img {
  margin-left: 15px;
  margin-right: 0;
}

.assignment-card .teacher-summary .assignment-card-details__info .assignment-card-details__info--media i {
  margin-right: 12px;
}

.assignment-card .teacher-summary .assignment-card-details__info .assignment-card-details__info--media i:last-of-type {
  margin-right: 0;
}

.assignment-card .teacher-summary .assignment-card-details__subject {
  font-size: 14px;
  font-weight: 600;
  color: #2c363f;
}

.assignment-card .teacher-summary .assignment-card-details__subject span {
  font-weight: 400;
  margin: 0 5px;
}

.assignment-card .teacher-summary .assignment-card-description {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.42;
  color: #57595a;
  margin-top: 5px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.assignment-card .assignment-status {
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 8px;
  min-width: 100px;
}

.assignment-card .assignment-status span {
  font-size: 13px;
  font-weight: 500;
  color: #11b74f;
  margin-top: 10px;
}

.assignment-card .assignment-status.assignment-status--uncomplated {
  opacity: 0.81;
}

.assignment-card .assignment-status.assignment-status--uncomplated span {
  font-size: 11px;
  color: #b6bbc8;
}

.badge-num {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  line-height: 20px;
  background-color: #e22020;
  font-size: 11px;
  font-weight: normal;
  color: #ffffff;
  border-radius: 50%;
}

.student-breadcrumb span {
  cursor: pointer;
}

.student-breadcrumb .opened-page {
  font-size: 16px;
  font-weight: 600;
  color: #0793c7;
}

.student-breadcrumb .opened-page.no-separator + .ant-breadcrumb-separator {
  display: none;
}

.student-breadcrumb .ant-breadcrumb-separator {
  font-size: 20px;
  font-weight: 900;
}

.student-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: fixed;
  bottom: 0px;
  right: calc(50% - 41px);
  z-index: 10;
  padding: 10px;
  margin: 10px;
  border-radius: 15px;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  background-color: white;
}

.student-pagination.exam {
  box-shadow: unset;
  background-color: unset;
  right: calc(50% - 160px);
}

@media only screen and (max-width: 769px) {
  .student-pagination.exam {
    right: calc(50% - 80px);
  }
}

.student-pagination.exam .ant-pagination-item {
  background-color: #fafafa;
  color: #2699fb;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
}

.student-pagination.exam .ant-pagination-item-link {
  background-color: #fafafa;
  color: #2699fb;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
}

.student-pagination.exam .ant-pagination-item-active {
  background-color: #2699fb;
  color: #fafafa;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.student-pagination.exam .ant-pagination-item-active a {
  color: #fafafa;
}

.contnet-download-modal {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.contnet-download-modal.active {
  box-shadow: inset 0px 0px 14px 0px #dadada;
  background-color: #def1f8;
}

.downloads-holder {
  display: flex;
  overflow-x: auto;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 30%;
}

.downloads-holder .contnet-download {
  width: 180px;
  height: 121px;
  position: relative;
  text-align: center;
  padding: 10px;
}

.downloads-holder .contnet-download .poster {
  width: 100%;
  height: 100%;
  max-height: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
  backdrop-filter: blur(3px);
  opacity: 0.8;
  object-fit: contain;
}

.downloads-holder .contnet-download .photo-video-download {
  cursor: pointer;
  height: 100px;
  width: 100%;
}

.downloads-holder .contnet-download .photo-video-download video {
  position: relative;
  object-fit: cover;
}

.downloads-holder .contnet-download .play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.downloads-holder .download-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.downloads-holder .download-info p {
  font-size: 11px;
  color: #3c3c38;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.downloads-holder .download-info div {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.photo-video-holder {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 70vh;
  max-width: 100%;
}

@media only screen and (max-width: 993px) {
  .photo-video-holder {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
    max-width: 100%;
  }
}

.photo-video-holder .contnet-download {
  height: 121px;
  padding: 10px;
  display: flex;
  position: relative;
  text-align: center;
  margin: 10px;
}

.photo-video-holder .contnet-download .poster {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
  backdrop-filter: blur(3px);
  opacity: 0.8;
  object-fit: contain;
}

.photo-video-holder .contnet-download .photo-video-download {
  width: 350px;
  cursor: pointer;
  position: relative;
}

@media only screen and (max-width: 993px) {
  .photo-video-holder .contnet-download .photo-video-download {
    width: 200px;
  }
}

.photo-video-holder .contnet-download .photo-video-download video {
  position: relative;
  object-fit: cover;
}

.photo-video-holder .contnet-download .photo-video-icon {
  transform: translate(-50%, -50%);
}

.photo-video-holder .contnet-download .play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.photo-video-holder .download-info {
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
}

.photo-video-holder .download-info p {
  font-size: 11px;
  color: #3c3c38;
  width: 120px;
  height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

@media only screen and (max-width: 993px) {
  .photo-video-holder .download-info p {
    font-size: 12px;
    color: #3c3c38;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
}

.photo-video-holder .download-info div {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.attachment-details-holder {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 70vh;
  max-width: 100%;
  border-left: 9px solid #e3dfdf;
}

body.rtl .attachment-details-holder {
  border-left: unset;
  border-right: 9px solid #e3dfdf;
}

@media only screen and (max-width: 993px) {
  .attachment-details-holder {
    border-left: unset;
    border-right: unset;
    border-top: 9px solid #e3dfdf;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .attachment-details-holder {
    border-top: 1px solid #e3dfdf;
    padding-top: 15px;
  }
}

.attachment-details-holder.flex {
  display: flex;
  border: unset !important;
}

@media only screen and (max-width: 993px) {
  .attachment-details-holder {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
    max-height: 70vh;
    max-width: 100%;
  }
}

.attachment-details-holder .contnet-download {
  width: 200px !important;
  flex-basis: 100%;
  display: flex;
  position: relative;
  text-align: center;
  margin: 10px;
}

@media only screen and (max-width: 993px) {
  .attachment-details-holder .contnet-download {
    width: 90% !important;
  }
}

@media only screen and (max-width: 993px) {
  .attachment-details-holder .contnet-download {
    margin: 0px 10px;
  }
}

.attachment-details-holder .contnet-download .poster {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
  backdrop-filter: blur(3px);
  opacity: 0.8;
  object-fit: contain;
}

.attachment-details-holder .contnet-download .photo-video-download {
  width: 100%;
  height: 100px;
  cursor: pointer;
  position: relative;
}

.attachment-details-holder .contnet-download .photo-video-download img {
  object-fit: cover;
}

.attachment-details-holder .contnet-download .photo-video-download video {
  position: relative;
  object-fit: cover;
}

.attachment-details-holder .contnet-download .photo-video-icon {
  transform: translate(-50%, -50%);
}

.attachment-details-holder .contnet-download .play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.attachment-details-holder .download-info {
  display: flex;
  width: 200px;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 993px) {
  .attachment-details-holder .download-info {
    width: 100%;
  }
}

.attachment-details-holder .download-info p {
  font-size: 11px;
  color: #3c3c38;
  width: 120px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

@media only screen and (max-width: 993px) {
  .attachment-details-holder .download-info p {
    font-size: 12px;
    color: #3c3c38;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
}

.attachment-details-holder .download-info div {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.attachment-details-holder .download-info div img {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-thumb {
  background-color: #0793c7;
}

::-webkit-scrollbar {
  width: 5px;
}

.media_name {
  display: none !important;
}

@media only screen and (max-width: 993px) {
  .media_name {
    padding: 20px;
    margin: 10px;
    color: #313131;
    display: block !important;
  }
}

@media only screen and (max-width: 577px) {
  .media_name {
    padding: 20px;
    margin: 10px;
    display: block !important;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    text-align: center;
  }
}

.media_content_container {
  background-color: #fff;
  border: 9px solid #e3dfdf;
}

.video-modal .video-react-video {
  pointer-events: none;
}

.video-modal .ant-modal-close-x {
  width: 44px;
  height: 44px;
  line-height: 37px;
}

@media only screen and (max-width: 993px) {
  .video-modal .ant-modal-close-x {
    width: 30px;
    height: 30px;
    line-height: 25px;
  }
}

.video-modal .ant-modal-close {
  top: -20px;
  right: -20px;
  /* top: 13px;
    right: 15px; */
  background: #707070 !important;
  border-radius: 50%;
  color: #f9f9fc;
  border-radius: 50%;
}

@media only screen and (max-width: 993px) {
  .video-modal .ant-modal-close {
    top: 10px;
    right: 10px;
  }
}

.ant-modal-close {
  color: #222;
}

.ant-modal-body {
  padding: 0px;
}

.anticon {
  width: 14px;
  height: 14px;
}

.photo_video_wrapper_col {
  display: flex;
  align-items: center;
  min-height: 70vh;
  max-height: 70vh;
}

@media only screen and (max-width: 769px) {
  .photo_video_wrapper_col {
    min-height: 50vh;
  }
}

.video-wraper .video-player-player {
  padding-top: 0 !important;
  height: 70vh;
}

.video-wraper .contain {
  object-fit: contain;
}

.video-wraper .cover {
  object-fit: cover;
}

.second-part {
  padding: 0 21px;
}

.second-part .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.second-part .header .title {
  font-size: 14px;
  font-weight: 500;
  color: #2c363f;
  padding-top: 0;
}

.second-part .download-link {
  font-size: 14px;
  font-weight: 600;
  color: #0793C7;
}

.files-uploaded-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 12px;
}

.files-uploaded-wrapper .file-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  max-width: 230px;
  border-radius: 6px;
  border: solid 1px #d2dade;
  background-color: #ffffff;
  padding: 6px 4px;
  margin-inline-end: 10px;
  margin-bottom: 10px;
}

.files-uploaded-wrapper .file-card .file-type {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  max-width: 36px;
  max-height: 36px;
  border-radius: 50%;
  padding: 5px;
  box-shadow: none;
  background-color: #ededed;
}

.files-uploaded-wrapper .file-card .file-type img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  object-fit: contain;
}

.files-uploaded-wrapper .file-card .file-info {
  display: flex;
  flex-direction: column;
  margin: 0 8px;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.files-uploaded-wrapper .file-card .file-info p {
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.files-uploaded-wrapper .file-card .file-info span {
  font-size: 12px;
  font-weight: normal;
  color: #acb3c5;
}

.files-uploaded-wrapper .file-card i {
  color: #acb3c5;
}

.attachment-holder .attachment-holder__actions {
  display: flex;
  justify-content: flex-end;
  margin: auto;
}

.attachment-holder .attachment-holder__actions .attachment-icon {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #e6e6e6;
  color: #575757;
  margin: 10px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  cursor: pointer;
}

.attachment-holder .attachment-holder__actions .ant-divider {
  width: 2px;
  height: 27px;
  margin: 0px;
}

.attachment-holder .attachment-holder__actions .upload-errors {
  background: #fff;
  color: #2db2bb;
}

.attachment-holder .attachment-holder__actions .upload-errors p {
  display: inline-block;
  margin: 0 10px;
  vertical-align: top;
  text-decoration: underline;
  cursor: pointer;
}

.attachment-holder .attachment-holder__actions .upload-errors .anticon-info-circle {
  vertical-align: middle;
}

.attachment-holder__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 20px;
}

@media only screen and (max-width: 769px) {
  .attachment-holder__list {
    grid-template-columns: 1fr;
  }
}

.attachment__holder--data > li {
  height: 53px;
  border-radius: 4px;
  border: solid 1px #cccccc;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  position: relative;
}

.attachment__holder--data .file__name--modal {
  display: flex;
  align-items: center;
  height: 100%;
}

.attachment__holder--data .remove-btn {
  /*    .ant-modal-confirm {
      .ant-modal-confirm-body {
          text-align: center;
          i {
              display: none;
          }
      }
  } */
  display: block;
  line-height: 0.8;
  position: absolute;
  right: 7px;
  top: 15px;
  font-size: 31px;
  color: #c3b1b1;
  cursor: pointer;
  font-weight: 300;
}

body.rtl .attachment__holder--data .remove-btn {
  left: 7px;
  right: unset;
}

.attachment__holder--data .ant-progress-text {
  position: absolute;
  top: -19px;
  right: 30px;
  width: 15px;
  font-size: 10px;
}

.attachment__holder--data .ant-progress-outer {
  padding-right: 0;
}

.attachment__holder--data .progress-container {
  display: flex;
  position: absolute;
  bottom: -9px;
  width: 100%;
}

.attachment__holder--data .attachment--file__name {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.29;
  color: #2a7d9c;
  margin: 0;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.attachment__holder--data .file-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: transparent;
  width: 50px;
  border-radius: 4px;
}

.upload-area-holder {
  height: 57px;
  margin-right: auto !important;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 12px;
  margin-bottom: 10px;
}

.upload-area-holder .ant-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.upload-area-holder .ant-upload > span {
  font-size: 9px;
  font-weight: normal;
  color: #929191;
}

.upload-area-holder .ant-upload > button {
  width: 108px;
  height: 29px;
  border-radius: 4px;
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
  font-family: Lato;
  font-size: 11px;
  color: #9c9595;
}

.upload-area-holder.quiz_uploader--style {
  height: auto;
  border-radius: 0;
  border: none;
  padding: 0;
  margin-bottom: 0;
}

.upload-area-holder.quiz_uploader--style .ant-upload > button {
  border-right: 1px solid #cccccc !important;
  margin-right: 27px !important;
}

.attachment-wrapper,
.post-action {
  display: flex;
  align-items: center;
}

.attachment-wrapper .text-counter,
.post-action .text-counter {
  font-size: 13px;
  color: #283e4a;
}

.attachment-wrapper--type {
  display: flex;
  align-items: center;
  border-right: 1px solid #d8d4e1;
  padding-right: 8px;
  margin-right: 8px;
}

body.rtl .attachment-wrapper--type {
  border-left: 1px solid #d8d4e1;
  border-right: 0;
  padding-left: 8px;
  margin-left: 8px;
  padding-right: 0;
  margin-right: 0;
}

.attachment-wrapper--type.attachment-wrapper--type__file {
  border: 0 !important;
}

.attachment-wrapper--type__text {
  font-size: 12px;
  color: #283e4a;
  margin-left: 6px;
}

body.rtl .attachment-wrapper--type__text {
  margin-right: 6px;
  margin-left: 0;
}

@media only screen and (max-width: 577px) {
  .attachment-wrapper--type__text {
    display: none;
  }
}

.react-mathjax-preview-result figure img {
  max-width: 500px;
}

.react-mathjax-preview-result ul {
  list-style: inside !important;
  list-style-position: inside;
  padding: revert;
}

.react-mathjax-preview-result ol {
  list-style: decimal !important;
  list-style-position: inside;
  padding: revert;
}

br[data-cke-filler] {
  display: none;
}

.ck-editor__main {
  line-height: 30px;
}

.rtl .ant-pagination.mini .ant-pagination-prev,
.rtl .ant-pagination.mini .ant-pagination-next {
  transform: rotate(0) !important;
}

footer.web-footer * {
  font-family: Pridi;
}

footer.web-footer .footer-top-wrapper {
  padding: 11% 13% 5% 13%;
  background: url(../../resources/images/website/D15A7888_forprint.png) no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-attachment: fixed;
  position: relative;
}

footer.web-footer .footer-top-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.67);
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  left: 0;
}

footer.web-footer .footer-top-wrapper .logo-holder {
  display: flex;
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
}

@media only screen and (max-width: 769px) {
  footer.web-footer .footer-top-wrapper .logo-holder {
    justify-content: center;
  }
}

footer.web-footer .footer-top-wrapper .content {
  position: relative;
  z-index: 1;
  display: flex;
}

@media only screen and (max-width: 769px) {
  footer.web-footer .footer-top-wrapper .content {
    flex-direction: column;
  }
  footer.web-footer .footer-top-wrapper .content i {
    display: block !important;
    transform: rotate(-90deg);
  }
}

footer.web-footer .footer-top-wrapper .content > div {
  flex: 1;
}

footer.web-footer .footer-top-wrapper .content > div h4 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  white-space: nowrap;
}

footer.web-footer .footer-top-wrapper .content > div h4 i {
  display: none;
  transition: all ease 0.4s;
}

footer.web-footer .footer-top-wrapper .content > div .links-holder {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  transition: max-height ease 0.4s;
}

footer.web-footer .footer-top-wrapper .content > div .links-holder * {
  margin-bottom: 8px;
}

@media only screen and (max-width: 769px) {
  footer.web-footer .footer-top-wrapper .content > div .links-holder {
    max-height: 0;
    overflow: hidden;
    transition: max-height ease 0.4s;
    margin-top: 14px;
    margin-bottom: 12px;
  }
}

footer.web-footer .footer-top-wrapper .content > div a, footer.web-footer .footer-top-wrapper .content > div address {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.71;
}

@media only screen and (max-width: 769px) {
  footer.web-footer .footer-top-wrapper .content > div a, footer.web-footer .footer-top-wrapper .content > div address {
    font-weight: normal;
  }
}

footer.web-footer .footer-top-wrapper .content > div.active i {
  transition: all ease 0.4s;
  transform: rotate(0);
}

footer.web-footer .footer-top-wrapper .content > div.active .links-holder {
  transition: max-height ease 0.4s;
  max-height: 180px;
  height: auto;
}

footer.web-footer .footer-bottom-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.17;
  letter-spacing: 0.35px;
  color: #ffffff;
  height: 50px;
  background-color: #242430;
  padding: 10px;
}

@media only screen and (max-width: 769px) {
  footer.web-footer .footer-bottom-wrapper {
    font-size: 13px;
  }
}

#root {
  height: 100%;
}

.auth_bg {
  height: 100%;
  background-color: #ffffff;
}

.auth_bg .signIn-content-wrapper {
  width: 40%;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

body.rtl .auth_bg .signIn-content-wrapper {
  margin-right: auto;
  transform: scaleX(-1) translateY(-50%);
}

.auth_bg .signIn-content-wrapper .logo-img {
  max-width: 280px;
}

.auth_bg .signIn-content-wrapper .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 52px;
}

body.rtl .auth_bg .signIn-content-wrapper .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-right: 52px;
  padding-left: 16px;
}

@media only screen and (max-width: 577px) {
  .auth_bg .signIn-content-wrapper .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 45px;
  }
  body.rtl .auth_bg .signIn-content-wrapper .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-right: 45px;
    padding-left: 16px;
  }
}

.auth_bg .signIn-content-wrapper .ant-input-affix-wrapper .ant-input-prefix {
  left: 20px;
  font-size: 16px;
}

body.rtl .auth_bg .signIn-content-wrapper .ant-input-affix-wrapper .ant-input-prefix {
  left: auto;
  right: 20px;
}

.auth_bg.website-auth-wrapper {
  background-image: url(../../resources/images/website/bannerweb.png);
}

.auth_bg.website-auth-wrapper .overlay {
  opacity: 0.48;
}

.auth_bg.website-auth-wrapper .center_div .logo-img {
  display: none;
}

.auth_bg.website-auth-wrapper .center_div .title {
  font-size: 23px;
}

.auth_bg.website-auth-wrapper .center_div .sammary {
  padding-top: 22px;
}

.auth_bg.website-auth-wrapper .center_div .login-form-forgot {
  font-size: 13px;
  text-decoration: none;
  font-weight: normal;
}

.auth_bg.website-auth-wrapper .center_div .ant-input-affix-wrapper .ant-input-prefix {
  display: flex;
  font-size: 20px;
}

.auth_bg.website-auth-wrapper .center_div .ant-form-item input {
  padding-top: 0 !important;
  height: 42px;
  margin: 0;
  padding-left: 40px !important;
}

body.rtl .auth_bg.website-auth-wrapper .center_div .ant-form-item input {
  padding-right: 40px !important;
  padding-left: 0 !important;
}

.auth_bg.website-auth-wrapper.rest-password-wrapper .ant-form-item .ant-form-item-label label {
  display: none;
}

.auth_bg.website-auth-wrapper.rest-password-wrapper .ant-form-item .ant-form-item-control {
  position: relative;
}

.auth_bg.website-auth-wrapper.rest-password-wrapper .ant-form-item .ant-form-item-control::before {
  content: url("../../resources/images/website/icon/password.svg");
  position: absolute;
  top: 6px;
  left: 10px;
}

body.rtl .auth_bg.website-auth-wrapper.rest-password-wrapper .ant-form-item .ant-form-item-control::before {
  right: 10px;
  left: auto;
}

@media only screen and (max-width: 481px) {
  .auth_bg.website-auth-wrapper.rest-password-wrapper .center_div .title {
    font-size: 20px !important;
  }
}

.center_div {
  position: relative;
  background: #fff;
  width: 85%;
  height: 90%;
  margin: auto;
  padding: 50px;
  border-radius: 4px;
  box-shadow: 0 3px 80px 0 rgba(0, 0, 0, 0.1);
  background: #fff url("../../resources/images/student/login_bg.svg") no-repeat;
  background-size: cover;
}

body.rtl .center_div {
  transform: scaleX(-1);
}

.center_div .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.1;
  background-color: #000000;
  display: none;
}

.center_div .title {
  padding-top: 25px;
  font-size: 19px;
  font-weight: normal;
  text-align: center;
  color: #000000;
}

.center_div .login-form-forgot {
  text-decoration: underline;
}

@media only screen and (max-width: 993px) {
  .center_div {
    width: 90%;
    padding: 50px 25px;
    background-position: center;
  }
}

@media only screen and (max-width: 769px) {
  .center_div .signIn-content-wrapper {
    width: 50%;
  }
}

@media only screen and (max-width: 577px) {
  .center_div {
    padding: 0;
    width: 100%;
    height: 100%;
    background: #00bfdc;
  }
  .center_div .overlay {
    display: block;
  }
  .center_div .signIn-content-wrapper {
    width: 90%;
    background: white;
    padding: 30px;
    margin: auto;
  }
}

.pt-2 {
  padding-top: 20px;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-4 {
  padding-bottom: 40px;
}

.mr-2 {
  margin-right: 20px;
}

body.rtl .mr-2 {
  margin-left: 20px;
}

.title {
  font-size: 25px;
  font-weight: bold;
  color: #4b4b4b;
  padding-top: 14px;
}

.lang_style {
  font-size: 16px;
  color: #0793C7;
  text-transform: capitalize;
}

.rem_color {
  color: #0793C7 !important;
}

.sammary {
  font-size: 14px;
  color: #565656;
  line-height: 1.21;
  padding-top: 10px;
}

.size17 {
  font-size: 17px;
  color: #565656;
  line-height: 1.24;
}

.black {
  color: #000;
}

.graytitle {
  color: #a3a3a3;
  font-size: 45px;
}

.ligt_black {
  color: #242424;
}

.weight-600 {
  font-weight: 600;
}

.font-19 {
  font-size: 19px;
}

.ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: 15px;
}

.rtl .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  left: 15px !important;
  right: auto !important;
}

.withOut_icons .ant-form-item-children-icon {
  display: none;
}

.reset_holder .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: 30px;
}

body.rtl .reset_holder .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: auto !important;
  left: 30px !important;
}

.ant-form-item-control.has-error .ant-form-explain {
  padding-left: 0px;
}

body.rtl .ant-form-item-control.has-error .ant-form-explain {
  padding-left: 0 !important;
  padding-right: 0px !important;
}

.dashboard-wrapper {
  overflow: hidden;
  height: 100vh;
}

@media only screen and (max-width: 993px) {
  .dashboard-wrapper {
    height: 100%;
  }
}

.dashboard-wrapper .ant-layout {
  height: 100%;
  background-color: #f9f9fc;
}

.dashboard-wrapper .ant-table-wrapper {
  overflow: auto;
}

.dashboard-wrapper .ant-table-wrapper .ant-pagination {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #ededed;
  margin: 0;
}

.dashboard-wrapper .ant-table-wrapper .ant-spin-nested-loading,
.dashboard-wrapper .ant-table-wrapper .ant-spin-container {
  height: 100%;
}

.dashboard-wrapper .ant-table-wrapper .ant-spin-nested-loading .ant-spin,
.dashboard-wrapper .ant-table-wrapper .ant-spin-container .ant-spin {
  top: 50%;
  left: 50%;
}

.dashboard-wrapper .ant-table-wrapper .ant-spin-nested-loading .lottie-spinner,
.dashboard-wrapper .ant-table-wrapper .ant-spin-container .lottie-spinner {
  position: relative;
  top: 0;
  left: 0;
}

.dashboard-wrapper .ant-table-wrapper .ant-table-filter-dropdown .ant-input {
  height: 30px;
  padding: 5px !important;
}

.dashboard-wrapper .ant-table-scroll .ant-table-header {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 0 !important;
  overflow: auto;
  max-height: 44px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.dashboard-wrapper .ant-table-scroll .ant-table-thead th:not(.ant-table-selection-column) {
  font-size: 16px;
  font-weight: 600;
  color: #474747;
  text-transform: capitalize;
}

.dashboard-wrapper .ant-table-scroll .ant-table-thead th:not(.ant-table-selection-column):last-of-type {
  border: none;
}

.dashboard-wrapper .ant-table-scroll .ant-table-body {
  overflow: auto !important;
  max-height: calc( 100vh - 230px) !important;
  min-height: calc(100vh - 230px) !important;
  /* Track */
  /* Handle */
}

.dashboard-wrapper .ant-table-scroll .ant-table-body::-webkit-scrollbar {
  width: 8px;
}

.dashboard-wrapper .ant-table-scroll .ant-table-body::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.dashboard-wrapper .ant-table-scroll .ant-table-body::-webkit-scrollbar-thumb {
  background: #0793C7;
  border-radius: 5px;
}

.dashboard-wrapper .ant-table-scroll .ant-table-tbody > tr:nth-child(even) {
  background-color: #f4f4f4;
}

.dashboard-wrapper .ant-table-scroll .ant-table-tbody > tr:hover > td {
  background: transparent;
}

.dashboard-wrapper .ant-table-scroll .ant-table-tbody > tr > td {
  border: 0;
  padding: 9px 16px;
  font-size: 16px;
  font-weight: 600;
  color: #474747;
}

.dashboard-wrapper .setting-wrapper {
  padding: 3.125rem 4.375rem;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  text-align: center;
}

.dashboard-wrapper .setting-wrapper h3 {
  text-align: left;
  color: #077aa3;
  padding-bottom: 4.375rem;
}

body.rtl .dashboard-wrapper .setting-wrapper h3 {
  text-align: right;
}

.dashboard-wrapper .setting-wrapper .last-header h3 {
  padding-top: 4.375rem;
  padding-bottom: 3.75rem;
}

@media only screen and (max-width: 769px) {
  .dashboard-wrapper .setting-wrapper .counter-input-holder {
    width: 100%;
    align-items: left;
  }
  body.rtl .dashboard-wrapper .setting-wrapper .counter-input-holder {
    align-items: right;
  }
}

@media only screen and (max-width: 769px) {
  .dashboard-wrapper .setting-wrapper .ant-row-flex-space-around {
    justify-content: flex-start;
  }
}

.custom_pagination_holder {
  position: absolute;
  top: 6px;
  right: 5px;
}

.custom_pagination_holder .ant-pagination-options {
  margin: 0;
}

.custom_pagination_holder .ant-pagination-options-quick-jumper {
  display: flex;
  font-size: 0;
}

.custom_pagination_holder .ant-pagination-item {
  display: none;
}

.custom_pagination_holder .ant-select-selection-selected-value {
  margin-top: -4px;
}

body.rtl .custom_pagination_holder {
  right: auto;
  left: 5px;
}

body.rtl .custom_pagination_holder .anticon-right {
  transform: rotate(180deg);
}

body.rtl .custom_pagination_holder .anticon-left {
  transform: rotate(180deg);
}

.custom_pagination_holder .ant-select .ant-select-selection {
  background-color: #ffffff;
  padding: 4px 3px !important;
  margin: 0 0 0 20px;
  width: 70px;
  height: 33px;
}

body.rtl .custom_pagination_holder .ant-select .ant-select-selection {
  margin: 0 0px 0 0px;
}

.user_modal-holder .Personal-info-wrapper {
  height: 100%;
}

.admin-grid .ant-table-thead tr:first-of-type th:last-of-type .ant-table-column-sorter {
  right: 50% !important;
}

body.rtl .admin-grid .ant-table-thead tr:first-of-type th:last-of-type .ant-table-column-sorter {
  right: 41% !important;
}

.admin-grid .ant-table-thead tr:first-of-type th:last-of-type .ant-table-filter-icon {
  right: 45% !important;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

body.rtl .ant-menu-item::after {
  border-left: 0 !important;
  border-right: 3px solid #0793C7 !important;
  left: 0 !important;
  right: unset !important;
}

.ant-drawer-body {
  padding: 1px !important;
}

.student-main-drawer {
  /*   &.ant-drawer-right{
      body.rtl &{
        left: unset !important;
      }
    } */
}

body.rtl .student-main-drawer.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
  left: unset;
}

.error-screen-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 60px);
}

.error-screen-wrapper .logo-holder {
  width: 280px;
}

.bg_color {
  background: #F5F5F5;
}

.p-2 {
  padding: 20px;
}

.Error_holder {
  width: 80%;
  margin: 0 auto;
}

.error_num {
  color: #a3a3a3;
  font-size: 11.625rem;
  font-weight: bold;
}

.not_found {
  color: #a3a3a3;
  font-size: 3.9375rem;
  font-weight: bold;
}

.error_text {
  color: #a3a3a3;
  font-size: 1.125rem;
}

.pb-3 {
  padding-bottom: 30px;
}

@media screen and (max-width: 545px) {
  .d-none {
    display: none;
  }
  .custom_width {
    width: 90% !important;
  }
}

.add_School .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #0793C7;
  padding-top: 28px;
  padding-left: 16px;
}

.add_School .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #0793C7;
  border-radius: 0;
}

.add_School .ant-collapse > .ant-collapse-item:last-of-type {
  margin-bottom: 60px;
}

.add_School .ant-collapse-content {
  border-top: 1px solid #0793C7;
}

.add_School .ant-collapse-content.ant-collapse-content-active {
  overflow: visible;
}

.add_School .ant-collapse-content .ant-collapse-content-box {
  padding: 16px 0;
}

.add_School .ant-collapse-item.ant-collapse-item-active {
  border-bottom: none !important;
}

.add_School .ant-collapse {
  border: none;
  background-color: transparent;
  width: 80%;
  margin: auto;
}

.add_School .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 60%;
  right: 16px;
  left: unset !important;
}

.add_School .small {
  font-weight: 500;
  color: #989797;
  line-height: 2.18;
}

.add_School .handel_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 25px;
}

.add_School .image_map {
  width: 100%;
  backdrop-filter: blur(29.1px);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  height: 227px;
}

.add_School .mt-1 {
  margin-top: 10px;
}

.add_School .counter-input-holder .ant-form-item .ant-input-number {
  min-width: 160px !important;
}

.payment_modal .radio_holders .ant-radio-group {
  width: 100%;
}

.payment_modal .radio_holders .ant-radio-group > div {
  display: flex;
  margin-bottom: 10px;
}

@media only screen and (max-width: 769px) {
  .payment_modal .radio_holders .ant-radio-group > div {
    flex-direction: column;
  }
}

.payment_modal .radio_holders .Dayes_holder {
  display: inline-block;
  position: relative;
  display: flex;
  align-items: center;
}

.payment_modal .radio_holders .Dayes_holder input {
  color: #0793C7;
}

.payment_modal .radio_holders .Dayes_holder .payment_span {
  left: 63px;
  top: 9px;
  position: absolute;
}

.payment_modal .radio_holders .Dayes_holder .ant-form-item {
  margin-bottom: 0;
}

.payment_modal .day_input {
  max-height: 30px;
  max-width: 100px;
  padding: 5px !important;
}

.payment_modal .ant-tabs-nav-container {
  overflow: visible;
}

.payment_modal .ant-tabs-nav {
  width: 100%;
}

.payment_modal .ant-tabs-nav .ant-tabs-tab {
  width: 50%;
  margin: 0 !important;
  text-align: center;
  color: #0793C7;
  font-weight: bold;
  font-size: 1.375rem;
  background-color: #ededed;
  padding: 19px 16px;
}

.payment_modal .ant-tabs-nav .ant-tabs-ink-bar {
  display: none !important;
}

.payment_modal .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #FFF;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.payment_modal .two_inputs_holder {
  border: solid 1px #dadce0 !important;
  border-radius: 4px;
  width: 130px;
}

.payment_modal .two_inputs_holder input {
  border: none;
  width: 40px;
}

.payment_modal .two_inputs_holder input:hover {
  outline: none !important;
  border: none !important;
}

.payment_modal .payment_span2 {
  left: 90px !important;
  top: 6px !important;
}

.fixed_width {
  width: 150px;
}

@media only screen and (max-width: 769px) {
  .fixed_width {
    width: 120px;
  }
}

.change_input {
  height: 30px;
  width: 50px;
  padding: 4px !important;
}

.payment-holder {
  width: 85%;
  margin: auto;
}

@media only screen and (max-width: 769px) {
  .payment-holder {
    width: 95%;
  }
}

.payment-holder h4 {
  color: #242424;
  padding: 0 0 38px 0;
}

body.rtl .payment-holder h4 {
  text-align: right;
}

.payment-holder h6 {
  color: #242424;
  padding: 0 0 38px 0;
  font-size: 0.875rem;
}

body.rtl .payment-holder h6 {
  text-align: right;
}

.payment_table .ant-table-body {
  margin: 0 !important;
}

.payment_table tr:nth-child(even) {
  background-color: #f8f8f8;
}

.payment_table .ant-table-thead {
  background-color: #dadce0;
}

.manage_student_modal .ant-modal-header {
  border: none;
}

.manage_student_modal .ant-modal-title {
  color: #656565;
  font-size: 1.5rem;
  font-weight: bold;
}

.manage_student_modal .ant-row > div {
  background: transparent;
  border: 0;
}

.manage_student_modal .gutter-box {
  padding: 5px 0;
}

.manage_student_modal .ant-col-10 {
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.16);
  padding: 10px;
  line-height: 1.8;
  height: 80px;
}

.manage_student_modal .ant-col-14 .gutter-box {
  display: flex;
  align-items: center;
  border: solid 1px #dadce0;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0 !important;
}

.manage_student_modal .ant-col-14 .gutter-box .small_div {
  padding: 10px;
  width: 50%;
  min-height: 80px;
}

.manage_student_modal .ant-col-14 .gutter-box .border_right {
  border-right: 1px solid #dadce0;
}

.manage_student_modal .ant-col-14 .gutter-box .small_div {
  height: auto;
  padding: 6px 10px !important;
  font-size: 12px;
}

.manage_student_modal .ant-col-14 .gutter-box .small_div p {
  margin-bottom: 10px;
}

.manage_student_modal .ant-col-14 .gutter-box .ant-input {
  height: auto;
  padding: 6px 10px !important;
  font-size: 12px;
  color: #0793C7;
  border: none;
}

.manage_student_modal .ant-col-14 .plus {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  padding: 0;
  border: none;
}

.manage_student_modal .line_span {
  display: block;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.6);
  margin: 20px 0;
}

.manage_student_modal .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}

.manage_student_modal tr:nth-child(even) {
  background-color: #f8f8f8;
}

.manage_student_modal .ant-table-thead {
  background-color: #dadce0;
}

.manage_student_modal td, .manage_student_modal th {
  padding: 3px 8px !important;
  text-align: center !important;
}

.manage_student_modal .btn-60 {
  margin-right: 10px;
}

body.rtl .manage_student_modal .btn-60 {
  margin-left: 10px;
  margin-right: 0px;
}

.up {
  color: #0793C7;
  position: relative;
}

.up::before {
  position: absolute;
  left: -10px;
  color: #0793C7;
}

.down {
  color: #2db2bb;
  position: relative;
}

.down::before {
  content: "-";
  position: absolute;
  left: -10px;
  color: #2db2bb;
}

.evictive_date {
  border: solid 1px #dadce0;
  border-radius: 4px;
}

.green_label {
  color: #0793C7;
}

@media only screen and (max-width: 993px) {
  body,
  html {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 769px) {
  body,
  html {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 577px) {
  .ant-calendar-range-left,
  .ant-calendar-range-right {
    float: none !important;
    width: 100%;
  }
}

@media only screen and (max-width: 577px) {
  .ant-calendar-range {
    width: 320px;
  }
}

.show-in-ipad {
  display: none !important;
}

@media only screen and (max-width: 993px) {
  .show-in-ipad {
    display: flex !important;
  }
}

.hide-in-ipad {
  display: flex !important;
}

@media only screen and (max-width: 993px) {
  .hide-in-ipad {
    display: none !important;
  }
}

.montserrat-font * {
  font-family: Pridi, Cairo, sans-serif;
}

body.rtl .montserrat-font * {
  font-family: Cairo, Pridi, sans-serif;
}

.montserrat-font .ant-form-explain {
  font-size: 10px;
}

.montserrat-font .ant-input:-moz-placeholder {
  font-family: Pridi;
  font-size: 17px;
  font-weight: 400;
  color: #989898;
  text-transform: capitalize;
}

@media only screen and (max-width: 577px) {
  .montserrat-font .ant-input:-moz-placeholder {
    font-size: 13px;
  }
}

.montserrat-font .ant-input::-moz-placeholder {
  font-family: Pridi;
  font-size: 17px;
  font-weight: 400;
  color: #989898;
  text-transform: capitalize;
}

@media only screen and (max-width: 577px) {
  .montserrat-font .ant-input::-moz-placeholder {
    font-size: 13px;
  }
}

.montserrat-font .ant-input:-ms-input-placeholder {
  font-family: Pridi;
  font-size: 17px;
  font-weight: 400;
  color: #989898;
  text-transform: capitalize;
}

@media only screen and (max-width: 577px) {
  .montserrat-font .ant-input:-ms-input-placeholder {
    font-size: 13px;
  }
}

.montserrat-font .ant-input::-webkit-input-placeholder {
  font-family: Pridi;
  font-size: 17px;
  font-weight: 400;
  color: #989898;
  text-transform: capitalize;
}

@media only screen and (max-width: 577px) {
  .montserrat-font .ant-input::-webkit-input-placeholder {
    font-size: 13px;
  }
}

.montserrat-font .ant-upload-list-picture-card .ant-upload-list-item-error {
  border: none;
}

html[lang="ar"] {
  font-size: 14px !important;
}

.rtl {
  direction: rtl;
}

.rtl caption {
  text-align: right;
}

.rtl .react-tel-input .selected-flag .arrow {
  left: auto;
  right: 20px;
}

.rtl .react-tel-input .selected-flag {
  padding: 0 8px 0 0px;
}

.rtl .move-left-enter,
.rtl .move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .move-left-enter.move-left-enter-active,
.rtl .move-left-appear.move-left-appear-active {
  animation-name: antMoveleftIn;
  animation-play-state: running;
}

.rtl .move-left-leave.move-left-leave-active {
  animation-name: antMoveleftOut;
  animation-play-state: running;
  pointer-events: none;
}

.rtl .move-left-enter,
.rtl .move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.rtl .move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.rtl .move-right-enter,
.rtl .move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .move-right-enter.move-right-enter-active,
.rtl .move-right-appear.move-right-appear-active {
  animation-name: antMoverightIn;
  animation-play-state: running;
}

.rtl .move-right-leave.move-right-leave-active {
  animation-name: antMoverightOut;
  animation-play-state: running;
  pointer-events: none;
}

.rtl .move-right-enter,
.rtl .move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.rtl .move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

@keyframes antMoveleftIn {
  0% {
    transform-origin: 100% 0;
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform-origin: 100% 0;
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes antMoveleftOut {
  0% {
    transform-origin: 100% 0;
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform-origin: 100% 0;
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes antMoverightIn {
  0% {
    opacity: 0;
    transform-origin: 100% 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform-origin: 100% 0;
    transform: translateX(0%);
  }
}

@keyframes antMoverightOut {
  0% {
    transform-origin: 100% 0;
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform-origin: 100% 0;
    transform: translateX(-100%);
    opacity: 0;
  }
}

.rtl .slide-left-enter,
.rtl .slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .slide-left-enter.slide-left-enter-active,
.rtl .slide-left-appear.slide-left-appear-active {
  animation-name: antSlideleftIn;
  animation-play-state: running;
}

.rtl .slide-left-leave.slide-left-leave-active {
  animation-name: antSlideleftOut;
  animation-play-state: running;
  pointer-events: none;
}

.rtl .slide-left-enter,
.rtl .slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rtl .slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.rtl .slide-right-enter,
.rtl .slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .slide-right-enter.slide-right-enter-active,
.rtl .slide-right-appear.slide-right-appear-active {
  animation-name: antSliderightIn;
  animation-play-state: running;
}

.rtl .slide-right-leave.slide-right-leave-active {
  animation-name: antSliderightOut;
  animation-play-state: running;
  pointer-events: none;
}

.rtl .slide-right-enter,
.rtl .slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rtl .slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@keyframes antSlideleftIn {
  0% {
    opacity: 0;
    transform-origin: 100% 0%;
    transform: scaleX(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 100% 0%;
    transform: scaleX(1);
  }
}

@keyframes antSlideleftOut {
  0% {
    opacity: 1;
    transform-origin: 100% 0%;
    transform: scaleX(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 0%;
    transform: scaleX(0.8);
  }
}

@keyframes antSliderightIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleX(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleX(1);
  }
}

@keyframes antSliderightOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleX(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleX(0.8);
  }
}

.rtl .zoom-left-enter,
.rtl .zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .zoom-left-enter.zoom-left-enter-active,
.rtl .zoom-left-appear.zoom-left-appear-active {
  animation-name: antZoomleftIn;
  animation-play-state: running;
}

.rtl .zoom-left-leave.zoom-left-leave-active {
  animation-name: antZoomleftOut;
  animation-play-state: running;
  pointer-events: none;
}

.rtl .zoom-left-enter,
.rtl .zoom-left-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.rtl .zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.rtl .zoom-right-enter,
.rtl .zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .zoom-right-enter.zoom-right-enter-active,
.rtl .zoom-right-appear.zoom-right-appear-active {
  animation-name: antZoomrightIn;
  animation-play-state: running;
}

.rtl .zoom-right-leave.zoom-right-leave-active {
  animation-name: antZoomrightOut;
  animation-play-state: running;
  pointer-events: none;
}

.rtl .zoom-right-enter,
.rtl .zoom-right-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.rtl .zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@keyframes antZoomleftIn {
  0% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(0.8);
  }
  100% {
    transform-origin: 100% 50%;
    transform: scale(1);
  }
}

@keyframes antZoomleftOut {
  0% {
    transform-origin: 100% 50%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(0.8);
  }
}

@keyframes antZoomrightIn {
  0% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(0.8);
  }
  100% {
    transform-origin: 0% 50%;
    transform: scale(1);
  }
}

@keyframes antZoomrightOut {
  0% {
    transform-origin: 0% 50%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(0.8);
  }
}

.rtl .ant-alert-icon {
  top: 12.5px;
  right: 16px;
  position: absolute;
}

.rtl .ant-alert-close-icon {
  font-size: 12px;
  position: absolute;
  left: 16px;
  top: 8px;
  line-height: 22px;
  overflow: hidden;
  cursor: pointer;
}

.rtl .ant-alert-close-text {
  position: absolute;
  left: 16px;
}

.rtl .ant-alert-with-description .ant-alert-icon {
  position: absolute;
  top: 16px;
  right: 24px;
  font-size: 24px;
}

.rtl .ant-alert-with-description .ant-alert-close-icon {
  position: absolute;
  top: 16px;
  left: 16px;
  cursor: pointer;
  font-size: 14px;
}

.rtl .ant-anchor {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  padding-right: 2px;
}

.rtl .ant-anchor-wrapper {
  background-color: #fff;
  overflow: auto;
  padding-right: 4px;
  margin-right: -4px;
}

.rtl .ant-anchor-ink {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
}

.rtl .ant-anchor-ink-ball {
  right: 50%;
}

.rtl .ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  margin-right: 0;
  margin-left: 0;
}

.rtl .ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: auto;
}

.rtl .ant-select-auto-complete.ant-select .ant-select-search--inline {
  position: static;
  float: right;
}

.rtl .ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-left: 0 !important;
}

.rtl .ant-select-arrow {
  left: 11px;
  right: auto;
}

.rtl .ant-select-selection__clear {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  opacity: 0;
  position: absolute;
  left: 11px;
  z-index: 1;
  background: #fff;
  top: 50%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  width: 12px;
  height: 12px;
  margin-top: -6px;
  line-height: 12px;
  cursor: pointer;
  transition: color 0.3s ease, opacity 0.15s ease;
}

.rtl .ant-select-selection-selected-value {
  float: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 0;
}

.rtl .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  background: #f5f5f5;
  color: #aaa;
  padding-left: 10px;
}

.rtl .ant-select-selection__rendered {
  margin-right: 11px;
  margin-left: 11px;
}

.rtl .ant-select-sm .ant-select-selection__clear,
.rtl .ant-select-sm .ant-select-arrow {
  left: 8px;
}

.rtl .ant-select-selection__placeholder,
.rtl .ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 0;
  left: 9px;
  color: #bfbfbf;
  line-height: 20px;
  height: 20px;
  max-width: 100%;
  margin-top: -10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}

.rtl .ant-select-search__field__placeholder {
  right: 12px;
}

.rtl .ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  right: -9999px;
  white-space: pre;
  pointer-events: none;
}

.rtl .ant-select-search--inline > i {
  float: left;
}

.rtl .ant-select-selection--multiple .ant-select-search--inline {
  float: right;
  position: static;
  width: auto;
  padding: 0;
  max-width: 100%;
}

.rtl .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 5px;
}

.rtl .ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-right: 6px;
}

.rtl .ant-select-selection--multiple .ant-select-selection__choice {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  float: right;
  margin-left: 4px;
  max-width: 99%;
  position: relative;
  overflow: hidden;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0 10px 0 20px;
}

.rtl .ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-left: 20px;
}

.rtl .ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-left: 20px;
}

.rtl .ant-table-thead > tr > th .ant-table-column-sorter {
  right: auto;
  left: 35px;
}

.rtl .ant-select-dropdown {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  margin: 0;
  padding: 0;
  list-style: none;
  direction: rtl;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 1050;
  top: -9999px;
  position: absolute;
  outline: none;
  font-size: 14px;
}

.rtl .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomleft,
.rtl .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomleft {
  animation-name: antSlideUpIn;
}

.rtl .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topleft,
.rtl .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topleft {
  animation-name: antSlideDownIn;
}

.rtl .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomleft {
  animation-name: antSlideUpOut;
}

.rtl .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topleft {
  animation-name: antSlideDownOut;
}

.rtl .ant-select-dropdown-menu {
  outline: none;
  margin-bottom: 0;
  padding-right: 0;
  list-style: none;
  max-height: 250px;
  overflow: auto;
}

.rtl .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-right: 20px;
}

.rtl .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:after {
  font-family: "anticon";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\E632";
  color: transparent;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333) rotate(0deg);
  transition: all 0.2s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  font-weight: bold;
  text-shadow: 0 0.1px 0, -0.1px 0 0, 0 -0.1px 0, 0.1px 0;
}

.rtl .ant-input-group[class*="col-"] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}

.rtl .ant-input-group > [class*="col-"] {
  padding-left: 8px;
}

.rtl .ant-input-group > [class*="col-"]:last-child {
  padding-left: 0;
}

.rtl .ant-input-group .ant-input {
  float: right;
  width: 100%;
  margin-bottom: 0;
}

.rtl .ant-input-group-addon > i:only-child:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.rtl .ant-input-group > .ant-input:first-child,
.rtl .ant-input-group-addon:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.rtl .ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-input-group-addon:first-child {
  border-left: 0;
}

.rtl .ant-input-group-addon:last-child {
  border-right: 0;
}

.rtl .ant-input-group > .ant-input:last-child,
.rtl .ant-input-group-addon:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.rtl .ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  width: 100%;
  float: right;
}

.rtl .ant-input-group.ant-input-group-compact > * {
  border-radius: 0;
  border-left-width: 0;
  vertical-align: top;
  float: none;
  display: inline-block;
}

.rtl .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.rtl .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.rtl .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
  border-radius: 0;
  border-left-width: 0;
}

.rtl .ant-input-group.ant-input-group-compact > *:first-child,
.rtl .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.rtl .ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.rtl .ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rtl .has-success.has-feedback .ant-form-item-children-icon,
.rtl .has-warning.has-feedback .ant-form-item-children-icon,
.rtl .has-error.has-feedback .ant-form-item-children-icon,
.rtl .is-validating.has-feedback .ant-form-item-children-icon {
  right: auto;
  left: 0;
}

.rtl .ant-input-group.ant-input-group-compact > *:last-child,
.rtl .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.rtl .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.rtl .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
}

.rtl .ant-form-item-control.has-error .ant-form-explain {
  text-align: right;
}

.rtl .ant-input-affix-wrapper .ant-input-prefix {
  left: auto;
  right: 12px;
}

.rtl .ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
  right: auto;
}

.rtl .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-right: 30px;
}

.rtl .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-left: 30px;
}

.rtl .ant-input-search:not(.ant-input-search-small) > .ant-input-suffix {
  left: 12px;
}

.rtl .ant-input-search > .ant-input-suffix > .ant-input-search-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .ant-input-search.ant-input-search-enter-button > .ant-input {
  padding-left: 46px;
}

.rtl .ant-input-search.ant-input-search-enter-button > .ant-input-suffix {
  left: 0;
}

.rtl .ant-btn > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn:hover > a:only-child:after,
.rtl .ant-btn:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn:active > a:only-child:after,
.rtl .ant-btn.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn.disabled > a:only-child:after,
.rtl .ant-btn[disabled] > a:only-child:after,
.rtl .ant-btn.disabled:hover > a:only-child:after,
.rtl .ant-btn[disabled]:hover > a:only-child:after,
.rtl .ant-btn.disabled:focus > a:only-child:after,
.rtl .ant-btn[disabled]:focus > a:only-child:after,
.rtl .ant-btn.disabled:active > a:only-child:after,
.rtl .ant-btn[disabled]:active > a:only-child:after,
.rtl .ant-btn.disabled.active > a:only-child:after,
.rtl .ant-btn[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-primary > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-primary:hover > a:only-child:after,
.rtl .ant-btn-primary:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-primary:active > a:only-child:after,
.rtl .ant-btn-primary.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-primary.disabled > a:only-child:after,
.rtl .ant-btn-primary[disabled] > a:only-child:after,
.rtl .ant-btn-primary.disabled:hover > a:only-child:after,
.rtl .ant-btn-primary[disabled]:hover > a:only-child:after,
.rtl .ant-btn-primary.disabled:focus > a:only-child:after,
.rtl .ant-btn-primary[disabled]:focus > a:only-child:after,
.rtl .ant-btn-primary.disabled:active > a:only-child:after,
.rtl .ant-btn-primary[disabled]:active > a:only-child:after,
.rtl .ant-btn-primary.disabled.active > a:only-child:after,
.rtl .ant-btn-primary[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-left-color: #40a9ff;
  border-right-color: #40a9ff;
}

.rtl .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}

.rtl .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-left-color: #40a9ff;
}

.rtl .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-left-color: #d9d9d9;
}

.rtl .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.rtl .ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #40a9ff;
}

.rtl .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.rtl .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
}

.rtl .ant-btn-ghost > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-ghost:hover > a:only-child:after,
.rtl .ant-btn-ghost:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-ghost:active > a:only-child:after,
.rtl .ant-btn-ghost.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-ghost.disabled > a:only-child:after,
.rtl .ant-btn-ghost[disabled] > a:only-child:after,
.rtl .ant-btn-ghost.disabled:hover > a:only-child:after,
.rtl .ant-btn-ghost[disabled]:hover > a:only-child:after,
.rtl .ant-btn-ghost.disabled:focus > a:only-child:after,
.rtl .ant-btn-ghost[disabled]:focus > a:only-child:after,
.rtl .ant-btn-ghost.disabled:active > a:only-child:after,
.rtl .ant-btn-ghost[disabled]:active > a:only-child:after,
.rtl .ant-btn-ghost.disabled.active > a:only-child:after,
.rtl .ant-btn-ghost[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-dashed > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-dashed:hover > a:only-child:after,
.rtl .ant-btn-dashed:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-dashed:active > a:only-child:after,
.rtl .ant-btn-dashed.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-dashed.disabled > a:only-child:after,
.rtl .ant-btn-dashed[disabled] > a:only-child:after,
.rtl .ant-btn-dashed.disabled:hover > a:only-child:after,
.rtl .ant-btn-dashed[disabled]:hover > a:only-child:after,
.rtl .ant-btn-dashed.disabled:focus > a:only-child:after,
.rtl .ant-btn-dashed[disabled]:focus > a:only-child:after,
.rtl .ant-btn-dashed.disabled:active > a:only-child:after,
.rtl .ant-btn-dashed[disabled]:active > a:only-child:after,
.rtl .ant-btn-dashed.disabled.active > a:only-child:after,
.rtl .ant-btn-dashed[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger:hover > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger:active > a:only-child:after,
.rtl .ant-btn-danger.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger.disabled > a:only-child:after,
.rtl .ant-btn-danger[disabled] > a:only-child:after,
.rtl .ant-btn-danger.disabled:hover > a:only-child:after,
.rtl .ant-btn-danger[disabled]:hover > a:only-child:after,
.rtl .ant-btn-danger.disabled:focus > a:only-child:after,
.rtl .ant-btn-danger[disabled]:focus > a:only-child:after,
.rtl .ant-btn-danger.disabled:active > a:only-child:after,
.rtl .ant-btn-danger[disabled]:active > a:only-child:after,
.rtl .ant-btn-danger.disabled.active > a:only-child:after,
.rtl .ant-btn-danger[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn:before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background: #fff;
  opacity: 0.35;
  content: "";
  border-radius: inherit;
  z-index: 1;
  transition: opacity 0.2s;
  pointer-events: none;
  display: none;
}

.rtl .ant-btn .anticon {
  transition: margin-right 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rtl .ant-btn.ant-btn-loading:before {
  display: block;
}

.rtl .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-right: 29px;
  pointer-events: none;
  position: relative;
}

.rtl .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-right: -14px;
}

.rtl .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-right: 24px;
}

.rtl .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-right: -17px;
}

.rtl .ant-btn-group .ant-btn + .ant-btn,
.rtl .ant-btn + .ant-btn-group,
.rtl .ant-btn-group span + .ant-btn,
.rtl .ant-btn-group .ant-btn + span,
.rtl .ant-btn-group + .ant-btn,
.rtl .ant-btn-group + .ant-btn-group {
  margin-right: -1px;
}

.rtl .ant-btn-group > .ant-btn:first-child,
.rtl .ant-btn-group > span:first-child > .ant-btn {
  margin-right: 0;
}

.rtl .ant-btn-group > .ant-btn:first-child:not(:last-child),
.rtl .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-btn-group > .ant-btn:last-child:not(:first-child),
.rtl .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-btn-group > .ant-btn-group {
  float: right;
}

.rtl .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding-left: 8px;
}

.rtl .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-right: 8px;
}

.rtl .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-btn:focus > span,
.rtl .ant-btn:active > span {
  position: relative;
}

.rtl .ant-btn > .anticon + span,
.rtl .ant-btn > span + .anticon {
  margin-right: 8px;
}

.rtl .ant-btn-clicked:after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border-radius: inherit;
  border: 0 solid #0793C7;
  opacity: 0.4;
  animation: buttonEffect 0.4s;
  display: block;
}

.rtl .ant-btn-background-ghost.ant-btn-primary > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-primary:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-danger > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-danger:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-two-chinese-chars > * {
  letter-spacing: 0.34em;
  margin-left: -0.34em;
}

@keyframes buttonEffect {
  to {
    opacity: 0;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border-width: 6px;
  }
}

.rtl .ant-back-top {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 10;
  position: fixed;
  left: 100px;
  bottom: 50px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .rtl .ant-back-top {
    left: 60px;
  }
}

@media screen and (max-width: 480px) {
  .rtl .ant-back-top {
    left: 20px;
  }
}

.rtl .ant-badge-status-processing:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #0793C7;
  content: "";
  animation: antStatusProcessing 1.2s infinite ease-in-out;
}

.rtl .ant-badge-status-text {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  margin-right: 8px;
}

.rtl .ant-breadcrumb-link > .anticon + span {
  margin-right: 4px;
}

.rtl .ant-fullcalendar-month-select {
  margin-right: 5px;
}

.rtl .ant-fullcalendar-header {
  padding: 11px 0 11px 16px;
  text-align: left;
}

.rtl .ant-fullcalendar-header .ant-select-dropdown {
  text-align: right;
}

.rtl .ant-fullcalendar-header .ant-radio-group {
  margin-right: 8px;
  text-align: right;
}

.rtl .ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rtl .ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rtl .ant-fullcalendar-content {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: -9px;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  margin-right: 16px;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  text-align: right;
  margin: 0 4px;
  display: block;
  color: rgba(0, 0, 0, 0.65);
  height: 116px;
  padding: 4px 8px;
  border-top: 2px solid #e8e8e8;
  transition: background 0.3s;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
  text-align: left;
  padding-left: 12px;
  padding-bottom: 5px;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-value {
  text-align: left;
  background: transparent;
  width: auto;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  height: 90px;
  overflow-y: auto;
  position: static;
  width: auto;
  right: auto;
  bottom: auto;
}

.rtl .ant-radio-wrapper {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  margin-left: 8px;
  cursor: pointer;
}

.rtl .ant-radio-checked:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #0793C7;
  content: "";
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  visibility: hidden;
}

.rtl .ant-radio-inner {
  position: relative;
  top: 0;
  right: 0;
  display: block;
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  border-color: #d9d9d9;
  background-color: #fff;
  transition: all 0.3s;
}

.rtl .ant-radio-inner:after {
  position: absolute;
  width: 8px;
  height: 8px;
  right: 3px;
  top: 3px;
  border-radius: 4px;
  display: table;
  border-top: 0;
  border-right: 0;
  content: " ";
  background-color: #0793C7;
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.rtl .ant-radio-input {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
}

.rtl span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-radio-button-wrapper {
  margin: 0;
  height: 32px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.65);
  display: inline-block;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-right: 0;
  border-top-width: 1.02px;
  background: #fff;
  padding: 0 15px;
  position: relative;
}

.rtl .ant-radio-button-wrapper > .ant-radio-button {
  margin-right: 0;
  display: block;
  width: 0;
  height: 0;
}

.rtl .ant-radio-button-wrapper:not(:first-child)::before {
  content: "";
  display: block;
  top: 0;
  right: -1px;
  width: 1px;
  height: 100%;
  position: absolute;
  background-color: #d9d9d9;
}

.rtl .ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}

.rtl .ant-card-extra {
  float: left;
  padding: 17.5px 0;
  text-align: left;
  margin-right: auto;
}

.rtl .ant-card-grid {
  border-radius: 0;
  border: 0;
  box-shadow: -1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, -1px 1px 0 0 #e8e8e8, -1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  width: 33.33%;
  float: right;
  padding: 24px;
  transition: all 0.3s;
}

.rtl .ant-card-actions > li {
  float: right;
  text-align: center;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
}

.rtl .ant-card-actions > li:not(:last-child) {
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-card-padding-transition .ant-card-extra {
  transition: left 0.3s;
}

.rtl .ant-card-meta-avatar {
  padding-left: 16px;
  float: right;
}

.rtl .ant-card-loading-block {
  display: inline-block;
  margin: 5px 0 0 2%;
  height: 14px;
  border-radius: 2px;
  background: linear-gradient(-90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  animation: card-loading 1.4s ease infinite;
  background-size: 600% 600%;
}

.rtl .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  margin: 0;
  border: 1px solid #e8e8e8;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  background: #fafafa;
  margin-left: 2px;
  padding: 0 16px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  line-height: 38px;
}

.rtl .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
  font-size: 12px;
  margin-right: 3px;
  margin-left: -5px;
  overflow: hidden;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  height: 14px;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-left: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
  border-left: 0;
  border-radius: 0 4px 4px 0;
  margin-left: 1px;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab-active {
  margin-left: -1px;
  padding-left: 18px;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-right: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-tab {
  border-right: 0;
  border-radius: 4px 0 0 4px;
  margin-right: 1px;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-tab-active {
  margin-right: -1px;
  padding-right: 18px;
}

.rtl .ant-tabs-ink-bar {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 1px;
  box-sizing: border-box;
  height: 2px;
  background-color: #0793C7;
  transform-origin: 100% 0;
}

.rtl .ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left: 32px;
}

.rtl .ant-tabs-tab-prev-icon,
.rtl .ant-tabs-tab-next-icon {
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  line-height: inherit;
  vertical-align: baseline;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  text-align: center;
  text-transform: none;
}

.rtl .ant-tabs-tab-prev {
  right: 0;
}

.rtl .ant-tabs-nav {
  box-sizing: border-box;
  padding-right: 0;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  margin: 0;
  list-style: none;
  display: inline-block;
}

.rtl .ant-tabs-nav .ant-tabs-tab:last-child {
  margin-left: 0;
}

.rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-left: 8px;
}

.rtl .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content-animated {
  display: flex;
  flex-direction: row;
  will-change: margin-left;
  transition: margin-right 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rtl .ant-tabs-vertical > .ant-tabs-bar .ant-tabs-ink-bar {
  width: 2px;
  right: auto;
  height: auto;
  top: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar {
  float: right;
  border-left: 1px solid #e8e8e8;
  margin-left: -1px;
  margin-bottom: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
  text-align: left;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-container {
  margin-left: -1px;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-left: -1px;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-ink-bar {
  left: 1px;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-content {
  padding-right: 24px;
  border-right: 1px solid #e8e8e8;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar {
  float: left;
  border-right: 1px solid #e8e8e8;
  margin-right: -1px;
  margin-bottom: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-container {
  margin-right: -1px;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-right: -1px;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-ink-bar {
  right: 1px;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-content {
  padding-left: 24px;
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-tabs-left .ant-tabs-ink-bar-animated,
.rtl .ant-tabs-right .ant-tabs-ink-bar-animated {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rtl .no-flex > .ant-tabs-content-animated,
.rtl .ant-tabs-no-animation > .ant-tabs-content-animated,
.rtl .ant-tabs-vertical > .ant-tabs-content-animated {
  transform: none !important;
  margin-right: 0 !important;
}

.rtl .ant-carousel .slick-track {
  position: relative;
  right: 0;
  top: 0;
  display: block;
}

.rtl [dir="rtl"] .ant-carousel .slick-slide {
  float: left;
}

.rtl .ant-carousel .slick-prev {
  right: -25px;
}

.rtl .ant-carousel .slick-prev:before {
  content: "\2190";
}

.rtl .ant-carousel .slick-next {
  left: -25px;
}

.rtl .ant-carousel-vertical .slick-dots {
  width: 3px;
  bottom: auto;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
}

.rtl .ant-cascader-picker-label {
  position: absolute;
  right: 0;
  height: 20px;
  line-height: 20px;
  top: 50%;
  margin-top: -10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding: 0 12px;
}

.rtl .ant-cascader-picker-clear {
  opacity: 0;
  position: absolute;
  left: 12px;
  z-index: 2;
  background: #fff;
  top: 50%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  width: 12px;
  height: 12px;
  margin-top: -6px;
  line-height: 12px;
  cursor: pointer;
  transition: color 0.3s ease, opacity 0.15s ease;
}

.rtl .ant-cascader-picker-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 12px;
  width: 12px;
  height: 12px;
  font-size: 12px;
  margin-top: -6px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.25);
}

.rtl .ant-cascader-picker-small .ant-cascader-picker-clear,
.rtl .ant-cascader-picker-small .ant-cascader-picker-arrow {
  left: 8px;
}

.rtl .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomleft,
.rtl .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomleft {
  animation-name: antSlideUpIn;
}

.rtl .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topleft,
.rtl .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topleft {
  animation-name: antSlideDownIn;
}

.rtl .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomleft {
  animation-name: antSlideUpOut;
}

.rtl .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topleft {
  animation-name: antSlideDownOut;
}

.rtl .ant-cascader-menu {
  display: inline-block;
  vertical-align: top;
  min-width: 111px;
  height: 180px;
  list-style: none;
  margin: 0;
  padding: 0;
  border-left: 1px solid #e8e8e8;
  overflow: auto;
}

.rtl .ant-cascader-menu:last-child {
  border-left-color: transparent;
  margin-left: -1px;
  border-radius: 4px 0 0 4px;
}

.rtl .ant-cascader-menu-item-expand {
  position: relative;
  padding-left: 24px;
}

.rtl .ant-cascader-menu-item-expand:after {
  font-family: "anticon";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\E61F";
  display: inline-block;
  font-size: 12px;
  font-size: 8px \9;
  transform: scale(0.66667) rotate(0deg);
  color: rgba(0, 0, 0, 0.45);
  position: absolute;
  left: 12px;
}

.rtl .ant-checkbox-checked:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #0793C7;
  content: "";
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  visibility: hidden;
}

.rtl .ant-checkbox-inner {
  position: relative;
  top: 0;
  right: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
}

.rtl .ant-checkbox-input {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rtl .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-right: 8px;
}

.rtl .ant-checkbox-wrapper + span,
.rtl .ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-checkbox-group-item {
  display: inline-block;
  margin-left: 8px;
  margin-right: 0px;
}

.rtl .ant-checkbox-group-item:last-child {
  margin-left: 0;
}

.rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-right: 0;
}

.rtl .ant-row {
  position: relative;
  margin-right: 0;
  margin-left: 0;
  height: auto;
  zoom: 1;
  display: block;
  box-sizing: border-box;
}

.rtl .ant-col-1,
.rtl .ant-col-xs-1,
.rtl .ant-col-sm-1,
.rtl .ant-col-md-1,
.rtl .ant-col-lg-1,
.rtl .ant-col-2,
.rtl .ant-col-xs-2,
.rtl .ant-col-sm-2,
.rtl .ant-col-md-2,
.rtl .ant-col-lg-2,
.rtl .ant-col-3,
.rtl .ant-col-xs-3,
.rtl .ant-col-sm-3,
.rtl .ant-col-md-3,
.rtl .ant-col-lg-3,
.rtl .ant-col-4,
.rtl .ant-col-xs-4,
.rtl .ant-col-sm-4,
.rtl .ant-col-md-4,
.rtl .ant-col-lg-4,
.rtl .ant-col-5,
.rtl .ant-col-xs-5,
.rtl .ant-col-sm-5,
.rtl .ant-col-md-5,
.rtl .ant-col-lg-5,
.rtl .ant-col-6,
.rtl .ant-col-xs-6,
.rtl .ant-col-sm-6,
.rtl .ant-col-md-6,
.rtl .ant-col-lg-6,
.rtl .ant-col-7,
.rtl .ant-col-xs-7,
.rtl .ant-col-sm-7,
.rtl .ant-col-md-7,
.rtl .ant-col-lg-7,
.rtl .ant-col-8,
.rtl .ant-col-xs-8,
.rtl .ant-col-sm-8,
.rtl .ant-col-md-8,
.rtl .ant-col-lg-8,
.rtl .ant-col-9,
.rtl .ant-col-xs-9,
.rtl .ant-col-sm-9,
.rtl .ant-col-md-9,
.rtl .ant-col-lg-9,
.rtl .ant-col-10,
.rtl .ant-col-xs-10,
.rtl .ant-col-sm-10,
.rtl .ant-col-md-10,
.rtl .ant-col-lg-10,
.rtl .ant-col-11,
.rtl .ant-col-xs-11,
.rtl .ant-col-sm-11,
.rtl .ant-col-md-11,
.rtl .ant-col-lg-11,
.rtl .ant-col-12,
.rtl .ant-col-xs-12,
.rtl .ant-col-sm-12,
.rtl .ant-col-md-12,
.rtl .ant-col-lg-12,
.rtl .ant-col-13,
.rtl .ant-col-xs-13,
.rtl .ant-col-sm-13,
.rtl .ant-col-md-13,
.rtl .ant-col-lg-13,
.rtl .ant-col-14,
.rtl .ant-col-xs-14,
.rtl .ant-col-sm-14,
.rtl .ant-col-md-14,
.rtl .ant-col-lg-14,
.rtl .ant-col-15,
.rtl .ant-col-xs-15,
.rtl .ant-col-sm-15,
.rtl .ant-col-md-15,
.rtl .ant-col-lg-15,
.rtl .ant-col-16,
.rtl .ant-col-xs-16,
.rtl .ant-col-sm-16,
.rtl .ant-col-md-16,
.rtl .ant-col-lg-16,
.rtl .ant-col-17,
.rtl .ant-col-xs-17,
.rtl .ant-col-sm-17,
.rtl .ant-col-md-17,
.rtl .ant-col-lg-17,
.rtl .ant-col-18,
.rtl .ant-col-xs-18,
.rtl .ant-col-sm-18,
.rtl .ant-col-md-18,
.rtl .ant-col-lg-18,
.rtl .ant-col-19,
.rtl .ant-col-xs-19,
.rtl .ant-col-sm-19,
.rtl .ant-col-md-19,
.rtl .ant-col-lg-19,
.rtl .ant-col-20,
.rtl .ant-col-xs-20,
.rtl .ant-col-sm-20,
.rtl .ant-col-md-20,
.rtl .ant-col-lg-20,
.rtl .ant-col-21,
.rtl .ant-col-xs-21,
.rtl .ant-col-sm-21,
.rtl .ant-col-md-21,
.rtl .ant-col-lg-21,
.rtl .ant-col-22,
.rtl .ant-col-xs-22,
.rtl .ant-col-sm-22,
.rtl .ant-col-md-22,
.rtl .ant-col-lg-22,
.rtl .ant-col-23,
.rtl .ant-col-xs-23,
.rtl .ant-col-sm-23,
.rtl .ant-col-md-23,
.rtl .ant-col-lg-23,
.rtl .ant-col-24,
.rtl .ant-col-xs-24,
.rtl .ant-col-sm-24,
.rtl .ant-col-md-24,
.rtl .ant-col-lg-24 {
  position: relative;
  min-height: 1px;
  padding-right: 0;
  padding-left: 0;
}

.rtl .ant-col-1,
.rtl .ant-col-2,
.rtl .ant-col-3,
.rtl .ant-col-4,
.rtl .ant-col-5,
.rtl .ant-col-6,
.rtl .ant-col-7,
.rtl .ant-col-8,
.rtl .ant-col-9,
.rtl .ant-col-10,
.rtl .ant-col-11,
.rtl .ant-col-12,
.rtl .ant-col-13,
.rtl .ant-col-14,
.rtl .ant-col-15,
.rtl .ant-col-16,
.rtl .ant-col-17,
.rtl .ant-col-18,
.rtl .ant-col-19,
.rtl .ant-col-20,
.rtl .ant-col-21,
.rtl .ant-col-22,
.rtl .ant-col-23,
.rtl .ant-col-24 {
  float: right;
  flex: 0 0 auto;
}

.rtl .ant-col-push-24 {
  right: 100%;
}

.rtl .ant-col-pull-24 {
  left: 100%;
}

.rtl .ant-col-offset-24 {
  margin-right: 100%;
}

.rtl .ant-col-push-23 {
  right: 95.83333333%;
}

.rtl .ant-col-pull-23 {
  left: 95.83333333%;
}

.rtl .ant-col-offset-23 {
  margin-right: 95.83333333%;
}

.rtl .ant-col-push-22 {
  right: 91.66666667%;
}

.rtl .ant-col-pull-22 {
  left: 91.66666667%;
}

.rtl .ant-col-offset-22 {
  margin-right: 91.66666667%;
}

.rtl .ant-col-push-21 {
  right: 87.5%;
}

.rtl .ant-col-pull-21 {
  left: 87.5%;
}

.rtl .ant-col-offset-21 {
  margin-right: 87.5%;
}

.rtl .ant-col-push-20 {
  right: 83.33333333%;
}

.rtl .ant-col-pull-20 {
  left: 83.33333333%;
}

.rtl .ant-col-offset-20 {
  margin-right: 83.33333333%;
}

.rtl .ant-col-push-19 {
  right: 79.16666667%;
}

.rtl .ant-col-pull-19 {
  left: 79.16666667%;
}

.rtl .ant-col-offset-19 {
  margin-right: 79.16666667%;
}

.rtl .ant-col-push-18 {
  right: 75%;
}

.rtl .ant-col-pull-18 {
  left: 75%;
}

.rtl .ant-col-offset-18 {
  margin-right: 75%;
}

.rtl .ant-col-push-17 {
  right: 70.83333333%;
}

.rtl .ant-col-pull-17 {
  left: 70.83333333%;
}

.rtl .ant-col-offset-17 {
  margin-right: 70.83333333%;
}

.rtl .ant-col-push-16 {
  right: 66.66666667%;
}

.rtl .ant-col-pull-16 {
  left: 66.66666667%;
}

.rtl .ant-col-offset-16 {
  margin-right: 66.66666667%;
}

.rtl .ant-col-push-15 {
  right: 62.5%;
}

.rtl .ant-col-pull-15 {
  left: 62.5%;
}

.rtl .ant-col-offset-15 {
  margin-right: 62.5%;
}

.rtl .ant-col-push-14 {
  right: 58.33333333%;
}

.rtl .ant-col-pull-14 {
  left: 58.33333333%;
}

.rtl .ant-col-offset-14 {
  margin-right: 58.33333333%;
}

.rtl .ant-col-push-13 {
  right: 54.16666667%;
}

.rtl .ant-col-pull-13 {
  left: 54.16666667%;
}

.rtl .ant-col-offset-13 {
  margin-right: 54.16666667%;
}

.rtl .ant-col-push-12 {
  right: 50%;
}

.rtl .ant-col-pull-12 {
  left: 50%;
}

.rtl .ant-col-offset-12 {
  margin-right: 50%;
}

.rtl .ant-col-push-11 {
  right: 45.83333333%;
}

.rtl .ant-col-pull-11 {
  left: 45.83333333%;
}

.rtl .ant-col-offset-11 {
  margin-right: 45.83333333%;
}

.rtl .ant-col-push-10 {
  right: 41.66666667%;
}

.rtl .ant-col-pull-10 {
  left: 41.66666667%;
}

.rtl .ant-col-offset-10 {
  margin-right: 41.66666667%;
}

.rtl .ant-col-push-9 {
  right: 37.5%;
}

.rtl .ant-col-pull-9 {
  left: 37.5%;
}

.rtl .ant-col-offset-9 {
  margin-right: 37.5%;
}

.rtl .ant-col-push-8 {
  right: 33.33333333%;
}

.rtl .ant-col-pull-8 {
  left: 33.33333333%;
}

.rtl .ant-col-offset-8 {
  margin-right: 33.33333333%;
}

.rtl .ant-col-push-7 {
  right: 29.16666667%;
}

.rtl .ant-col-pull-7 {
  left: 29.16666667%;
}

.rtl .ant-col-offset-7 {
  margin-right: 29.16666667%;
}

.rtl .ant-col-push-6 {
  right: 25%;
}

.rtl .ant-col-pull-6 {
  left: 25%;
}

.rtl .ant-col-offset-6 {
  margin-right: 25%;
}

.rtl .ant-col-push-5 {
  right: 20.83333333%;
}

.rtl .ant-col-pull-5 {
  left: 20.83333333%;
}

.rtl .ant-col-offset-5 {
  margin-right: 20.83333333%;
}

.rtl .ant-col-push-4 {
  right: 16.66666667%;
}

.rtl .ant-col-pull-4 {
  left: 16.66666667%;
}

.rtl .ant-col-offset-4 {
  margin-right: 16.66666667%;
}

.rtl .ant-col-push-3 {
  right: 12.5%;
}

.rtl .ant-col-pull-3 {
  left: 12.5%;
}

.rtl .ant-col-offset-3 {
  margin-right: 12.5%;
}

.rtl .ant-col-push-2 {
  right: 8.33333333%;
}

.rtl .ant-col-pull-2 {
  left: 8.33333333%;
}

.rtl .ant-col-offset-2 {
  margin-right: 8.33333333%;
}

.rtl .ant-col-push-1 {
  right: 4.16666667%;
}

.rtl .ant-col-pull-1 {
  left: 4.16666667%;
}

.rtl .ant-col-offset-1 {
  margin-right: 4.16666667%;
}

.rtl .ant-col-push-0 {
  right: auto;
}

.rtl .ant-col-pull-0 {
  left: auto;
}

.rtl .ant-col-push-0 {
  right: auto;
}

.rtl .ant-col-pull-0 {
  left: auto;
}

.rtl .ant-col-offset-0 {
  margin-right: 0;
}

.rtl .ant-col-xs-1,
.rtl .ant-col-xs-2,
.rtl .ant-col-xs-3,
.rtl .ant-col-xs-4,
.rtl .ant-col-xs-5,
.rtl .ant-col-xs-6,
.rtl .ant-col-xs-7,
.rtl .ant-col-xs-8,
.rtl .ant-col-xs-9,
.rtl .ant-col-xs-10,
.rtl .ant-col-xs-11,
.rtl .ant-col-xs-12,
.rtl .ant-col-xs-13,
.rtl .ant-col-xs-14,
.rtl .ant-col-xs-15,
.rtl .ant-col-xs-16,
.rtl .ant-col-xs-17,
.rtl .ant-col-xs-18,
.rtl .ant-col-xs-19,
.rtl .ant-col-xs-20,
.rtl .ant-col-xs-21,
.rtl .ant-col-xs-22,
.rtl .ant-col-xs-23,
.rtl .ant-col-xs-24 {
  float: right;
  flex: 0 0 auto;
}

.rtl .ant-col-xs-push-24 {
  right: 100%;
}

.rtl .ant-col-xs-pull-24 {
  left: 100%;
}

.rtl .ant-col-xs-offset-24 {
  margin-right: 100%;
}

.rtl .ant-col-xs-push-23 {
  right: 95.83333333%;
}

.rtl .ant-col-xs-pull-23 {
  left: 95.83333333%;
}

.rtl .ant-col-xs-offset-23 {
  margin-right: 95.83333333%;
}

.rtl .ant-col-xs-push-22 {
  right: 91.66666667%;
}

.rtl .ant-col-xs-pull-22 {
  left: 91.66666667%;
}

.rtl .ant-col-xs-offset-22 {
  margin-right: 91.66666667%;
}

.rtl .ant-col-xs-push-21 {
  right: 87.5%;
}

.rtl .ant-col-xs-pull-21 {
  left: 87.5%;
}

.rtl .ant-col-xs-offset-21 {
  margin-right: 87.5%;
}

.rtl .ant-col-xs-push-20 {
  right: 83.33333333%;
}

.rtl .ant-col-xs-pull-20 {
  left: 83.33333333%;
}

.rtl .ant-col-xs-offset-20 {
  margin-right: 83.33333333%;
}

.rtl .ant-col-xs-push-19 {
  right: 79.16666667%;
}

.rtl .ant-col-xs-pull-19 {
  left: 79.16666667%;
}

.rtl .ant-col-xs-offset-19 {
  margin-right: 79.16666667%;
}

.rtl .ant-col-xs-push-18 {
  right: 75%;
}

.rtl .ant-col-xs-pull-18 {
  left: 75%;
}

.rtl .ant-col-xs-offset-18 {
  margin-right: 75%;
}

.rtl .ant-col-xs-push-17 {
  right: 70.83333333%;
}

.rtl .ant-col-xs-pull-17 {
  left: 70.83333333%;
}

.rtl .ant-col-xs-offset-17 {
  margin-right: 70.83333333%;
}

.rtl .ant-col-xs-push-16 {
  right: 66.66666667%;
}

.rtl .ant-col-xs-pull-16 {
  left: 66.66666667%;
}

.rtl .ant-col-xs-offset-16 {
  margin-right: 66.66666667%;
}

.rtl .ant-col-xs-push-15 {
  right: 62.5%;
}

.rtl .ant-col-xs-pull-15 {
  left: 62.5%;
}

.rtl .ant-col-xs-offset-15 {
  margin-right: 62.5%;
}

.rtl .ant-col-xs-push-14 {
  right: 58.33333333%;
}

.rtl .ant-col-xs-pull-14 {
  left: 58.33333333%;
}

.rtl .ant-col-xs-offset-14 {
  margin-right: 58.33333333%;
}

.rtl .ant-col-xs-push-13 {
  right: 54.16666667%;
}

.rtl .ant-col-xs-pull-13 {
  left: 54.16666667%;
}

.rtl .ant-col-xs-offset-13 {
  margin-right: 54.16666667%;
}

.rtl .ant-col-xs-push-12 {
  right: 50%;
}

.rtl .ant-col-xs-pull-12 {
  left: 50%;
}

.rtl .ant-col-xs-offset-12 {
  margin-right: 50%;
}

.rtl .ant-col-xs-push-11 {
  right: 45.83333333%;
}

.rtl .ant-col-xs-pull-11 {
  left: 45.83333333%;
}

.rtl .ant-col-xs-offset-11 {
  margin-right: 45.83333333%;
}

.rtl .ant-col-xs-push-10 {
  right: 41.66666667%;
}

.rtl .ant-col-xs-pull-10 {
  left: 41.66666667%;
}

.rtl .ant-col-xs-offset-10 {
  margin-right: 41.66666667%;
}

.rtl .ant-col-xs-push-9 {
  right: 37.5%;
}

.rtl .ant-col-xs-pull-9 {
  left: 37.5%;
}

.rtl .ant-col-xs-offset-9 {
  margin-right: 37.5%;
}

.rtl .ant-col-xs-push-8 {
  right: 33.33333333%;
}

.rtl .ant-col-xs-pull-8 {
  left: 33.33333333%;
}

.rtl .ant-col-xs-offset-8 {
  margin-right: 33.33333333%;
}

.rtl .ant-col-xs-push-7 {
  right: 29.16666667%;
}

.rtl .ant-col-xs-pull-7 {
  left: 29.16666667%;
}

.rtl .ant-col-xs-offset-7 {
  margin-right: 29.16666667%;
}

.rtl .ant-col-xs-push-6 {
  right: 25%;
}

.rtl .ant-col-xs-pull-6 {
  left: 25%;
}

.rtl .ant-col-xs-offset-6 {
  margin-right: 25%;
}

.rtl .ant-col-xs-push-5 {
  right: 20.83333333%;
}

.rtl .ant-col-xs-pull-5 {
  left: 20.83333333%;
}

.rtl .ant-col-xs-offset-5 {
  margin-right: 20.83333333%;
}

.rtl .ant-col-xs-push-4 {
  right: 16.66666667%;
}

.rtl .ant-col-xs-pull-4 {
  left: 16.66666667%;
}

.rtl .ant-col-xs-offset-4 {
  margin-right: 16.66666667%;
}

.rtl .ant-col-xs-push-3 {
  right: 12.5%;
}

.rtl .ant-col-xs-pull-3 {
  left: 12.5%;
}

.rtl .ant-col-xs-offset-3 {
  margin-right: 12.5%;
}

.rtl .ant-col-xs-push-2 {
  right: 8.33333333%;
}

.rtl .ant-col-xs-pull-2 {
  left: 8.33333333%;
}

.rtl .ant-col-xs-offset-2 {
  margin-right: 8.33333333%;
}

.rtl .ant-col-xs-push-1 {
  right: 4.16666667%;
}

.rtl .ant-col-xs-pull-1 {
  left: 4.16666667%;
}

.rtl .ant-col-xs-offset-1 {
  margin-right: 4.16666667%;
}

.rtl .ant-col-push-0 {
  right: auto;
}

.rtl .ant-col-pull-0 {
  left: auto;
}

.rtl .ant-col-xs-push-0 {
  right: auto;
}

.rtl .ant-col-xs-pull-0 {
  left: auto;
}

.rtl .ant-col-xs-offset-0 {
  margin-right: 0;
}

@media (min-width: 576px) {
  .rtl .ant-col-sm-1,
  .rtl .ant-col-sm-2,
  .rtl .ant-col-sm-3,
  .rtl .ant-col-sm-4,
  .rtl .ant-col-sm-5,
  .rtl .ant-col-sm-6,
  .rtl .ant-col-sm-7,
  .rtl .ant-col-sm-8,
  .rtl .ant-col-sm-9,
  .rtl .ant-col-sm-10,
  .rtl .ant-col-sm-11,
  .rtl .ant-col-sm-12,
  .rtl .ant-col-sm-13,
  .rtl .ant-col-sm-14,
  .rtl .ant-col-sm-15,
  .rtl .ant-col-sm-16,
  .rtl .ant-col-sm-17,
  .rtl .ant-col-sm-18,
  .rtl .ant-col-sm-19,
  .rtl .ant-col-sm-20,
  .rtl .ant-col-sm-21,
  .rtl .ant-col-sm-22,
  .rtl .ant-col-sm-23,
  .rtl .ant-col-sm-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-sm-push-24 {
    right: 100%;
  }
  .rtl .ant-col-sm-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-sm-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-sm-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-sm-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-sm-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-sm-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-sm-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-sm-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-sm-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-sm-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-sm-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-sm-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-sm-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-sm-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-sm-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-sm-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-sm-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-sm-push-18 {
    right: 75%;
  }
  .rtl .ant-col-sm-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-sm-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-sm-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-sm-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-sm-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-sm-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-sm-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-sm-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-sm-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-sm-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-sm-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-sm-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-sm-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-sm-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-sm-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-sm-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-sm-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-sm-push-12 {
    right: 50%;
  }
  .rtl .ant-col-sm-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-sm-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-sm-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-sm-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-sm-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-sm-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-sm-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-sm-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-sm-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-sm-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-sm-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-sm-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-sm-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-sm-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-sm-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-sm-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-sm-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-sm-push-6 {
    right: 25%;
  }
  .rtl .ant-col-sm-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-sm-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-sm-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-sm-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-sm-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-sm-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-sm-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-sm-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-sm-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-sm-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-sm-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-sm-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-sm-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-sm-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-sm-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-sm-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-sm-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-sm-push-0 {
    right: auto;
  }
  .rtl .ant-col-sm-pull-0 {
    left: auto;
  }
  .rtl .ant-col-sm-offset-0 {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .rtl .ant-col-md-1,
  .rtl .ant-col-md-2,
  .rtl .ant-col-md-3,
  .rtl .ant-col-md-4,
  .rtl .ant-col-md-5,
  .rtl .ant-col-md-6,
  .rtl .ant-col-md-7,
  .rtl .ant-col-md-8,
  .rtl .ant-col-md-9,
  .rtl .ant-col-md-10,
  .rtl .ant-col-md-11,
  .rtl .ant-col-md-12,
  .rtl .ant-col-md-13,
  .rtl .ant-col-md-14,
  .rtl .ant-col-md-15,
  .rtl .ant-col-md-16,
  .rtl .ant-col-md-17,
  .rtl .ant-col-md-18,
  .rtl .ant-col-md-19,
  .rtl .ant-col-md-20,
  .rtl .ant-col-md-21,
  .rtl .ant-col-md-22,
  .rtl .ant-col-md-23,
  .rtl .ant-col-md-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-md-push-24 {
    right: 100%;
  }
  .rtl .ant-col-md-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-md-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-md-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-md-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-md-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-md-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-md-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-md-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-md-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-md-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-md-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-md-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-md-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-md-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-md-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-md-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-md-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-md-push-18 {
    right: 75%;
  }
  .rtl .ant-col-md-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-md-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-md-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-md-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-md-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-md-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-md-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-md-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-md-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-md-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-md-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-md-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-md-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-md-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-md-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-md-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-md-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-md-push-12 {
    right: 50%;
  }
  .rtl .ant-col-md-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-md-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-md-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-md-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-md-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-md-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-md-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-md-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-md-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-md-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-md-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-md-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-md-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-md-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-md-order-8 {
    order: 8;
  }
  .rtl .ant-col-md-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-md-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-md-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-md-push-6 {
    right: 25%;
  }
  .rtl .ant-col-md-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-md-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-md-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-md-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-md-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-md-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-md-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-md-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-md-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-md-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-md-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-md-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-md-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-md-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-md-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-md-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-md-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-md-push-0 {
    right: auto;
  }
  .rtl .ant-col-md-pull-0 {
    left: auto;
  }
  .rtl .ant-col-md-offset-0 {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .rtl .ant-col-lg-1,
  .rtl .ant-col-lg-2,
  .rtl .ant-col-lg-3,
  .rtl .ant-col-lg-4,
  .rtl .ant-col-lg-5,
  .rtl .ant-col-lg-6,
  .rtl .ant-col-lg-7,
  .rtl .ant-col-lg-8,
  .rtl .ant-col-lg-9,
  .rtl .ant-col-lg-10,
  .rtl .ant-col-lg-11,
  .rtl .ant-col-lg-12,
  .rtl .ant-col-lg-13,
  .rtl .ant-col-lg-14,
  .rtl .ant-col-lg-15,
  .rtl .ant-col-lg-16,
  .rtl .ant-col-lg-17,
  .rtl .ant-col-lg-18,
  .rtl .ant-col-lg-19,
  .rtl .ant-col-lg-20,
  .rtl .ant-col-lg-21,
  .rtl .ant-col-lg-22,
  .rtl .ant-col-lg-23,
  .rtl .ant-col-lg-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-lg-push-24 {
    right: 100%;
  }
  .rtl .ant-col-lg-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-lg-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-lg-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-lg-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-lg-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-lg-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-lg-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-lg-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-lg-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-lg-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-lg-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-lg-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-lg-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-lg-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-lg-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-lg-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-lg-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-lg-push-18 {
    right: 75%;
  }
  .rtl .ant-col-lg-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-lg-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-lg-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-lg-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-lg-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-lg-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-lg-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-lg-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-lg-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-lg-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-lg-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-lg-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-lg-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-lg-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-lg-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-lg-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-lg-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-lg-push-12 {
    right: 50%;
  }
  .rtl .ant-col-lg-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-lg-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-lg-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-lg-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-lg-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-lg-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-lg-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-lg-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-lg-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-lg-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-lg-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-lg-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-lg-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-lg-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-lg-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-lg-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-lg-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-lg-push-6 {
    right: 25%;
  }
  .rtl .ant-col-lg-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-lg-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-lg-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-lg-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-lg-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-lg-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-lg-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-lg-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-lg-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-lg-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-lg-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-lg-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-lg-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-lg-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-lg-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-lg-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-lg-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-lg-push-0 {
    right: auto;
  }
  .rtl .ant-col-lg-pull-0 {
    left: auto;
  }
  .rtl .ant-col-lg-offset-0 {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .rtl .ant-col-xl-1,
  .rtl .ant-col-xl-2,
  .rtl .ant-col-xl-3,
  .rtl .ant-col-xl-4,
  .rtl .ant-col-xl-5,
  .rtl .ant-col-xl-6,
  .rtl .ant-col-xl-7,
  .rtl .ant-col-xl-8,
  .rtl .ant-col-xl-9,
  .rtl .ant-col-xl-10,
  .rtl .ant-col-xl-11,
  .rtl .ant-col-xl-12,
  .rtl .ant-col-xl-13,
  .rtl .ant-col-xl-14,
  .rtl .ant-col-xl-15,
  .rtl .ant-col-xl-16,
  .rtl .ant-col-xl-17,
  .rtl .ant-col-xl-18,
  .rtl .ant-col-xl-19,
  .rtl .ant-col-xl-20,
  .rtl .ant-col-xl-21,
  .rtl .ant-col-xl-22,
  .rtl .ant-col-xl-23,
  .rtl .ant-col-xl-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-xl-push-24 {
    right: 100%;
  }
  .rtl .ant-col-xl-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-xl-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-xl-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-xl-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-xl-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-xl-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-xl-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-xl-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-xl-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-xl-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-xl-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-xl-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-xl-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-xl-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-xl-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-xl-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-xl-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-xl-push-18 {
    right: 75%;
  }
  .rtl .ant-col-xl-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-xl-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-xl-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-xl-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-xl-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-xl-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-xl-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-xl-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-xl-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-xl-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-xl-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-xl-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-xl-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-xl-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-xl-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-xl-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-xl-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-xl-push-12 {
    right: 50%;
  }
  .rtl .ant-col-xl-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-xl-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-xl-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-xl-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-xl-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-xl-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-xl-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-xl-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-xl-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-xl-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-xl-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-xl-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-xl-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-xl-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-xl-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-xl-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-xl-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-xl-push-6 {
    right: 25%;
  }
  .rtl .ant-col-xl-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-xl-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-xl-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-xl-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-xl-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-xl-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-xl-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-xl-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-xl-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-xl-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-xl-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-xl-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-xl-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-xl-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-xl-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-xl-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-xl-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-xl-push-0 {
    right: auto;
  }
  .rtl .ant-col-xl-pull-0 {
    left: auto;
  }
  .rtl .ant-col-xl-offset-0 {
    margin-right: 0;
  }
  .rtl .ant-col-xl-order-0 {
    order: 0;
  }
}

@media (min-width: 1600px) {
  .rtl .ant-col-xxl-1,
  .rtl .ant-col-xxl-2,
  .rtl .ant-col-xxl-3,
  .rtl .ant-col-xxl-4,
  .rtl .ant-col-xxl-5,
  .rtl .ant-col-xxl-6,
  .rtl .ant-col-xxl-7,
  .rtl .ant-col-xxl-8,
  .rtl .ant-col-xxl-9,
  .rtl .ant-col-xxl-10,
  .rtl .ant-col-xxl-11,
  .rtl .ant-col-xxl-12,
  .rtl .ant-col-xxl-13,
  .rtl .ant-col-xxl-14,
  .rtl .ant-col-xxl-15,
  .rtl .ant-col-xxl-16,
  .rtl .ant-col-xxl-17,
  .rtl .ant-col-xxl-18,
  .rtl .ant-col-xxl-19,
  .rtl .ant-col-xxl-20,
  .rtl .ant-col-xxl-21,
  .rtl .ant-col-xxl-22,
  .rtl .ant-col-xxl-23,
  .rtl .ant-col-xxl-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-xxl-push-24 {
    right: 100%;
  }
  .rtl .ant-col-xxl-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-xxl-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-xxl-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-xxl-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-xxl-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-xxl-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-xxl-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-xxl-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-xxl-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-xxl-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-xxl-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-xxl-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-xxl-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-xxl-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-xxl-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-xxl-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-xxl-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-xxl-push-18 {
    right: 75%;
  }
  .rtl .ant-col-xxl-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-xxl-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-xxl-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-xxl-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-xxl-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-xxl-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-xxl-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-xxl-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-xxl-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-xxl-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-xxl-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-xxl-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-xxl-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-xxl-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-xxl-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-xxl-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-xxl-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-xxl-push-12 {
    right: 50%;
  }
  .rtl .ant-col-xxl-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-xxl-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-xxl-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-xxl-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-xxl-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-xxl-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-xxl-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-xxl-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-xxl-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-xxl-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-xxl-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-xxl-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-xxl-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-xxl-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-xxl-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-xxl-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-xxl-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-xxl-push-6 {
    right: 25%;
  }
  .rtl .ant-col-xxl-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-xxl-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-xxl-order-6 {
    order: 6;
  }
  .rtl .ant-col-xxl-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-xxl-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-xxl-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-xxl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .rtl .ant-col-xxl-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-xxl-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-xxl-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-xxl-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-xxl-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-xxl-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-xxl-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-xxl-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-xxl-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-xxl-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-xxl-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-xxl-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-xxl-push-0 {
    right: auto;
  }
  .rtl .ant-col-xxl-pull-0 {
    left: auto;
  }
  .rtl .ant-col-xxl-offset-0 {
    margin-right: 0;
  }
}

.rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
  right: 16px;
}

.rtl .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
}

.rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topleft,
.rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topright,
.rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topleft,
.rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topright {
  animation-name: antSlideDownIn;
}

.rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomleft,
.rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomright,
.rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomleft,
.rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomright {
  animation-name: antSlideUpIn;
}

.rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topleft,
.rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topright {
  animation-name: antSlideDownOut;
}

.rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomleft,
.rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomright {
  animation-name: antSlideUpOut;
}

.rtl .ant-calendar-picker-clear,
.rtl .ant-calendar-picker-icon {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 12px;
  top: 50%;
  margin-top: -7px;
  line-height: 14px;
  font-size: 12px;
  transition: all 0.3s;
  user-select: none;
}

.rtl .ant-calendar-picker-small .ant-calendar-picker-clear,
.rtl .ant-calendar-picker-small .ant-calendar-picker-icon {
  left: 8px;
}

.rtl .ant-calendar {
  position: relative;
  outline: none;
  width: 280px;
  border: 1px solid #fff;
  list-style: none;
  font-size: 14px;
  text-align: right;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  line-height: 1.5;
}

.rtl .ant-calendar-header .ant-calendar-prev-century-btn,
.rtl .ant-calendar-header .ant-calendar-prev-decade-btn,
.rtl .ant-calendar-header .ant-calendar-prev-year-btn {
  right: 7px;
}

.rtl .ant-calendar-header .ant-calendar-next-century-btn,
.rtl .ant-calendar-header .ant-calendar-next-decade-btn,
.rtl .ant-calendar-header .ant-calendar-next-year-btn {
  left: 7px;
}

.rtl .ant-calendar-header .ant-calendar-prev-month-btn {
  right: 29px;
}

.rtl .ant-calendar-header .ant-calendar-prev-month-btn:after {
  content: "\2039";
}

.rtl .ant-calendar-header .ant-calendar-next-month-btn {
  left: 29px;
}

.rtl .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  position: relative;
  margin-left: 5px;
  padding-right: 5px;
}

.rtl .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date:before {
  content: " ";
  position: absolute;
  top: -1px;
  right: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid #bcbcbc;
  border-radius: 2px;
}

.rtl .ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rtl .ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rtl .ant-calendar .ant-calendar-clear-btn {
  display: none;
  position: absolute;
  left: 5px;
  text-indent: -76px;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 7px;
  margin: 0;
}

.rtl .ant-calendar .ant-calendar-ok-btn > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn:hover > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn:active > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar-range-left {
  float: right;
}

.rtl .ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-left: 1.5px solid #e8e8e8;
}

.rtl .ant-calendar-range-right {
  float: left;
}

.rtl .ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-right: 1.5px solid #e8e8e8;
}

.rtl .ant-calendar-range-middle {
  position: absolute;
  right: 50%;
  width: 20px;
  margin-right: -132px;
  text-align: center;
  height: 34px;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.45);
}

.rtl .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-right: -118px;
}

.rtl .ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
  margin-right: -12px;
}

.rtl .ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-right: 0;
}

.rtl .ant-calendar-range .ant-calendar-input,
.rtl .ant-calendar-range .ant-calendar-time-picker-input {
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  height: 24px;
  border: 0;
  box-shadow: none;
  padding-right: 0;
  padding-left: 0;
}

.rtl .ant-calendar-range .ant-calendar-in-range-cell:before {
  content: "";
  display: block;
  background: #e6f7ff;
  border-radius: 0;
  border: 0;
  position: absolute;
  top: 4px;
  bottom: 4px;
  right: 0;
  left: 0;
}

.rtl div.ant-calendar-range-quick-selector {
  text-align: right;
}

.rtl div.ant-calendar-range-quick-selector > a {
  margin-left: 8px;
}

.rtl .ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  margin-left: 8px;
}

.rtl .ant-calendar-time-picker-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 14px;
  text-align: right;
  background-color: #fff;
  background-clip: padding-box;
  line-height: 1.5;
  overflow: hidden;
  width: 100%;
}

.rtl .ant-calendar-time-picker-select {
  float: right;
  font-size: 14px;
  border-left: 1px solid #e8e8e8;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  height: 226px;
}

.rtl .ant-calendar-time-picker-select:first-child {
  border-right: 0;
  margin-right: 0;
}

.rtl .ant-calendar-time-picker-select:last-child {
  border-left: 0;
}

.rtl .ant-calendar-time .ant-calendar-footer-btn {
  text-align: left;
}

.rtl .ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: right;
  margin: 0;
}

.rtl .ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  display: inline-block;
  margin-left: 8px;
}

.rtl .ant-calendar-month-panel {
  position: absolute;
  top: 1px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  border-radius: 4px;
  background: #fff;
  outline: none;
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  right: 7px;
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:after,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:after,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:after {
  content: "\AB";
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  left: 7px;
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
  right: 29px;
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:after {
  content: "\2039";
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
  left: 29px;
}

.rtl .ant-calendar-year-panel {
  position: absolute;
  top: 1px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  border-radius: 4px;
  background: #fff;
  outline: none;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
  right: 7px;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  left: 7px;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
  right: 29px;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:after {
  content: "\2039";
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
  left: 29px;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:after {
  content: "\203A";
}

.rtl .ant-calendar-decade-panel {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  outline: none;
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
  right: 7px;
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:after,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:after,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:after {
  content: "\AB";
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  left: 7px;
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
  right: 29px;
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
  left: 29px;
}

.rtl .ant-time-picker-panel-inner {
  position: relative;
  outline: none;
  list-style: none;
  font-size: 14px;
  text-align: right;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  overflow: hidden;
  right: -2px;
}

.rtl .ant-time-picker-panel-clear-btn {
  position: absolute;
  left: 8px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 7px;
  margin: 0;
}

.rtl .ant-time-picker-panel-select {
  float: right;
  font-size: 14px;
  border-right: 1px solid #e8e8e8;
  box-sizing: border-box;
  width: 56px;
  overflow: hidden;
  position: relative;
  max-height: 192px;
}

.rtl .ant-time-picker-panel-select:first-child {
  border-right: 0;
  margin-right: 0;
}

.rtl .ant-time-picker-panel-select:last-child {
  border-left: 0;
}

.rtl .ant-time-picker-panel-select li {
  list-style: none;
  box-sizing: content-box;
  margin: 0;
  padding: 0 12px 0 0;
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: right;
  cursor: pointer;
  user-select: none;
  transition: background 0.3s;
}

.rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topleft,
.rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topright,
.rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topleft,
.rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topright {
  animation-name: antSlideDownIn;
}

.rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomleft,
.rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomright,
.rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomleft,
.rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomright {
  animation-name: antSlideUpIn;
}

.rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topleft,
.rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topright {
  animation-name: antSlideDownOut;
}

.rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomleft,
.rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomright {
  animation-name: antSlideUpOut;
}

.rtl .ant-time-picker-icon {
  position: absolute;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 14px;
  height: 14px;
  line-height: 14px;
  left: 11px;
  color: rgba(0, 0, 0, 0.25);
  top: 50%;
  margin-top: -7px;
}

.rtl .ant-time-picker-small .ant-time-picker-icon {
  left: 7px;
}

.rtl .ant-divider-horizontal.ant-divider-with-text-left {
  display: table;
  white-space: nowrap;
  text-align: center;
  background: transparent;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin: 16px 0;
}

.rtl .ant-divider-horizontal.ant-divider-with-text-left:before {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 5%;
  border-top: 1px solid #e8e8e8;
  transform: translateY(50%);
}

.rtl .ant-divider-horizontal.ant-divider-with-text-left:after {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 95%;
  border-top: 1px solid #e8e8e8;
  transform: translateY(50%);
}

.rtl .ant-divider-horizontal.ant-divider-with-text-left-inner-text {
  display: inline-block;
  padding: 0 10px;
}

.rtl .ant-divider-horizontal.ant-divider-with-text-right {
  display: table;
  white-space: nowrap;
  text-align: center;
  background: transparent;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin: 16px 0;
}

.rtl .ant-divider-horizontal.ant-divider-with-text-right:before {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 95%;
  border-top: 1px solid #e8e8e8;
  transform: translateY(50%);
}

.rtl .ant-divider-horizontal.ant-divider-with-text-right:after {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 5%;
  border-top: 1px solid #e8e8e8;
  transform: translateY(50%);
}

.rtl .ant-divider-horizontal.ant-divider-with-text-right-inner-text {
  display: inline-block;
  padding: 0 10px;
}

.rtl .ant-dropdown-menu {
  text-align: right;
}

.rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  left: 8px;
}

.rtl .ant-dropdown-menu-submenu-title {
  padding-left: 26px;
}

.rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  top: 0;
  right: 100%;
  position: absolute;
  min-width: 100%;
  margin-right: 4px;
  transform-origin: 100% 0;
}

.rtl .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomleft,
.rtl .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomleft,
.rtl .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.rtl .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.rtl .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomright,
.rtl .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomright {
  animation-name: antSlideUpIn;
}

.rtl .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topleft,
.rtl .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topleft,
.rtl .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.rtl .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.rtl .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topright,
.rtl .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topright {
  animation-name: antSlideDownIn;
}

.rtl .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomleft,
.rtl .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.rtl .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomright {
  animation-name: antSlideUpOut;
}

.rtl .ant-pagination.mini .ant-pagination-prev,
.rtl .ant-pagination.mini .ant-pagination-next {
  transform: rotate(180deg);
}

.rtl .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topleft,
.rtl .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.rtl .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topright {
  animation-name: antSlideDownOut;
}

.rtl .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-form-item-required:before {
  display: inline-block;
  margin-left: 4px;
  content: "*";
  font-family: SimSun;
  line-height: 1;
  font-size: 14px;
  color: #f5222d;
}

.rtl .ant-form-item-label {
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.rtl .ant-form-text {
  display: inline-block;
  padding-left: 8px;
}

.rtl form .has-feedback .ant-input {
  padding-left: 24px;
}

.rtl form .has-feedback > .ant-select .ant-select-arrow,
.rtl form .has-feedback > .ant-select .ant-select-selection__clear,
.rtl form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.rtl form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  left: 28px;
}

.rtl form .has-feedback > .ant-select .ant-select-selection-selected-value,
.rtl form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-left: 42px;
}

.rtl form .has-feedback .ant-cascader-picker-arrow {
  margin-left: 17px;
}

.rtl form .has-feedback .ant-cascader-picker-clear {
  left: 28px;
}

.rtl form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  left: 28px;
}

.rtl form .has-feedback .ant-calendar-picker-icon,
.rtl form .has-feedback .ant-time-picker-icon,
.rtl form .has-feedback .ant-calendar-picker-clear,
.rtl form .has-feedback .ant-time-picker-clear {
  left: 28px;
}

.rtl form .ant-radio-inline,
.rtl form .ant-checkbox-inline {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
  margin-right: 8px;
}

.rtl form .ant-radio-inline:first-child,
.rtl form .ant-checkbox-inline:first-child {
  margin-right: 0;
}

.rtl form .ant-checkbox-vertical,
.rtl form .ant-radio-vertical {
  display: block;
}

.rtl form .ant-checkbox-vertical + .ant-checkbox-vertical,
.rtl form .ant-radio-vertical + .ant-radio-vertical {
  margin-right: 0;
}

.rtl form .ant-input-number + .ant-form-text {
  margin-right: 8px;
}

.rtl .ant-input-group-wrap .ant-select-selection {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-input-group-wrap .ant-select-selection:hover {
  border-color: #d9d9d9;
}

.rtl .ant-input-group-wrap .ant-select-selection--single {
  margin-right: -1px;
  height: 40px;
  background-color: #eee;
}

.rtl .ant-input-group-wrap .ant-select-selection--single .ant-select-selection__rendered {
  padding-right: 8px;
  padding-left: 25px;
}

.rtl .ant-form-vertical .ant-form-item-label,
.rtl .ant-col-24.ant-form-item-label,
.rtl .ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  margin: 0;
  display: block;
  text-align: right;
  line-height: 1.5;
}

@media (max-width: 575px) {
  .rtl .ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
  .rtl .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

@media (max-width: 767px) {
  .rtl .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

@media (max-width: 991px) {
  .rtl .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

@media (max-width: 1199px) {
  .rtl .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

@media (max-width: 1599px) {
  .rtl .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

.rtl .ant-form-inline .ant-form-item {
  display: inline-block;
  margin-left: 16px;
  margin-bottom: 0;
}

.rtl .has-success.has-feedback .ant-form-item-children:after,
.rtl .has-warning.has-feedback .ant-form-item-children:after,
.rtl .has-error.has-feedback .ant-form-item-children:after,
.rtl .is-validating.has-feedback .ant-form-item-children:after {
  position: absolute;
  top: 50%;
  left: 0;
  visibility: visible;
  pointer-events: none;
  width: 32px;
  height: 20px;
  line-height: 20px;
  margin-top: -10px;
  text-align: center;
  font-size: 14px;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  font-family: "anticon";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  z-index: 1;
}

.rtl .ant-input-number-handler-up-inner,
.rtl .ant-input-number-handler-down-inner {
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 12px;
  user-select: none;
  position: absolute;
  width: 12px;
  height: 12px;
  transition: all 0.1s linear;
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  transform: scale(0.58333) rotate(0deg);
  left: 4px;
  color: rgba(0, 0, 0, 0.45);
}

.rtl .ant-layout-sider-right {
  order: 1;
}

.rtl .ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  left: -36px;
  text-align: center;
  width: 36px;
  height: 42px;
  line-height: 42px;
  background: #001529;
  color: #fff;
  font-size: 18px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.rtl .ant-list-pagination {
  margin-top: 24px;
  text-align: left;
}

.rtl .ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}

.rtl .ant-list-item-meta-avatar {
  margin-left: 16px;
}

.rtl .ant-list-item-action {
  font-size: 0;
  flex: 0 0 auto;
  margin-right: 48px;
  padding: 0;
  list-style: none;
}

.rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
}

.rtl .ant-list-item-action-split {
  background-color: #e8e8e8;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 14px;
}

.rtl .ant-list-vertical .ant-list-item-extra {
  margin-right: 58px;
}

.rtl .ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}

.rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
}

.rtl .ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}

.rtl .ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}

.rtl .ant-list-bordered .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
  padding-right: 24px;
  padding-left: 24px;
}

.rtl .ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}

.rtl .ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 16px;
  padding-left: 16px;
}

@media screen and (max-width: 768px) {
  .rtl .ant-list-item-action {
    margin-right: 24px;
  }
  .rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
  }
}

@media screen and (max-width: 480px) {
  .rtl .ant-list-item-action {
    margin-right: 12px;
  }
  .rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 0;
  }
}

.rtl .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  right: 50%;
  margin: -10px;
}

.rtl .ant-spin-blur:after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.3;
  transition: all 0.3s;
  z-index: 10;
}

.rtl .ant-spin-dot i:nth-child(1) {
  right: 0;
  top: 0;
}

.rtl .ant-spin-dot i:nth-child(2) {
  left: 0;
  top: 0;
  animation-delay: 0.4s;
}

.rtl .ant-spin-dot i:nth-child(3) {
  left: 0;
  bottom: 0;
  animation-delay: 0.8s;
}

.rtl .ant-spin-dot i:nth-child(4) {
  right: 0;
  bottom: 0;
  animation-delay: 1.2s;
}

.rtl .ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}

.rtl .ant-pagination-item {
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  min-width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  margin-left: 8px;
  margin-right: 0;
  font-family: Arial;
  outline: 0;
}

.rtl .ant-pagination-prev,
.rtl .ant-pagination-next,
.rtl .ant-pagination-jump-prev,
.rtl .ant-pagination-jump-next {
  margin-left: 8px;
  margin-right: 0;
  transform: scale(-1);
}

.rtl .ant-pagination-options {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
}

.rtl .ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  margin-left: 8px;
}

.rtl .ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  margin-left: 8px;
  height: 24px;
}

.rtl .ant-pagination-simple .ant-pagination-simple-pager input {
  margin-left: 8px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  outline: none;
  padding: 0 6px;
  height: 100%;
  text-align: center;
  transition: border-color 0.3s;
}

.rtl .ant-pagination.mini .ant-pagination-jump-prev,
.rtl .ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  line-height: 24px;
  margin-left: 0;
}

.rtl .ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
}

.rtl .ant-mention-dropdown {
  right: -9999px;
}

.rtl .ant-menu-item .anticon,
.rtl .ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-left: 10px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: 50%;
  left: 16px;
  right: auto;
  width: 10px;
}

.rtl .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 34px;
  padding-right: 16px;
}

.rtl .ant-tooltip-placement-right,
.rtl .ant-tooltip-placement-rightTop,
.rtl .ant-tooltip-placement-rightBottom {
  padding-right: 8px;
}

.rtl .ant-tooltip-placement-left,
.rtl .ant-tooltip-placement-leftTop,
.rtl .ant-tooltip-placement-leftBottom {
  padding-left: 8px;
}

.rtl .ant-tooltip-inner {
  text-align: right;
}

.rtl .ant-tooltip-placement-top .ant-tooltip-arrow {
  right: 50%;
  margin-right: -5px;
}

.rtl .ant-tooltip-placement-topleft .ant-tooltip-arrow {
  right: 16px;
}

.rtl .ant-tooltip-placement-topright .ant-tooltip-arrow {
  left: 16px;
}

.rtl .ant-tooltip-placement-right .ant-tooltip-arrow,
.rtl .ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.rtl .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  right: 3px;
  border-width: 5px 0 5px 5px;
  border-left-color: rgba(0, 0, 0, 0.75);
}

.rtl .ant-tooltip-placement-left .ant-tooltip-arrow,
.rtl .ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.rtl .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  left: 3px;
  border-width: 5px 5px 5px 0;
  border-right-color: rgba(0, 0, 0, 0.75);
}

.rtl .ant-tooltip-placement-bottom .ant-tooltip-arrow {
  right: 50%;
  margin-right: -5px;
}

.rtl .ant-tooltip-placement-bottomleft .ant-tooltip-arrow {
  right: 16px;
}

.rtl .ant-tooltip-placement-bottomright .ant-tooltip-arrow {
  left: 16px;
}

.rtl .ant-message {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: fixed;
  z-index: 1010;
  width: 100%;
  top: 16px;
  right: 0;
  pointer-events: none;
}

.rtl .ant-message .anticon {
  margin-left: 8px;
  font-size: 16px;
  top: 1px;
  position: relative;
}

.rtl .ant-modal-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.rtl .ant-modal-close {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  z-index: 10;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: color 0.3s;
  color: rgba(0, 0, 0, 0.45);
  outline: 0;
  padding: 0;
}

.rtl .ant-modal-footer {
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: left;
  border-radius: 0 0 4px 4px;
}

.rtl .ant-modal-footer button + button {
  margin-right: 8px;
  margin-bottom: 0;
}

.rtl .ant-modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #373737;
  background-color: rgba(0, 0, 0, 0.65);
  height: 100%;
  z-index: 1000;
  filter: alpha(opacity=50);
}

.rtl .ant-confirm-body .ant-confirm-content {
  margin-right: 38px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 8px;
}

.rtl .ant-confirm-body > .anticon {
  font-size: 22px;
  margin-left: 16px;
  float: right;
}

.rtl .ant-confirm .ant-confirm-btns {
  margin-top: 24px;
  float: left;
}

.rtl .ant-confirm .ant-confirm-btns button + button {
  margin-right: 8px;
  margin-bottom: 0;
}

.rtl .ant-notification {
  margin-left: 24px;
}

.rtl .ant-notification-topleft,
.rtl .ant-notification-bottomleft {
  margin-right: 24px;
  margin-left: 0;
}

.rtl .ant-notification-topleft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.rtl .ant-notification-bottomleft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.rtl .ant-notification-topleft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.rtl .ant-notification-bottomleft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationleftFadeIn;
}

.rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-left: 24px;
}

.rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  font-size: 16px;
  margin-right: 24px;
  margin-bottom: 4px;
}

.rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  font-size: 14px;
}

.rtl .ant-notification-notice-icon {
  position: absolute;
  font-size: 24px;
  line-height: 24px;
  margin-right: 4px;
}

.rtl .ant-notification-notice-close {
  position: absolute;
  left: 22px !important;
  text-align: right;
  right: auto;
  top: 16px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}

.rtl .ant-notification-notice-btn {
  float: left;
  margin-top: 16px;
}

.rtl .ant-modal-confirm-btns {
  float: left;
}

.rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-left: 16px;
  margin-right: 0;
}

@keyframes NotificationFadeIn {
  0% {
    opacity: 0;
    right: 384px;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes NotificationleftFadeIn {
  0% {
    opacity: 0;
    left: 384px;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

.rtl .ant-popover {
  right: 0;
  text-align: right;
}

.rtl .ant-popover-placement-top,
.rtl .ant-popover-placement-topleft,
.rtl .ant-popover-placement-topright {
  padding-bottom: 9px;
}

.rtl .ant-popover-placement-right,
.rtl .ant-popover-placement-rightTop,
.rtl .ant-popover-placement-rightBottom {
  padding-right: 9px;
}

.rtl .ant-popover-placement-bottom,
.rtl .ant-popover-placement-bottomleft,
.rtl .ant-popover-placement-bottomright {
  padding-top: 9px;
}

.rtl .ant-popover-placement-left,
.rtl .ant-popover-placement-leftTop,
.rtl .ant-popover-placement-leftBottom {
  padding-left: 9px;
}

.rtl .ant-popover-message-title {
  padding-right: 22px;
}

.rtl .ant-popover-buttons {
  text-align: left;
  margin-bottom: 4px;
}

.rtl .ant-popover-buttons button {
  margin-right: 8px;
}

.rtl .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-topleft > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-topright > .ant-popover-content > .ant-popover-arrow {
  bottom: 6px;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}

.rtl .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  right: 50%;
  transform: translateX(50%) rotate(-45deg);
}

.rtl .ant-popover-placement-topleft > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}

.rtl .ant-popover-placement-topright > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}

.rtl .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}

.rtl .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.rtl .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}

.rtl .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}

.rtl .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-bottomleft > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-bottomright > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  box-shadow: 1px -1px 4px rgba(0, 0, 0, 0.06);
}

.rtl .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  right: 50%;
  transform: translateX(50%) rotate(-45deg);
}

.rtl .ant-popover-placement-bottomleft > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}

.rtl .ant-popover-placement-bottomright > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}

.rtl .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
}

.rtl .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.rtl .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}

.rtl .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}

.rtl .ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-left: 0;
  padding-left: 0;
}

.rtl .ant-progress-show-info .ant-progress-outer {
  padding-left: calc(2em + 8px);
  margin-left: calc(-2em - 8px);
}

.rtl .ant-progress-success-bg {
  background-color: #52c41a;
  position: absolute;
  top: 0;
  right: 0;
}

.rtl .ant-progress-text {
  word-break: normal;
  width: 2em;
  text-align: right;
  font-size: 1em;
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  line-height: 1;
}

.rtl .ant-progress-status-active .ant-progress-bg:before {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  border-radius: 10px;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
}

.rtl .ant-progress-circle .ant-progress-text {
  right: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
}

.rtl .ant-rate-star {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-left: 8px;
  position: relative;
  transition: all 0.3s;
  color: inherit;
  cursor: pointer;
}

.rtl .ant-rate-star-first {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}

.rtl .ant-rate-text {
  margin-right: 8px;
  display: inline-block;
  font-size: 14px;
}

.rtl .ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-bottom: -7px;
}

.rtl .ant-slider-vertical .ant-slider-mark {
  top: 0;
  right: 12px;
  width: 18px;
  height: 100%;
}

.rtl .ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  white-space: nowrap;
}

.rtl .ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}

.rtl .ant-slider-vertical .ant-slider-dot {
  top: auto;
  right: 2px;
  margin-bottom: -4px;
}

.rtl .ant-slider-mark {
  position: absolute;
  top: 14px;
  right: 0;
  width: 100%;
  font-size: 14px;
}

.rtl .ant-slider-dot {
  position: absolute;
  top: -2px;
  margin-right: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e8e8e8;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rtl .ant-slider-dot:first-child {
  margin-right: -4px;
}

.rtl .ant-slider-dot:last-child {
  margin-right: -4px;
}

.rtl .ant-steps-item-icon {
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 32px;
  font-size: 16px;
  margin-left: 8px;
  transition: background-color 0.3s, border-color 0.3s;
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.rtl .ant-steps-item-tail {
  position: absolute;
  right: 0;
  width: 100%;
  top: 12px;
  padding: 0 10px;
}

.rtl .ant-steps-item-title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  display: inline-block;
  padding-left: 16px;
  position: relative;
  line-height: 32px;
}

.rtl .ant-steps-item-title:after {
  content: "";
  height: 1px;
  width: 9999px;
  background: #e8e8e8;
  display: block;
  position: absolute;
  top: 16px;
  right: 100%;
}

.rtl .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-left: 16px;
  white-space: nowrap;
}

.rtl .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-left: 0;
}

.rtl .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}

.rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 32px;
  top: 0;
  right: 0.5px;
  width: 32px;
  height: 32px;
}

.rtl .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-left: 12px;
}

.rtl .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-left: 0;
}

.rtl .ant-steps-small .ant-steps-item-title {
  font-size: 14px;
  line-height: 24px;
  padding-left: 12px;
}

.rtl .ant-steps-vertical .ant-steps-item-icon {
  float: right;
  margin-left: 16px;
}

.rtl .ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail {
  position: absolute;
  right: 16px;
  top: 0;
  height: 100%;
  width: 1px;
  padding: 38px 0 6px;
}

.rtl .ant-steps-vertical.ant-steps-small .ant-steps-item-tail {
  position: absolute;
  right: 12px;
  top: 0;
  padding: 30px 0 6px;
}

@media (max-width: 480px) {
  .rtl .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: right;
    margin-left: 16px;
  }
  .rtl .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    right: 16px;
    top: 0;
    height: 100%;
    width: 1px;
    padding: 38px 0 6px;
  }
  .rtl .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-tail {
    position: absolute;
    right: 12px;
    top: 0;
    padding: 30px 0 6px;
  }
  .rtl .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-title {
    line-height: 24px;
  }
}

.rtl .ant-steps-label-vertical .ant-steps-item-tail {
  padding: 0 24px;
  margin-right: 48px;
}

.rtl .ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-right: 36px;
}

.rtl .ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}

.rtl .ant-steps-label-vertical .ant-steps-item-description {
  text-align: right;
}

.rtl .ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
}

.rtl .ant-steps-dot .ant-steps-item-icon {
  padding-left: 0;
  width: 8px;
  height: 8px;
  line-height: 8px;
  border: 0;
  margin-right: 67px;
  background: transparent;
}

.rtl .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
  width: 100%;
  height: 100%;
}

.rtl .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after {
  content: "";
  background: rgba(0, 0, 0, 0.001);
  width: 60px;
  height: 32px;
  position: absolute;
  top: -12px;
  right: -26px;
}

.rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-top: 8px;
}

.rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item-tail {
  margin: 0;
  right: -9px;
  top: 2px;
  padding: 22px 0 4px;
}

.rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
}

.rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
}

.rtl .ant-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: right;
  border-radius: 4px 4px 0 0;
}

.rtl .ant-table-thead > tr > th {
  text-align: right;
}

.rtl .ant-table-thead > tr > th .anticon-filter,
.rtl .ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  right: auto;
  left: 0;
  margin-right: 0px;
  vertical-align: text-bottom;
}

.rtl .ant-table-thead > tr:first-child > th:first-child {
  text-align: center;
}

.rtl .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters,
.rtl .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 20px !important;
  padding-left: 30px !important;
}

.rtl .ant-table-thead > tr > th .ant-table-column-sorter + .anticon-filter {
  margin-right: 4px;
}

.rtl .ant-table-thead > tr:first-child > th:first-child {
  border-top-right-radius: 4px;
}

.rtl .ant-table-thead > tr:first-child > th:last-child {
  border-top-left-radius: 4px;
}

.rtl .ant-table-footer:before {
  content: "";
  height: 1px;
  background: #fafafa;
  position: absolute;
  top: -1px;
  width: 100%;
  right: 0;
}

.rtl .ant-table.ant-table-bordered .ant-table-title {
  border: 1px solid #e8e8e8;
  padding-right: 16px;
  padding-left: 16px;
}

.rtl .ant-table-thead > tr > th.ant-table-selection-column-custom {
  padding-right: 16px;
  padding-left: 0;
}

.rtl .ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.rtl .ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-left: 0;
}

.rtl .ant-table-loading .ant-table-spin-holder {
  height: 20px;
  line-height: 20px;
  right: 50%;
  top: 50%;
  margin-right: -30px;
  position: absolute;
}

.rtl .ant-table-column-sorter {
  position: relative;
  margin-right: 8px;
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  text-align: center;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.45);
}

.rtl .ant-table-column-sorter-up:after,
.rtl .ant-table-column-sorter-down:after {
  position: absolute;
  content: "";
  height: 30px;
  width: 14px;
  right: 0;
}

.rtl table.ant-table-fixed {
  width: auto;
}

.rtl .ant-table-bordered .ant-table-header > table,
.rtl .ant-table-bordered .ant-table-body > table,
.rtl .ant-table-bordered .ant-table-fixed-left table,
.rtl .ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-left: 0;
  border-bottom: 0;
}

.rtl .ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-table-bordered .ant-table-thead > tr > th,
.rtl .ant-table-bordered .ant-table-tbody > tr > td {
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-placeholder .anticon {
  margin-left: 4px;
}

.rtl .ant-table-pagination.ant-pagination {
  float: left;
  display: flex;
  flex-direction: row-reverse;
  direction: ltr;
}

.rtl .ant-table-filter-dropdown {
  min-width: 96px;
  margin-right: -8px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.rtl .ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-left: 0;
}

.rtl .ant-table-filter-dropdown-link.confirm {
  float: right;
}

.rtl .ant-table-filter-dropdown-link.clear {
  float: left;
}

.rtl .ant-table-selection-select-all-custom {
  margin-left: 4px !important;
}

.rtl .ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-right: -30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.rtl .ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-left: 8px;
}

.rtl .ant-table-fixed-left,
.rtl .ant-table-fixed-right {
  position: absolute;
  top: 0;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  border-radius: 0;
}

.rtl .ant-table-fixed-left table,
.rtl .ant-table-fixed-right table {
  width: auto;
  background: #fff;
}

.rtl .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.rtl .ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}

.rtl .ant-table-fixed-left {
  right: 0;
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}

.rtl .ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}

.rtl .ant-table-fixed-left .ant-table-body-inner {
  margin-left: -20px;
  padding-left: 20px;
}

.rtl .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-left: 0;
}

.rtl .ant-table-fixed-left,
.rtl .ant-table-fixed-left table {
  border-radius: 0 4px 0 0;
}

.rtl .ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-left-radius: 0;
}

.rtl .ant-table-fixed-right {
  left: 0;
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}

.rtl .ant-table-fixed-right,
.rtl .ant-table-fixed-right table {
  border-radius: 4px 0 0 0;
}

.rtl .ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}

.rtl .ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-right-radius: 0;
}

.rtl .ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: none;
}

.rtl .ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  box-shadow: none;
}

.rtl .ant-table-small.ant-table-bordered {
  border-left: 0;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-content {
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-footer:before {
  display: none;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-right: 0;
  border-bottom: 0;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-thead > tr > th:last-child,
.rtl .ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-left: none;
}

.rtl .ant-tag {
  margin-left: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.rtl .ant-tag .anticon-cross {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333) rotate(0deg);
  cursor: pointer;
  margin-right: 3px;
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
}

.rtl .ant-timeline-item-tail {
  position: absolute;
  right: 4px;
  top: 0.75em;
  height: 100%;
  border-right: 2px solid #e8e8e8;
}

.rtl .ant-timeline-item-head-custom {
  right: 5px;
  width: auto;
}

.rtl .ant-timeline-item-last .ant-timeline-item-tail {
  border-right: 2px dotted #e8e8e8;
  display: none;
}

.rtl .ant-transfer-list-search-action {
  color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 28px;
  line-height: 32px;
  text-align: center;
}

.rtl .ant-transfer-list-header {
  right: 0;
  width: 100%;
}

.rtl .ant-transfer-list-header-title {
  position: absolute;
  left: 12px;
}

.rtl .ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  width: 100%;
}

.rtl .ant-transfer-list-content-item > span {
  padding-left: 0;
}

.rtl .ant-transfer-list-footer {
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}

.rtl .ant-select-tree-checkbox-checked:after {
  position: absolute;
  top: 0;
  right: 0;
}

.rtl .ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
}

.rtl .ant-select-tree-checkbox-inner:after {
  right: 4.57142857px;
  border-right: 0;
}

.rtl .ant-select-tree-checkbox-input {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rtl .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
  content: " ";
  transform: scale(1);
  position: absolute;
  right: 2.42857143px;
  top: 5.92857143px;
  width: 9.14285714px;
  height: 1.14285714px;
}

.rtl .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
  transform: rotate(-45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  content: " ";
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.rtl .ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-right: 8px;
}

.rtl .ant-select-tree-checkbox-wrapper + span,
.rtl .ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-left: 8px;
}

.rtl .ant-select-tree-checkbox-group-item:last-child {
  margin-left: 0;
}

.rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-right: 0;
}

.rtl .ant-select-tree-icon__open {
  margin-left: 2px;
  vertical-align: top;
}

.rtl .ant-select-tree-icon__close {
  margin-left: 2px;
  vertical-align: top;
}

.rtl .ant-tree-checkbox-checked:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #0793C7;
  content: "";
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  visibility: hidden;
}

.rtl .ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  right: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
}

.rtl .ant-tree-checkbox-inner:after {
  transform: rotate(-45deg) scale(0);
  position: absolute;
  right: 4.57142857px;
  top: 1.14285714px;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  content: " ";
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}

.rtl .ant-tree-checkbox-input {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rtl .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
  content: " ";
  transform: scale(1);
  position: absolute;
  right: 2.42857143px;
  top: 5.92857143px;
  width: 9.14285714px;
  height: 1.14285714px;
}

.rtl .ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
  transform: rotate(-45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  content: " ";
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.rtl .ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-right: 8px;
}

.rtl .ant-tree-checkbox-wrapper + span,
.rtl .ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-tree-checkbox-group-item {
  display: inline-block;
  margin-left: 8px;
}

.rtl .ant-tree-checkbox-group-item:last-child {
  margin-left: 0;
}

.rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-right: 0;
}

.rtl .ant-tree li span.ant-tree-icon_loading {
  position: absolute;
  right: 0;
  top: 1px;
  background: #fff;
  transform: translateX(100%);
  transition: all 0.3s;
}

.rtl .ant-tree-icon__open {
  margin-left: 2px;
  vertical-align: top;
}

.rtl .ant-tree-icon__close {
  margin-left: 2px;
  vertical-align: top;
}

.rtl .ant-tree.ant-tree-show-line li:not(:last-child):before {
  content: " ";
  width: 1px;
  border-right: 1px solid #d9d9d9;
  height: 100%;
  position: absolute;
  right: 12px;
  margin: 22px 0;
}

.rtl .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #d9d9d9;
  width: 104px;
  height: 104px;
  border-radius: 4px;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  vertical-align: top;
  margin-left: 8px;
  margin-bottom: 8px;
  display: table;
}

.rtl .ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 22px;
  width: 100%;
  display: none;
}

.rtl .ant-upload-list-item .anticon-cross {
  left: 4px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}

.rtl .ant-upload-list-item-error .anticon-cross {
  opacity: 1;
  color: #f5222d !important;
}

.rtl .ant-upload-list-item-progress {
  line-height: 0;
  font-size: 14px;
  position: absolute;
  width: 100%;
  bottom: -12px;
  padding-right: 26px;
}

.rtl .ant-upload-list-picture .ant-upload-list-item-thumbnail,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  position: absolute;
  top: 8px;
  right: 8px;
  text-align: center;
}

.rtl .ant-upload-list-picture .ant-upload-list-item-icon,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 36px;
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -18px;
  margin-right: -18px;
}

.rtl .ant-upload-list-picture .ant-upload-list-item-name,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 8px 0 0;
  line-height: 44px;
  transition: all 0.3s;
  padding-right: 48px;
  padding-left: 8px;
  max-width: 100%;
  display: inline-block;
  box-sizing: border-box;
}

.rtl .ant-upload-list-picture .ant-upload-list-item-progress,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 56px;
  margin-top: 0;
  bottom: 14px;
  width: calc(100% - 24px);
}

.rtl .ant-upload-list-picture .anticon-cross,
.rtl .ant-upload-list-picture-card .anticon-cross {
  position: absolute;
  left: 8px;
  top: 8px;
  line-height: 1;
}

.rtl .ant-upload-list-picture-card .ant-upload-list-item {
  float: right;
  width: 104px;
  height: 104px;
  margin: 0 0 8px 8px;
}

.rtl .ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  z-index: 10;
  white-space: nowrap;
  opacity: 0;
  transition: all 0.3s;
}

.rtl .ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  bottom: 32px;
}

.bg_gray {
  padding: 20px 5%;
}

.profile_container {
  background: #fff;
  padding: 20px 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile_container .react-tel-input input[type=text], .profile_container .react-tel-input input[type=tel] {
  text-align: left !important;
}

body.rtl .profile_container .react-tel-input input[type=text], body.rtl .profile_container .react-tel-input input[type=tel] {
  text-align: right !important;
}

@media only screen and (max-width: 993px) {
  .profile_container .personal-info-input {
    flex-direction: column;
  }
  .profile_container .personal-info-input > div {
    flex: 1;
    margin-right: 0 !important;
    width: 100%;
  }
  .profile_container .personal-info-input > div.pt-3 {
    padding-top: 20px;
  }
  .profile_container .personal-info-input > div:not(:last-of-type) .ant-form-item {
    margin-bottom: 10px;
  }
  .profile_container .personal-info-input > div:last-of-type .ant-form-item {
    margin-bottom: 20px;
  }
}

.profile_container .react-tel-input .selected-flag .flag {
  right: 18px !important;
}

@media only screen and (max-width: 641px) {
  .profile_container .flex-center-between {
    display: block !important;
  }
  .profile_container .react-tel-input input[type=text], .profile_container .react-tel-input input[type=tel] {
    padding-left: 40px !important;
  }
  body.rtl .profile_container .react-tel-input input[type=text], body.rtl .profile_container .react-tel-input input[type=tel] {
    padding-left: 0px !important;
    padding-right: 40px !important;
  }
}

.profile_container .react-tel-input input[type=text], .profile_container .react-tel-input input[type=tel] {
  padding-left: 40px !important;
}

body.rtl .profile_container .react-tel-input input[type=text], body.rtl .profile_container .react-tel-input input[type=tel] {
  padding-left: 0px !important;
  padding-right: 40px !important;
}

@media only screen and (max-width: 362px) {
  .profile_container .has-success.has-feedback .ant-form-item-children-icon svg, .profile_container .has-warning.has-feedback .ant-form-item-children-icon svg, .profile_container .has-error.has-feedback .ant-form-item-children-icon svg, .profile_container .is-validating.has-feedback .ant-form-item-children-icon svg {
    right: 18px !important;
    left: unset !important;
  }
}

.profile_container .profile_title {
  font-size: 24px;
  font-weight: bold;
  color: #242424;
}

.profile_container .personal-info-input > div {
  flex: 1;
}

.profile_container .personal-info-input > div:not(:last-of-type) {
  margin-right: 40px;
}

body.rtl .profile_container .personal-info-input > div:not(:last-of-type) {
  margin-left: 40px !important;
  margin-right: 0;
}

.profile_container .avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}

.profile_container .avatar-upload .avatar-edit {
  position: absolute;
  right: 43px;
  z-index: 1;
  top: 10px;
}

.profile_container .avatar-upload .avatar-edit input {
  display: none;
}

.profile_container .avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all .2s ease-in-out;
}

.profile_container .avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.profile_container .avatar-upload .avatar-edit input + label:after {
  content: url(../../resources/images/camera.svg);
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.profile_container .avatar-upload .avatar-preview {
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.profile_container .avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.profile_container #imagePreview {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  left: -6px;
  top: -5px;
}

.profile_container .profile_subtitle {
  font-size: 18px;
  font-weight: bold;
  color: #242424;
  display: inline-block;
  white-space: nowrap;
  margin-right: 10px;
}

body.rtl .profile_container .profile_subtitle {
  margin-left: 10px;
  margin-right: 0 !important;
}

.profile_container hr {
  width: 83%;
  margin-left: auto;
  display: inline-block;
}

.profile_container .subtitle_holder {
  display: flex;
  align-items: baseline;
}

.profile_container .w-35 {
  width: 35%;
  min-width: 248px;
}

.profile_container .pt-3 {
  padding-top: 30px;
}

.profile_container .line {
  height: 1px;
  background: #dddddd;
  width: 100%;
}

.profile_container .align_center {
  display: flex;
  align-items: center;
}

.profile_container .deactivate_modal .ant-modal-footer {
  border: none;
}

.profile_container .deactivate_modal .ant-modal-header {
  display: flex !important;
  justify-content: center !important;
  border: none !important;
}

.profile_container .deactivate_modal .ant-modal-content {
  padding: 50px 80px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}

.profile_container .deactivate_modal .ant-modal-title {
  font-size: 22px;
  font-weight: bold;
  color: #242424;
}

.profile_container .ant-upload.ant-upload-select-picture-card img {
  height: 100px !important;
}

.changPass_modal .ant-modal-header {
  display: flex !important;
  justify-content: center !important;
  border: none !important;
}

.changPass_modal .ant-modal-title {
  font-size: 22px;
  font-weight: bold;
  color: #242424;
}

.changPass_modal .ant-modal-content {
  padding: 50px 80px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}

@media only screen and (max-width: 769px) {
  .changPass_modal .ant-modal-content {
    padding: 50px 40px;
  }
}

@media only screen and (max-width: 577px) {
  .changPass_modal .ant-modal-content {
    padding: 30px 10px;
  }
  .changPass_modal .ant-modal-content .ant-modal-body {
    padding-bottom: 0;
  }
}

.changPass_modal .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: 35px;
}

body.rtl .changPass_modal .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  left: 35px !important;
  right: auto !important;
}

.changPass_modal .ant-modal-footer {
  border: none;
}

.changPass_modal .ant-form-item-control.has-error .ant-form-explain {
  font-size: 11px !important;
  line-height: 1.2;
  padding-top: 6px;
}

body.rtl .changPass_modal .ant-form-item-control.has-error .ant-form-explain {
  line-height: 1 !important;
}

.changPass_modal .has-success.has-feedback .ant-form-item-children-icon, .changPass_modal .has-warning.has-feedback .ant-form-item-children-icon, .changPass_modal .has-error.has-feedback .ant-form-item-children-icon, .changPass_modal .is-validating.has-feedback .ant-form-item-children-icon {
  top: 62% !important;
  right: 3px !important;
}

body.rtl .changPass_modal .has-success.has-feedback .ant-form-item-children-icon, body.rtl .changPass_modal .has-warning.has-feedback .ant-form-item-children-icon, body.rtl .changPass_modal .has-error.has-feedback .ant-form-item-children-icon, body.rtl .changPass_modal .is-validating.has-feedback .ant-form-item-children-icon {
  right: auto !important;
  left: 3px;
}

body.rtl .changPass_modal .ant-form-item-control .ant-form-explain {
  white-space: unset !important;
  bottom: -25px !important;
}

@media only screen and (max-width: 362px) {
  body.rtl .changPass_modal .ant-form-item-control .ant-form-explain {
    bottom: -29px !important;
    font-size: 10px !important;
  }
}

.changPass_modal .ant-form-item {
  margin-bottom: 45px !important;
}

.timer_holder {
  position: relative;
  display: flex;
  justify-content: center;
}

.timer_holder #countdown {
  position: relative;
  margin: auto;
  height: 80px;
  width: 80px;
  text-align: center;
}

.timer_holder #countdown-number {
  height: 20px;
}

.timer_holder svg {
  position: absolute;
  top: 0;
  width: 80px;
  height: 80px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

.timer_holder svg .dinamic_circle {
  stroke-dasharray: 186px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 3px;
  stroke: #fc5646;
  fill: none;
  animation: countdown 60s linear 1 backwards;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 186px;
  }
}

.timer_holder svg .static_circle {
  stroke-dasharray: 186px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 3px;
  stroke: #b7b7b7;
  fill: #f2f2f2;
  background: #f2f2f2 !important;
}

.timer_holder .contant_holder {
  color: #565656;
  display: inline-block;
  line-height: 60px;
  position: relative;
  z-index: 1;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.password_changed .ant-modal-header {
  display: none;
}

.password_changed .ant-modal-content {
  padding: 5% 5%;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.password_changed .ant-modal-footer {
  border: none;
  display: flex;
  justify-content: center;
}

.password_changed .ant-modal-footer > div > :first-child {
  display: none;
}

.password_changed .ant-modal-footer .ant-btn-primary {
  background: #0793C7;
}

.change_password .ant-modal-header {
  display: none;
}

.change_password .ant-modal-content {
  padding: 5% 5%;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.change_password .ant-modal-footer {
  border: none;
}

.change_password .ant-modal-footer .ant-btn-primary {
  background: #0793C7;
}

.mail {
  color: #174586;
  font-size: 15px;
  font-weight: 500;
}

.size20 {
  font-size: 19px;
  font-weight: 500;
}

.natificatin_holder .ant-table-pagination.ant-pagination {
  display: none;
}

.natificatin_holder .ant-checkbox-wrapper {
  display: none;
}

.natificatin_holder .ant-table-thead > tr > th {
  background: #fff;
}

.natificatin_holder .ant-table-thead > tr > th.ant-table-selection-column, .natificatin_holder .ant-table-tbody > tr > td.ant-table-selection-column {
  display: none;
}

.natificatin_holder .ant-table-thead > tr:first-child > th:last-child > :first-child {
  display: flex;
  justify-content: flex-end;
  margin-right: 15%;
  color: #3967a9;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.natificatin_holder .notifi {
  color: #242424;
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.natificatin_holder .min {
  font-size: 10px;
  font-weight: bold;
  color: #565656;
}

.natificatin_holder .unread {
  width: 10px;
  height: 10px;
  background-color: #3967a9;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  top: 2px;
}

.natificatin_holder .ant-table-row {
  background-color: rgba(57, 103, 169, 0.09);
}

.natificatin_holder .ml-2 {
  margin-left: 20px;
}

.natificatin_holder tr td {
  padding: 0;
}

.natificatin_holder tr .notify-row {
  padding: 16px;
  background-color: rgba(57, 103, 169, 0.09);
}

.natificatin_holder tr .notify-row.seen {
  background-color: #ffffff;
}

.natificatin_holder tr .notify-row.seen .notifi .unread {
  display: none;
}

.natificatin_holder .space_between {
  display: flex;
  justify-content: space-between;
}

.h-100 {
  height: 100%;
}

.small_space_r {
  margin-right: 10px;
}

body.rtl .small_space_r {
  margin-left: 10px;
  margin-right: 0 !important;
}

.add-station-modal .ant-modal-footer {
  padding: 20px 74px !important;
}

.add-station-modal .ant-modal-footer > div button:first-of-type:hover, .add-station-modal .ant-modal-footer > div button:first-of-type:focus, .add-station-modal .ant-modal-footer > div button:first-of-type:active {
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  color: rgba(0, 0, 0, 0.65);
}

.add-station-modal .ant-modal-body {
  padding: 24px 75px !important;
}

@media only screen and (max-width: 577px) {
  .add-station-modal .ant-modal-body {
    padding: 24px !important;
  }
}

.add-station-modal .ant-select.ant-select-open .ant-select-arrow svg {
  color: #444;
}

.add-station-modal .map-wrapper > div > div {
  width: 90% !important;
  position: relative !important;
}

@media only screen and (max-width: 577px) {
  .add-station-modal .map-wrapper > div > div {
    width: 100% !important;
    margin-bottom: 30px;
  }
}

.add-station-modal .map-wrapper > div > div > div {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  border-radius: 8px;
}

.add-station-modal .map-wrapper .ant-form-item .ant-input {
  font-weight: 600;
}

.add-station-modal .map-wrapper .ant-form-item .ant-form-item-label label {
  color: #4b4b4b !important;
  font-weight: normal;
}

.add-station-modal .map-wrapper input {
  position: absolute;
  top: 11px;
  left: 9px;
  border-radius: 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
  border: none;
  padding: 5px 10px;
  width: 60%;
}

.add-station-modal .map-wrapper input::-webkit-input-placeholder {
  color: #bcbcbc;
  font-size: 16px;
}

body.rtl .add-station-modal .map-wrapper input {
  right: 9px;
  left: auto;
}

.add-station-modal .editble-grid-wrapper {
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  max-height: 350px;
  overflow-y: scroll;
}

.add-station-modal .editble-grid-wrapper.isEmpty {
  min-height: 200px;
}

.add-station-modal .editble-grid-wrapper .ant-table-thead > tr > th {
  background: transparent;
  text-align: center;
  border-bottom: 1px solid #CBCBCB !important;
}

.add-station-modal .editble-grid-wrapper .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: transparent;
}

.add-station-modal .editble-grid-wrapper .ant-table-bordered .ant-table-thead > tr > th, .add-station-modal .editble-grid-wrapper .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #CBCBCB !important;
  padding: 8px !important;
  font-weight: bold;
}

.add-station-modal .editble-grid-wrapper .ant-table-bordered .ant-table-thead > tr > th:last-child, .add-station-modal .editble-grid-wrapper .ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none !important;
}

.add-station-modal .editble-grid-wrapper .ant-table table {
  border: none !important;
}

.add-station-modal .editble-grid-wrapper .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.add-station-modal .editble-grid-wrapper th {
  font-size: 15px;
  font-weight: 500;
  color: #7e7e7e;
}

.add-station-modal .editble-grid-wrapper td {
  text-align: center !important;
  color: #404040;
}

.add-station-modal .editble-grid-wrapper td a {
  margin: 0px 11px;
}

.add-station-modal .editble-grid-wrapper td a i {
  font-size: 18px;
}

.add-station-modal .editble-grid-wrapper .ant-select .ant-select-selection {
  border: none !important;
  padding: 0 !important;
  height: 20px;
}

.add-station-modal .editble-grid-wrapper .ant-select .ant-select-selection .ant-select-arrow {
  top: 87%;
}

.add-station-modal .editble-grid-wrapper .ant-select .ant-select-selection .ant-select-selection-selected-value {
  padding-right: 44px !important;
}

body.rtl .add-station-modal .editble-grid-wrapper .ant-select .ant-select-selection .ant-select-selection-selected-value {
  padding-left: 44px !important;
}

.add-station-modal .editble-grid-wrapper .ant-table-placeholder {
  top: 107px !important;
  border: none;
}

body.rtl .add-station-modal .editble-grid-wrapper .ant-table-placeholder {
  border: none;
}

.pricing-settings-wrapper {
  margin: 25px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.pricing-settings-wrapper.form-editable .grid-holder > div:nth-last-child(-n + 3) {
  border-bottom: 1.5px solid #cbcbcb;
}

.pricing-settings-wrapper .grid-holder {
  padding: 10px 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.pricing-settings-wrapper .grid-holder > div {
  min-height: 60px;
  text-align: center;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1.5px solid #cbcbcb;
  border-right: 1.5px solid #cbcbcb;
  position: relative;
}

body.rtl .pricing-settings-wrapper .grid-holder > div {
  border-right: 0;
  border-left: 1.5px solid #cbcbcb;
}

.pricing-settings-wrapper .grid-holder > div:nth-child(1), .pricing-settings-wrapper .grid-holder > div:nth-child(4), .pricing-settings-wrapper .grid-holder > div:nth-child(7) {
  color: #25ae81;
  text-align: left;
  justify-content: flex-start;
}

.pricing-settings-wrapper .grid-holder > div:nth-child(-n + 3) {
  color: #404040;
}

.pricing-settings-wrapper .grid-holder > div:nth-child(3n) {
  border-right: 0;
}

body.rtl .pricing-settings-wrapper .grid-holder > div:nth-child(3n) {
  border-left: 0;
}

.pricing-settings-wrapper .grid-holder > div:nth-last-child(-n + 3) {
  border-bottom: 0;
}

.pricing-settings-wrapper .grid-holder .ant-form-item {
  margin-bottom: 0;
  width: 150px;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number {
  position: relative;
  border: 0;
  margin: 0;
  opacity: 0.42;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number.ant-input-number-disabled {
  background-color: #fff;
  opacity: 1;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number.ant-input-number-disabled:after {
  opacity: 1;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number input {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number input[disabled] {
  background-color: #fff;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number:after {
  content: "L.E";
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  background-color: #fff;
}

body.rtl .pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number:after {
  content: 'ج.م';
  right: auto;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number + .warning {
  position: absolute;
  bottom: -20px;
  width: 100%;
  text-align: center;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d83025;
  font-size: 14px;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number .ant-input-number-handler-wrap span i {
  color: #000;
  font-size: 18px;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number .ant-input-number-handler-wrap span.ant-input-number-handler-down-disabled i, .pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number .ant-input-number-handler-wrap span.ant-input-number-handler-up-disabled i {
  color: #b5b5b5;
}

.pricing-settings-wrapper .grid-holder .edit-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

body.rtl .pricing-settings-wrapper .grid-holder .edit-btn {
  right: auto;
  left: 0;
}

body.rtl .ant-popover {
  right: unset !important;
}

.ant-drawer > button {
  background: transparent !important;
  color: black !important;
  border: none;
  box-shadow: none;
  outline: none !important;
}

.ant-drawer > button:focus, .ant-drawer > button:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.ant-drawer > button:hover {
  color: black !important;
}

.ant-drawer .empty-state {
  margin-top: 25px;
}

.request-info-wrapper > div > div {
  min-height: 30px;
}

.request-info-wrapper .request-actions-wrapper {
  display: flex;
  justify-content: center;
}

.request-info-wrapper .request-actions-wrapper button {
  padding-left: 40px;
  padding-right: 40px;
  margin: auto 15px;
}

@media only screen and (max-width: 993px) {
  .view-profile-drawer .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.view-profile-drawer .ant-drawer-close .anticon {
  color: #c30c00 !important;
}

body.rtl .view-profile-drawer .ant-drawer-close {
  right: unset !important;
  left: 0;
}

.view-profile-drawer .ant-drawer-content-wrapper {
  transition: transform 0.7s ease 0s, width 0.7s ease 0s;
}

.view-profile-drawer .ant-drawer-body {
  padding: 0 !important;
}

body.rtl .view-profile-drawer .ant-tabs-nav .ant-tabs-tab {
  margin: 0 !important;
}

.view-profile-drawer .customer-profile-content .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #eef1f4;
  position: relative;
}

.view-profile-drawer .customer-profile-content .header .user-image {
  width: 87px;
  height: 87px;
  min-width: 87px;
  max-width: 87px;
  min-height: 87px;
  max-height: 87px;
  border-radius: 50%;
}

.view-profile-drawer .customer-profile-content .header .expand {
  position: absolute;
  top: 16px;
  left: 53px;
  cursor: pointer;
}

body.rtl .view-profile-drawer .customer-profile-content .header .expand {
  right: 47px;
  left: unset;
}

.view-profile-drawer .customer-profile-content .header .back-button {
  position: absolute;
  top: 17px;
  left: 25px;
  font-size: 17px;
  cursor: pointer;
}

body.rtl .view-profile-drawer .customer-profile-content .header .back-button {
  right: 25px;
  left: unset;
  transform: rotate(180deg);
}

.view-profile-drawer .customer-profile-content .header .name.without-avatar {
  margin-top: 16px;
  font-size: 20px;
  align-self: flex-start;
}

body.rtl .view-profile-drawer .customer-profile-content .ant-tabs-nav-container {
  transform: scaleX(-1) !important;
}

body.rtl .view-profile-drawer .customer-profile-content .content {
  text-align: right;
}

.view-profile-drawer .customer-profile-content .content .ant-tabs-bar {
  padding: 0 12px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .ant-tabs-bar {
  text-align: left;
}

.view-profile-drawer .customer-profile-content .content .ant-tabs-nav {
  display: flex;
  align-items: center;
  justify-content: start;
}

body.rtl .view-profile-drawer .customer-profile-content .content .ant-tabs-nav {
  display: inline-flex !important;
}

.view-profile-drawer .customer-profile-content .content .ant-tabs-nav .ant-tabs-tab-active {
  color: #2db2bb;
  font-weight: bold;
}

.view-profile-drawer .customer-profile-content .content .ant-tabs-nav .ant-tabs-tab {
  font-size: 14px;
  color: #2db2bb;
}

body.rtl .view-profile-drawer .customer-profile-content .content .ant-tabs-nav .ant-tabs-tab {
  transform: scaleX(-1);
}

.view-profile-drawer .customer-profile-content .content .ant-tabs-ink-bar {
  background: #2db2bb !important;
}

.view-profile-drawer .customer-profile-content .content h4 {
  font-size: 16px;
  font-weight: bold;
  color: #2db2bb;
  line-height: 1.31;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  margin-bottom: 10px;
}

.view-profile-drawer .customer-profile-content .content h4.without-border {
  border-bottom: none;
}

.view-profile-drawer .customer-profile-content .content .buttons-group button:first-of-type {
  margin-inline-end: 10px;
}

.view-profile-drawer .customer-profile-content .content .comment-textArea {
  margin-bottom: 15px;
  overflow-y: scroll;
}

.view-profile-drawer .customer-profile-content .content .comment-textArea p {
  color: #444444;
  font-size: 16px;
}

.view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(1)::before {
  content: url("../../resources/images/icons/email.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(1)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(2)::before {
  content: url("../../resources/images/icons/phone.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(2)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(3)::before {
  content: url("../../resources/images/icons/user.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(3)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(4)::before {
  content: url("../../resources/images/icons/gender.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(4)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g2 div:nth-child(1)::before {
  content: url("../../resources/images/icons/car.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g2 div:nth-child(1)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g2 div:nth-child(2)::before {
  content: url("../../resources/images/icons/battery.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g2 div:nth-child(2)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g2 div:nth-child(3)::before {
  content: url("../../resources/images/icons/driving-license.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g2 div:nth-child(3)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .gray {
  color: #494848;
}

.view-profile-drawer .ant-tabs {
  direction: ltr;
}

.view-profile-drawer .license-img {
  align-items: flex-start;
}

.view-profile-drawer .license-img img {
  width: 130px;
}

.view-profile-drawer .license-img img:first-of-type {
  margin-right: 10px;
}

.view-profile-drawer .license-img img:last-of-type {
  margin-left: 10px;
  width: 17px;
}

.view-profile-drawer .card-status-holder {
  display: flex;
  flex-direction: column;
}

.view-profile-drawer .card-status-holder > label {
  margin-bottom: 15px;
}

.view-profile-drawer .send-comment {
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;
}

.view-profile-drawer .send-comment button {
  margin-inline-start: 5px;
}

.view-profile-drawer .min-h-80 {
  min-height: 80px;
}

.view-profile-drawer .ant-row {
  max-height: calc(100vh - 250px);
  min-height: calc(100vh - 250px);
  overflow: hidden scroll;
}

.view-profile-drawer .details-wrapper div {
  min-height: 21px;
}

body.rtl .ant-drawer-left .ant-drawer-content-wrapper, body.rtl
.ant-drawer-right .ant-drawer-content-wrapper {
  right: unset;
  left: 0;
}

.ant-drawer-content-wrapper {
  transition: width 0.7s ease 0s;
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  opacity: 0 !important;
}

.ant-drawer-mask {
  background-color: transparent !important;
}

.account-auth-wrapper {
  background: url(../../resources/images/website/bannerweb.png) no-repeat;
  background-size: cover;
}

.account-auth-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.48);
  width: 100%;
  height: 100%;
}

.account-auth-wrapper .account-auth-content {
  margin: 0 auto;
  padding-top: 70px;
  padding-bottom: 3%;
  width: 90%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-auth-wrapper .account-auth-content .auth-content-holder {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(255, 255, 255, 0.58);
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 580px;
  height: 75vh;
  z-index: 5;
}

.account-auth-wrapper .account-auth-content .auth-content-holder .have-account-holder {
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: normal;
  line-height: 0.77;
  color: #4e4e4e;
  margin-top: 30px;
}

.account-auth-wrapper .account-auth-content .auth-content-holder .have-account-holder button {
  font-size: 13px;
  font-weight: normal;
  line-height: 0.77;
  color: #008539;
  margin: 0;
}

@media only screen and (max-width: 993px) {
  .account-auth-wrapper .account-auth-content {
    width: 90%;
  }
  .account-auth-wrapper .account-auth-content .auth-content-holder {
    transform: none !important;
    min-height: auto;
    height: 86vh;
  }
  .account-auth-wrapper .account-auth-content .auth-content-holder > div {
    width: 100%;
    padding: 70px 50px;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 993px) and (max-width: 577px) {
  .account-auth-wrapper .account-auth-content .auth-content-holder > div {
    padding: 40px 25px;
    overflow: auto;
  }
}

@media only screen and (max-width: 993px) {
  .account-auth-wrapper .account-auth-content .auth-content-holder > div h1 {
    font-size: 25px;
    font-weight: bold;
  }
  .account-auth-wrapper .account-auth-content .have-account-holder {
    display: flex !important;
  }
}

.account-auth-wrapper .signin-up-wrapper:after {
  content: "";
  background: url(../../resources/images/website/login-form-bg.svg) no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 555;
  pointer-events: none;
}

@media only screen and (max-width: 993px) {
  .account-auth-wrapper .signin-up-wrapper:after {
    background: none;
  }
}

body.rtl .account-auth-wrapper .signin-up-wrapper:after {
  right: 0;
  left: auto;
}

.account-auth-wrapper .signin-up-wrapper .login-form-forgot-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}

.account-auth-wrapper .signin-up-wrapper .login-form-forgot-holder > label {
  padding: 0;
  font-size: 16px;
  color: #000000;
  line-height: 1.19;
  font-weight: normal;
}

.account-auth-wrapper .signin-up-wrapper .login-form-forgot-holder > label .ant-checkbox-inner {
  border-radius: 4px;
  border: solid 1px #000000;
}

@media only screen and (max-width: 769px) {
  .account-auth-wrapper .signin-up-wrapper .login-form-forgot-holder {
    flex-direction: column;
    margin: 15px 0;
  }
}

.account-auth-wrapper .signin-up-wrapper .login-form-forgot {
  font-size: 16px;
  display: block;
  width: fit-content;
  margin: auto;
  margin: 0;
  text-decoration: underline;
}

.account-auth-wrapper .signin-up-wrapper .form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 85px;
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.account-auth-wrapper .signin-up-wrapper .form-container form {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.account-auth-wrapper .signin-up-wrapper .form-container form button {
  font-size: 19px;
  margin-top: 50px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .tel-wrapper .react-tel-input input {
  text-indent: 0px !important;
  padding-top: 16px !important;
}

.account-auth-wrapper .signin-up-wrapper .form-container h1 {
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: bold;
  line-height: 0.61;
  color: #008539;
  white-space: nowrap;
}

.account-auth-wrapper .signin-up-wrapper .form-container .social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item {
  margin-bottom: 20px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item .ant-calendar-picker {
  width: 100%;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item .ant-calendar-picker .ant-calendar-picker-icon {
  font-size: 20px;
  right: auto;
  left: 12px;
  display: flex;
  align-items: center;
  width: 20px;
}

body.rtl .account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item .ant-calendar-picker .ant-calendar-picker-icon {
  left: auto;
  right: 12px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
  display: flex;
  font-size: 20px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item input {
  padding-top: 0 !important;
  height: 42px;
  margin: 0;
  padding-left: 40px !important;
}

body.rtl .account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item input {
  padding-right: 40px !important;
  padding-left: 0 !important;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper {
  border-bottom: 1px solid #dadce0 !important;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .anticon {
  position: absolute;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  margin-left: 14px;
  margin-top: 2px;
  bottom: 0;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .anticon svg {
  display: none;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .anticon:after {
  content: url("../../resources/images/website/icon/gender.svg");
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-radio-group {
  width: 100%;
  text-align: left;
  padding-left: 45px;
}

body.rtl .account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-radio-group {
  text-align: right;
  padding-right: 45px;
  padding-left: 0;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  top: 3.2px;
  left: 3.2px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio + span {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430 !important;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430;
  display: inline-flex;
  align-items: center;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
  border: solid 0.8px #4e4d4d;
  width: 16px;
  height: 16px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-form-explain {
  position: absolute;
}

.account-auth-wrapper .signin-up-wrapper .sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.account-auth-wrapper .signin-up-wrapper .sign-in-container .password-input-holder .anticon svg {
  display: none;
}

.account-auth-wrapper .signin-up-wrapper .sign-in-container .password-input-holder .anticon:after {
  content: url("../../resources/images/website/icon/password.svg");
}

body.rtl .account-auth-wrapper .signin-up-wrapper .sign-in-container {
  right: 0;
  left: auto;
}

.account-auth-wrapper .signin-up-wrapper .sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

body.rtl .account-auth-wrapper .signin-up-wrapper .sign-up-container {
  right: 0;
  left: auto;
}

.account-auth-wrapper .signin-up-wrapper .sign-up-container .password-input-holder .anticon:not(.anticon-eye-invisible):not(.anticon-eye) svg {
  display: none;
}

.account-auth-wrapper .signin-up-wrapper .sign-up-container .password-input-holder .anticon:not(.anticon-eye-invisible):not(.anticon-eye):after {
  content: url("../../resources/images/website/icon/password.svg");
}

@media only screen and (max-width: 769px) {
  .account-auth-wrapper .signin-up-wrapper .sign-up-container .gender-wrapper + button {
    margin-top: 30px;
  }
}

.account-auth-wrapper .signin-up-wrapper .overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 10;
}

body.rtl .account-auth-wrapper .signin-up-wrapper .overlay-container {
  right: 50%;
  left: auto;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container h1 {
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.64px;
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container p {
  font-size: 19px;
  font-weight: normal;
  line-height: 1.53;
  text-align: center;
  color: #ffffff;
  padding-top: 25px;
  padding-bottom: 50px;
  width: 75%;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container button {
  text-transform: capitalize;
  transition: transform 80ms ease-in;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container button:active {
  transform: scale(0.92);
}

.account-auth-wrapper .signin-up-wrapper .overlay-container button:focus {
  outline: none;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container button.ghost {
  background-color: transparent;
  border-color: #ffffff;
  height: 52px;
  border-radius: 26px;
  border: solid 2px #ffffff;
  padding: 5px 75px;
  font-size: 26px;
  font-weight: normal;
  color: #ffffff;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container .overlay {
  background: #008539;
  background-image: linear-gradient(227deg, #86d958 84%, #008539 19%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

body.rtl .account-auth-wrapper .signin-up-wrapper .overlay-container .overlay {
  right: -100%;
  left: auto;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container .overlay .overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

@media only screen and (max-width: 993px) {
  .account-auth-wrapper .signin-up-wrapper .overlay-container {
    display: none;
  }
}

.account-auth-wrapper .signin-up-wrapper.right-panel-active .sign-in-container {
  transform: translateX(100%);
  opacity: 0;
  z-index: 1;
}

body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .sign-in-container {
  transform: translateX(-100%);
}

.account-auth-wrapper .signin-up-wrapper.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s forwards;
}

body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .sign-up-container {
  transform: translateX(-100%);
}

@media only screen and (max-width: 993px) {
  .account-auth-wrapper .signin-up-wrapper.right-panel-active .sign-up-container {
    transform: translateX(0);
    animation: none;
  }
  body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .sign-up-container {
    transform: translateX(0);
  }
}

.account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay-container {
  transform: translateX(100%);
}

.account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay {
  transform: translateX(50%);
}

body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay {
  transform: translateX(-50%);
}

.account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay-left {
  transform: translateX(0);
}

body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay-left {
  transform: translateX(0);
}

.account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay-right {
  transform: translateX(20%);
}

body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay-right {
  transform: translateX(-20%);
}

.account-auth-wrapper .signin-up-wrapper .overlay-left {
  transform: translateX(-20%);
}

body.rtl .account-auth-wrapper .signin-up-wrapper .overlay-left {
  transform: translateX(20%);
}

.account-auth-wrapper .signin-up-wrapper .overlay-right {
  right: 0;
  transform: translateX(0);
}

body.rtl .account-auth-wrapper .signin-up-wrapper .overlay-right {
  left: 0;
  right: auto;
  transform: translateX(0);
}

.account-auth-wrapper .signin-up-wrapper .social-container {
  margin: 20px 0;
}

@keyframes show {
  0% {
    opacity: 0;
    z-index: 1;
  }
  50% {
    opacity: 0;
    z-index: 1;
  }
  51% {
    opacity: 1;
    z-index: 5;
  }
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.register-info-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}

.register-info-wrapper * {
  font-family: Pridi;
}

.register-info-wrapper .ant-input:-moz-placeholder {
  font-weight: 400;
  font-size: 17px;
}

.register-info-wrapper .ant-input::-moz-placeholder {
  font-weight: 400;
  font-size: 17px;
}

.register-info-wrapper .ant-input:-ms-input-placeholder {
  font-weight: 400;
  font-size: 17px;
}

.register-info-wrapper .ant-input::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 17px;
}

.register-info-wrapper .ant-select-selection__placeholder {
  font-weight: 400 !important;
  font-size: 17px !important;
}

.register-info-wrapper .content {
  width: 40%;
  margin: auto;
}

.register-info-wrapper .content h2 {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -1.25px;
  color: #008539;
  text-align: center;
  margin-bottom: 20px;
}

.register-info-wrapper .content h4 {
  font-size: 19px;
  font-weight: normal;
  line-height: 1.53;
  text-align: center;
  color: #716e6e;
  padding-bottom: 10px;
}

.register-info-wrapper .content .form-holder {
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(1)::after {
  content: url("../../resources/images/website/icon/car.svg");
  position: absolute;
  top: 23px;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(1)::after {
  top: 27px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(1) .ant-form-extra {
  font-size: 14px;
  font-weight: normal;
  padding: 0 30px;
  color: #5d71aa;
  white-space: nowrap;
}

@media only screen and (max-width: 577px) {
  .register-info-wrapper .content .form-holder > .ant-form-item:nth-child(1) .ant-form-extra {
    padding: 0;
  }
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(1)::after {
  top: 22px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(2)::after {
  content: url("../../resources/images/website/icon/cartype.svg");
  position: absolute;
  top: 23px;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(2) .ant-form-extra {
  font-size: 14px;
  font-weight: normal;
  padding: 0 30px;
  color: #5d71aa;
  white-space: nowrap;
}

@media only screen and (max-width: 577px) {
  .register-info-wrapper .content .form-holder > .ant-form-item:nth-child(2) .ant-form-extra {
    padding: 0;
  }
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(2)::after {
  top: 22px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(3)::after {
  content: url("../../resources/images/website/icon/battery.svg");
  position: absolute;
  top: 23px;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(3)::after {
  top: 27px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(3) .ant-form-extra {
  font-size: 14px;
  font-weight: normal;
  padding: 0 30px;
  color: #5d71aa;
  white-space: nowrap;
}

@media only screen and (max-width: 577px) {
  .register-info-wrapper .content .form-holder > .ant-form-item:nth-child(3) .ant-form-extra {
    padding: 0;
  }
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(3)::after {
  top: 22px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(4)::after {
  content: url("../../resources/images/website/icon/license.svg");
  position: absolute;
  top: 23px;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(4)::after {
  top: 27px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(4) .ant-form-extra {
  font-size: 14px;
  font-weight: normal;
  padding: 0 30px;
  color: #5d71aa;
  white-space: nowrap;
}

@media only screen and (max-width: 577px) {
  .register-info-wrapper .content .form-holder > .ant-form-item:nth-child(4) .ant-form-extra {
    padding: 0;
  }
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(4)::after {
  top: 22px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(5)::after {
  content: url("../../resources/images/website/icon/address.svg");
  position: absolute;
  top: 23px;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(5)::after {
  top: 27px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(5) .ant-form-extra {
  font-size: 14px;
  font-weight: normal;
  padding: 0 30px;
  color: #5d71aa;
  white-space: nowrap;
}

@media only screen and (max-width: 577px) {
  .register-info-wrapper .content .form-holder > .ant-form-item:nth-child(5) .ant-form-extra {
    padding: 0;
  }
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(5)::after {
  top: 22px !important;
}

.register-info-wrapper .btns-action-holder {
  padding: 50px;
  padding-bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.register-info-wrapper .btns-action-holder a {
  font-size: 21px;
  font-weight: normal;
  color: #575758;
}

@media only screen and (max-width: 769px) {
  .register-info-wrapper .btns-action-holder a {
    font-size: 15px;
    color: #4e4e4e;
  }
}

@media only screen and (max-width: 993px) {
  .register-info-wrapper .content {
    width: 95%;
  }
  .register-info-wrapper .content h2 {
    font-size: 21px;
    font-weight: 500;
  }
  .register-info-wrapper .content h4 {
    font-size: 15px;
    font-weight: normal;
  }
  .register-info-wrapper .content .form-holder {
    padding-left: 25px;
    padding-right: 25px;
  }
  .register-info-wrapper .btns-action-holder {
    padding: 30px;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .register-info-wrapper .btns-action-holder button {
    margin: 0;
    margin-bottom: 15px;
  }
}

.scheduler-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0px 0px 0px 35px;
  /* .dx-scheduler-all-day-table-row { height: 250px; }   */
  /* .k-scheduler-head{
    .k-scheduler-group:nth-of-type(2){
    .k-scheduler-row{     */
  /* .k-scheduler-weekview tr:nth-child(2) .k-scheduler-table td{
        height: 150px !important;
    } */
  /* }}} */
  display: flex;
  justify-content: flex-start;
  margin: 0 35px;
}

body.rtl .scheduler-wrapper {
  margin: 0px 35px 0px 0px;
}

@media only screen and (max-width: 993px) {
  .scheduler-wrapper {
    margin: 0px 10px;
  }
}

.scheduler-wrapper .ant-popover-arrow {
  display: none;
}

@media only screen and (max-width: 769px) {
  .scheduler-wrapper .k-scheduler-views {
    /*    right: 0px;
            body.rtl &{
                left:0px
            } */
  }
}

.scheduler-wrapper .k-scheduler-head .k-scheduler-group.k-group-horizontal:nth-of-type(2):nth-child(2) {
  min-height: 100px;
}

@media only screen and (max-width: 769px) {
  .scheduler-wrapper .k-scheduler-layout-flex .k-scheduler-cell {
    font-size: 6px;
  }
}

.scheduler-wrapper .k-event-actions .k-icon {
  display: none;
}

@media only screen and (max-width: 769px) {
  .scheduler-wrapper {
    margin: 0 10px;
  }
}

.scheduler-wrapper .k-scheduler-toolbar {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.scheduler-wrapper .k-scheduler-toolbar .k-spacer {
  display: none;
}

.scheduler-wrapper .k-scheduler-toolbar .k-datepicker {
  margin: auto;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 577px) {
  .scheduler-wrapper .k-scheduler-toolbar .k-datepicker {
    width: 100px;
  }
}

@media only screen and (max-width: 769px) {
  .scheduler-wrapper .k-scheduler-toolbar .k-datepicker {
    margin: unset;
    justify-content: unset;
  }
}

@media only screen and (max-width: 769px) and (max-width: 769px) {
  .scheduler-wrapper .k-scheduler-toolbar .k-datepicker .k-button {
    font-size: 8px;
  }
}

.scheduler-wrapper .k-scheduler-toolbar .k-datepicker .k-i-calendar {
  display: none;
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-views button {
  font-weight: 400;
  background-color: transparent;
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-views button.k-state-active {
  color: #074860;
  font-weight: bold;
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-views button::before {
  opacity: 0;
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-navigation .k-button:first-of-type {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #def1f8;
  color: #074860;
  border-radius: 5px;
  width: 85px;
  height: 27.7px;
  margin: auto 10px;
}

@media only screen and (max-width: 769px) {
  .scheduler-wrapper .k-scheduler-toolbar .k-scheduler-navigation .k-button:first-of-type {
    display: none;
  }
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-navigation .k-button-icon {
  background: transparent;
  width: 29px;
  height: 25px;
  margin: auto 5px;
  border-radius: 6px;
  border: solid 1px #b1cbd4;
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-navigation .k-button-icon::before {
  opacity: 0;
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-navigation .k-button-icon:hover {
  background: transparent;
}

.scheduler-wrapper .k-side-cell {
  background: #f8f5f5;
  color: #0793c7;
}

.scheduler-wrapper .calendar-item-card {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
}

.scheduler-wrapper .calendar-item-card .calendar-item-card__title {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
}

@media only screen and (max-width: 769px) {
  .scheduler-wrapper .calendar-item-card .calendar-item-card__title {
    font-size: 12px;
  }
}

.scheduler-wrapper .calendar-item-card.week {
  display: flex;
  align-items: center;
  justify-content: center;
  /*   padding: 20px 15px;
           
            */
}

.scheduler-wrapper .calendar-item-card.week:nth-child(2) {
  border-bottom: 5px solid #b1cbd4;
}

.scheduler-wrapper .calendar-item-card.week .calendar-item-card__title {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.25;
}

@media only screen and (max-width: 769px) {
  .scheduler-wrapper .calendar-item-card.week .calendar-item-card__title {
    font-size: 6px;
    font-weight: 400;
  }
}

.scheduler-wrapper .calendar-item-card.week .calendar-item-card__desc {
  padding-bottom: 5px;
  /* width: 80%; */
  text-align: center;
  font-size: 12px;
}

@media only screen and (max-width: 769px) {
  .scheduler-wrapper .calendar-item-card.week .calendar-item-card__desc {
    font-size: 6px;
    font-weight: 400;
  }
}

.scheduler-wrapper .calendar-item-card.day {
  align-items: center;
  justify-content: center;
}

.scheduler-wrapper .calendar-item-card.month {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_calendar_popover.ant-popover {
  width: 140px;
  height: 75px;
}

.custom_calendar_popover.ant-popover .ant-popover-content {
  min-width: 140px;
  max-width: 140px;
}

.custom_calendar_popover.ant-popover .ant-popover-content .ant-popover-arrow {
  display: none;
}

.custom_calendar_popover.ant-popover .ant-popover-content button {
  width: 90%;
  background: #0793c7;
  color: #fff;
}

.custom_calendar_popover.ant-popover .ant-popover-title {
  font-weight: 600;
  padding-top: 10px;
  min-width: 140px;
  max-width: 140px;
  font-size: 10px;
  flex-direction: column;
  align-items: center;
}

.custom_calendar_popover.ant-popover .ant-popover-inner-content {
  padding: 5px 0px;
  min-width: 140px;
  max-width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom_calendar_popover.ant-popover .ant-popover-inner-content p {
  padding: 0px 7px 5px 0px;
}

.custom_calendar_popover.ant-popover.homework .ant-popover-content {
  border: 1px #ff0000 solid;
}

.custom_calendar_popover.ant-popover.onlinesession .ant-popover-content {
  border: 1px #00a2ff solid;
}

.custom_calendar_popover.ant-popover.timetable .ant-popover-content {
  border: 1px #d9a311 solid;
}

.scale-down, .home-content .statistics-wrapper .content, .home-content .charging-card-wrapper .content, .home-content .work-wrapper .content .steps-wrapper .step-holder .content, .home-content .chargings-article-wrapper .content {
  transform: scale(0.8);
}

.scale-down-9, .account-auth-wrapper .account-auth-content .auth-content-holder, .home-content .hero-wrapper, .home-content .work-wrapper .content {
  transform: scale(0.9);
}

.home-content .hero-wrapper {
  display: flex;
  padding-top: 25px;
  position: relative;
  height: calc(100vh - 70px);
  transform-origin: left;
}

.home-content .hero-wrapper > div {
  width: 50%;
}

.home-content .hero-wrapper > div:first-of-type img {
  position: absolute;
  top: 0;
  animation-duration: 1.8s;
  animation-name: flowingMove;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-delay: 0;
}

.home-content .hero-wrapper > div:first-of-type .content {
  width: 70%;
  margin-left: auto;
  margin-top: 210px;
  position: relative;
}

.home-content .hero-wrapper > div:first-of-type .content h2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.55;
  color: #008539;
}

.home-content .hero-wrapper > div:first-of-type .content h3 {
  font-size: 25px;
  font-weight: 600;
  line-height: 1.54;
  color: #242430;
  margin-top: -10px;
  margin-bottom: 10px;
}

.home-content .hero-wrapper > div:first-of-type .content p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  color: #242430;
  padding-bottom: 41px;
  margin-top: 10px;
  width: 90%;
}

.home-content .hero-wrapper > div:last-of-type {
  display: flex;
  align-items: flex-end;
}

.home-content .hero-wrapper > div:last-of-type img {
  position: relative;
  margin-left: 120%;
  animation-duration: 1s;
  animation-name: carMove;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
  transition: all ease-in-out 0.4s;
  left: 30px;
  min-height: 400px;
}

.home-content .hero-wrapper > div:last-of-type img:hover {
  transform: translateX(25px);
}

@media only screen and (max-width: 993px) {
  .home-content .hero-wrapper > div:last-of-type img {
    animation-delay: unset !important;
  }
}

@media only screen and (max-width: 993px) {
  .home-content .hero-wrapper {
    flex-direction: column-reverse;
    transform-origin: unset;
    justify-content: center;
    height: 100vh;
  }
  .home-content .hero-wrapper > div {
    width: 100% !important;
  }
  .home-content .hero-wrapper > div:first-of-type .content {
    text-align: center !important;
    width: 100%;
    margin-top: 50px;
  }
  .home-content .hero-wrapper > div:first-of-type .content h2 {
    font-size: 32px;
    letter-spacing: 0.42px;
  }
  .home-content .hero-wrapper > div:first-of-type .content h3 {
    font-size: 32px;
    letter-spacing: -0.16px;
  }
  .home-content .hero-wrapper > div:first-of-type .content p {
    margin: auto;
    font-size: 15px;
    line-height: 1.6;
  }
  .home-content .hero-wrapper > div:first-of-type img {
    display: none;
  }
  .home-content .hero-wrapper > div:last-of-type img {
    margin: 0;
    left: 0;
    min-height: auto;
    width: 100%;
  }
}

body.rtl .home-content .hero-wrapper {
  transform-origin: right;
}

body.rtl .home-content .hero-wrapper > div {
  width: 50%;
}

body.rtl .home-content .hero-wrapper > div:first-of-type img {
  transform: scaleX(-1);
}

body.rtl .home-content .hero-wrapper > div:first-of-type .content {
  margin-left: 0;
  margin-right: auto;
}

@media only screen and (max-width: 993px) {
  body.rtl .home-content .hero-wrapper > div:first-of-type .content {
    text-align: center !important;
  }
}

body.rtl .home-content .hero-wrapper > div:last-of-type img {
  right: 30px;
  left: auto;
  margin-right: 120%;
  margin-left: 0;
  transform: scaleX(-1);
  animation-name: carMoveRtl;
}

.home-content .statistics-wrapper {
  background: url(../../resources/images/website/statistics-bg.png) no-repeat;
  background-size: cover;
  height: 450px;
  background-attachment: fixed;
  background-position: center top;
}

body.rtl .home-content .statistics-wrapper {
  background-position: top left;
  transition: none !important;
  animation: none !important;
}

.home-content .statistics-wrapper .content {
  color: #ffffff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-content .statistics-wrapper .content > div {
  width: 45%;
}

.home-content .statistics-wrapper .content > div h2 {
  font-size: 54px;
  font-weight: 600;
  line-height: 1.54;
  letter-spacing: 1.19px;
  color: #ffffff;
}

.home-content .statistics-wrapper .content > div h2 p {
  display: inline-block;
  font-size: 54px;
  font-weight: 500;
  line-height: 1.54;
  letter-spacing: 3.56px;
  color: #ffffff;
  min-width: 160px;
}

.home-content .statistics-wrapper .content > div h2 p.save-money {
  min-width: 90px;
}

.home-content .statistics-wrapper .content > div h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.54;
  text-transform: capitalize;
  color: #008539;
  margin-top: -5px;
  padding-bottom: 20px;
}

.home-content .statistics-wrapper .content > div p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
}

.home-content .statistics-wrapper .content-statistic {
  width: 70%;
  min-width: 440px;
}

.home-content .statistics-wrapper .content-statistic h2 {
  display: flex;
  flex-direction: column;
  font-size: 51px;
  font-weight: 600;
  line-height: 1.22;
  letter-spacing: 1.28px;
}

.home-content .statistics-wrapper .content-statistic h2 span {
  font-weight: normal !important;
  margin-bottom: -13px;
}

.home-content .statistics-wrapper .content-statistic p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.home-content .statistics-wrapper .content-statistic button {
  margin-top: 24px;
  background-image: linear-gradient(to right, #008539 16%, #5db92c 115%);
}

@media only screen and (max-width: 993px) {
  .home-content .statistics-wrapper {
    height: 100vh;
  }
  .home-content .statistics-wrapper .content {
    flex-direction: column;
    justify-content: center;
    transform: initial;
    padding: 0 20px;
  }
  .home-content .statistics-wrapper .content > div {
    width: 100%;
    text-align: center !important;
  }
  .home-content .statistics-wrapper .content > div h2 {
    font-size: 45px;
  }
  .home-content .statistics-wrapper .content > div h2 p {
    font-size: 45px;
  }
  .home-content .statistics-wrapper .content > div h4 {
    font-size: 20px;
  }
  .home-content .statistics-wrapper .content > div h4 + p {
    font-size: 14px;
    line-height: 1.71;
  }
}

.home-content .charging-card-wrapper {
  height: 350px;
  background-color: #ffffff;
}

.home-content .charging-card-wrapper .content {
  width: 100%;
  max-width: 1300px;
  height: 520px;
  border-radius: 4px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.27);
  background-color: #ffffff;
  display: flex;
  margin: auto;
  position: relative;
  top: -70px;
  bottom: -70px;
}

.home-content .charging-card-wrapper .content > div:first-of-type {
  width: 40%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 60px;
}

.home-content .charging-card-wrapper .content > div:first-of-type h2 {
  font-size: 51px;
  font-weight: 600;
  line-height: 1;
  color: #242430;
}

.home-content .charging-card-wrapper .content > div:first-of-type h2 span {
  display: block;
  color: #008539;
}

.home-content .charging-card-wrapper .content > div:first-of-type p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.49;
  text-align: left;
  color: #242430;
  padding-top: 20px;
  padding-bottom: 30px;
}

body.rtl .home-content .charging-card-wrapper .content > div:first-of-type p {
  text-align: right;
}

.home-content .charging-card-wrapper .content > div:first-of-type button {
  width: fit-content;
}

.home-content .charging-card-wrapper .content > div:last-of-type {
  width: 60%;
}

.home-content .charging-card-wrapper .content > div:last-of-type img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 993px) {
  .home-content .charging-card-wrapper {
    height: 90vh;
  }
  .home-content .charging-card-wrapper .content {
    flex-direction: column;
    top: 0;
    bottom: 0;
    height: 100%;
    transform: initial;
  }
  .home-content .charging-card-wrapper .content > div {
    width: 100% !important;
    height: 50%;
  }
  .home-content .charging-card-wrapper .content > div:first-of-type {
    padding: 30px;
    text-align: center;
    align-items: center;
  }
  .home-content .charging-card-wrapper .content > div:first-of-type h2 {
    font-size: 32px;
    text-align: center;
    line-height: 1.22;
    letter-spacing: 0.8px;
  }
  .home-content .charging-card-wrapper .content > div:first-of-type p {
    font-size: 14px;
    line-height: 1.36;
    text-align: center;
  }
}

.home-content .work-wrapper {
  min-height: 100vh;
  background-color: #eaefe8;
  padding: 10% 6% 0 6%;
  height: auto;
}

.home-content .work-wrapper.animation-active .slide-right {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
  transition: all ease-in-out 0.4s;
}

.home-content .work-wrapper.animation-active .slide-left {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
  transition: all ease-in-out 0.4s;
}

.home-content .work-wrapper.animation-active .slide-top {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition: all ease-in-out 0.4s;
}

.home-content .work-wrapper.animation-active .slide-bottom {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition: all ease-in-out 0.4s;
}

.home-content .work-wrapper.animation-active .rotate-y {
  transform: rotateY(0);
}

.home-content .work-wrapper.animation-active .charger-img-holder > div {
  top: 0 !important;
}

.home-content .work-wrapper .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-content .work-wrapper .content h2 {
  font-size: 51px;
  font-weight: 600;
  line-height: 1;
  color: #242430;
  text-align: center;
  padding-bottom: 30px;
}

.home-content .work-wrapper .content h2 span {
  display: inline-block;
  font-weight: 600;
  color: #008539;
  line-height: 1;
}

.home-content .work-wrapper .content .steps-wrapper {
  display: flex;
  justify-content: space-between;
}

.home-content .work-wrapper .content .steps-wrapper .step-holder .content h5 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.55;
  color: #008539;
}

.home-content .work-wrapper .content .steps-wrapper .step-holder .content h5 span {
  width: 32px;
  height: 32px;
  display: inline-flex;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.55;
  color: #242430;
  border-radius: 50%;
  margin-right: 16px;
}

body.rtl .home-content .work-wrapper .content .steps-wrapper .step-holder .content h5 span {
  margin-left: 16px;
  margin-right: 0;
}

.home-content .work-wrapper .content .steps-wrapper .step-holder .content p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  color: #242430;
  margin-top: 10px;
  margin-right: 130px;
}

body.rtl .home-content .work-wrapper .content .steps-wrapper .step-holder .content p {
  margin-left: 130px;
  margin-right: 0;
}

.home-content .work-wrapper .content .steps-wrapper .step-holder .img-holder {
  display: flex;
  justify-content: flex-end;
}

body.rtl .home-content .work-wrapper .content .steps-wrapper .step-holder .img-holder {
  transform: scaleX(-1);
  justify-content: flex-start;
}

.home-content .work-wrapper .content .steps-wrapper .step-holder img {
  position: relative;
  top: -60px;
}

.home-content .work-wrapper .content .steps-wrapper > div:not(.charger-img-holder) {
  width: 35%;
  margin-top: 60px;
}

.home-content .work-wrapper .content .steps-wrapper > div.charger-img-holder {
  width: 309px;
  min-width: 309px;
  max-width: 309px;
  min-height: 596px;
  max-height: 596px;
  position: relative;
  overflow: hidden;
  transform: scale(0.88);
}

.home-content .work-wrapper .content .steps-wrapper > div.charger-img-holder > div {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background-size: cover;
  transition: all ease-in-out 0.6s;
}

.home-content .work-wrapper .content .steps-wrapper > div.charger-img-holder > div:first-of-type {
  left: 0;
  top: 100%;
  background-position: 0%;
  background-image: url(../../resources/images/website/Charger.png);
}

.home-content .work-wrapper .content .steps-wrapper > div.charger-img-holder > div:last-of-type {
  left: 50%;
  top: -100%;
  background-position: -100%;
  background-image: url(../../resources/images/website/Charger.png);
}

.home-content .work-wrapper .content .steps-wrapper > div:first-of-type {
  padding-right: 30px;
}

.home-content .work-wrapper .content .steps-wrapper > div:last-of-type {
  padding-left: 30px;
}

@media only screen and (max-width: 993px) {
  .home-content .work-wrapper {
    min-height: 75vh;
  }
  .home-content .work-wrapper .steps-wrapper > div {
    width: 50% !important;
    margin-top: 30px !important;
  }
  .home-content .work-wrapper .charger-img-holder {
    display: none !important;
  }
}

@media only screen and (max-width: 577px) {
  .home-content .work-wrapper .content {
    transform: initial;
  }
  .home-content .work-wrapper .content h2 {
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-content .work-wrapper .content h2 span {
    margin-right: 8px;
  }
  .home-content .work-wrapper .content h5,
  .home-content .work-wrapper .content p {
    font-weight: 400 !important;
    padding-top: 10px;
  }
  .home-content .work-wrapper .content .steps-wrapper {
    flex-direction: column;
  }
  .home-content .work-wrapper .content .steps-wrapper > div {
    width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-top: 0 !important;
  }
  .home-content .work-wrapper .content .steps-wrapper .step-holder {
    margin-bottom: 5px !important;
  }
  .home-content .work-wrapper .content .steps-wrapper .step-holder img {
    display: none !important;
  }
  .home-content .work-wrapper .content .steps-wrapper .step-holder p {
    margin-right: 0 !important;
  }
}

.home-content .chargings-article-wrapper {
  padding: 90px 75px 90px 40px;
  background-color: #ffffff;
  overflow: hidden;
}

.home-content .chargings-article-wrapper .content {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.home-content .chargings-article-wrapper .content > div {
  width: 47%;
  will-change: transform, opacity;
}

.home-content .chargings-article-wrapper .content > div:first-of-type {
  position: relative;
  height: 635px;
}

.home-content .chargings-article-wrapper .content > div:first-of-type img {
  max-width: 100%;
}

.home-content .chargings-article-wrapper .content > div:first-of-type img.img-stay {
  position: absolute;
  left: 0;
  animation-name: slideimgsAnimation-left;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-duration: 4s;
}

@keyframes slideimgsAnimation-left {
  0% {
    left: 0;
    transform: rotate(0);
    z-index: 10;
  }
  50% {
    left: 0;
    transform: rotate(0);
  }
  100% {
    left: -140%;
    transform: rotate(-180deg);
  }
}

body.rtl .home-content .chargings-article-wrapper .content > div:first-of-type img.img-stay {
  right: 0;
  left: auto;
  animation-name: slideimgsAnimation-right;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-duration: 4s;
}

@keyframes slideimgsAnimation-right {
  0% {
    right: 0;
    transform: rotate(0);
    z-index: 10;
  }
  50% {
    right: 0;
    transform: rotate(0);
  }
  100% {
    right: -140%;
    transform: rotate(180deg);
  }
}

.home-content .chargings-article-wrapper .content > div:first-of-type img.img-move {
  position: absolute;
  left: 0;
  z-index: 5;
  animation-name: slideimgsAnimation-left;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-duration: 4s;
  animation-delay: 2s;
}

@keyframes slideimgsAnimation-left {
  0% {
    left: 0;
    transform: rotate(0);
  }
  50% {
    left: 0;
    transform: rotate(0);
  }
  100% {
    left: -140%;
    transform: rotate(-180deg);
    z-index: 44;
  }
}

body.rtl .home-content .chargings-article-wrapper .content > div:first-of-type img.img-move {
  animation-name: slideimgsAnimation-right;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-duration: 4s;
  animation-delay: 2s;
  right: 0;
  left: auto;
}

@keyframes slideimgsAnimation-right {
  0% {
    right: 0;
    transform: rotate(0);
  }
  50% {
    right: 0;
    transform: rotate(0);
  }
  100% {
    right: -140%;
    transform: rotate(180deg);
    z-index: 44;
  }
}

.home-content .chargings-article-wrapper .content > div:last-of-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 120px;
}

.home-content .chargings-article-wrapper .content > div:last-of-type article {
  margin-bottom: 20px;
}

.home-content .chargings-article-wrapper .content > div:last-of-type article h2 {
  font-size: 54px;
  font-weight: 600;
  line-height: 1.54;
  color: #242430;
  white-space: nowrap;
}

.home-content .chargings-article-wrapper .content > div:last-of-type article h2 span {
  display: inline-block;
  font-size: 54px;
  font-weight: 600;
  line-height: 1.54;
  text-align: left;
  color: #008539;
  width: 80px;
}

.home-content .chargings-article-wrapper .content > div:last-of-type article h2 span.customer-counter {
  width: 115px;
}

.home-content .chargings-article-wrapper .content > div:last-of-type article h2 i {
  font-size: 91px;
  font-weight: 300;
  line-height: 1.55;
  color: #242430;
}

.home-content .chargings-article-wrapper .content > div:last-of-type article h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.54;
  color: #008539;
}

.home-content .chargings-article-wrapper .content > div:last-of-type article p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
  color: #242430;
}

@media only screen and (max-width: 993px) {
  .home-content .chargings-article-wrapper {
    padding: 80px 40px;
    background: url(../../resources/images/website/bannerweb.png) no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
  }
  .home-content .chargings-article-wrapper:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.82);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .home-content .chargings-article-wrapper .content {
    transform: none;
  }
  .home-content .chargings-article-wrapper .content > div {
    width: 100%;
    height: auto;
    z-index: 1;
  }
  .home-content .chargings-article-wrapper .content > div:first-of-type {
    display: none;
  }
  .home-content .chargings-article-wrapper .content > div article {
    text-align: center;
  }
  .home-content .chargings-article-wrapper .content > div article h2 {
    font-size: 32px !important;
    color: #FFFF !important;
    white-space: normal !important;
  }
  .home-content .chargings-article-wrapper .content > div article h2 span {
    font-size: 32px !important;
    color: #FFFF !important;
    width: 80px !important;
  }
  .home-content .chargings-article-wrapper .content > div article h2 i {
    display: none !important;
  }
  .home-content .chargings-article-wrapper .content > div article h4 {
    font-size: 17px !important;
  }
  .home-content .chargings-article-wrapper .content > div article p {
    text-align: center !important;
    padding-top: 20px;
  }
}

body.rtl .home-content .chargings-article-wrapper {
  padding: 90px 40px 90px 75px;
}

.home-content .nearest-station-wrapper {
  min-height: 65vh;
  height: 500px;
}

.home-content .nearest-station-wrapper .content {
  display: flex;
  height: 100%;
}

.home-content .nearest-station-wrapper .content > div {
  width: 50%;
}

.home-content .nearest-station-wrapper .content .station-info-holder {
  padding: 50px 90px 0 90px;
  background: url(../../resources/images/website/nearest-station-bg.svg) no-repeat;
  box-shadow: 3px -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #eaefe8;
  position: relative;
}

.home-content .nearest-station-wrapper .content .station-info-holder h2 {
  font-size: 43px;
  font-weight: 600;
  line-height: 1.53;
  color: #242430;
  text-align: center;
}

.home-content .nearest-station-wrapper .content .station-info-holder p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  color: #242430;
  width: 80%;
  margin: auto;
  padding-top: 10px;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .set-location-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 30px;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .set-location-header h4 {
  font-size: 21px;
  font-weight: 500;
  line-height: 1.52;
  color: #454545;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .set-location-header .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  border: solid 1px #707070;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .set-location-header .ant-checkbox-wrapper .ant-checkbox + span {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.56;
  text-align: left;
  color: #454545;
}

body.rtl .home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .set-location-header .ant-checkbox-wrapper .ant-checkbox + span {
  text-align: right;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .ant-input-search .ant-input {
  height: 40px;
  padding-top: 0 !important;
  font-size: 16px;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .ant-input-search .ant-input:-moz-placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #b4b4b4;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .ant-input-search .ant-input::-moz-placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #b4b4b4;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .ant-input-search .ant-input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #b4b4b4;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .ant-input-search .ant-input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #b4b4b4;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .anticon-search {
  display: none;
}

.home-content .nearest-station-wrapper .content .map-holder {
  position: relative;
}

.home-content .nearest-station-wrapper .content .map-holder iframe,
.home-content .nearest-station-wrapper .content .map-holder .mapouter,
.home-content .nearest-station-wrapper .content .map-holder .gmap_canvas {
  width: 100% !important;
  height: 100% !important;
}

.home-content .nearest-station-wrapper .find-station-btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.52;
  text-align: center;
  color: #ffffff;
  height: 70px;
  border-radius: 44px;
  background-color: #008539;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 35px;
}

@media only screen and (max-width: 993px) {
  .home-content .nearest-station-wrapper {
    min-height: auto;
    height: auto;
  }
  .home-content .nearest-station-wrapper .find-station-btn {
    width: 100%;
    position: relative;
    font-size: 16px;
    font-weight: normal;
    height: 60px;
    border-radius: 0;
    margin-bottom: 0;
  }
  .home-content .nearest-station-wrapper .content {
    flex-direction: column;
  }
  .home-content .nearest-station-wrapper .content > div {
    width: 100%;
  }
  .home-content .nearest-station-wrapper .content > div:first-of-type {
    padding: 25px 30px 60px 30px;
  }
  .home-content .nearest-station-wrapper .content > div:first-of-type h2 {
    font-size: 32px;
    font-weight: 500;
  }
  .home-content .nearest-station-wrapper .content > div:first-of-type p {
    font-size: 14px;
    font-weight: normal;
    width: 100%;
  }
  .home-content .nearest-station-wrapper .content > div:first-of-type .set-location-header {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .home-content .nearest-station-wrapper .content > div:last-of-type {
    min-height: 400px;
    height: 400px;
  }
}

.about-us-wrapper .about-us-content .hero-wrapper {
  display: flex;
  align-items: center;
  padding-top: 25px;
  position: relative;
  height: calc(100vh - 70px);
  overflow: hidden;
  transform: scale(0.9);
  transform-origin: bottom;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(1) {
  width: 27%;
  align-self: flex-end;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(1) .img-holder {
  transform: translateY(100%);
  animation: move-up 1.6s ease 0.8s 1 forwards;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(1) .img-holder img {
  max-width: 100%;
  height: auto;
}

body.rtl .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(1) .img-holder img {
  transform: scaleX(-1);
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) {
  width: 50%;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h2 {
  font-size: 44px;
  font-weight: 600;
  line-height: 1.55;
  text-align: left;
  color: #008539;
}

body.rtl .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h2 {
  text-align: right;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.57;
  text-align: left;
  color: #242430;
  padding-top: 20px;
  padding-bottom: 20px;
}

body.rtl .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h5 {
  text-align: right;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h5 p {
  display: inline-block;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.49;
  text-align: left;
  color: #008539;
}

body.rtl .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h5 p {
  text-align: right;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) .img-holder {
  right: -110%;
  transform: rotate(180deg);
  position: relative;
  animation: rotate-left 0.7s ease 0.5s 1 forwards;
  transition: margin-bottom ease-in-out 1s;
}

body.rtl .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) .img-holder {
  left: -110%;
  right: auto;
  transform: rotate(-180deg);
  animation-name: rotate-left-rtl;
}

body.rtl .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) .img-holder img {
  transform: scaleX(-1);
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) .img-holder img {
  max-width: 100%;
  height: auto;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) .img-holder:hover {
  transition: margin-bottom ease-in-out 0.3s;
  margin-bottom: -40px;
}

@media only screen and (max-width: 993px) {
  .about-us-wrapper .about-us-content .hero-wrapper {
    transform: none;
    padding: 30px;
    position: relative;
    height: calc(100vh - 140px);
    padding-top: 90px;
    align-items: flex-start;
  }
  .about-us-wrapper .about-us-content .hero-wrapper::after {
    content: "";
    background: url(../../resources/images/website/businessline-chargers.png) no-repeat;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.2;
    width: 155px;
    height: 377px;
    background-size: cover;
    pointer-events: none;
    zoom: 0.9;
  }
  .about-us-wrapper .about-us-content .hero-wrapper::before {
    content: "";
    background: url(../../resources/images/website/mission-bg-leaves.png) no-repeat;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.2;
    width: 170px;
    height: 123px;
    background-size: cover;
    pointer-events: none;
    zoom: 0.9;
  }
  .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(1), .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) {
    display: none;
  }
  .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) {
    width: 100% !important;
  }
  .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) article {
    text-align: center;
  }
  .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) article h2 {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }
  .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) article h5 {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    line-height: 1.6;
  }
}

@media only screen and (max-width: 993px) and (max-width: 577px) {
  .about-us-wrapper .about-us-content .hero-wrapper {
    padding-top: 30px;
  }
}

.about-us-wrapper .about-us-content .our-stations-wrapper {
  height: 100vh;
  background: url(../../resources/images/website/our-stations-bg.png) no-repeat;
  background-size: cover;
  background-position: center left;
  background-attachment: fixed;
  overflow: hidden;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content {
  display: flex;
  justify-content: space-between;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.76);
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div {
  width: 47%;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div:first-of-type {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div:first-of-type h2 {
  font-size: 47px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  text-align: center;
  color: #008539;
  margin-bottom: 35px;
  text-transform: capitalize;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div:first-of-type h4 {
  width: 81%;
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  will-change: transform;
  margin-right: 10%;
}

body.rtl .about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider {
  margin-left: 10%;
  margin-right: unset;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider img {
  max-width: 100%;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider img.img-stay {
  position: absolute;
  right: 0;
  animation-name: slideimgsAnimation-right;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-duration: 4s;
}

@keyframes slideimgsAnimation-right {
  0% {
    right: 0;
    transform: rotate(0);
    z-index: 10;
  }
  50% {
    right: 0;
    transform: rotate(0);
  }
  100% {
    right: -140%;
    transform: rotate(180deg);
  }
}

body.rtl .about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider img.img-stay {
  left: 0;
  right: auto;
  animation-name: slideimgsAnimation-left;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-duration: 4s;
}

@keyframes slideimgsAnimation-left {
  0% {
    left: 0;
    transform: rotate(0);
    z-index: 10;
  }
  50% {
    left: 0;
    transform: rotate(0);
  }
  100% {
    left: -140%;
    transform: rotate(-180deg);
  }
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider img.img-move {
  position: absolute;
  right: 0;
  z-index: 5;
  animation-name: slideimgsAnimation-right;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-duration: 4s;
  animation-delay: 2s;
}

@keyframes slideimgsAnimation-right {
  0% {
    right: 0;
    transform: rotate(0);
  }
  50% {
    right: 0;
    transform: rotate(0);
  }
  100% {
    right: -140%;
    transform: rotate(180deg);
    z-index: 44;
  }
}

body.rtl .about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider img.img-move {
  left: 0;
  right: auto;
  animation-name: slideimgsAnimation-left;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-duration: 4s;
  animation-delay: 2s;
}

@keyframes slideimgsAnimation-left {
  0% {
    left: 0;
    transform: rotate(0);
  }
  50% {
    left: 0;
    transform: rotate(0);
  }
  100% {
    left: -140%;
    transform: rotate(-180deg);
    z-index: 44;
  }
}

@media only screen and (max-width: 993px) {
  .about-us-wrapper .about-us-content .our-stations-wrapper {
    height: 80vh;
    background-position: center;
  }
  .about-us-wrapper .about-us-content .our-stations-wrapper .content > div {
    width: 100% !important;
  }
  .about-us-wrapper .about-us-content .our-stations-wrapper .content > div:first-of-type h2 {
    font-size: 32px;
    font-weight: 600;
  }
  .about-us-wrapper .about-us-content .our-stations-wrapper .content > div:first-of-type h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.33;
    width: 85%;
  }
  .about-us-wrapper .about-us-content .our-stations-wrapper .content > div:last-of-type {
    display: none;
  }
}

@media only screen and (max-width: 481px) {
  .about-us-wrapper .about-us-content .our-stations-wrapper {
    height: 100vh !important;
  }
}

.about-us-wrapper .about-us-content .our-partners-wrapper {
  height: 50vh;
  min-height: 450px;
  padding-top: 40px;
  overflow: hidden;
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content h2 {
  display: flex;
  align-items: center;
  font-size: 51px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #242430;
  position: relative;
  padding: 0 50px;
}

@media only screen and (max-width: 993px) {
  .about-us-wrapper .about-us-content .our-partners-wrapper .content h2 {
    font-size: 32px;
    font-weight: 600;
  }
  .about-us-wrapper .about-us-content .our-partners-wrapper .content h2 p {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content h2 p {
  margin-right: 15px;
  font-size: 51px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #008539;
}

body.rtl .about-us-wrapper .about-us-content .our-partners-wrapper .content h2 p {
  margin-left: 15px;
  margin-right: 0;
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content h2:after {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 100%;
  height: 3px;
  background-color: #efefef;
  left: 0;
  right: 0;
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content h2:before {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 33.333%;
  height: 3px;
  background-color: #008539;
  left: 50%;
  transform: translateX(-50%);
  z-index: 22;
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content .slider-wrapper {
  width: 100%;
  margin-top: 50px;
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content .slider-wrapper .slick-list .slick-track > div {
  text-align: center;
  min-height: 250px;
  max-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content .slider-wrapper .slick-list .slick-track > div img {
  width: 100%;
  height: 100%;
  max-width: 200px;
}

.contact-section-wrapper .contact-holder:after {
  content: "";
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.67);
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
}

.contact-section-wrapper .contact-holder .infinty-info-holder {
  display: flex;
  position: relative;
  margin: 0 10%;
  top: -77px;
  top: 72px;
  z-index: 5;
}

@media only screen and (max-width: 1249px) {
  .contact-section-wrapper .contact-holder .infinty-info-holder {
    margin: 0 5%;
  }
}

@media only screen and (max-width: 769px) {
  .contact-section-wrapper .contact-holder .infinty-info-holder {
    margin: 0 1%;
  }
}

.contact-section-wrapper .contact-holder .infinty-info-holder > div {
  height: 144px;
  background-image: linear-gradient(91deg, #008539 3%, #86d958 66%);
  flex: 1;
  width: 33.33333%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  justify-content: flex-start;
  padding: 30px;
}

.contact-section-wrapper .contact-holder .infinty-info-holder > div .icon-holder {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  background-color: #ffffff;
  border-radius: 50%;
  margin-right: 22px;
}

body.rtl .contact-section-wrapper .contact-holder .infinty-info-holder > div .icon-holder {
  margin-left: 22px;
  margin-right: 0;
}

.contact-section-wrapper .contact-holder .infinty-info-holder > div h4 {
  font-size: 19px;
  font-weight: 500;
  line-height: 1.14;
  text-align: left;
  color: #ffffff;
}

body.rtl .contact-section-wrapper .contact-holder .infinty-info-holder > div h4 {
  text-align: right;
}

.contact-section-wrapper .contact-holder .infinty-info-holder > div h4 a {
  color: #ffffff;
  font-weight: 500;
}

.contact-section-wrapper .contact-holder .infinty-social-holder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  display: none;
}

.contact-section-wrapper .contact-holder .infinty-social-holder .socia-holder {
  margin-top: 35px;
}

.contact-section-wrapper .contact-holder .infinty-social-holder .socia-holder > a {
  margin-right: 27px;
}

.contact-section-wrapper .contact-holder .infinty-social-holder .socia-holder > a:last-of-type {
  margin-right: 0;
}

@media only screen and (max-width: 993px) {
  .contact-section-wrapper .contact-holder .infinty-info-holder {
    flex-direction: column;
    top: 30px;
  }
  .contact-section-wrapper .contact-holder .infinty-info-holder > div {
    width: 100%;
    margin-bottom: 18px;
    padding: 20px;
  }
  .contact-section-wrapper .contact-holder .infinty-info-holder > div h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.45px;
  }
}

.how-work-wrapper .how-work-content .how-video-wrapper {
  position: relative;
  height: 100vh;
}

@media only screen and (max-width: 577px) {
  .how-work-wrapper .how-work-content .how-video-wrapper {
    height: 60vh;
  }
}

.how-work-wrapper .how-work-content .how-video-wrapper video {
  position: absolute;
  object-fit: fill;
}

.how-work-wrapper .how-work-content .how-video-wrapper video[poster] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.how-work-wrapper .how-work-content .how-video-wrapper .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.how-work-wrapper .how-work-content .how-video-wrapper .play.not-visible {
  visibility: hidden;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper {
  min-height: 100vh;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .steps-title {
  font-size: 51px;
  font-weight: 600;
  line-height: 1;
  color: #242430;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  padding-bottom: 0 !important;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .steps-title span {
  color: #008539 !important;
  font-size: 51px;
  font-weight: 600;
  line-height: 1;
  padding: 0 20px;
}

@media only screen and (max-width: 993px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .steps-title span {
    font-size: 40px;
  }
}

@media only screen and (max-width: 993px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .steps-title {
    font-size: 40px;
  }
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps {
  width: 90%;
  margin: auto;
  padding: 25px 0;
  display: flex;
  align-items: baseline;
  height: 100%;
  margin-top: 25px;
}

@media only screen and (max-width: 641px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps {
    width: 90% !important;
    margin: unset !important;
  }
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder {
  display: flex;
  align-items: center;
  width: 73%;
  margin: auto;
  margin-bottom: 20px;
  height: 100px;
  padding: 20px;
  box-sizing: content-box;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder:hover {
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder.active-step {
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content {
  width: 74%;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content h5 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.55;
  color: #008539;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content h5 span {
  width: 32px;
  height: 32px;
  display: inline-flex;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.55;
  color: #242430;
  border-radius: 50%;
  margin-right: 16px;
}

body.rtl .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content h5 span {
  margin-left: 16px;
  margin-right: 0;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  color: #242430;
  margin-top: 10px;
}

@media only screen and (max-width: 641px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content p {
    margin-right: 20px !important;
  }
  body.rtl .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content p {
    margin-left: 20px !important;
    margin-right: 0 !important;
  }
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder img {
  position: relative;
  top: -13px;
  width: 115px;
}

body.rtl .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder img {
  transform: scaleX(-1);
}

@media only screen and (max-width: 641px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder img {
    width: 90px !important;
  }
}

@media only screen and (max-width: 769px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder {
    width: 100% !important;
  }
}

@media only screen and (max-width: 641px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder {
    padding-left: 20px !important;
  }
}

.how-work-wrapper .contact-section-wrapper {
  margin-top: 0 !important;
}

.slide-right {
  opacity: 0;
  transform: translateX(-50px);
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

.slide-left {
  opacity: 0;
  transform: translateX(50px);
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

.slide-top {
  opacity: 0;
  transform: translateY(-50px);
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

.slide-bottom {
  opacity: 0;
  transform: translateY(50px);
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

.rotate-y {
  transform-style: preserve-3d;
  transform: rotateY(90deg);
  transition: all ease-in-out 0.3s;
}

@keyframes rotate-left {
  0% {
    right: -110%;
    transform: rotate(180deg);
  }
  100% {
    right: 0;
    transform: rotate(0);
  }
}

@keyframes rotate-left-rtl {
  0% {
    left: -110%;
    transform: rotate(-180deg);
  }
  100% {
    left: 0;
    transform: rotate(0);
  }
}

@keyframes move-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes flowingMove {
  from {
    top: 0;
  }
  to {
    top: 120px;
  }
}

@keyframes carMove {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0;
  }
}

@keyframes carMoveRtl {
  from {
    margin-right: 100%;
  }
  to {
    margin-right: 0;
  }
}

@media only screen and (max-width: 993px) {
  @keyframes carMoveRtl {
    from {
      margin-right: -100%;
    }
    to {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 993px) {
  @keyframes carMove {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0;
    }
  }
}

@keyframes chargingMove {
  0% {
    left: -120%;
    transform: rotate(-180deg);
  }
  90% {
    left: 0;
    transform: rotate(0);
  }
  100% {
    left: 0;
    transform: rotate(0);
  }
}

@keyframes chargingMoveRtl {
  0% {
    right: -120%;
    transform: rotate(180deg);
  }
  90% {
    right: 0;
    transform: rotate(0);
  }
  100% {
    right: 0;
    transform: rotate(0);
  }
}

.centerd-slider {
  height: 100vh;
}

.centerd-slider .ant-carousel, .centerd-slider .slick-slider, .centerd-slider .slick-list {
  height: 100%;
}

.centerd-slider .slick-track {
  top: 45px !important;
  height: 100%;
}

.centerd-slider .slick-active {
  opacity: 1 !important;
  position: relative;
  top: -30px;
  border-radius: 4px;
}

.centerd-slider .slick-active img {
  transform: scaleY(1.25);
}

.centerd-slider .slick-slide {
  opacity: 0.47;
  backdrop-filter: blur(26px);
  overflow: hidden visible;
  height: 100%;
}

.centerd-slider .slick-dots {
  position: relative;
  top: -120px;
}

.centerd-slider .slick-dots li button {
  width: 12px !important;
  height: 12px !important;
  background-color: #e4e4e4;
  border-radius: 50% !important;
}

.centerd-slider .slick-dots li.slick-active button {
  width: 12px !important;
  height: 12px !important;
  background-color: #008539 !important;
  border-radius: 50% !important;
}

.centerd-slider .slick-dots .slick-active {
  top: 0 !important;
}

@media only screen and (max-width: 1025px) {
  .centerd-slider {
    display: none;
  }
}

@media only screen and (max-width: 1025px) {
  .steps-wrapper {
    width: 100% !important;
  }
}

.gradient-fill {
  background-image: linear-gradient(to right, #008539 16%, #5db92c 115%) !important;
}

.station-search-wrapper {
  background-color: #fff;
  /******************************* shared component ****************************/
}

.station-search-wrapper * {
  font-family: Pridi;
}

.station-search-wrapper .grid-wrapper {
  position: relative;
  min-height: 500px;
}

.station-search-wrapper .grid-wrapper .tiles-grid-wrapper {
  padding: 30px;
}

.station-search-wrapper .grid-wrapper .tiles-grid-wrapper .tiles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 18px;
}

@media only screen and (max-width: 577px) {
  .station-search-wrapper .grid-wrapper .tiles-grid-wrapper {
    padding: 30px 10px !important;
  }
  .station-search-wrapper .grid-wrapper .tiles-grid-wrapper .tiles-grid {
    grid-template-columns: 1fr;
  }
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper {
  padding: 30px;
}

@media only screen and (max-width: 769px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper {
    padding: 30px 10px;
  }
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder {
  position: relative;
  min-height: 500px;
  height: 85vh;
  background-color: #fff;
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .map-holder > div > div {
  width: 96% !important;
  height: 96% !important;
  margin: auto !important;
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .map-holder > div > div > div {
  height: 100% !important;
  bottom: 0 !important;
  right: 0 !important;
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider {
  position: absolute;
  width: 100%;
  bottom: 20px;
}

@media only screen and (max-width: 641px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider {
    bottom: -35px !important;
  }
}

@media only screen and (max-width: 577px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider {
    bottom: -44px !important;
  }
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .slick-track > .slick-slide > div {
  margin-left: 10px;
  margin-right: 10px;
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-detailes {
  width: 57%;
}

@media only screen and (max-width: 401px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-detailes {
    padding-right: 10px !important;
  }
}

@media only screen and (max-width: 381px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-detailes {
    width: 100% !important;
  }
}

@media only screen and (max-width: 769px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-detailes {
    width: 100% !important;
  }
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-img-holder {
  width: 43%;
}

@media only screen and (max-width: 577px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-img-holder img {
    display: none !important;
  }
}

@media only screen and (max-width: 381px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-img-holder {
    display: none !important;
  }
}

@media only screen and (max-width: 769px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-img-holder {
    min-width: unset !important;
    max-width: unset !important;
  }
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-card-holder {
  width: 100%;
  max-width: 410px !important;
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .slick-track {
  min-width: 460px !important;
}

.station-search-wrapper .station-more-detailes-wrpper {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  margin: 100px 30px 25px 30px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 5;
  transition: all 0.4s ease;
  transform: scale(0);
}

@media only screen and (max-width: 769px) {
  .station-search-wrapper .station-more-detailes-wrpper {
    margin: 45px 4px 25px 4px !important;
  }
}

@media only screen and (max-width: 481px) {
  .station-search-wrapper .station-more-detailes-wrpper {
    margin: 18px 4px 25px 4px !important;
    z-index: 99;
  }
}

.station-search-wrapper .station-more-detailes-wrpper.active {
  transform: scale(1);
}

.station-search-wrapper .station-more-detailes-wrpper .content {
  height: 100%;
  padding: 20px;
  padding-bottom: 15px;
  position: relative;
}

.station-search-wrapper .station-more-detailes-wrpper .content > .anticon-close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #c62f26;
  font-size: 22px;
  cursor: pointer;
  z-index: 22;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header {
  display: flex;
  height: 170px;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .img-holder {
  width: 185px;
  height: 170px;
  margin-right: 30px;
}

@media only screen and (max-width: 769px) {
  .station-search-wrapper .station-more-detailes-wrpper .content > header .img-holder {
    margin-right: 10px !important;
  }
}

@media only screen and (max-width: 481px) {
  .station-search-wrapper .station-more-detailes-wrpper .content > header .img-holder {
    display: none;
  }
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder {
  padding-right: 8%;
  border-bottom: 1px solid #c3c3c3;
  flex: 1;
}

@media only screen and (max-width: 769px) {
  .station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder {
    padding-right: 5px !important;
    border-bottom: none !important;
  }
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder h4 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.77px;
  color: #000000;
  margin-bottom: 15px;
  padding-top: 8px;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div h6 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.58px;
  color: #575757;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div h6 img {
  margin-right: 5px;
}

body.rtl .station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div h6 img {
  margin-left: 5px;
  margin-right: 0;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div > a,
.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div address {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.58px;
  color: #000000;
  padding-left: 5px;
}

@media only screen and (max-width: 769px) {
  .station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div > a,
  .station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div address {
    font-size: 10px;
  }
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  position: relative;
  border-bottom: 1px solid #c3c3c3;
  flex: 1;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder:after {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  width: 1px;
  height: 90%;
  background-color: #c3c3c3;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder > div {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder > div h6 {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.58px;
  color: #008539;
  min-width: 120px;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder > div h6 img {
  margin-right: 3px;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder > div p {
  display: flex;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.58px;
  text-align: left;
  color: #000000;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder > div p span {
  padding-left: 6px;
  padding-right: 4px;
  font-weight: 500;
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper {
  height: calc(100% - 170px);
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper header h3 {
  font-size: 22px;
  font-weight: 500;
  color: #393939;
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper header button {
  width: 128px;
  height: 39px;
  border-radius: 21px;
  background-image: linear-gradient(89deg, #008539 1%, #86d958 100%);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.58px;
  color: #ffffff;
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper .map-holder {
  height: calc(100% - 80px);
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper .map-holder > div {
  height: 100%;
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper .map-holder > div > div {
  position: relative !important;
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper .map-holder > div > div > div {
  height: 100% !important;
}

.station-search-wrapper .grid-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 70px;
  margin: 60px 30px 0px 30px;
}

@media only screen and (max-width: 481px) {
  .station-search-wrapper .grid-header {
    display: block !important;
    height: auto !important;
    margin: 60px 10px 0px 10px;
  }
}

.station-search-wrapper .grid-header > div:first-of-type {
  display: flex;
  flex-direction: column;
}

.station-search-wrapper .grid-header > div:first-of-type h4 {
  font-size: 19px;
  font-weight: 500;
  color: #393939;
}

.station-search-wrapper .grid-header > div:first-of-type p {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  color: #808080;
}

@media only screen and (max-width: 481px) {
  .station-search-wrapper .grid-header > div:first-of-type {
    padding-bottom: 15px !important;
  }
}

.station-search-wrapper .grid-header > div:last-of-type {
  display: flex;
  height: 100%;
  border-radius: 4px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
}

.station-search-wrapper .grid-header > div:last-of-type button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.42;
  color: #808080;
  outline: none !important;
}

.station-search-wrapper .grid-header > div:last-of-type button:first-of-type {
  border-right: 1px solid #e8e8e8;
}

body.rtl .station-search-wrapper .grid-header > div:last-of-type button:first-of-type {
  border-left: 1px solid #e8e8e8;
  border-right: none;
}

.station-search-wrapper .grid-header > div:last-of-type button .anticon {
  margin-right: 17px;
}

body.rtl .station-search-wrapper .grid-header > div:last-of-type button .anticon {
  margin-left: 17px;
  margin-right: 0;
}

.station-search-wrapper .grid-header > div:last-of-type button.active {
  color: #008539;
}

@media only screen and (max-width: 481px) {
  .station-search-wrapper .grid-header > div:last-of-type {
    width: 100% !important;
    height: 45px !important;
  }
  .station-search-wrapper .grid-header > div:last-of-type button {
    width: 50% !important;
  }
}

.station-search-wrapper .station-card-holder {
  display: flex;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 13px 15px;
}

body.rtl .station-search-wrapper .station-card-holder {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 481px) {
  .station-search-wrapper .station-card-holder {
    padding: 10px !important;
  }
}

.station-search-wrapper .station-card-holder .station-img-holder img {
  width: 165px;
  height: 165px;
  object-fit: contain;
}

@media only screen and (max-width: 577px) {
  .station-search-wrapper .station-card-holder .station-img-holder {
    width: 130px;
    max-width: 130px;
    min-width: 130px;
  }
  .station-search-wrapper .station-card-holder .station-img-holder img {
    object-fit: cover;
  }
}

@media only screen and (max-width: 577px) {
  .station-search-wrapper .station-card-holder .station-img-holder {
    display: none !important;
  }
}

@media only screen and (max-width: 481px) {
  .station-search-wrapper .station-card-holder .station-img-holder {
    display: none !important;
  }
}

.station-search-wrapper .station-card-holder .station-detailes {
  padding-left: 12px;
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  flex-wrap: nowrap;
}

.station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div h6 {
  display: flex;
  margin-right: 6px;
  flex-wrap: nowrap;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.42px;
  color: #5e5e5e;
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 769px) {
  .station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div h6 {
    max-width: unset;
    white-space: normal;
    align-items: flex-start;
  }
}

.station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div h6 img {
  margin-right: 6px;
}

body.rtl .station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div h6 {
  margin-left: 6px;
  margin-right: 0;
}

body.rtl .station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div h6 img {
  margin-left: 8px;
  margin-right: 0;
}

.station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div p {
  font-size: 15px;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  font-weight: 200;
  color: #008539;
}

.station-search-wrapper .station-card-holder .station-detailes button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 90px;
  height: 26px;
  border-radius: 21px;
  background-image: linear-gradient(89deg, #008539 1%, #5db92c 100%);
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.58px;
  color: #ffffff;
  align-self: center;
}

@media only screen and (max-width: 481px) {
  .station-search-wrapper .station-card-holder .station-detailes button {
    width: 115px;
    height: 33px;
    font-size: 15px;
  }
}

body.rtl .station-search-wrapper .station-card-holder .station-detailes {
  padding-right: 12px;
  padding-left: 0;
}

.station-search-wrapper .station-card-holder h5 {
  font-size: 17px;
  line-height: 1.2;
  letter-spacing: 0.64px;
  font-weight: 500;
  letter-spacing: 0.64px;
  color: #393939;
  margin-bottom: 6px;
  padding-top: 5px;
}

@media only screen and (max-width: 481px) {
  .station-search-wrapper .station-card-holder h5 {
    align-self: center;
  }
}

.search-header-wrapper {
  height: 25vh;
  max-height: 320px;
  background: #fff;
  background-size: cover;
  background-position: center;
  position: relative;
}

@media only screen and (max-width: 769px) {
  .search-header-wrapper {
    height: 50vh !important;
  }
}

.search-header-wrapper .content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 35px;
  height: 100%;
  z-index: 5;
}

.search-header-wrapper .titles-holder {
  text-align: center;
}

.search-header-wrapper .titles-holder h1 {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.56;
  letter-spacing: 2.06px;
  color: #242430;
}

@media only screen and (max-width: 769px) {
  .search-header-wrapper .titles-holder h1 {
    font-size: 35px !important;
  }
}

.search-header-wrapper .titles-holder p {
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.53;
  color: #242430;
}

@media only screen and (max-width: 769px) {
  .search-header-wrapper .titles-holder p {
    font-size: 25px !important;
    padding: 0 10px;
  }
}

@media only screen and (max-width: 401px) {
  .search-header-wrapper .titles-holder p {
    font-size: 17px !important;
  }
}

.search-header-wrapper .search-input-holder {
  width: 90vw;
  max-width: 870px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  height: 57px;
  border-radius: 53px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 14px 20px 14px 25px;
}

@media only screen and (max-width: 481px) {
  .search-header-wrapper .search-input-holder {
    max-width: 360px !important;
    height: 56px;
    padding: 14px 8px 14px 12px !important;
  }
}

@media only screen and (max-width: 362px) {
  .search-header-wrapper .search-input-holder {
    max-width: 310px !important;
    height: 40px;
    bottom: -24px;
  }
}

@media only screen and (max-width: 362px) {
  .search-header-wrapper .search-input-holder .ant-select-selection-selected-value {
    font-size: 15px !important;
  }
}

.search-header-wrapper .search-input-holder .select-city-holder {
  width: 140px;
}

.search-header-wrapper .search-input-holder .select-city-holder .ant-select-selection {
  padding-top: 0 !important;
  height: 33px;
  border-radius: 0 !important;
  color: #414141;
  font-size: 19px;
  font-weight: 400;
  border-bottom: 0 !important;
}

.search-header-wrapper .search-input-holder .select-city-holder .ant-select-selection .ant-select-arrow {
  transform: translateY(-50%);
  margin-top: 0;
}

.search-header-wrapper .search-input-holder .ant-input-group-compact {
  display: flex;
  align-items: center;
}

.search-header-wrapper .search-input-holder .ant-input {
  padding-top: 0 !important;
  height: 33px !important;
  border-bottom: 0 !important;
  border-left: 1px solid #ddd;
  padding-left: 36px !important;
  padding-right: 10px !important;
  background: url(../../resources/images/website/icon/search.svg) no-repeat;
  background-position: 12px 9px;
  width: 97%;
}

.search-header-wrapper .search-input-holder .ant-input + .ant-input-suffix {
  display: none;
}

@media only screen and (max-width: 993px) {
  .search-header-wrapper .search-input-holder .ant-input {
    border: 0 !important;
    border-bottom: 0 !important;
  }
}

body.rtl .search-header-wrapper .search-input-holder .ant-input {
  border-right: 1px solid #ddd;
  border-left: none;
  padding-right: 36px !important;
  padding-left: 10px !important;
  background-position-x: calc(100% - 12px);
}

.search-header-wrapper .search-input-holder .ant-input:-moz-placeholder {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.11px;
  text-align: left;
  color: #989898;
}

body.rtl .search-header-wrapper .search-input-holder .ant-input:-moz-placeholder {
  text-align: right;
}

.search-header-wrapper .search-input-holder .ant-input::-moz-placeholder {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.11px;
  text-align: left;
  color: #989898;
}

body.rtl .search-header-wrapper .search-input-holder .ant-input::-moz-placeholder {
  text-align: right;
}

.search-header-wrapper .search-input-holder .ant-input:-ms-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.11px;
  text-align: left;
  color: #989898;
}

body.rtl .search-header-wrapper .search-input-holder .ant-input:-ms-input-placeholder {
  text-align: right;
}

.search-header-wrapper .search-input-holder .ant-input::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.11px;
  text-align: left;
  color: #989898;
}

body.rtl .search-header-wrapper .search-input-holder .ant-input::-webkit-input-placeholder {
  text-align: right;
}

.search-header-wrapper .search-input-holder .ant-input-search-button {
  width: 125px;
  height: 40px;
  border-radius: 53px;
  background-image: linear-gradient(to right, #008539 16%, #5db92c 115%);
  font-size: 19px;
  font-weight: 400;
  color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3px;
}

@media only screen and (max-width: 769px) {
  .search-header-wrapper .search-input-holder .ant-input-search-button {
    width: 98px !important;
    height: 35px !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 362px) {
  .search-header-wrapper .search-input-holder .ant-input-search-button {
    width: 71px !important;
    height: 30px !important;
    font-size: 12px !important;
  }
}

.search-header-wrapper .search-input-holder .prefix-icon-holder {
  margin-right: 10px;
  height: 30px;
}

body.rtl .search-header-wrapper .search-input-holder .prefix-icon-holder {
  margin-left: 10px;
  margin-right: 0;
}

.request-card-wrapper * {
  font-family: Pridi;
}

.request-card-wrapper .search-header-wrapper {
  background: #fff;
  background-attachment: fixed;
  background-position: top;
}

.request-card-wrapper > .content {
  padding: 6px 20% 20px 20%;
  margin-top: -30px;
  background-color: #fff;
}

@media only screen and (max-width: 769px) {
  .request-card-wrapper > .content {
    padding: 20px !important;
  }
}

.request-card-wrapper > .content .ask-card-sginin-holder {
  background-color: #ffffff;
  height: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px 12px;
  margin-bottom: 22px;
  position: relative;
}

.request-card-wrapper > .content .ask-card-sginin-holder h4 {
  font-size: 21px;
  font-weight: normal;
  line-height: 1.19;
  color: #242430;
  margin-bottom: 30px;
  text-align: center;
}

@media only screen and (max-width: 769px) {
  .request-card-wrapper > .content .ask-card-sginin-holder h4 {
    font-size: 15px !important;
    padding: 0 12px;
    line-height: 1.4;
    margin-bottom: 10px;
  }
}

.request-card-wrapper > .content .ask-card-sginin-holder .anticon {
  position: absolute;
  right: 12px;
  top: 12px;
  color: #d83025;
  font-size: 25px;
  cursor: pointer;
}

@media only screen and (max-width: 769px) {
  .request-card-wrapper > .content .ask-card-sginin-holder .anticon {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 769px) {
  .request-card-wrapper > .content .ask-card-sginin-holder .gradient-fill {
    min-height: 40px !important;
    font-size: 13px;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper {
  background-color: #fff;
  padding: 20px 10%;
}

@media only screen and (max-width: 481px) {
  .request-card-wrapper > .content .charging-card-form-wrapper {
    padding: 20px !important;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper h2 {
  font-size: 19px;
  font-weight: 500;
  line-height: 1.19;
  color: #242430;
  text-align: center;
}

@media only screen and (max-width: 481px) {
  .request-card-wrapper > .content .charging-card-form-wrapper h2 {
    font-size: 18px;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper h4 {
  font-size: 21px;
  font-weight: bold;
  line-height: 1.19;
  color: #242430;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 481px) {
  .request-card-wrapper > .content .charging-card-form-wrapper h4 {
    font-size: 18px;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper .request-reject-reason {
  color: #d83025;
  font-weight: normal;
  text-align: center;
  margin-top: 25px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item {
  margin-bottom: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item .ant-calendar-picker {
  width: 100%;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item .ant-calendar-picker .ant-calendar-picker-icon {
  font-size: 20px;
  right: auto;
  left: 12px;
  display: flex;
  align-items: center;
  width: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
  display: flex;
  font-size: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item input {
  padding-top: 0 !important;
  height: 42px;
  margin: 0;
  padding-left: 40px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item input {
  padding-right: 40px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper {
  border-bottom: 1px solid #dadce0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .anticon {
  position: absolute;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  margin-left: 14px;
  margin-top: 2px;
  bottom: 0;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .anticon svg {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .anticon:after {
  content: url("../../resources/images/website/icon/gender.svg");
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-form-explain {
  position: absolute;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
  width: 100%;
  text-align: left;
  padding-left: 45px;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
  text-align: right;
  padding-right: 45px;
  padding-left: 0;
}

@media only screen and (max-width: 993px) {
  .request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
    padding-left: 5px !important;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  top: 3.2px;
  left: 3.2px;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  right: 3.2px;
  left: auto;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio + span {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430;
  display: inline-flex;
  align-items: center;
  margin: 0 30px;
  margin-bottom: 4px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
  border: solid 0.8px #4e4d4d;
  width: 16px;
  height: 16px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder {
  position: relative;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item.is-hidden {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(1)::after {
  content: url("../../resources/images/website/icon/car.svg");
  position: absolute;
  top: 23px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item.is-hidden {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  content: url("../../resources/images/website/icon/cartype.svg");
  position: absolute;
  top: 23px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item.is-hidden {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(3)::after {
  content: url("../../resources/images/website/icon/battery.svg");
  position: absolute;
  top: 23px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item.is-hidden {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(4)::after {
  content: url("../../resources/images/website/icon/license.svg");
  position: absolute;
  top: 23px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .ant-form-extra {
  font-size: 14px;
  line-height: 2.07;
  color: #5d71aa;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-checkbox + span {
  font-weight: normal !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item {
  margin-bottom: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item .ant-calendar-picker {
  width: 100%;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item .ant-calendar-picker .ant-calendar-picker-icon {
  font-size: 20px;
  right: auto;
  left: 12px;
  display: flex;
  align-items: center;
  width: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
  display: flex;
  font-size: 20px;
  left: 0 !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
  right: 0 !important;
  width: 16px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item input {
  padding-top: 0 !important;
  height: 42px;
  margin: 0;
  padding-left: 28px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item input {
  padding-right: 28px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper {
  border-bottom: 1px solid #dadce0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .anticon {
  position: absolute;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  margin-left: 14px;
  margin-top: 2px;
  bottom: 0;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .anticon svg {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .anticon:after {
  content: url("../../resources/images/website/icon/gender.svg");
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-form-explain {
  position: absolute;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
  width: 100%;
  text-align: left;
  padding-left: 45px;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
  text-align: right;
  padding-right: 45px;
  padding-left: 0;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  top: 3.2px;
  left: 3.2px;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  right: 2px;
  left: auto;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio + span {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430;
  display: inline-flex;
  align-items: center;
  margin: 0 30px;
  margin-bottom: 4px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
  border: solid 0.8px #4e4d4d;
  width: 16px;
  height: 16px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder + div .gradient-light-fill {
  min-height: 40px !important;
  font-size: 13px !important;
}

.user-profile-wrapper {
  background: #f5f5f5;
  height: calc(100% - 70px);
}

@media only screen and (max-width: 577px) {
  .user-profile-wrapper {
    height: 100% !important;
  }
}

@media only screen and (max-width: 577px) {
  .user-profile-wrapper .ant-tabs-bar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.user-profile-wrapper .ant-tabs {
  background: #f5f5f5;
  padding: 32px 6%;
  height: 100%;
}

@media only screen and (max-width: 577px) {
  .user-profile-wrapper .ant-tabs {
    height: unset;
  }
}

.user-profile-wrapper .ant-tabs .ant-tabs-left-bar {
  margin-right: 15px;
  background: white;
  border: none;
  width: 260px;
  max-width: 260px;
  padding: 22px 0;
}

body.rtl .user-profile-wrapper .ant-tabs .ant-tabs-left-bar {
  margin-left: 15px !important;
  margin-right: 0 !important;
}

.user-profile-wrapper .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  width: 100%;
}

.user-profile-wrapper .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  width: 4px !important;
}

@media only screen and (max-width: 641px) {
  .user-profile-wrapper .ant-tabs .ant-tabs-left-bar {
    width: 170px !important;
  }
}

@media only screen and (max-width: 577px) {
  .user-profile-wrapper .ant-tabs .ant-tabs-left-bar {
    margin-bottom: 20px;
    height: fit-content;
    width: 100% !important;
    max-width: unset !important;
  }
}

.user-profile-wrapper .ant-tabs .ant-tabs-tab {
  text-align: center !important;
  margin: 0 0 10px 0 !important;
  padding: 11px 24px !important;
  color: #4b4b4b !important;
  font-size: 19px;
  line-height: 0.68;
  font-family: Pridi;
}

@media only screen and (max-width: 577px) {
  .user-profile-wrapper .ant-tabs .ant-tabs-tab {
    padding: 21px 24px !important;
    font-size: 16px;
  }
}

.user-profile-wrapper .ant-tabs .ant-tabs-top-bar {
  background: white;
  border-bottom: none;
}

.user-profile-wrapper .ant-tabs .ant-tabs-content {
  background: white;
  border: none;
  height: 100%;
  padding: 0;
  overflow-y: scroll;
}

@media only screen and (max-width: 577px) {
  .user-profile-wrapper .ant-tabs .ant-tabs-content {
    width: 90%;
    margin: auto !important;
    display: block !important;
    height: unset !important;
    overflow-y: unset !important;
  }
}

.user-profile-wrapper .ant-tabs .ant-tabs-nav {
  width: 100%;
}

.user-profile-wrapper .ant-tabs .ant-tabs-nav .ant-tabs-tab-active {
  background-color: rgba(92, 174, 48, 0.16);
  color: black !important;
  font-weight: 600 !important;
}

@media only screen and (max-width: 577px) {
  .user-profile-wrapper .ant-tabs .ant-tabs-nav .ant-tabs-tab-active {
    background-color: white !important;
  }
}

@media only screen and (max-width: 769px) {
  .user-profile-wrapper .ant-tabs {
    padding: 32px 5px !important;
  }
}

@media only screen and (max-width: 577px) {
  .user-profile-wrapper .ant-tabs {
    padding: 10px 0 !important;
  }
}

.user-profile-wrapper .personal-info-holder .border-bottom {
  border-bottom: 1px solid #dadce0;
}

.user-profile-wrapper .personal-info-holder .ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-checked + span {
  color: #242430 !important;
}

.user-profile-wrapper .personal-info-holder .ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner {
  width: 15px;
  height: 15px;
  border-color: #008539;
}

.user-profile-wrapper .personal-info-holder .ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner:after {
  background-color: #008539;
}

.user-profile-wrapper .personal-info-holder .ant-radio-inner::after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
}

body.rtl .user-profile-wrapper .personal-info-holder .ant-radio-inner::after {
  right: 2px;
  left: auto;
}

.user-profile-wrapper .personal-info-holder .form-holder {
  position: relative;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-input {
  padding-left: 30px !important;
}

body.rtl .user-profile-wrapper .personal-info-holder .form-holder .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(1)::after {
  content: url("../../resources/images/website/icon/user.svg");
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(2)::after {
  content: url("../../resources/images/website/icon/user1.svg");
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(3)::after {
  content: url("../../resources/images/website/icon/email.svg");
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  content: url("../../resources/images/website/icon/phone.svg");
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  content: url("../../resources/images/website/icon/gender.svg");
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(6)::after {
  content: url("../../resources/images/website/icon/address.svg");
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

@media only screen and (max-width: 401px) {
  .user-profile-wrapper .personal-info-holder .resp-m-0 {
    margin: 0 !important;
  }
  .user-profile-wrapper .personal-info-holder div:nth-child(2) {
    padding: 15px;
  }
}

.user-profile-wrapper ::-webkit-scrollbar-thumb {
  background: transparent !important;
}

.user-profile-wrapper .header {
  position: sticky;
  width: 100%;
  background: white;
  top: 0;
  z-index: 9;
}

.user-profile-wrapper .car-info-tab {
  height: 100%;
}

.user-profile-wrapper .carInfo-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.user-profile-wrapper .carInfo-wrapper > div:nth-child(1) {
  flex: 0 0 auto;
}

.user-profile-wrapper .carInfo-wrapper > div:nth-child(2) {
  flex: 1 1 auto;
}

.user-profile-wrapper .carInfo-wrapper > div:nth-child(3) {
  flex: 0 0 auto;
}

.user-profile-wrapper .carInfo-wrapper .ant-select-selection__rendered {
  margin-left: 30px;
}

body.rtl .user-profile-wrapper .carInfo-wrapper .ant-select-selection__rendered {
  margin-left: 0 !important;
  margin-right: 30px;
}

.user-profile-wrapper .carInfo-wrapper .ant-input {
  padding-left: 30px !important;
}

body.rtl .user-profile-wrapper .carInfo-wrapper .ant-input {
  padding-left: 0 !important;
  padding-right: 30px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .ant-input {
  padding-left: 30px !important;
}

body.rtl .user-profile-wrapper .carInfo-wrapper .form-holder .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder {
  position: relative;
  border-right: solid 0.5px #adadad;
}

body.rtl .user-profile-wrapper .carInfo-wrapper .form-holder .col-holder {
  border-right: none;
  border-left: solid 0.5px #adadad;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(1)::after {
  content: url("../../resources/images/website/icon/car.svg");
  position: absolute;
  top: 23px;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3) {
  margin-bottom: 0 !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  content: url("../../resources/images/website/icon/cartype.svg");
  position: absolute;
  top: 23px;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3) {
  margin-bottom: 0 !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3)::after {
  content: url("../../resources/images/website/icon/battery.svg");
  position: absolute;
  top: 23px;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3) {
  margin-bottom: 0 !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(4)::after {
  content: url("../../resources/images/website/icon/license.svg");
  position: absolute;
  top: 23px;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3) {
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 993px) {
  .user-profile-wrapper .carInfo-wrapper .form-holder .col-holder {
    border: none !important;
    padding: 0 !important;
    margin-bottom: 20px;
  }
}

.user-profile-wrapper .carInfo-wrapper .box-holder {
  border-radius: 4px;
  border: solid 0.5px #adadad;
  margin: 16px 0;
}

.user-profile-wrapper .carInfo-wrapper .box-holder .dark-blue {
  color: #5d71aa;
  margin-top: -5px;
}

@media only screen and (max-width: 1025px) {
  .user-profile-wrapper .carInfo-wrapper .box-holder .dark-blue {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 993px) {
  .user-profile-wrapper .carInfo-wrapper .box-holder .dark-blue {
    font-size: 14px !important;
  }
}

.user-profile-wrapper .carInfo-wrapper .box-holder .ant-form-item {
  margin-bottom: 5px !important;
}

.user-profile-wrapper .carInfo-wrapper .box-holder .delete-box-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #d83025;
  cursor: pointer;
}

@media only screen and (max-width: 993px) {
  .user-profile-wrapper .carInfo-wrapper .box-holder .resp-handel {
    padding-left: 0 !important;
  }
}

.user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
  border-radius: 0 !important;
  border: dashed 0.5px #707070 !important;
  width: 180px !important;
}

@media only screen and (max-width: 1249px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 140px !important;
  }
}

@media only screen and (max-width: 1025px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 104px !important;
  }
}

@media only screen and (max-width: 993px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 200px !important;
  }
}

@media only screen and (max-width: 769px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 140px !important;
  }
}

@media only screen and (max-width: 401px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 115px !important;
  }
}

.user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card img {
  border-radius: 0 !important;
}

.user-profile-wrapper .border-shadow {
  box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.16);
}

@media only screen and (max-width: 993px) {
  .user-profile-wrapper .request-info .ant-table-wrapper {
    overflow: auto;
  }
  .user-profile-wrapper .request-info .ant-table-wrapper .ant-spin-nested-loading {
    width: max-content;
    min-width: 991px;
    overflow: auto;
  }
}

.user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th,
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td {
  text-align: center;
}

body.rtl .user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th, body.rtl
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td {
  border-left: none !important;
}

.user-profile-wrapper .request-info .inprogress-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: solid 2px rgba(112, 112, 112, 0.17);
  margin: auto;
}

.user-profile-wrapper .request-info .ant-table-thead > tr > th {
  background: white !important;
  padding: 4px 0 11px;
}

.user-profile-wrapper .request-info .ant-table table {
  border: none !important;
  overflow: hidden;
}

.user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th:last-of-type,
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td:last-of-type {
  border-right: none !important;
}

body.rtl .user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th:last-of-type, body.rtl
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td:last-of-type {
  border-left: none !important;
  border-right: 1px solid #e8e8e8 !important;
}

body.rtl .user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th:first-of-type, body.rtl
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td:first-of-type {
  border-right: none !important;
}

.user-profile-wrapper .request-info .ant-table-tbody > tr:last-of-type > td {
  border-bottom: none !important;
}

.user-profile-wrapper .request-info .ant-table-content {
  border-radius: 4px;
  border: solid 0.5px #e8e8e8;
  padding: 12px;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
  content: url("../../resources/images/website/icon/clock.svg");
  position: absolute;
  top: 0px;
  left: 25px;
}

body.rtl .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
  right: 25px;
  left: unset;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  top: -4px;
  left: 60px;
}

@media only screen and (max-width: 1025px) {
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
    top: 0px;
    left: 0px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
    top: 0px;
    left: 5px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
    top: -4px;
    left: 18px;
  }
}

.user-profile-wrapper .request-info .ant-table-thead tr th > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) {
  width: 30%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) {
  width: 20%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) {
  width: 40%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(4) {
  width: 10%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
  content: url("../../resources/images/website/icon/creditCard.svg");
  position: absolute;
  top: 0px;
  left: 25px;
}

body.rtl .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
  right: 25px;
  left: unset;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  top: -4px;
  left: 60px;
}

@media only screen and (max-width: 1025px) {
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
    top: 0px;
    left: 0px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
    top: 0px;
    left: 5px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
    top: -4px;
    left: 18px;
  }
}

.user-profile-wrapper .request-info .ant-table-thead tr th > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) {
  width: 30%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) {
  width: 20%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) {
  width: 40%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(4) {
  width: 10%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  content: url("../../resources/images/website/icon/location.svg");
  position: absolute;
  top: 0px;
  left: 25px;
}

body.rtl .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  right: 25px;
  left: unset;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  top: -4px;
  left: 60px;
}

@media only screen and (max-width: 1025px) {
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
    top: 0px;
    left: 0px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
    top: 0px;
    left: 5px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
    top: -4px;
    left: 18px;
  }
}

.user-profile-wrapper .request-info .ant-table-thead tr th > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) {
  width: 30%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) {
  width: 20%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) {
  width: 40%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(4) {
  width: 10%;
}

.user-profile-wrapper .request-info .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.user-profile-wrapper .request-info .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.user-profile-wrapper .request-info .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.user-profile-wrapper .request-info .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #f5f5f5;
}

.user-profile-wrapper .request-info tr td:not(:nth-child(3)) {
  font-size: 16px;
}

.user-profile-wrapper .request-info tr td > div.card-status-holder.need-action {
  color: #008539;
}

.user-profile-wrapper .request-info tr td > div.card-status-holder.new {
  color: #1b6bba;
}

.user-profile-wrapper .request-info tr td > div.card-status-holder.in-progress {
  color: #98681b;
}

.user-profile-wrapper .request-info.no-requests .ant-table-wrapper {
  display: none;
}

.user-profile-wrapper .request-info.no-requests .empty-requests {
  display: block !important;
}

.user-profile-wrapper .request-info.no-requests .empty-requests h3 {
  color: #a3a3a3;
  font-family: Pridi;
}

@media only screen and (max-width: 577px) {
  .user-profile-wrapper .request-info.no-requests .empty-requests img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 577px) {
  .user-profile-wrapper .request-info.no-requests .empty-requests div:nth-child(2) {
    padding: 13px !important;
  }
}

@media only screen and (max-width: 993px) {
  .user-profile-wrapper .request-info .ant-table-pagination.ant-pagination {
    float: left;
    align-self: unset !important;
  }
}

.user-profile-wrapper .request-info .request-info-row-action {
  cursor: pointer;
}

.user-profile-wrapper .request-info .request-info-row-action span {
  height: 25px;
  width: 25px;
  border: 2px solid #70707021;
  border-radius: 50%;
  margin: auto;
  display: block;
}

.user-profile-wrapper .request-info .ant-spin-container {
  display: flex;
  flex-direction: column;
}

.user-profile-wrapper .request-info .ant-spin-container .ant-table-pagination.ant-pagination {
  align-self: center;
}

.cursor {
  cursor: pointer;
}

.cancel-modal p {
  color: #383737;
  line-height: 1.2;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
}

.cancel-modal .anticon {
  color: #d83025;
}

.cancel-modal .ant-modal-body {
  padding: 60px 24px 24px !important;
}

.changePassModal .ant-modal-header {
  border: none;
  text-align: center;
  padding-top: 30px;
}

.changePassModal .ant-modal-header .ant-modal-title {
  font-size: 20px;
  font-weight: 600;
  color: #383737;
}

.changePassModal .ant-modal-body {
  padding-top: 0 !important;
}

.changePassModal .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: 35px !important;
}

body.rtl .changePassModal .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  left: 35px !important;
  right: unset !important;
  top: 35px;
}

body.rtl .changePassModal .ant-input-password.ant-input-affix-wrapper + .ant-form-item-children-icon .anticon-close-circle svg {
  top: 18px;
}

.changePassModal .linear-green {
  background: linear-gradient(271deg, #86d958 100%, #008539 0%) !important;
}

.changePassModal .ant-form-item {
  margin-bottom: 5px !important;
}

.changePassModal .ant-modal-close-x {
  color: #d83025;
}

.changePassModal .form-holder {
  position: relative;
}

.changePassModal .form-holder .ant-input {
  padding-left: 30px !important;
}

body.rtl .changePassModal .form-holder .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.changePassModal .form-holder .ant-form-item::after {
  content: url("../../resources/images/website/icon/password.svg");
  position: absolute;
  top: 25px;
}

.fixed-footer {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
}

@media only screen and (max-width: 577px) {
  .fixed-footer {
    justify-content: center !important;
  }
}

.ant-table-fixed-left {
  left: 12px !important;
  top: 12px !important;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-content {
  padding-right: 0 !important;
}

.contact-us-wrapper .contactus-form-wrapper {
  height: calc(100vh - 70px);
}

@media only screen and (max-width: 641px) {
  .contact-us-wrapper .contactus-form-wrapper {
    padding: 0 !important;
    height: 100vh;
    background: url(../../resources/images/website/Contact-Us-Page.png) no-repeat;
    background-attachment: fixed;
    background-position: top;
    background-position: top;
    background-size: cover;
    position: relative;
  }
}

.contact-us-wrapper .contactus-form-wrapper .helpImg-holder {
  flex: 1 1;
  position: relative;
  text-align: center;
}

.contact-us-wrapper .contactus-form-wrapper .helpImg-holder img {
  width: 100%;
  max-width: 550px;
}

.contact-us-wrapper .contactus-form-wrapper .helpImg-holder .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f7f7f7;
  line-height: 1.24;
  letter-spacing: 2.1px;
  font-size: 42px;
  font-family: Pridi;
}

@media only screen and (max-width: 641px) {
  .contact-us-wrapper .contactus-form-wrapper .helpImg-holder {
    display: none;
  }
}

.contact-us-wrapper .contactus-form-wrapper .form-holder {
  flex: 1 1;
  position: relative;
}

@media only screen and (max-width: 641px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder {
    margin-top: 60px;
  }
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .title {
  font-size: 21px;
  font-weight: bold;
  line-height: 1.19;
  color: #242430;
  text-align: center;
  font-family: Pridi;
}

@media only screen and (max-width: 641px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder .title {
    color: white !important;
  }
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .sub-title {
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  font-family: pridi;
  padding-top: 8px;
  display: none;
}

@media only screen and (max-width: 641px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder .sub-title {
    display: block !important;
  }
}

@media only screen and (max-width: 641px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder ::placeholder {
    color: white !important;
  }
}

.contact-us-wrapper .contactus-form-wrapper .form-holder button {
  border: none !important;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form {
  width: 80%;
  margin: auto;
  position: relative;
}

@media only screen and (max-width: 641px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form {
    margin-top: 40px;
  }
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-input {
  padding-left: 30px !important;
}

@media only screen and (max-width: 641px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-input {
    color: #FFF;
  }
}

body.rtl .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(1)::after {
  content: url("../../resources/images/website/icon/user.svg");
  position: absolute;
  top: 25px;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after {
  display: none;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(2)::after {
  content: url("../../resources/images/website/icon/email.svg");
  position: absolute;
  top: 25px;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after {
  display: none;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after {
  content: url("../../resources/images/website/icon/phone.svg");
  position: absolute;
  top: 25px;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after {
  display: none;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(4)::after {
  content: url("../../resources/images/website/icon/request.svg");
  position: absolute;
  top: 25px;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after {
  display: none;
}

@media only screen and (max-width: 641px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(1)::after {
    content: url("../../resources/images/website/icon/user-white.svg");
    position: absolute;
    top: 25px;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after {
    display: none;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(2)::after {
    content: url("../../resources/images/website/icon/mail-white.svg");
    position: absolute;
    top: 25px;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after {
    display: none;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after {
    content: url("../../resources/images/website/icon/phone-white.svg");
    position: absolute;
    top: 25px;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after {
    display: none;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(4)::after {
    content: url("../../resources/images/website/icon/request-white.svg");
    position: absolute;
    top: 25px;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after {
    display: none;
  }
}

.contact-us-wrapper .contactus-form-wrapper .form-holder button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 128px;
  height: 39px;
  border-radius: 21px;
  background-image: linear-gradient(89deg, #008539 1%, #86d958 100%);
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.58px;
  color: #ffffff;
  margin: auto;
}

@media only screen and (max-width: 641px) {
  .contact-us-wrapper .contactus-form-wrapper .overlay-black {
    background: rgba(0, 0, 0, 0.47);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.contact-us-wrapper .contactus-form-wrapper .react-tel-input .selected-flag {
  padding: 0 !important;
}

.contact-us-wrapper .contactus-form-wrapper textarea {
  resize: none;
}

.contact-us-wrapper .contact-details-wrapper {
  height: 70vh;
}

@media only screen and (max-width: 769px) {
  .contact-us-wrapper .contact-details-wrapper {
    height: 100vh;
  }
  .contact-us-wrapper .contact-details-wrapper > div:first-child {
    padding-top: 250px;
  }
}

.contact-us-wrapper .contact-details-wrapper > div:first-child {
  height: 100%;
  position: relative;
}

.contact-us-wrapper .contact-details-wrapper > div:first-child > div:first-child {
  height: 100%;
  position: relative;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
}

.contact-us-wrapper .contact-details-wrapper > div:first-child > div:first-child > div > div {
  height: 100% !important;
}

.contact-us-wrapper .contact-details-wrapper .contact-us-info {
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.29);
  background-color: #ffffff;
  padding: 42px 35px;
  max-width: 368px;
  min-width: 300px;
  position: absolute;
  top: 50px;
  left: 90px;
}

.contact-us-wrapper .contact-details-wrapper .contact-us-info p {
  font-size: 14px;
  line-height: 1.71;
  color: #404040;
}

.contact-us-wrapper .contact-details-wrapper .contact-us-info h4 {
  font-size: 16px;
  font-weight: 600;
  color: black;
  font-family: Pridi;
}

@media only screen and (max-width: 769px) {
  .contact-us-wrapper .contact-details-wrapper .contact-us-info {
    top: 15px !important;
    left: 35px !important;
  }
}

@media only screen and (max-width: 641px) {
  .contact-us-wrapper .contact-details-wrapper .contact-us-info {
    top: -33px !important;
    left: calc((100vw - 368px) / 2) !important;
  }
}

@media only screen and (max-width: 381px) {
  .contact-us-wrapper .contact-details-wrapper .contact-us-info {
    top: -33px !important;
    left: calc((100vw - 344px) / 2) !important;
    max-width: 344px !important;
    padding: 20px !important;
  }
  body.rtl .contact-us-wrapper .contact-details-wrapper .contact-us-info {
    left: auto;
    right: calc((100vw - 344px) / 2) !important;
  }
}

@media only screen and (max-width: 641px) {
  .contact-us-wrapper .tel-wrapper .react-tel-input .form-control {
    background: transparent;
    color: #FFF;
  }
}

.student-result-drawer .ant-drawer-content-wrapper {
  width: auto;
}

.student-result-drawer .ant-drawer-close {
  color: #f80000;
  font-size: 22px;
}

body.rtl .student-result-drawer .ant-drawer-close {
  left: 0;
  right: auto;
}

.student-result-drawer .ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
}

.student-result-drawer.ant-drawer.ant-drawer-open .ant-drawer-mask {
  opacity: 0.43 !important;
  backdrop-filter: blur(10px);
  border: solid 1px #707070;
  background-color: rgba(0, 0, 0, 0.34) !important;
}

.student-result-drawer .ant-drawer-header {
  background-color: rgba(210, 218, 222, 0.35);
}

.student-result-drawer .ant-drawer-body {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.student-result-drawer .ant-drawer-title > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.student-result-drawer .ant-drawer-title h3 {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.student-result-drawer .ant-drawer-title p {
  font-size: 14px;
  font-weight: 500;
  color: #2c363f;
}

.student-result-drawer .ant-drawer-content-wrapper {
  width: auto !important;
}

@media only screen and (max-width: 769px) {
  .student-result-drawer .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.student-result-drawer .student-total-degree {
  flex: 1;
}

.student-result-drawer .student-total-degree h5 {
  font-size: 14px;
  font-weight: 800;
  color: #2c363f;
  margin-bottom: 20px;
  padding: 0 24px;
}

.student-result-drawer .student-total-degree .material-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
}

@media only screen and (max-width: 769px) {
  .student-result-drawer .student-total-degree .material-wrapper {
    padding: 0 10px;
  }
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree h6 {
  font-size: 19px;
  font-weight: 500;
  color: #4e4e4f;
  border-right: 1px solid #d2dade;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 50px;
  width: 125px;
}

@media only screen and (max-width: 769px) {
  .student-result-drawer .student-total-degree .material-wrapper .material-degree h6 {
    margin: 0 !important;
  }
}

body.rtl .student-result-drawer .student-total-degree .material-wrapper .material-degree h6 {
  margin-right: 0;
  margin-left: 50px;
  border-left: 1px solid #d2dade;
  border-right: 0;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree p {
  font-size: 18px;
  font-weight: normal;
  color: #11b74f;
  width: 100px;
  text-align: center;
  margin-left: 60px;
}

body.rtl .student-result-drawer .student-total-degree .material-wrapper .material-degree p {
  margin-right: 60px;
  margin-left: 0;
}

@media only screen and (max-width: 769px) {
  .student-result-drawer .student-total-degree .material-wrapper .material-degree p {
    margin: 0 !important;
  }
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress {
  width: 150px !important;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress > div {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 769px) {
  .student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress > div {
    flex-direction: column;
    justify-content: center;
  }
  .student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress > div .ant-progress-outer {
    padding: 0 16px;
  }
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress .ant-progress-inner {
  height: 10px !important;
  border-radius: 3px;
  background-color: #dbe6eb;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress .ant-progress-bg {
  background-color: #11b74f !important;
  height: 10px !important;
  border-radius: 3px !important;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress-text {
  font-size: 16px;
  font-weight: 600;
  color: #2c363f !important;
}

@media only screen and (max-width: 769px) {
  .student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress-text {
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree.material-degree--VGood .ant-progress .ant-progress-bg {
  background-color: #b7b111 !important;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree.material-degree--VGood p {
  color: #b7b111;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree.material-degree--good .ant-progress .ant-progress-bg {
  background-color: #ffbc08 !important;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree.material-degree--good p {
  color: #ffbc08;
}

.student-result-drawer .student-total-degree .material-wrapper.total-result-warpper {
  height: 76.5px;
  background-color: rgba(210, 218, 222, 0.19);
  display: flex;
  justify-content: center;
  margin: 25px 0;
  border-top: dashed 1px #d2dade;
  border-bottom: dashed 1px #d2dade;
}

.student-result-drawer .student-total-degree .material-wrapper.total-result-warpper h6 {
  font-size: 19px;
  font-weight: bold;
  color: #000000;
  border-right: none;
}

.student-result-drawer .student-total-degree .material-wrapper.total-result-warpper .ant-progress-text {
  font-weight: 800;
}

.student-result-drawer .degree-result-msg {
  height: 100px;
  width: 75%;
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #000000;
}

.student-result-drawer .degree-result-msg span {
  font-weight: 800;
  color: #11b74f;
}

.material-degree {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.material-degree h6 {
  font-size: 19px;
  font-weight: 500;
  color: #4e4e4f;
  border-right: 1px solid #d2dade;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 50px;
  width: 125px;
}

@media only screen and (max-width: 769px) {
  .material-degree h6 {
    margin: 0 !important;
  }
}

body.rtl .material-degree h6 {
  margin-right: 0;
  margin-left: 50px;
  border-left: 1px solid #d2dade;
  border-right: 0;
}

.material-degree p {
  font-size: 18px;
  font-weight: normal;
  color: #11b74f;
  width: 100px;
  text-align: center;
  margin-left: 60px;
}

body.rtl .material-degree p {
  margin-right: 60px;
  margin-left: 0;
}

@media only screen and (max-width: 769px) {
  .material-degree p {
    margin: 0 !important;
  }
}

.material-degree .ant-progress {
  width: 150px !important;
}

.material-degree .ant-progress > div {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 769px) {
  .material-degree .ant-progress > div {
    flex-direction: column;
    justify-content: center;
  }
  .material-degree .ant-progress > div .ant-progress-outer {
    padding: 0 16px;
  }
}

.material-degree .ant-progress .ant-progress-inner {
  height: 10px !important;
  border-radius: 3px;
  background-color: #dbe6eb;
}

.material-degree .ant-progress .ant-progress-bg {
  background-color: #11b74f !important;
  height: 10px !important;
  border-radius: 3px !important;
}

.material-degree .ant-progress-text {
  font-size: 16px;
  font-weight: 600;
  color: #2c363f !important;
}

@media only screen and (max-width: 769px) {
  .material-degree .ant-progress-text {
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }
}

.material-degree.material-degree--VGood .ant-progress .ant-progress-bg {
  background-color: #b7b111 !important;
}

.material-degree.material-degree--VGood p {
  color: #b7b111;
}

.material-degree.material-degree--good .ant-progress .ant-progress-bg {
  background-color: #ffbc08 !important;
}

.material-degree.material-degree--good p {
  color: #ffbc08;
}

.notifications-list-wrapper .notifications-list-header {
  position: sticky;
  top: 0;
  background: #f1f4f0;
  height: 55px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 2;
  box-shadow: 1px 2px 7px #5555552c;
}

body.rtl .notifications-list-wrapper .notifications-list-header {
  padding-right: 20px;
}

.notifications-list-wrapper .notifications-list-header h3 {
  color: #000000;
  font-weight: 600;
  font-size: 21px;
  padding-inline-start: 9px;
}

.notifications-list-wrapper .notifications-list-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 70px;
  width: 100%;
  height: 55px;
  padding-left: 20px;
  font-size: 17px;
  font-weight: 600;
  background: #fff;
  color: #008539;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  z-index: 5;
}

.notifications-list-wrapper .notifications-holder {
  height: 100vh;
  overflow: scroll;
}

.notifications-list-wrapper .notifications-holder .notification-holder {
  display: flex;
  background-color: #fff;
  height: 101px;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
}

.notifications-list-wrapper .notifications-holder .notification-holder.active {
  background-color: #f5faf2;
}

.notifications-list-wrapper .notifications-holder .notification-holder.active .notification-content p {
  font-weight: bold;
}

.notifications-list-wrapper .notifications-holder .notification-holder.is-date {
  height: 65px;
  cursor: default;
  align-items: center;
}

.notifications-list-wrapper .notifications-holder .notification-holder.is-last::after {
  display: none;
}

.notifications-list-wrapper .notifications-holder .notification-holder::after {
  content: '';
  display: block;
  position: absolute;
  background: #cfcfcf;
  height: 1px;
  width: calc(100% - 40px);
  margin: auto;
  bottom: 0;
  left: 20px;
}

.notifications-list-wrapper .notifications-holder .notification-holder .notification-content {
  display: flex;
  align-items: flex-start;
  position: relative;
  top: 20px;
}

.notifications-list-wrapper .notifications-holder .notification-holder .notification-content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.33;
}

@media only screen and (max-width: 993px) {
  .notifications-list-wrapper .notifications-holder .notification-holder .notification-content p {
    font-size: 13px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
  }
}

.notifications-list-wrapper .notifications-holder .notification-holder .notification-content img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-inline-end: 10px;
  box-shadow: 0px 0px 3px 0px #7a7a7a3b;
}

.notifications-list-wrapper .notifications-holder .notification-holder time {
  position: absolute;
  bottom: 5px;
  left: 70px;
  color: #939191;
}

body.rtl .notifications-list-wrapper .notifications-holder .notification-holder time {
  left: 0;
  right: 70px;
}

.notifications-list-wrapper .notifications-holder .notification-holder h4 {
  font-size: 19px;
}

.notifications-list-wrapper .notifications-holder .lottie-spinner {
  top: calc(50% - 100px);
  left: calc(50% - 100px);
}

.notifications-list-wrapper .notifications-holder .ant-empty {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 125px);
  width: 250px;
  height: 100px;
}

.web-notifications .ant-drawer-wrapper-body {
  overflow: hidden !important;
}

.notification-count {
  background: #db4a41;
  width: 27px;
  height: 27px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 25px;
}

body.rtl .student-view .student-view__tabs {
  direction: ltr;
}

.student-view .student-view__tabs .ant-tabs-bar {
  border-bottom: 0;
}

.answer-media-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  margin-top: 10px;
  overflow-x: auto;
}

.answer-media-container .answer-media__card {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  border: solid 1px #d2dade;
  padding: 15px;
  border-radius: 6px;
  margin: 5px 0px;
  height: 50px;
}

.answer-media-container .answer-media__card p {
  color: #000000;
  font-size: 12px;
  white-space: nowrap;
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 30px;
}

.answer-media-container .answer-media__card i {
  font-size: 18px;
}

.answer-media-container .answer-media__card i:last-of-type {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

body.rtl .answer-media-container .answer-media__card i:last-of-type {
  left: 20px;
  right: unset;
}

.assigments-tab .calender-show-hide {
  position: relative;
  z-index: 20;
  width: auto;
  margin-left: auto;
}

body.rtl .assigments-tab .calender-show-hide {
  margin-right: auto;
  margin-left: 50px;
}

@media only screen and (max-width: 993px) {
  .assigments-tab .calender-show-hide {
    position: absolute;
    bottom: unset;
    margin-top: -15px;
    background-color: #fff !important;
    padding: 10px;
    right: -30px;
    border-radius: 5px;
    border: 1px solid #ebebeb;
    cursor: pointer;
  }
  body.rtl .assigments-tab .calender-show-hide {
    margin-right: unset;
    margin-left: unset;
    right: unset;
    left: 20px;
  }
}

.assigments-tab .courses-tab__content {
  margin-top: 10px;
}

.assigments-tab .courses-tab__content .ant-tabs-content .tabs-content-wrapper {
  min-height: 45vh;
  height: 60vh;
  position: relative;
  overflow-y: auto;
}

.assigments-tab .ant-tabs-nav .ant-tabs-tab {
  margin: 0 10px 0 0;
  font-size: 16px;
  font-weight: 600;
}

body.rtl .assigments-tab .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 10px;
}

@media only screen and (max-width: 381px) {
  .assigments-tab .ant-tabs-nav .ant-tabs-tab {
    font-size: 11px;
    padding: 12px !important;
  }
}

.assigments-tab .assignments-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(470px, 1fr));
  gap: 12px 20px;
}

@media only screen and (max-width: 993px) {
  .assigments-tab .assignments-grid {
    grid-template-columns: 1fr;
    overflow-y: auto;
    max-height: calc(100vh - 210px);
  }
}

.display-block {
  display: block !important;
}

.assignment-deatails {
  padding: 0 32px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 577px) {
  .assignment-deatails {
    padding: 0 10px;
    margin-top: 15px;
  }
}

.assignment-deatails .ant-tabs-nav .ant-tabs-tab {
  font-size: 12px;
  font-weight: normal;
}

.assignment-deatails .assignment-info {
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  padding: 20px;
}

body.rtl .assignment-deatails .assignment-info .ant-breadcrumb {
  text-align: right;
}

.assignment-deatails .ant-divider {
  margin-bottom: 5px;
}

.assignment-deatails .assignment-info__teacher-questions {
  padding-inline-start: 25px;
  margin-top: 15px;
}

@media only screen and (max-width: 577px) {
  .assignment-deatails .assignment-info__teacher-questions {
    padding-inline-start: 0;
  }
}

.assignment-deatails .assignment-info__teacher-questions > header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.assignment-deatails .assignment-info__teacher-questions > header .teacher-info {
  display: flex;
  align-items: center;
}

.assignment-deatails .assignment-info__teacher-questions > header .teacher-info--name {
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  margin-inline-start: 6px;
}

.assignment-deatails .assignment-info__teacher-questions > header .question-time {
  font-size: 14px;
  font-weight: normal;
  color: #00adee;
  display: flex;
  align-items: center;
}

.assignment-deatails .assignment-info__teacher-questions > header .question-time i {
  margin-inline-start: 5px;
}

.assignment-deatails .assignment-info__teacher-questions > header .feedback-badge {
  width: 143px;
  height: 44px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  border: solid 0.5px rgba(210, 218, 222, 0.23);
  background-color: rgba(210, 218, 222, 0.23);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
  margin-inline-end: -33px;
}

.assignment-deatails .assignment-info__teacher-questions > header .feedback-badge h5 {
  font-size: 15px;
  font-weight: 500;
  color: #3c3c38;
}

.assignment-deatails .assignment-info__teacher-questions .teacher-questions__list .teacher-questions__list--question {
  display: flex;
  max-height: 70vh;
  overflow: auto;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.56;
  color: #3c3c38;
  padding-top: 15px;
  margin-inline-end: auto;
  margin-bottom: 10px;
  word-break: break-word;
}

@media only screen and (max-width: 993px) {
  .assignment-deatails .assignment-info__teacher-questions .teacher-questions__list .teacher-questions__list--question {
    width: 100%;
  }
}

.assignment-deatails .assignment-info__teacher-questions .teacher-questions__list .teacher-questions__list--question .ck-editor__main > .ck-editor__editable {
  border: none;
  pointer-events: none;
}

.assignment-deatails .assignment-info__teacher-questions .teacher-questions__list .teacher-questions__list--question .ck-toolbar {
  border: none;
}

.assignment-deatails .assignment-info__teacher-questions.assignment-info__student-answers .teacher-questions__list--question {
  color: #767676;
}

.assignment-deatails .assignment-info__teacher-questions.assignment-info__student-answers .teacher-info--name {
  color: #0793c7;
  font-weight: normal;
}

.assignment-deatails .assignment-info__teacher-questions.assignment-info__student-answers .files-uploaded-wrapper {
  padding: 15px 0;
}

.assignment-deatails .assignment-info__teacher-questions.assignment-info__student-answers .files-uploaded-wrapper .file-card {
  cursor: pointer;
}

.assignment-deatails .assignment-files {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  padding: 12px;
}

.assignment-deatails .assignment-files .second-part {
  padding: 0;
}

.assignment-deatails .assignment-files .second-part .attachment-details-holder {
  flex-direction: column;
}

@media only screen and (max-width: 993px) {
  .assignment-deatails .assignment-files .second-part .attachment-details-holder {
    overflow-y: hidden;
  }
  .assignment-deatails .assignment-files .second-part .attachment-details-holder.flex {
    border: unset !important;
    flex-direction: row;
  }
  .assignment-deatails .assignment-files .second-part .attachment-details-holder.flex-column {
    border: unset !important;
    flex-direction: column;
  }
}

.assignment-deatails .assignment-files .second-part .downloads-holder {
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 20px 10px;
  max-height: 73vh;
  overflow: auto;
}

.assignment-deatails .assignment-files .second-part .downloads-holder > div {
  margin: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.assignment-deatails .assignment-files .second-part .download-info {
  margin-inline-end: 0;
  justify-content: space-between;
}

.assignment-deatails .assignment-files .second-part .contnet-download {
  width: auto;
  height: auto;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
}

.assignment-deatails .assignment-files .second-part .contnet-download .poster {
  padding: 10px;
  box-shadow: none;
}

.assignment-deatails .assignment-files .title {
  font-size: 14px;
  font-weight: bold;
  color: #222222;
}

.assignment-deatails .reply-holder {
  border: solid 1px #d4d4d4;
  border-radius: 4px;
  margin: 20px 0;
}

.assignment-deatails .reply-holder textarea {
  padding-top: 5px;
}

.assignment-deatails .reply-holder .ant-form-item {
  margin-bottom: 0;
}

.assignment-deatails .reply-holder .ant-comment-inner {
  padding: 0;
}

.assignment-deatails .reply-holder .ant-comment-inner .ant-comment-avatar,
.assignment-deatails .reply-holder .ant-comment-inner .ant-comment-content-author {
  margin: 0;
}

.assignment-deatails .reply-holder .ant-comment-inner textarea.ant-input {
  padding: 10px 12px;
  resize: none;
  min-height: 210px;
  height: 210px;
  border: none;
}

.assignment-deatails .reply-holder .textarea-action-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F2F2F2;
  padding: 2px 10px;
  height: 48px;
  border-radius: 0 0 4px 4px;
}

.assignment-deatails .reply-holder .textarea-action-btns .textarea-icon {
  width: 38px;
  height: 38px;
  color: #0793c7;
  margin: 2px;
  margin-inline-end: 10px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  cursor: pointer;
  border-radius: 4px;
  background-color: rgba(7, 147, 199, 0.11);
}

.assignment-deatails .reply-holder .textarea-action-btns .attachment-holder__list {
  margin-top: 0;
  position: absolute;
  top: -60px;
  left: -60px;
  display: none;
}

.assignment-deatails .reply-holder .textarea-action-btns .attachment-holder__list li {
  width: 200px;
}

.assignment-deatails .reply-holder .textarea-action-btns .attachment-icon {
  background-color: transparent;
  margin: 2px;
}

.assignment-deatails .reply-holder .textarea-action-btns > div {
  display: flex;
  align-items: center;
}

.assignment-deatails .reply-holder .textarea-action-btns .attachment-holder__actions {
  border: 0;
  height: 38px;
}

.assignment_detials_responsive_card {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  border: 1px #d2dade solid;
  padding: 5px 10px;
  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}

.assignment_detials_responsive_card p {
  display: flex;
  color: #000000;
  margin: 10px 0px;
}

.assignment_detials_responsive_card p img {
  margin-right: 5px;
}

body.rtl .assignment_detials_responsive_card p img {
  margin-right: unset;
  margin-left: 10px;
}

.assignment_detials_responsive_card p:nth-child(3) {
  color: #00adee;
  font-weight: 500;
  margin: 5px 0px 10px 0px;
}

.assignment_detials_responsive_card .ck-editor__main > .ck-editor__editable {
  border: none;
  pointer-events: none;
}

.assignment_detials_responsive_card .ck-toolbar {
  border: none;
}

.feadback-pass-popover h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  color: #57595a;
}

.feadback-pass-popover .feedback-article {
  border-radius: 6px;
  border: solid 0.5px #d2dade;
  background-color: #ffffff;
  padding: 10px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  color: #6e727d;
  margin: 10px 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.feadback-pass-popover .passed-alert {
  font-size: 12px;
  margin-top: 7px;
}

.feadback-pass-popover .passed-alert img {
  margin-inline-start: 0;
  width: 14px;
}

.feadback-pass-popover .fail-alert {
  color: #ee3700;
}

.passed-alert,
.fail-alert {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  color: #11b74f;
}

.passed-alert img,
.fail-alert img {
  margin-inline-start: 20px;
  margin-inline-end: 5px;
}

.fail-alert {
  color: #ee3700;
}

.homework-actions {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

@media only screen and (max-width: 769px) {
  .homework-actions {
    justify-content: center;
  }
}

.homework-actions button.primary-fill, .homework-actions button.primary-outline {
  padding: 9px 58px 9px 58px;
}

.homework-actions button.primary-outline {
  margin: 0 20px;
}

.reply-wrapper {
  color: #0793c7;
  font-size: 12px;
  font-weight: 600;
  line-height: 2.08;
}

.reply-wrapper textarea {
  border: none;
}

.reply-wrapper .reply-actions {
  background: rgba(237, 237, 237, 0.73);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px;
  height: 45px;
}

.reply-wrapper span {
  cursor: pointer;
}

body.rtl .reply-wrapper span {
  margin-right: 0;
}

.loadingIndicator_wrapper .lottie-spinner {
  top: calc(50% + 100px);
}

.teacher-questions__list--question > div p {
  margin-bottom: 10px;
}

.attachments_list_slider {
  height: 150px;
}

.attachments_list_slider .slick-dots {
  bottom: -5px;
}

.attachments_list_slider .slick-dots li.slick-active button::before {
  color: #2b7cd3;
  content: '•';
  font-size: 7px;
}

.attachments_list_slider .slick-dots li button::before {
  content: 'O';
  font-size: 10px;
  color: #2b7cd3;
  font-weight: 500;
}

.exams-tab {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 10px;
}

.exams-tab .student-exams-tabs {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
}

.exams-tab .student-exams-tabs .student-exams-tab {
  padding: 0.5rem;
  cursor: pointer;
}

.exams-tab .student-exams-tabs .student-exams-tab span {
  margin: 10px;
  padding: 2px 6px;
  background: #f16a6a;
  height: 20px;
  width: 20px;
  border-radius: 100px;
  color: #fff;
}

.exams-tab .student-exams-tabs .student-exams-tab.active {
  border-bottom: 1px solid #0793c7;
}

.exams-tab .ant-tabs {
  flex: 1;
  margin-left: 35px;
}

.exams-tab .ant-tabs .ant-tabs-content {
  height: 100%;
  min-height: 50vh;
}

.exams-tab .ant-tabs .ant-tabs-content .ant-tabs-tabpane {
  overflow-y: auto;
  height: calc(100% - 35px);
}

@media only screen and (max-width: 577px) {
  .exams-tab .ant-tabs {
    margin: 0 !important;
  }
}

body.rtl .exams-tab .ant-tabs {
  margin-left: 0;
  margin-right: 35px;
}

.exams-tab .ant-tabs .ant-tabs-bar {
  border: none;
}

body.rtl .exams-tab .ant-tabs {
  direction: ltr;
}

body.rtl .exams-tab .ant-tabs-nav {
  transform: scaleX(-1);
}

body.rtl .exams-tab .ant-tabs-tab {
  transform: scaleX(-1);
}

@media only screen and (max-width: 481px) {
  body.rtl .exams-tab .ant-tabs-tab {
    transform: scaleX(1);
  }
}

@media only screen and (max-width: 401px) {
  .exams-tab .ant-tabs-tab {
    margin: 0 5px 0 0;
    font-size: 10px;
    padding: 10px 0 !important;
  }
}

@media only screen and (max-width: 481px) {
  .exams-tab .ant-tabs-tab {
    margin: 0 9px 0 0;
    padding: 12px 9px;
  }
}

body.rtl .exams-tab .ant-tabs-nav-scroll {
  display: flex;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 577px) {
  .exams-tab .ant-tabs-nav-scroll {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

body.rtl .exams-tab .ant-timeline-item-content {
  margin-left: 0;
  margin-right: 70px;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 993px) {
  body.rtl .exams-tab .ant-timeline-item-content {
    flex-direction: column;
  }
}

body.rtl .exams-tab .ant-timeline-item-content .exams-timeline-holder__content {
  transform: scaleX(-1);
}

body.rtl .exams-tab .exams-timeline-holder__content-title {
  justify-content: flex-start;
  flex-direction: row;
}

body.rtl .exams-tab .exams-timeline-holder__content-title p {
  margin-left: 10px;
  margin-right: 0;
}

body.rtl .exams-tab .exams-timeline-holder__content-title div {
  margin-right: 22px;
  margin-left: 0;
}

body.rtl .exams-tab .exams-timeline-holder__content-description {
  flex-direction: row;
}

.exam-schedule-wrapper {
  margin: 5px 0px;
}

@media only screen and (max-width: 577px) {
  .exam-schedule-wrapper {
    margin-bottom: 40px;
    margin: 5px 2px;
    overflow-y: auto;
    height: calc(100vh - 339px);
  }
  body.rtl .exam-schedule-wrapper {
    margin: 15px 0 15px 0;
    height: calc(100vh - 400px);
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item {
  margin-block-start: 10px;
  padding: 5px;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item .ant-timeline-item-tail {
  top: 45px;
}

body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item .ant-timeline-item-tail {
  left: unset;
  border-width: 2px;
  border-left: 0;
  right: -1px;
}

@media only screen and (max-width: 1025px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item .ant-timeline-item-tail {
    display: none;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom {
  background-color: transparent;
  top: 45px;
}

body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom {
  left: unset;
  right: -54px;
}

@media only screen and (max-width: 1025px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom {
    top: calc(100% - 109px);
    height: 100%;
    width: 100%;
  }
  body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom {
    width: fit-content;
  }
}

@media only screen and (max-width: 577px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom {
    left: 13px;
    top: 0 !important;
    height: 100%;
    transform: translate(-50%, 0%);
  }
  body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom {
    left: 20px;
    right: unset;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom .exams-timeline-holder__date {
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  background: #0793c7;
  color: #fff;
  border-radius: 6px;
}

@media only screen and (max-width: 1025px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom .exams-timeline-holder__date {
    height: 97.5%;
    border-radius: 6px 0 0 6px;
    background: #fff;
    color: #0793c7;
    border: solid 1px #f4f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom .exams-timeline-holder__date {
    border-radius: 0 6px 6px 0;
  }
}

@media only screen and (max-width: 577px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom .exams-timeline-holder__date {
    border-radius: 0;
    height: 100%;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom .exams-timeline-holder__date i {
  font-size: 18px;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom .exams-timeline-holder__date p {
  padding-top: 8px;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
  margin-left: 70px;
  min-height: 90px;
  background: #fff;
  border-radius: 6px;
  border: solid 1px #f4f4f4;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 20px;
}

@media only screen and (max-width: 993px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
    flex-direction: column;
    padding-bottom: 17px;
    min-height: 201px;
  }
}

@media only screen and (max-width: 1025px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
    margin-left: 34px;
    border-radius: 0 6px 6px 0;
  }
  body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
    margin-right: 34px;
    border-radius: 6px 0 0 6px;
  }
}

@media only screen and (max-width: 577px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
    width: 94%;
    margin-left: 42px !important;
    border-radius: 0;
    top: 0;
  }
}

@media only screen and (max-width: 401px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }
}

@media only screen and (max-width: 381px) {
  body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
    width: 91%;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content {
  display: flex;
  flex-direction: column;
  width: 60%;
}

@media only screen and (max-width: 993px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content {
    width: 100%;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-title {
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

@media only screen and (max-width: 1025px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-title {
    font-size: 12px;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-title p {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: #0793c7;
  color: #fff;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-inline-end: 10px;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-title div {
  font-size: 13px;
  margin-inline-start: 22px;
  border: 1px solid #444;
  color: #444;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description {
  margin-top: 20px;
  display: flex;
  text-align: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 993px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description {
    display: grid;
    grid-template-columns: 60% 40%;
    row-gap: 20px;
  }
}

@media only screen and (max-width: 481px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description {
    grid-template-columns: 100%;
    padding-left: 13px;
  }
  body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description {
    padding-right: 13px;
    padding-left: 0;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description li {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: #2c363f;
  display: inline-flex;
  align-items: center;
  flex-direction: inherit;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description li img {
  margin-right: 5px;
}

body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description li img {
  margin-right: 0;
  margin-left: 5px;
}

@media only screen and (max-width: 401px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description li {
    font-size: 10px;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks {
  display: none !important;
  border: none !important;
  padding: 0 !important;
  min-width: 120px;
}

@media only screen and (max-width: 1025px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks {
    display: flex !important;
    align-items: flex-start;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks .exam-mark {
  border: none !important;
  padding: 0 !important;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks .exam-mark .student-mark,
.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks .exam-mark .slash {
  color: #0793c7;
  font-size: 13px;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks .exam-mark .exam-full-mark {
  color: #4f6170;
  font-size: 13px;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks .under-correction {
  color: #0793c7;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__action {
  align-self: center;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__action .ant-statistic-content {
  font-size: 15px !important;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__action:first-of-type {
  margin-right: 10px;
}

@media only screen and (max-width: 401px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__action {
    width: 87px;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .actions-holder {
  align-self: center;
}

@media only screen and (max-width: 993px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .actions-holder {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 481px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .actions-holder {
    display: flex;
  }
}

.exam-schedule-wrapper .exam__card {
  display: flex;
}

.instructions-wrapper .instructions__title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 26px;
  padding: 0 24px;
}

body.rtl .instructions-wrapper .instructions__title {
  text-align: right;
}

@media only screen and (max-width: 769px) {
  .instructions-wrapper .instructions__title {
    font-size: 20px;
  }
}

.instructions-wrapper .instructions__info {
  display: flex;
  align-items: center;
}

.instructions-wrapper .instructions__info:first-of-type {
  margin-right: 50px;
}

body.rtl .instructions-wrapper .instructions__info:first-of-type {
  margin-right: 0;
  margin-left: 50px;
}

.instructions-wrapper .instructions__row {
  display: flex;
  margin-bottom: 14px;
  align-items: center;
}

@media only screen and (max-width: 769px) {
  .instructions-wrapper .instructions__row {
    display: block;
  }
  .instructions-wrapper .instructions__row > div {
    margin-bottom: 14px;
  }
}

.instructions-wrapper .instructions__info-label {
  font-size: 14px;
  color: #868ea4;
  margin-right: 10px;
}

body.rtl .instructions-wrapper .instructions__info-label {
  margin-right: 0;
  margin-left: 10px;
  text-align: right;
}

@media only screen and (max-width: 769px) {
  .instructions-wrapper .instructions__info-label {
    font-size: 12px;
  }
}

.instructions-wrapper .instructions__infoBack {
  font-size: 16px;
  font-weight: 500;
  color: #2c363f;
}

body.rtl .instructions-wrapper .instructions__infoBack {
  text-align: right;
}

.instructions-wrapper .instructions__infoBack span:first-of-type {
  font-size: 16px;
  color: #2c363f;
}

@media only screen and (max-width: 769px) {
  .instructions-wrapper .instructions__infoBack span:first-of-type {
    font-size: 14px;
  }
}

.instructions-wrapper .instructions__infoBack span:nth-child(2) {
  font-weight: 500;
  font-size: 16px;
  color: #2c363f;
}

@media only screen and (max-width: 769px) {
  .instructions-wrapper .instructions__infoBack span:nth-child(2) {
    font-size: 14px;
  }
}

.instructions-wrapper .instructions__infoBack span:nth-child(3) {
  font-size: 14px;
  font-weight: 500;
  color: #868ea4;
}

@media only screen and (max-width: 769px) {
  .instructions-wrapper .instructions__infoBack span:nth-child(3) {
    font-size: 12px;
  }
}

@media only screen and (max-width: 769px) {
  .instructions-wrapper .instructions__infoBack {
    font-size: 14px;
  }
}

.instructions-wrapper .info-holder {
  border-bottom: 10px #f9f9fc solid;
  margin-bottom: 20px;
  padding: 0 24px;
}

.instructions-wrapper .instructions__list {
  font-size: 16px;
  line-height: 1.94;
  margin-bottom: 10px;
}

body.rtl .instructions-wrapper .instructions__list {
  text-align: right;
}

.instructions-wrapper .ml-20 {
  margin-left: 20px;
}

.instructions-wrapper .list-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

@media only screen and (max-width: 769px) {
  .instructions-wrapper .list-holder {
    flex-direction: column;
    display: flex !important;
  }
}

.instructions-wrapper .list-holder ol {
  margin-left: 20px;
}

body.rtl .instructions-wrapper .list-holder ol {
  margin-right: 20px;
  margin-left: 0;
}

.instructions-wrapper .ant-modal-body {
  padding: 24px 0;
}

@media only screen and (max-width: 481px) {
  .instructions-wrapper .ant-modal {
    width: 95% !important;
  }
}

@media only screen and (max-width: 381px) {
  .responsive-timeline .ant-timeline-item-head-custom {
    top: 102px !important;
  }
}

.exams-timeline-holder-prev .ant-timeline-item-content {
  margin-left: 70px;
  min-height: 90px;
  display: flex;
  border: none !important;
  background: transparent !important;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

@media only screen and (max-width: 1025px) {
  .exams-timeline-holder-prev .ant-timeline-item-content {
    background: #fff !important;
    border-radius: 0 6px 6px 0;
    border: solid 1px #f4f4f4 !important;
  }
}

.exams-timeline-holder-prev .exams-timeline-holder__content {
  display: flex;
  flex-direction: column;
  width: 90% !important;
  background: #fff;
  border-radius: 0 6px 6px 0;
  border: solid 1px #f4f4f4;
  padding: 15px;
  width: 10%;
  min-width: 90px;
}

body.rtl .exams-timeline-holder-prev .exams-timeline-holder__content {
  border-radius: 6px 0 0 6px;
}

@media only screen and (max-width: 1025px) {
  .exams-timeline-holder-prev .exams-timeline-holder__content {
    width: 100% !important;
  }
}

@media only screen and (max-width: 769px) {
  .exams-timeline-holder-prev .exams-timeline-holder__content .exam-marks {
    display: flex !important;
    flex-direction: row;
    background: #fff;
    border-radius: 6px;
    border: none;
    padding: 5px;
    width: 100%;
    margin-left: 0;
  }
  .exams-timeline-holder-prev .exams-timeline-holder__content .exam-marks .student-mark,
  .exams-timeline-holder-prev .exams-timeline-holder__content .exam-marks .exam-full-mark,
  .exams-timeline-holder-prev .exams-timeline-holder__content .exam-marks .slash {
    font-size: 11px;
  }
  .exams-timeline-holder-prev .exams-timeline-holder__content .exam-marks .under__correction--resp {
    display: flex;
    align-items: center;
  }
  .exams-timeline-holder-prev .exams-timeline-holder__content .exam-marks .under__correction--resp img {
    width: 14px;
  }
  .exams-timeline-holder-prev .exams-timeline-holder__content .exam-marks .under__correction--resp p:first-of-type {
    margin: 0 5px !important;
  }
}

.exams-timeline-holder-prev .exam-marks {
  background: #fff;
  border-radius: 6px;
  border: solid 1px #f4f4f4;
  padding: 15px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 11px;
}

body.rtl .exams-timeline-holder-prev .exam-marks {
  margin-right: 11px;
  margin-left: 0;
}

@media only screen and (max-width: 993px) {
  body.rtl .exams-timeline-holder-prev .exam-marks {
    border-radius: 6px 0 0 6px;
  }
}

@media only screen and (max-width: 769px) {
  .exams-timeline-holder-prev .exam-marks {
    display: none;
  }
}

.exams-timeline-holder-prev .exam-marks .student-mark,
.exams-timeline-holder-prev .exam-marks .slash {
  color: #0793c7;
  font-size: 18px;
}

.exams-timeline-holder-prev .exam-marks .exam-full-mark {
  color: #4f6170;
  font-size: 18px;
}

.exams-timeline-holder-prev .ant-timeline-item-content {
  align-items: stretch !important;
}

@media only screen and (max-width: 993px) {
  .exams-timeline-holder-prev .ant-timeline-item-content {
    flex-direction: row !important;
    margin-left: 30px !important;
  }
  body.rtl .exams-timeline-holder-prev .ant-timeline-item-content {
    margin-right: 30px !important;
    margin-left: 0;
    flex-direction: row-reverse !important;
  }
}

@media only screen and (max-width: 769px) {
  .exams-timeline-holder-prev .ant-timeline-item-content {
    padding-bottom: 0 !important;
  }
}

.exams-timeline-holder-prev .ant-progress-text {
  display: none;
}

.exams-timeline-holder-prev .ant-progress-outer {
  padding: 0;
  margin: 0;
}

.exam__card {
  margin-bottom: 11px;
}

.exam__card .new__exam--card {
  opacity: 0.87;
  border: solid 1px #f4f4f4;
  background-color: #0793c7;
  color: #fff;
  min-width: 110px;
  border-radius: 6px 0 0 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 769px) {
  .exam__card .new__exam--card {
    min-width: 78px;
  }
}

body.rtl .exam__card .new__exam--card {
  border-radius: 0 6px 6px 0;
}

.exam__card .new__exam--card .start__end--exam {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
}

.exam__card .exam__card--details .exam__card--firstLine {
  display: flex;
  margin-bottom: 12px;
}

.exam__card .exam__card--details .exam__card--firstLine h5 {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #26a0ce;
}

@media only screen and (max-width: 769px) {
  .exam__card .exam__card--details .exam__card--firstLine h5 {
    font-size: 13px;
  }
}

.exam__card .exam__card--details .exams-timeline-holder__content-description {
  display: flex;
}

@media only screen and (max-width: 1025px) {
  .exam__card .exam__card--details .exams-timeline-holder__content-description {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.exam__card .exam__card--details .exams-timeline-holder__content-description li {
  font-size: 14px;
  font-weight: 500;
  color: #2c363f;
  margin-right: 25px;
  padding-right: 25px;
  border-right: 1px solid #d2dade;
}

@media only screen and (max-width: 1201px) {
  .exam__card .exam__card--details .exams-timeline-holder__content-description li {
    font-size: 11px;
  }
}

@media only screen and (max-width: 769px) {
  .exam__card .exam__card--details .exams-timeline-holder__content-description li {
    font-size: 9px;
    margin-right: 4px;
    padding-right: 4px;
    border-right: none;
    border-left: none;
    margin-bottom: 13px;
  }
  body.rtl .exam__card .exam__card--details .exams-timeline-holder__content-description li {
    font-size: 7px;
  }
}

body.rtl .exam__card .exam__card--details .exams-timeline-holder__content-description li {
  margin-left: 25px;
  padding-left: 25px;
  border-left: 1px solid #d2dade;
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.exam__card .exam__card--details .exams-timeline-holder__content-description li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

body.rtl .exam__card .exam__card--details .exams-timeline-holder__content-description li:last-child {
  margin-left: 0;
  padding-left: 0;
  border-left: none;
}

.exam__card .exam__card--details .exams-timeline-holder__content-description li > img {
  margin-right: 5px;
  opacity: 0.3;
}

body.rtl .exam__card .exam__card--details .exams-timeline-holder__content-description li > img {
  margin-right: 0;
  margin-left: 5px;
}

.exam__card--details {
  display: flex;
  flex-direction: column;
  width: 90% !important;
  background: #fff;
  border-radius: 0 6px 6px 0;
  border: solid 1px #f4f4f4;
  padding: 15px;
  width: 10%;
  min-width: 90px;
}

@media only screen and (max-width: 481px) {
  .exam__card--details {
    padding-bottom: 0;
  }
}

.exam_list_filters {
  display: flex;
  justify-content: center;
  align-items: center;
}

.exam_list_filters .flex_class {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 769px) {
  .exam_list_filters .flex_class {
    width: 100%;
  }
}

@media only screen and (max-width: 577px) {
  .exam_list_filters {
    flex-direction: column;
  }
}

@media only screen and (max-width: 769px) {
  .exam_list_filters .ant-calendar-picker {
    width: 48%;
  }
  .exam_list_filters .ant-calendar-picker .ant-calendar-picker-input {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.exam_list_filters .ant-input {
  height: 40px;
  width: 136px;
  background-color: #fff !important;
  border-radius: 6px;
  border: solid 0.5px #d2dade;
  margin: 0px 5px;
}

@media only screen and (max-width: 993px) {
  .exam_list_filters .ant-input {
    width: calc(50vw - 150px);
    margin: 2px 5px;
  }
}

@media only screen and (max-width: 769px) {
  .exam_list_filters .ant-input {
    width: 48%;
  }
}

.exam_list_filters .ant-input.ant-calendar-picker-input {
  width: 200px;
}

.exam_list_filters .ant-input.ant-calendar-picker-input .anticon {
  margin: -9px 0px;
}

@media only screen and (max-width: 993px) {
  .exam_list_filters .ant-input.ant-calendar-picker-input {
    width: calc(50vw - 150px);
    margin: 2px 5px;
  }
}

@media only screen and (max-width: 769px) {
  .exam_list_filters .ant-input.ant-calendar-picker-input {
    width: 48%;
  }
}

.exam_list_filters .ant-select {
  width: 136px;
  height: 40px;
  background-color: #fff;
  border-radius: 6px;
  border: solid 0.5px #d2dade;
  margin: 0px 5px !important;
}

@media only screen and (max-width: 993px) {
  .exam_list_filters .ant-select {
    width: calc(50vw - 150px);
    margin: 2px 5px !important;
  }
}

@media only screen and (max-width: 769px) {
  .exam_list_filters .ant-select {
    width: 48%;
  }
}

.exam_list_filters .ant-select-selection__placeholder {
  font-size: 12px !important;
  color: #a3a3a3 !important;
  font-weight: 400 !important;
  margin: -9px 6px !important;
}

.exam_list_filters .ant-calendar-range-picker-input {
  font-size: 10px;
}

.exam_list_filters .ant-calendar-range-picker-input::placeholder {
  font-size: 12px;
  margin: 5px 0px;
}

.exam_list_filters .ant-calendar-range-picker-separator {
  margin: 8px 0px;
}

.exam_list_filters .ant-select-selection-selected-value {
  font-size: 12px;
}

.exam_list_filters .ant-select-arrow {
  margin: -5px 0px;
}

.finished-modal-container {
  text-align: center;
  width: 638px !important;
  border-radius: 6px;
}

.finished-modal-container.result-bg .ant-modal-content {
  background: url(../../resources/images/exam/result-bg.png) no-repeat;
  background-size: cover;
}

.finished-modal-container .ant-modal-body {
  text-align: center;
  padding: 40px 60px;
}

.finished-modal-container .answer-submit-wrapper {
  text-align: center;
}

.finished-modal-container .answer-submit-wrapper h3 {
  font-size: 31px;
  font-weight: 600;
  line-height: 1.23;
  letter-spacing: 0.4px;
  color: #0793c7;
}

.finished-modal-container .answer-submit-wrapper img {
  margin-top: 30px;
  margin-bottom: 20px;
}

.finished-modal-container .answer-submit-wrapper h4 {
  font-size: 20px;
  font-weight: 600;
  color: #2c363f;
  line-height: 1.35;
}

.finished-modal-container .answer-submit-wrapper p {
  width: 80%;
  font-size: 18px;
  line-height: 1.5;
  margin: 10px auto 30px auto;
  color: #2c363f;
}

.finished-modal-container .result-exam-wrapper {
  background-image: url(../../resources/images/exam/result-backgroun.svg);
}

.finished-modal-container .result-exam-wrapper h4 {
  font-size: 35px;
  font-weight: 600;
  line-height: 1.23;
  letter-spacing: 0.46px;
  color: #a8cf46;
  text-align: center;
}

.finished-modal-container .result-exam-wrapper h5 {
  font-size: 21px;
  font-weight: bold;
  line-height: 1.19;
  letter-spacing: 0.27px;
  color: #2c363f;
  margin-top: 23px;
  margin-bottom: 18px;
  text-align: center;
}

.finished-modal-container .result-exam-wrapper .result-holder {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  font-size: 33px;
  font-weight: 600;
  line-height: 1.21;
  letter-spacing: 3.3px;
  color: #0793c7;
  margin-bottom: 10px;
}

.finished-modal-container .result-exam-wrapper .result-info {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 73px;
  border-radius: 6px;
  border: solid 1px #f0f0f0;
  background-color: #ffffff;
  margin-top: 44px;
  margin-bottom: 44px;
}

.finished-modal-container .result-exam-wrapper .result-info > div {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: 0.21px;
  color: #2c363f;
  display: flex;
  align-items: center;
}

.finished-modal-container .result-exam-wrapper .result-info > div span {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #a8cf46;
  color: #fff;
  white-space: nowrap;
  margin-inline-end: 8px;
}

.finished-modal-container .result-exam-wrapper .result-info > div.incorrect span {
  background-color: #ee3700;
}

.finished-modal-container .result-exam-wrapper .result-info > div.not-answer span {
  background-color: #f39c12;
}

.finished-modal-container .result-exam-wrapper button {
  margin-bottom: 25px;
}

.finished-modal-container .result-exam-wrapper + .btns-holder {
  text-align: center;
}

.exam-expaired {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100% !important;
}

.exam-expaired > div {
  text-align: center;
}

.exam-expaired h3 {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.22;
  letter-spacing: 0.42px;
  color: #2c363f;
}

.exam-expaired p {
  font-size: 21px;
  line-height: 1.57;
  letter-spacing: 0.27px;
  color: #2c363f;
  padding: 30px 0 80px;
}

@media only screen and (max-width: 769px) {
  .exam-expaired {
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 10px 30px;
  }
  .exam-expaired img {
    width: 70%;
  }
  .exam-expaired h3 {
    font-size: 22px;
  }
  .exam-expaired p {
    font-size: 16px;
    padding-bottom: 40px;
  }
}

.answer-radio-type .ant-radio-group {
  display: flex;
  flex-direction: column;
}

.answer-radio-type .ant-radio-group .ant-radio-wrapper {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.94;
  color: #000000;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.answer-radio-type .ant-radio-group .ant-radio-wrapper p {
  margin-bottom: 0;
  line-height: normal;
  margin-top: 0px;
}

.answer-radio-type .ant-radio-group .ant-radio-inner {
  border-width: 1px;
  border-color: #868ea4;
}

.answer-radio-type .radio-style--true .ant-radio-inner {
  border-color: #11b74f;
}

.answer-radio-type .radio-style--true .ant-radio-inner::after {
  background-color: #11b74f;
}

.answer-radio-type .radio-style--false .ant-radio-inner {
  border-color: #ee3700;
}

.answer-radio-type .radio-style--false .ant-radio-inner::after {
  background-color: #ee3700;
}

.answer-checkbox-type .ant-checkbox-wrapper {
  align-items: center;
  margin: 20px 0px;
}

.answer-checkbox-type .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.answer-checkbox-type .ant-checkbox-group .ant-checkbox-wrapper {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.94;
  color: #000000;
}

.answer-checkbox-type .ant-checkbox-group .ant-checkbox-inner {
  border-width: 1px;
  border-color: #868ea4;
}

.ant-tooltip .ant-tooltip-inner {
  color: #11b74f;
  background-color: #fff;
  font-size: 16px;
  font-weight: normal;
}

.ant-tooltip .ant-tooltip-arrow {
  border-right-color: #fff;
}

.answer-connect-type {
  display: grid;
  grid-template-columns: 1fr 1fr;
  direction: ltr;
}

@media only screen and (max-width: 577px) {
  .answer-connect-type {
    grid-template-columns: 35% 41%;
    justify-content: space-between;
  }
}

.answer-connect-type .Close {
  width: 25px;
  height: 25px;
  display: flex;
  visibility: visible;
  position: absolute;
  background-color: #d83025 !important;
  top: 0px;
  right: 0px;
  align-items: center;
  justify-content: center;
  font-size: 22px !important;
  line-height: 20px;
  color: #fff;
  border-radius: 0 6px 0 5px;
}

.answer-connect-type > div {
  display: grid;
  align-items: center;
  gap: 15px;
  width: fit-content;
}

.answer-connect-type > div > div {
  /*  height: 90px; */
  width: fit-content;
  z-index: 111;
  cursor: pointer;
}

.answer-connect-type > div p,
.answer-connect-type > div span {
  font-family: inherit !important;
  font-size: 17px !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important;
  text-align: center;
  white-space: normal;
}

.answer-connect-type .word-list > div {
  width: fit-content;
  position: relative;
}

.answer-connect-type .word-list .inner-html {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  min-width: 200px;
  width: 100px;
  max-width: 188px;
  min-height: 150px;
  border-radius: 6px;
  border: solid 1px #868ea4;
  background-color: transparent;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

@media only screen and (max-width: 769px) {
  .answer-connect-type .word-list .inner-html {
    max-width: 120px;
    max-height: 120px;
    min-width: 120px;
    min-height: 120px;
  }
}

.answer-connect-type .word-list .inner-html.SelectedClass {
  border: 2px solid #0793c7;
}

.answer-connect-type .word-list .inner-html iframe {
  max-width: 180px;
  max-height: 125px;
  object-fit: cover;
}

@media only screen and (max-width: 769px) {
  .answer-connect-type .word-list .inner-html iframe {
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
  }
}

.answer-connect-type .word-list .inner-html img {
  max-width: 180px;
  max-height: 125px;
  object-fit: cover;
}

@media only screen and (max-width: 769px) {
  .answer-connect-type .word-list .inner-html img {
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
  }
}

.answer-connect-type .word-list .word--dot {
  width: 14px;
  height: 14px;
  background-color: #0793c7;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -14px;
  transform: translateY(-50%);
  cursor: pointer;
}

.answer-connect-type .word-list .word--dot:before {
  display: none;
  content: "";
  width: 15px;
  height: 1px;
  background-color: #0793c7;
  position: absolute;
  top: 50%;
  right: -14px;
  transform: translateY(-50%);
}

.answer-connect-type .word-list .word--dot:after {
  display: none;
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #0793c7;
  position: absolute;
  top: 50%;
  right: -18px;
  transform: translateY(-50%);
}

body.rtl .answer-connect-type .word-list .word--dot {
  right: auto;
  left: -14px;
}

body.rtl .answer-connect-type .word-list .word--dot:before {
  right: auto;
  left: -14px;
}

body.rtl .answer-connect-type .word-list .word--dot:after {
  right: auto;
  left: -18px;
  border-left: 0;
  border-right: 10px solid #0793c7;
}

.answer-connect-type .imgs-list > div {
  position: relative;
  border-radius: 6px;
  border: solid 1px #868ea4;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  min-height: 150px;
}

.answer-connect-type .imgs-list .inner-html {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  min-width: 200px;
  width: 100%;
  margin: auto;
  display: inline-block;
}

@media only screen and (max-width: 769px) {
  .answer-connect-type .imgs-list .inner-html {
    max-width: 120px;
    max-height: 120px;
    min-width: 120px;
    min-height: 120px;
  }
}

.answer-connect-type .imgs-list .inner-html * {
  pointer-events: none;
}

.answer-connect-type .imgs-list .inner-html p {
  height: 100%;
  line-height: 88px !important;
  pointer-events: none;
}

.answer-connect-type .imgs-list .inner-html img {
  pointer-events: none;
  max-width: 180px;
  max-height: 125px;
  object-fit: cover;
}

@media only screen and (max-width: 769px) {
  .answer-connect-type .imgs-list .inner-html img {
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
  }
}

.answer-connect-type .imgs-list .inner-html iframe {
  max-width: 180px;
  max-height: 125px;
  object-fit: cover;
}

@media only screen and (max-width: 769px) {
  .answer-connect-type .imgs-list .inner-html iframe {
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
  }
}

.answer-connect-type .imgs-list img {
  margin: 0;
  width: 100%;
  height: 100%;
}

.answer-complate-type .inner-html {
  /* display: flex;
		flex-direction: column;
		max-height: 200px;
		overflow-y: auto; */
  /* @include mq('tablet') {
			max-width: 120px;
			max-height: 120px;
			min-width: 120px;
			min-height: 120px;
		} */
  /* 	> p {
			display: flex;
			align-items: center;
			justify-content: space-between;
			> span {
				display: flex;
				flex-wrap: wrap;
			}
		} */
}

.answer-complate-type .inner-html input {
  border: none;
  border-bottom: 1px solid #555;
  margin: 10px 5px !important;
}

@media only screen and (max-width: 769px) {
  .answer-complate-type {
    overflow-x: auto;
  }
}

.exam-view-wrapper {
  background-color: #f9f9fc;
  height: calc(100vh - 66px);
}

@media only screen and (max-width: 481px) {
  .exam-view-wrapper {
    height: 100%;
  }
}

@media screen and (device-aspect-ratio: 40 / 71) {
  .exam-view-wrapper {
    height: calc(100vh - 100px);
  }
}

.exam-view-wrapper .exam-detailes-container {
  display: flex;
  height: 100%;
}

@media only screen and (max-width: 1201px) {
  .exam-view-wrapper {
    padding-bottom: 0;
  }
  .exam-view-wrapper .exam-detailes-container {
    flex-direction: column;
    height: unset;
  }
}

@media only screen and (max-width: 1201px) and (max-width: 769px) {
  .exam-view-wrapper .exam-detailes-container > div:last-of-type {
    flex: 1;
    position: static;
  }
}

.exam-view-wrapper .exam-aside {
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.exam-view-wrapper .exam-aside .exam-aside__header {
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 15px 24px;
  border-bottom: 1px solid #ededed;
}

@media only screen and (max-width: 577px) {
  .exam-view-wrapper .exam-aside .exam-aside__header {
    padding-top: 60px !important;
  }
}

.exam-view-wrapper .exam-aside .exam-aside__header .info-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.exam-view-wrapper .exam-aside .exam-aside__header h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  color: #000000;
  margin-bottom: 5px;
}

body.rtl .exam-view-wrapper .exam-aside .exam-aside__header h1 {
  direction: ltr;
  display: flex;
  flex-direction: row-reverse;
}

.exam-view-wrapper .exam-aside .exam-aside__header p {
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.18px;
  color: #6f6f6f;
}

.exam-view-wrapper .exam-aside .exam-aside__header .ques-count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #ededed;
  margin-top: 10px;
  padding-top: 6px;
}

.exam-view-wrapper .exam-aside .exam-aside__header .ques-count h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #0793c7;
}

.exam-view-wrapper .exam-aside .exam-aside__header .ques-count h2 img {
  margin-inline-start: 15px;
  margin-inline-end: 5px;
}

.exam-view-wrapper .exam-aside .exam-aside__header .student-thumb-preview {
  width: 140px;
  transform: scale(-1, 1);
}

.exam-view-wrapper .exam-aside .questions-list-holder {
  flex: 1;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

@media only screen and (max-width: 1201px) {
  .exam-view-wrapper .exam-aside .questions-list-holder {
    overflow-y: hidden;
    padding: 5px 20px;
  }
}

.exam-view-wrapper .exam-aside .questions-list-holder::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.exam-view-wrapper .exam-aside .questions-list-holder::-webkit-scrollbar-thumb {
  background: #888;
}

.exam-view-wrapper .exam-aside .questions-list-holder::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (max-width: 1201px) {
  .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1201px) {
  .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps .ant-steps-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps .ant-steps-item:last-child {
  flex-grow: unset;
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item {
  display: flex;
  align-items: center;
  padding: 3px 16px 3px 20px;
  cursor: pointer;
  min-height: 57px;
  border-right: 5px solid transparent;
  outline: 0 !important;
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item:last-of-type .ant-steps-item-tail {
  display: none !important;
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item .ant-steps-item-tail {
  top: 14px;
  left: 36px;
  width: 1px;
  height: 98%;
  padding: 30px 0 0px;
  bottom: 0;
  z-index: 5;
}

@media only screen and (max-width: 1201px) {
  .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item .ant-steps-item-tail {
    display: block !important;
    top: -9px;
    left: 36px;
    width: 100%;
    height: 100%;
    padding: 28px 0px;
    bottom: 0;
    z-index: 0;
  }
  body.rtl .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item .ant-steps-item-tail {
    left: unset !important;
    right: 36px !important;
  }
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item .ant-steps-item-tail::after {
  background-color: #0793c7;
}

@media only screen and (max-width: 481px) {
  .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item .ant-steps-item-tail::after {
    width: 100% !important;
    height: 1px !important;
  }
}

body.rtl .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item {
  border-right: none;
}

@media only screen and (max-width: 1201px) {
  .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item {
    overflow: initial;
    flex-direction: row;
    background-color: transparent !important;
    margin: 0;
    padding: 4px 27px;
    min-height: auto;
    flex: none;
  }
  .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item.ant-steps-item-process .question {
    color: #0793c7 !important;
    font-weight: 500 !important;
  }
}

body.rtl .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item {
  /* @include mq("tablet") {
						right: unset;
						left: unset;
					} */
}

body.rtl .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item .ant-steps-item-tail {
  right: 50px;
  left: auto;
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item .ant-steps-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #0793c7;
  min-width: 32px;
  min-height: 32px;
  align-self: unset !important;
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item .ant-steps-item-icon .ant-steps-icon {
  font-size: 18px;
}

@media only screen and (max-width: 1201px) {
  .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item .ant-steps-item-icon {
    width: 26px;
    height: 26px;
    min-width: 26px;
    min-height: 26px;
    margin: 0px -15px;
    z-index: 2;
  }
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item .ant-steps-item-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1201px) {
  .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item .ant-steps-item-content {
    flex-direction: row;
    width: auto !important;
    min-height: auto;
    overflow: visible;
    margin-top: 0;
    margin: 0px -20px;
  }
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item.ant-steps-item-process {
  background-color: #f2fafc;
  border-right-color: #0793c7;
}

@media only screen and (max-width: 1201px) {
  .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item.ant-steps-item-process {
    border-right-color: #fff;
  }
}

body.rtl .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item.ant-steps-item-process {
  border-right-color: #0793c7;
  border-right-color: transparent;
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #0793c7;
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
  color: #f2fafc;
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item.marked .ant-steps-item-icon {
  background-color: #d9a311;
  border-color: #d9a311;
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item-content {
  width: 80%;
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item-content .ant-steps-item-title {
  width: 100%;
}

@media only screen and (max-width: 1201px) {
  .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item-content .ant-steps-item-title {
    width: fit-content;
    padding: 0;
    margin: 0;
    margin-inline-start: 6px;
    font-size: 16px;
  }
  .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item-content .ant-steps-item-title p {
    width: 100px;
    text-align: center;
    width: auto;
  }
  .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item-content .ant-steps-item-title::after {
    display: none;
  }
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item-content .question-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item-content .question-holder .question {
  font-size: 18px;
  color: #2c363f;
}

.exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item-content .question-holder .savebtn {
  cursor: pointer;
}

@media only screen and (max-width: 769px) {
  .exam-view-wrapper .exam-aside .questions-list-holder .ant-steps-item-content .question-holder .savebtn {
    display: none;
  }
}

body.rtl .exam_container_confirmation .ant-modal-confirm-title {
  text-align: right;
}

.correct-tooltip-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  position: relative;
}

.correct-tooltip-holder i {
  margin: auto 5px;
}

.filter-options-holder .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.filter-options-holder .ant-checkbox-group .ant-checkbox-wrapper {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.94;
  color: #000000;
  margin: 0;
}

.filter-options-holder .ant-checkbox-group .ant-checkbox-inner {
  border-width: 1px;
  border-color: #868ea4;
}

body.rtl .draggable {
  direction: ltr;
}

.exam-view-wrapper .student__exam--header {
  padding: 7px 0;
  margin: 0 20px;
  border-bottom: 1px solid #f9f9fc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exam-view-wrapper .student__exam--header:last-of-type {
  margin-bottom: 7px;
}

body.rtl .exam-view-wrapper .student__exam--header {
  text-align: right;
}

.exam-view-wrapper .student__exam--header h4 {
  width: 75%;
  font-size: 24px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 5px;
}

body.rtl .exam-view-wrapper .student__exam--header h4 img {
  transform: rotate(180deg);
}

.exam-view-wrapper .student__exam--header p {
  font-size: 14px;
  font-weight: normal;
  color: #6f6f6f;
}

.exam-view-wrapper .student__exam--header span.solved__question {
  font-size: 20px;
  font-weight: 600;
  color: #0793c7;
}

.exam-view-wrapper .student__exam--header span.solved__question img {
  margin-left: 25px;
}

body.rtl .exam-view-wrapper .student__exam--header span.solved__question img {
  margin-left: 0;
  margin-right: 25px;
}

.exam-view-wrapper .question_filter h6 {
  font-size: 12px;
  font-weight: normal;
  color: #2c363f;
  margin-bottom: 12px;
}

.exam-view-wrapper .question_filter h6 img {
  margin-right: 4px;
}

body.rtl .exam-view-wrapper .question_filter h6 img {
  margin-right: 0;
  margin-left: 4px;
}

.exam-view-wrapper .exam-question-view {
  background: transparent !important;
}

.exam-view-wrapper .exam-question-view .question_view--header {
  margin-bottom: 30px;
}

.exam-view-wrapper .exam-question-view .question_view--header h4 {
  font-size: 22px;
  font-weight: normal;
  color: #222222;
  margin-bottom: 7px;
}

@media only screen and (max-width: 769px) {
  .exam-view-wrapper .exam-question-view .question_view--header {
    /* max-height: 20vh;
				overflow-y: auto; */
  }
}

.exam-view-wrapper .exam-question-view .question_view--header {
  position: relative;
  z-index: 30;
  background-color: #fff;
  padding: 28px 0px;
}

body.rtl .exam-view-wrapper .exam-question-view .question_view--header {
  text-align: right;
}

.exam-view-wrapper .exam-question-view .question_view--header .inner-html {
  margin-top: 3rem;
}

@media only screen and (max-width: 769px) {
  .exam-view-wrapper .exam-question-view .question_view--header .inner-html {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 769px) {
  .exam-view-wrapper .exam-question-view .question_view--header {
    padding: 5px 0px;
    margin-bottom: 5px;
  }
}

.exam-view-wrapper .exam-question-view .question_view--body {
  border-radius: 6px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  padding: 0px 15px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

@media only screen and (max-width: 769px) {
  .exam-view-wrapper .exam-question-view .question_view--body {
    height: 70vh;
    overflow-y: auto;
    padding: 0px 5px;
  }
}

.exam-view-wrapper .exam-question-view .question_view--body .question-view-content > div {
  margin-bottom: 10px;
}

.exam-view-wrapper .exam-question-view .question_view--body > h6 {
  font-size: 17px;
  font-weight: 600;
  color: #0793c7;
}

.exam-view-wrapper .exam-question-view .question_view--body > p {
  font-size: 18px;
  font-weight: 500;
  color: #2c363f;
  margin: 30px 0;
}

.exam-view-wrapper .exam-question-view .question_view--body .question_answers {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.exam-view-wrapper .exam-question-view .question_view--body .question_answers .question_answers--options {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 28px;
  color: #000000;
}

.rearrange_correct_answer {
  border: 1px solid #0793c7;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 10px;
}

@media only screen and (max-width: 769px) {
  .parent-steps-holder.ant-steps-vertical {
    display: inline-flex;
    max-width: 100%;
    overflow-x: auto;
    padding: 0px 5px;
  }
  .parent-steps-holder .ant-steps-vertical::after {
    right: 10px;
  }
}

body.rtl .ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.875);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

body.rtl .answer-radio-type .radio-style--true .ant-radio-inner::after {
  background-color: #11b74f;
}

.inner-html {
  overflow-wrap: anywhere;
  word-break: break-all;
  text-align: left;
}

body.rtl .inner-html {
  text-align: right;
}

.inner-html p {
  line-height: 20px;
}

.inner-html > p {
  margin: 10px 0;
}

.inner-html img {
  max-width: unset !important;
  max-height: 250px;
}

@media only screen and (max-width: 577px) {
  .inner-html img {
    max-height: 200px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 577px) {
  .inner-html.rearrange p img {
    max-width: 170px;
    max-height: 200px;
    object-fit: cover;
  }
}

.inner-html p strong {
  font-weight: 800;
}

.inner-html p i {
  font-style: italic;
}

.exam_tooltip {
  z-index: 6;
}

.MathJax span {
  max-width: 100%;
  white-space: pre-wrap;
}

.MathJax nobr .math {
  width: 100% !important;
}

.MathJax nobr .math > span:first-child {
  width: 100% !important;
}

.MathJax nobr .math > span:first-child > span {
  width: 100% !important;
  position: unset !important;
  clip: unset !important;
}

.ant-checkbox-wrapper-disabled .inner-html #react-mathjax-preview {
  display: inline-block !important;
}

.ck-editor__main > .ck-editor__editable {
  border: none !important;
  pointer-events: none !important;
}

.ck-editor__main figure {
  max-width: 30% !important;
}

.ck-editor__main figure figcaption {
  display: none !important;
}

.ck-toolbar {
  border: none !important;
}

.ck-math-widget img {
  width: auto !important;
}

.exam-question-view {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding-bottom: 0;
}

@media only screen and (max-width: 769px) {
  .exam-question-view {
    padding-bottom: 0;
  }
}

.exam-question-view .question-view__header {
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  border-bottom: 1px solid #ededed;
}

.exam-question-view .question-view__header strong {
  font-weight: 500;
}

.exam-question-view .question-view__header > div {
  height: auto !important;
}

.exam-question-view .question-view__header .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.exam-question-view .question-view__header .title h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  color: #000000;
}

.exam-question-view .question-view__header .title .exam-time-counter {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #0793c7;
}

body.rtl .exam-question-view .question-view__header .title .exam-time-counter {
  flex-direction: row-reverse;
}

.exam-question-view .question-view__header .title .exam-time-counter span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 39px;
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #0793c7;
  margin: 0 5px;
}

@media only screen and (max-width: 769px) {
  .exam-question-view .question-view__header .title .exam-time-counter span {
    width: 32px;
    height: 32px;
    font-size: 17px;
  }
}

.exam-question-view .question-view__header .description {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.55;
  color: #2c363f;
  height: auto !important;
}

.exam-question-view .question-view__header .description * {
  height: auto !important;
}

.exam-question-view .question-view__header .description img {
  margin: 10px auto;
}

@media only screen and (max-width: 769px) {
  .exam-question-view .question-view__header .description {
    font-size: 16px;
    padding: 12px 0px;
  }
}

body.rtl .exam-question-view .question-view__header .description {
  direction: ltr;
}

@media only screen and (max-width: 769px) {
  .exam-question-view .question-view__header {
    position: static;
    min-height: auto;
    height: auto;
  }
  .exam-question-view .question-view__header .title h4 {
    font-size: 14px;
  }
  .exam-question-view .question-view__header .title time {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  body.rtl .exam-question-view .question-view__header .title time {
    right: auto;
    left: 16px;
  }
}

.exam-question-view .question-view__header .current-question-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.exam-question-view .question-view-content {
  flex: 1;
  overflow: auto;
  padding: 15px;
}

@media only screen and (max-width: 769px) {
  .exam-question-view .question-view-content {
    padding: 15px 5px;
    overflow: unset;
  }
}

.parent-steps-holder > .ant-steps-item .ant-steps-item-icon {
  align-self: flex-start;
}

@media only screen and (max-width: 769px) {
  .parent-steps-holder > .ant-steps-item .ant-steps-item-icon {
    align-self: center;
  }
}

.parent-steps-holder > .ant-steps-item .ant-steps-item-content {
  flex-direction: column;
  width: 100% !important;
}

.parent-steps-holder > .ant-steps-item .ant-steps-item-content .ant-steps-item-description {
  width: 100%;
}

.parent-steps-holder > .ant-steps-item .ant-steps-item-content .ant-steps-item-description .inner-steps-holder > .ant-steps-item .ant-steps-item-icon {
  align-self: center;
}

@media only screen and (max-width: 769px) {
  .parent-steps-holder > .ant-steps-item .ant-steps-item-content .ant-steps-item-description .inner-steps-holder {
    display: inline-flex;
    width: auto;
  }
}

.parent-steps-holder .single-step-holder .ant-steps-item-icon {
  align-self: center !important;
}

@media only screen and (max-width: 769px) {
  .parent-steps-holder .single-step-holder {
    flex-direction: column;
  }
  .parent-steps-holder .single-step-holder::after {
    top: -26px !important;
  }
}

.parent-steps-holder .multi-step-holder {
  background-color: transparent !important;
}

.parent-steps-holder .multi-step-holder > .ant-steps-item-icon {
  margin-top: 10px;
}

.parent-steps-holder .multi-step-holder > .ant-steps-item-content {
  margin-top: 10px;
}

.parent-steps-holder .multi-step-holder > .ant-steps-item-content > .ant-steps-item-title {
  margin-bottom: 20px;
  margin-top: 6px;
  padding-inline-end: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  color: #0793c7;
}

.parent-steps-holder .multi-step-holder > .ant-steps-item-content > .ant-steps-item-description {
  padding-bottom: 0;
}

.parent-steps-holder .multi-step-holder > .ant-steps-item-content .ant-steps-item-title {
  padding-inline-end: 0;
}

@media only screen and (max-width: 769px) {
  .parent-steps-holder .multi-step-holder {
    padding: 0 10px;
  }
  .parent-steps-holder .multi-step-holder:after {
    display: none;
  }
  .parent-steps-holder .multi-step-holder .ant-steps-item-description {
    max-width: fit-content;
  }
}

.question-view-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 6;
  padding: 15px;
  flex: 0;
}

@media only screen and (max-width: 769px) {
  .question-view-footer button {
    padding: 0 10px !important;
    margin: 15px 0px !important;
    font-size: 12px !important;
  }
}

.question-view-footer button:only-child {
  margin-left: auto;
}

body.rtl .question-view-footer button:only-child {
  margin-left: initial;
  margin-right: auto;
}

.question-view-footer button.show-answer {
  background-color: #11b74f;
}

.question-view-footer button.show-answer:hover {
  border: 1px solid #11b74f;
}

body.rtl .question-view-footer .next_prev.ant-btn-loading span {
  margin-left: 0px;
  margin-right: 17px;
}

@media only screen and (max-width: 769px) {
  .question-view-footer .next_prev {
    width: 80px;
  }
}

@media only screen and (max-width: 769px) {
  .question-view-footer {
    padding: 10px 0;
    background: #FFF;
    z-index: 666;
  }
}

.correct_answer_div {
  color: #11b74f;
  font-weight: 600;
  padding: 30px 0px;
}

body.rtl .correct_answer_div {
  text-align: right;
}

.correct_answer_div::before {
  content: "✓";
  font-size: 16px;
  font-weight: 700;
  color: #11b74f;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #11b74f;
  margin: 0px 5px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.ordinary_session_cell {
  background-color: #fff;
  border-radius: 10px;
  width: 120px;
  height: 46px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.ordinary_session_cell .flex_row {
  display: flex;
  justify-content: center;
}

.ordinary_session_cell .flex_row p {
  color: #2c363f;
  font-size: 11px;
  margin: 0px 2px;
}

.ordinary_session_cell .flex_row.subject_session p:first-child {
  color: #0793c7;
  font-size: 12px;
  font-weight: 600;
}

.online_session_cell {
  background-color: #12abe4;
  border-radius: 10px;
  width: 120px;
  height: 46px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.online_session_cell .flex_row {
  display: flex;
  justify-content: center;
}

.online_session_cell .flex_row p {
  color: #fff;
  font-size: 11px;
  margin: 0px 2px;
}

.online_session_cell .flex_row.subject_session p:first-child {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.student_timetable_wrapper {
  margin: 20px 20px;
}

.timetable_content_wrapper {
  display: flex;
}

@media only screen and (max-width: 1201px) {
  .timetable_content_wrapper {
    height: calc(100vh - 220px);
    overflow-y: auto;
  }
}

.timetable_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.timetable-container {
  display: grid;
  grid-template-rows: repeat(8, 55px);
  grid-template-columns: repeat(7, minmax(80px, 132px));
  background-color: #f5f5f5;
}

.timetable-sessions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 132px));
  grid-template-rows: repeat(9, 56px);
}

@media only screen and (max-width: 1201px) {
  .timetable-sessions {
    grid-template-rows: repeat(9, 115px);
  }
}

.session_time_cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.session_time_cell.border_bottom {
  border-bottom: 1px solid #fff;
}

.session_time_cell.border_right {
  border-right: 1px solid #fff;
}

.schedule-classes-grid {
  display: grid;
  height: 60px;
  grid-template-columns: repeat(7, minmax(80px, 132px));
  background-color: #f5f5f5;
}

.days_rows {
  display: flex;
  flex-direction: column;
  flex-basis: 14.28;
}

.sessions_colums {
  display: grid;
  grid-template-columns: repeat(7, minmax(80px, 132px));
  background-color: #f5f5f5;
}

.timetable_popover_info p {
  color: #91999c;
  font-size: 10px;
  /*  display: flex;
        justify-content: center; */
}

.timetable_popover_info .popover_session_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0px 47px;
}

.timetable_popover_info .flex_row {
  display: flex;
  justify-content: center;
}

.timetable_popover_info .flex_row p {
  color: #2c363f;
  font-size: 11px;
  margin: 0px 2px;
}

.timetable_popover_info .flex_row.subject_session p:first-child {
  color: #0793c7;
  font-size: 12px;
  font-weight: 600;
}

.timetable_popover_info .teacher_info p {
  font-size: 12px;
  color: #0793c7;
}

.timetable_popover_info .teacher_info p img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.cell_custom_popover .ant-popover-inner-content {
  min-width: 220px;
  max-width: 220px;
  height: 120px;
}

.cell_custom_popover .ant-popover-arrow {
  display: none;
}

.timetable_calendar {
  position: absolute !important;
  z-index: 100;
  width: 300px;
  right: 20px;
}

body.rtl .timetable_calendar {
  right: unset;
  left: 20px;
}

@media only screen and (max-width: 641px) {
  .timetable_calendar {
    width: 90%;
  }
}

.timetable_responsive_header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 63px;
  background-color: rgba(224, 229, 243, 0.42);
  border-bottom: #f9f9fc 1px solid;
}

.timetable_responsive_header .anticon.anticon-right, .timetable_responsive_header .anticon.anticon-left {
  padding: 5px;
  background-color: #fff;
  cursor: pointer;
  width: unset;
  height: unset;
  border-radius: 50%;
  color: #295396;
}

body.rtl .timetable_responsive_header .anticon.anticon-right, body.rtl .timetable_responsive_header .anticon.anticon-left {
  transform: rotate(180deg);
}

.timeTable_responsive_card_ordinary {
  background: rgba(0, 173, 238, 0.05);
  height: 105px;
  margin: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
}

body.rtl .timeTable_responsive_card_ordinary {
  align-items: flex-end;
}

.timeTable_responsive_card_ordinary p {
  color: #2c363f;
}

.timeTable_responsive_card_ordinary .subject_chapter {
  border-bottom: 1px solid #d2dade;
  width: 100%;
}

.timeTable_responsive_card_ordinary .subject_chapter h6 {
  color: #00adee;
}

.timeTable_responsive_card_ordinary .subject_chapter p {
  color: #00adee;
}

.timeTable_responsive_card_online {
  background: #12abe4;
  height: 105px;
  margin: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
}

body.rtl .timeTable_responsive_card_online {
  align-items: flex-end;
}

.timeTable_responsive_card_online p {
  color: #fff;
}

.timeTable_responsive_card_online .subject_chapter {
  border-bottom: 1px solid #d2dade;
  width: 100%;
}

.timeTable_responsive_card_online .subject_chapter h6 {
  color: #fff;
}

.timeTable_responsive_card_online .subject_chapter p {
  color: #fff;
}

.courses-page {
  background: white;
  border-radius: 12px;
  height: auto;
  padding: 18px 14px 38px 14px;
  margin: 12px 20px;
}

@media only screen and (max-width: 577px) {
  .courses-page {
    padding: 18px 10px 38px 10px;
    margin: 12px 10px;
  }
}

.courses-header {
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 15px;
}

.courses-header .courses-header__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: #2c363f;
  font-family: Montserrat;
}

.courses-header .courses-header__link {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  color: #0793c7;
  align-self: flex-end;
}

.filtration-result-wrapper .students-courses,
.courses-page .students-courses {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(324px, 1fr));
  gap: 29px;
  height: auto !important;
  min-height: auto !important;
}

@media only screen and (max-width: 577px) {
  .filtration-result-wrapper .students-courses,
  .courses-page .students-courses {
    display: block !important;
    height: 100vh !important;
  }
}

.filtration-result-wrapper {
  padding: 30px 32px;
  background: #f9f9fc;
  position: relative;
}

.filtration-result-wrapper .ant-pagination {
  position: fixed;
  bottom: 0;
  height: 40px;
  width: 200px;
  background-color: #fff;
  left: calc(50% - 100px);
  padding: 9px 60px;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.22);
  margin: 18px auto;
}

.filtration-result-wrapper .students-courses {
  row-gap: 5px;
  max-height: unset !important;
  margin-bottom: 20px;
}

@media only screen and (max-width: 993px) {
  .filtration-result-wrapper .students-courses {
    max-height: calc(100vh - 300px) !important;
  }
}

.filtration-result-wrapper .students-courses .student-course__header .student-course__title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.filtration-result-wrapper .students-courses .ant-empty {
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 100px);
  margin-top: 0 !important;
}

.students-course {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  padding: 6px 10px 6px 22px;
  cursor: pointer;
}

body.rtl .students-course {
  padding: 6px 22px 6px 10px;
}

.student-course__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -7px;
}

.student-course__header .student-course__title {
  font-size: 12px;
  line-height: 1.7;
  color: #2c363f;
  word-break: break-word;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.student-course__header .student-course__title .student-course__subject-name {
  color: #2c363f;
  margin-inline-end: 5px;
}

.student-course__mark {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.course-teacher {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.course-title {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2c363f;
}

.course-teacher_info {
  display: flex;
  align-items: center;
}

.course-teacher_info .course-teacher__img {
  width: 11px;
  height: 11px;
  object-fit: cover;
  border-radius: 50%;
  margin-inline-end: 5px;
}

.course-teacher_info .course-teacher__name {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.7;
  color: #8e96aa;
}

.student-course__date-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.student-course__date {
  display: flex;
  align-items: center;
  margin-bottom: -7px;
}

.student-course__date .student-course__date-format {
  font-size: 9px;
  line-height: 3.67;
  color: #acb3c5;
  padding-inline-start: 5px;
  padding-left: 5px;
}

body.rtl .student-course__date .student-course__date-format {
  padding-left: 0;
  padding-right: 5px;
}

.course-description {
  font-size: 10px;
  margin-top: 5px;
  line-height: 1.8;
  color: #57595a;
  width: 75%;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  height: 25px;
  display: flex;
  align-items: center;
}

body.rtl .course-description {
  text-align: right;
}

.students-course_type_video {
  background: rgba(0, 173, 238, 0.05);
  border-left: solid 4px #00adee !important;
}

.students-course_type_video .student-course__mark_type_video {
  background-color: #00adee;
}

.students-course_type_audio {
  background: rgba(217, 163, 17, 0.05);
  border-left: solid 4px #d9a311 !important;
}

.students-course_type_audio .student-course__mark_type_audio {
  background-color: #d9a311;
}

.students-course_type_doc {
  background: rgba(41, 83, 150, 0.05);
  border-left: solid 4px #295396 !important;
}

.students-course_type_doc .student-course__mark_type_doc {
  background-color: #295396;
}

.students-course_type_photo {
  background: rgba(254, 120, 142, 0.05);
  border-left: solid 4px #fe788e !important;
}

.students-course_type_photo .student-course__mark_type_photo {
  background-color: #fe788e;
}

.students-course_type_multiple {
  background: rgba(118, 254, 213, 0.05);
  border-left: solid 4px #78fed6 !important;
}

.students-course_type_multiple .student-course__mark_type_multiple {
  background-color: #78fed6;
}

.course-teacher__files-wrapper {
  display: inline-flex;
  flex-wrap: wrap;
}

.course-teacher__file {
  width: 27px;
  height: 27px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: 10px;
}

.course-teacher__file_type_video {
  background: rgba(0, 173, 238, 0.25);
}

.course-teacher__file_type_audio {
  background: rgba(217, 163, 17, 0.25);
}

.course-teacher__file_type_doc {
  background: rgba(41, 83, 150, 0.25);
}

.course-teacher__file_type_photo {
  background: rgba(254, 120, 142, 0.25);
}

.course-teacher__file_type_multiple {
  background: rgba(118, 254, 213, 0.25);
}

.courses-tab {
  height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
}

@media only screen and (max-width: 993px) {
  .courses-tab {
    overflow-y: hidden;
  }
}

.courses-tab .students-courses {
  max-height: calc(100vh - 190px);
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(324px, 1fr));
  column-gap: 29px;
}

@media only screen and (max-width: 769px) {
  .courses-tab .students-courses {
    display: block !important;
  }
}

.courses-tab .students-courses .students-course {
  margin-bottom: 20px;
  height: fit-content;
  min-height: 120px;
  max-height: 120px;
}

.courses-tab .students-courses .students-course_type_video {
  border: solid 0.5px #00adee;
}

.courses-tab .students-courses .students-course_type_doc {
  border: solid 0.5px #285295;
}

.courses-tab .students-courses .students-course_type_photo {
  border: solid 0.5px #fe788e;
}

.courses-tab .students-courses .students-course_type_audio {
  border: solid 0.5px #d7a110;
}

.courses-tab .students-courses .students-course_type_multiple {
  border: solid 0.5px #78fed6;
}

.courses-tab .students-courses .ant-empty {
  height: 100px;
  margin-top: 138px;
}

.courses-tab ::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.courses-tab ::-webkit-scrollbar {
  width: 0px;
}

.courses-tab__header {
  padding: 19px 32px 0px 32px;
  background: #f9f9fc;
}

.courses-tab__header.exams {
  padding: 0px;
}

@media only screen and (max-width: 993px) {
  .courses-tab__header.exams {
    border: unset;
  }
}

body.rtl .courses-tab__header.exams {
  padding: 0px;
}

@media only screen and (max-width: 993px) {
  .courses-tab__header {
    padding: 19px 10px 0px 10px;
  }
  body.rtl .courses-tab__header {
    height: auto;
    padding-top: 5px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .courses-tab__header {
    padding: 19px 20px 0px 20px;
  }
}

@media only screen and (max-width: 577px) {
  .courses-tab__header {
    border: 1px solid rgba(134, 142, 164, 0.19);
  }
}

.filtration-bar {
  background-color: #fff;
  margin: 10px auto;
  height: 87px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06);
}

@media only screen and (max-width: 993px) {
  .filtration-bar {
    height: 140px;
    position: absolute;
    z-index: 5;
    right: 5px;
    width: 95%;
  }
}

@media only screen and (max-width: 641px) {
  .filtration-bar {
    height: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .filtration-bar {
    height: auto;
    right: 20px;
    width: auto;
    padding: 10px 20px;
    left: 20px;
  }
}

.filtration-bar .ant-form-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: Pridi;
}

@media only screen and (max-width: 993px) {
  .filtration-bar .ant-form-inline {
    flex-direction: column;
    align-items: unset !important;
  }
}

@media only screen and (max-width: 641px) {
  .filtration-bar .ant-form-inline {
    padding: 10px;
  }
}

.filtration-bar .ant-form-inline .filtration__inputs {
  width: 100%;
}

.filtration-bar .ant-form-inline .ant-form-item {
  width: 24%;
  margin-left: 15px;
}

@media only screen and (max-width: 641px) {
  .filtration-bar .ant-form-inline .ant-form-item {
    width: 45%;
    margin-inline-start: 0;
  }
  .filtration-bar .ant-form-inline .ant-form-item:last-of-type {
    width: 90%;
    margin-top: 10px;
  }
}

body.rtl .filtration-bar .ant-form-inline .ant-form-item {
  margin-left: 0;
  margin-right: 15px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .filtration-bar .ant-form-inline .ant-form-item {
    margin-right: 10px;
    margin-left: 0;
  }
  body.rtl .filtration-bar .ant-form-inline .ant-form-item {
    margin-left: 10px;
    margin-right: 0;
  }
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper {
  border: 1px solid #d2dade;
  border-radius: 6px;
  width: 100%;
  height: 38px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper {
    height: 25px;
  }
  .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-select-selection {
    height: 25px;
  }
  .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-select-selection .ant-select-selection__rendered {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder {
    font-size: 9px;
    margin-top: 0;
    position: unset;
    margin: 0 !important;
  }
  .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
    font-size: 9px;
  }
  .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-input {
    font-size: 9px;
    height: 25px;
    padding: 0 10px !important;
  }
  .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-input::placeholder {
    font-size: 9px;
  }
  .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-input-search {
    height: 25px;
  }
  .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-input-search .ant-input-suffix i {
    font-size: 12px;
  }
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control {
  line-height: normal;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-input-search .ant-select-selection__placeholder,
.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-select .ant-select-selection__placeholder {
  margin-left: 10px;
  margin-top: -11px;
  font-weight: 300;
}

body.rtl .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-input-search .ant-select-selection__placeholder, body.rtl
.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-select .ant-select-selection__placeholder {
  margin-right: 10px;
  margin-left: 0;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-input-search.ant-input-affix-wrapper .ant-input-suffix,
.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-select.ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
  width: 0;
}

body.rtl .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-input-search.ant-input-affix-wrapper .ant-input-suffix, body.rtl
.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-select.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
  left: unset;
  transform: rotate(90deg);
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-input-search .ant-input,
.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-select .ant-input {
  border-bottom: none !important;
  border: none !important;
  margin-inline-start: 22px;
  margin-left: 22px;
  padding: 0 10px !important;
  width: calc(100% - 22px);
}

body.rtl .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-input-search .ant-input, body.rtl
.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-select .ant-input {
  margin-left: 0;
  margin-right: 22px;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-label {
  position: relative;
  top: -4px;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-label label {
  font-size: 14px;
  font-weight: 500;
  color: #222;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-label label .form-item-label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 25px;
  margin-inline-start: 2px;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-label label .form-item-label-wrapper button {
  border: none;
  font-size: 14px;
  padding-inline-end: 0;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-label label .form-item-label-wrapper button:hover {
  background: transparent;
  color: #d83025;
}

.filtration-bar .ant-form-inline .filtration-bar__buttons-group {
  margin-block-start: 12px;
  display: flex;
}

@media only screen and (max-width: 993px) {
  .filtration-bar .ant-form-inline .filtration-bar__buttons-group {
    align-self: flex-end;
  }
}

.filtration-bar .ant-form-inline .filtration-bar__buttons-group button {
  width: 78px;
  margin: 10px;
  font-size: 14px;
  font-weight: 500;
  border: none;
}

.filtration-bar .ant-form-inline .filtration-bar__buttons-group button:first-of-type {
  color: #2c363f;
  box-shadow: none;
  background: transparent;
  border: 1px solid #2c363f;
}

.filtration-bar .ant-form-inline .filtration-bar__buttons-group button:first-of-type:hover {
  background: transparent;
  color: #d83025;
  border: 1px solid #d83025;
}

.filtration-bar .ant-form-inline .filtration-bar__buttons-group button.primary-fill:active, .filtration-bar .ant-form-inline .filtration-bar__buttons-group button.primary-fill:focus {
  background: #0793C7;
}

@media (min-width: 768px) and (max-width: 992px) {
  .filtration-bar .ant-form-inline {
    flex-direction: row;
  }
  .filtration-bar .ant-form-inline .ant-form-item {
    width: 31%;
  }
  .filtration-bar .ant-form-inline .filtration-bar__buttons-group button {
    width: 64px;
    margin: 0 3px;
    padding: 0;
    height: 25px !important;
    font-size: 9px;
    min-height: unset !important;
  }
}

.filtration-bar.is-hidden {
  display: none;
}

.filter-btn {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .filter-btn button span {
    display: none;
  }
}

.filter-btn button {
  color: #767676;
  font-size: 16px;
  font-weight: normal;
}

.filter-btn button i {
  padding-inline-end: 8px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .filter-btn button {
    color: #0793c7;
  }
  .filter-btn button i::before {
    color: #0793c7;
  }
}

.d-w-m-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-bottom: 20px;
}

@media only screen and (max-width: 1201px) {
  .d-w-m-row {
    flex-direction: row;
    align-items: center;
  }
}

@media only screen and (max-width: 577px) {
  .d-w-m-row {
    flex-direction: column;
    align-items: flex-start;
  }
}

.d-w-m-row .d-w-m-row__title {
  font-size: 24px;
  color: #222222;
  font-weight: normal;
}

body.rtl .d-w-m-row .d-w-m-row__title {
  text-align: right;
}

.d-w-m-row .d-w-m-row__subtitle {
  font-size: 12px;
  color: #a7a7a7;
}

body.rtl .d-w-m-row .d-w-m-row__subtitle {
  text-align: right;
}

.d-w-m-row .d-w-m-tabs {
  display: flex;
  align-items: center;
}

.d-w-m-row .d-w-m-tabs .d-w-m-tab {
  font-size: 16px;
  font-weight: normal;
  color: #a7a7a7;
  line-height: 2.75;
  cursor: pointer;
  margin: 0 20px;
}

.d-w-m-row .d-w-m-tabs .active-tab {
  border-radius: 13px;
  border: solid 1px #0793c7;
  color: #0793c7;
  padding: 4px 13px;
  width: 57px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-filter-row {
  display: flex;
}

.tab-filter-row .filter-tabs {
  display: flex;
  visibility: hidden;
  align-items: center;
  margin-bottom: 10px;
}

.tab-filter-row .filter-tabs .filter-tab {
  font-size: 16px;
  color: #767676;
  margin-inline-end: 30px;
  padding-bottom: 10px;
  cursor: pointer;
}

.tab-filter-row .filter-tabs .filter-tab:hover {
  color: #0793c7 !important;
}

.tab-filter-row .filter-tabs .active-tab {
  color: #0793c7 !important;
  border-bottom: 2px solid #0793c7;
}

.courses-tab__content {
  display: flex;
  align-items: center;
  align-items: flex-start;
  padding: 0 35px 20px 27px;
  background: #f9f9fc;
  margin-top: 15px;
  position: relative;
}

.courses-tab__content .students-courses {
  padding: 0px 16px;
}

body.rtl .courses-tab__content .students-courses {
  border-radius: 0 10px 10px 0;
}

@media only screen and (max-width: 769px) {
  .courses-tab__content .students-courses {
    padding: 25px 10px;
  }
}

@media only screen and (max-width: 641px) {
  .courses-tab__content {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 993px) {
  .courses-tab__content {
    padding: 0 10px 20px 10px;
  }
}

/* body.rtl .ant-popover{
    left: 30% !important;
} */
.popover__header-title {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.22;
  color: #000000;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popover__header-title .bold {
  font-weight: bold;
  margin-inline-end: 10px;
}

.ant-popover-title {
  border: none;
  min-width: 280px;
  max-width: 280px;
  padding-top: 15px;
}

.popover-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popover__close-icon {
  color: #f80000;
  font-size: 22px;
  cursor: pointer;
}

.popover__content .teacher-info {
  display: flex;
  align-items: flex-start;
}

.popover__content .popover__content-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-inline-end: 10px;
}

.popover__content .popover__content-teacher {
  font-size: 14px;
  font-weight: 500;
  color: #767676;
  margin-bottom: 5px;
}

.popover__content .date {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.popover__content .date .date-format {
  font-size: 12px;
  color: #00adee;
  margin-left: 5px;
}

body.rtl .popover__content .date .date-format {
  margin-right: 5px;
  margin-left: 0;
}

.popover__content .date .education {
  font-size: 12px;
  font-weight: 300;
  color: #000000;
  margin-left: 5px;
}

body.rtl .popover__content .date .education {
  margin-right: 5px;
  margin-left: 0;
}

.popover__content .icon-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popover__content .icon-holder .icon {
  margin-right: 5px;
}

body.rtl .popover__content .icon-holder .icon {
  margin-left: 5px;
  margin-right: 0;
}

.popover__content .icon-holder .icon-video:before {
  margin-right: 5px;
}

body.rtl .popover__content .icon-holder .icon-video:before {
  margin-left: 5px;
  margin-right: 0;
}

.popover__content .icon-holder .icon-doc:before {
  margin-right: 5px;
}

body.rtl .popover__content .icon-holder .icon-doc:before {
  margin-left: 5px;
  margin-right: 0;
}

.popover__content .info-holder {
  width: 100%;
}

.popover__content .content-paragraph {
  font-size: 13px;
  font-weight: normal;
  color: #3c3c38;
  border-top: solid 1px #dfdfdf;
  padding-top: 5px;
  margin-top: 5px;
  word-break: break-all;
}

.popover__content .popover__footer {
  padding-top: 15px;
  display: flex;
  justify-content: center;
}

.popover__content .popover__footer .popover__button {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  height: 34px;
  border-radius: 6px;
  background-color: #0793c7;
  padding: 0 60px;
}

.ant-popover-inner-content {
  min-width: 280px;
  max-width: 280px;
}

.course-deatails {
  margin: 0px 32px 22px 32px;
  padding: 13px 21px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
}

@media only screen and (max-width: 641px) {
  .course-deatails {
    margin: 22px 10px 22px 10px;
    padding: 13px 10px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .course-deatails {
    margin: 0;
    padding: 20px 30px;
  }
}

.course-deatails .date {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-inline-end: 25px;
}

.course-deatails .date .date-format {
  font-size: 12px;
  color: #00adee;
  margin-left: 5px;
}

body.rtl .course-deatails .date .date-format {
  margin-right: 5px;
  margin-left: 0;
}

.course-deatails .date .education {
  font-size: 12px;
  font-weight: 300;
  color: #000000;
  margin-left: 5px;
}

body.rtl .course-deatails .date .education {
  margin-right: 5px;
  margin-left: 0;
}

.course-deatails .date .teacher-name {
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  margin-left: 5px;
}

body.rtl .course-deatails .date .teacher-name {
  margin-right: 5px;
  margin-left: 0;
}

.course-deatails .first-part {
  display: flex;
  justify-content: space-between;
  padding-left: 21;
}

body.rtl .course-deatails .first-part {
  padding-right: 21px;
  padding-left: 0;
}

@media only screen and (max-width: 769px) {
  .course-deatails .first-part {
    flex-direction: column-reverse;
    padding-top: 20px;
  }
}

.course-deatails .pragraph-holder {
  flex-basis: 70%;
  padding-top: 3px;
  margin-left: 25px;
}

body.rtl .course-deatails .pragraph-holder {
  margin-right: 25px;
  margin-left: 0;
}

@media only screen and (max-width: 769px) {
  .course-deatails .pragraph-holder {
    margin-left: 0;
    margin-right: 0;
  }
}

.course-deatails .teacher-image {
  width: 145px;
  height: 145px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  padding: 2px;
}

@media only screen and (max-width: 769px) {
  .course-deatails .teacher-image {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .course-deatails .teacher-image {
    width: 118px;
    height: 118px;
  }
}

.course-deatails .teacher-image img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.course-deatails .content-paragraph {
  font-size: 16px;
  line-height: 1.56;
  color: #3c3c38;
  padding-bottom: 20px;
  padding-top: 20px;
  word-break: break-all;
}

.course-deatails .icon-holder {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.course-deatails .icon-holder .icon {
  margin-right: 5px;
}

body.rtl .course-deatails .icon-holder .icon {
  margin-right: 0;
  margin-left: 5px;
}

.course-deatails .icon-holder .icon-video:before {
  margin-right: 5px;
}

body.rtl .course-deatails .icon-holder .icon-video:before {
  margin-right: 0;
  margin-left: 5px;
}

.course-deatails .icon-holder .icon-doc:before {
  margin-right: 5px;
}

body.rtl .course-deatails .icon-holder .icon-doc:before {
  margin-right: 0;
  margin-left: 5px;
}

.course-deatails .teacher-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 769px) {
  .course-deatails .teacher-holder {
    align-items: flex-start;
  }
}

.course-deatails .teacher-holder .date {
  padding-top: 8px;
}

@media only screen and (max-width: 769px) {
  .course-deatails .teacher-holder {
    margin-bottom: 10px;
  }
}

.course-deatails hr {
  width: 95%;
  border-top: 0.5px solid #f9f9fc;
}

.course-deatails > p.course__details--title {
  font-size: 16px;
  font-weight: 600;
  margin: 12px 0;
  color: #0793c7;
  margin-left: 25px;
}

body.rtl .course-deatails > p.course__details--title {
  margin-right: 25px;
  margin-left: 0;
}

.no-course__image {
  width: 50%;
}

@media only screen and (max-width: 641px) {
  .no-course__image {
    width: 100%;
  }
}

.clear-btn {
  border: none;
  background: transparent;
  color: #d83025 !important;
}

.clear-btn:hover {
  color: #d83025 !important;
  background: transparent !important;
}

.courses-block {
  display: block !important;
  position: relative;
  height: 100%;
  min-height: 400px;
}

.full-border {
  border-radius: 10px !important;
}

@media only screen and (max-width: 641px) {
  .course-fullwidth {
    width: 100% !important;
  }
  .students-courses {
    border-radius: 10px !important;
  }
}

body.rtl .courses-tab .ant-tabs {
  direction: ltr;
}

body.rtl .courses-tab .ant-tabs-nav {
  transform: scaleX(-1);
}

body.rtl .courses-tab .ant-tabs-tab {
  transform: scaleX(-1);
}

body.rtl .courses-tab .ant-tabs-nav-scroll {
  display: flex;
  flex-direction: row-reverse;
}

.list__btn--back {
  font-size: 20px;
  font-weight: normal;
  color: #222222;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.list__btn--back img {
  margin-right: 10px;
}

body.rtl .list__btn--back img {
  margin-right: 0;
  margin-left: 10px;
}

.customize-calendar {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border-radius: 0 10px 10px 0;
  margin-inline-start: 2px;
  min-width: 265px;
  height: calc(100vh - 190px);
  min-height: calc(100vh - 190px);
  position: relative;
}

body.rtl .customize-calendar {
  border-radius: 10px 0 0 10px;
}

.customize-calendar .ant-fullcalendar-value {
  padding-top: 6px;
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
}

.customize-calendar .ant-fullcalendar {
  border-top: none !important;
  border-bottom: 1px solid #f9f9fc;
}

.customize-calendar .ant-fullcalendar-header {
  display: none;
}

.customize-calendar .calendar-header {
  display: flex;
  padding: 25px 20px 25px 20px;
  align-items: center;
  justify-content: space-around;
}

.customize-calendar .calendar-header__button {
  width: 33px;
  height: 33px;
  border: solid 1px #868ea4;
  border-radius: 50%;
  color: #868ea4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3px;
}

body.rtl .customize-calendar .calendar-header__button {
  transform: rotate(180deg);
}

.customize-calendar .calendar-header__button:focus {
  outline: none !important;
}

.customize-calendar .calendar-current-date {
  font-size: 14px;
  font-weight: 500;
  color: #2c363f;
  font-family: Pridi;
}

.customize-calendar .calendar-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
}

.customize-calendar .calendar-footer__button {
  height: 38px;
  border-radius: 6px;
  background-color: #0793c7;
  padding: 0 43px;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.customize-calendar .calendar-footer__button:focus {
  outline: none !important;
}

@media only screen and (max-width: 769px) {
  .customize-calendar {
    margin-bottom: 20px;
  }
}

.calender-show-hide {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
  /* @media (min-width: 768px) and (max-width: 992px) {
		top: 125px;
	}
 */
}

@media only screen and (max-width: 993px) {
  .calender-show-hide {
    position: absolute;
    right: 45px;
    top: 95px;
  }
  .calender-show-hide .calendar-current-date {
    display: none;
  }
}

body.rtl .calender-show-hide {
  margin-left: 50px;
}

@media only screen and (max-width: 769px) {
  body.rtl .calender-show-hide {
    left: 45px;
  }
}

.calender-show-hide .show-hide-arrow {
  width: 22px;
  height: 22px;
  background-color: #e7e9ea;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (max-width: 993px) {
  .calender-show-hide .show-hide-arrow {
    display: none;
  }
}

.calender-show-hide .show-hide-arrow img {
  width: 10px;
}

.calender-show-hide .calendar-current-date {
  margin: 0 7px;
  cursor: pointer;
}

.no-calender {
  display: none;
}

.rotate-arrow {
  transform: rotate(180deg);
  transition: transform 0.3s linear;
}

.rotate-arrow-reverse {
  transform: rotate(360deg);
  transition: transform 0.3s linear;
}

@media only screen and (max-width: 641px) {
  .calendar {
    position: absolute !important;
    right: calc((100vw - 340px) / 2);
    top: -60px;
    z-index: 6;
  }
  .calendar .customize-calendar {
    border-radius: 10px !important;
    height: auto;
    min-height: auto;
  }
  .calendar .customize-calendar .calendar-footer {
    min-height: 53px;
  }
}

.feadback-popover .no-feedback {
  text-align: center;
}

.feadback-popover .no-feedback__paragraph {
  opacity: 0.38;
  font-family: Montserrat;
  font-size: 13px;
  line-height: 1.38;
  color: #57595a;
  margin-top: 30px;
}

.homwork__card {
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (max-width: 993px) {
  .homwork__card {
    height: 163px;
  }
}

.homwork__card .homwork__card--feedback {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 25px;
}

.homwork__card .homwork__card--feedback .homwork__card--student {
  display: flex;
}

.homwork__card .homwork__card--feedback .homwork__card--student > img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.homwork__card .homwork__card--feedback .homwork__card--student .homwork__card--details {
  margin-left: 10px;
}

body.rtl .homwork__card .homwork__card--feedback .homwork__card--student .homwork__card--details {
  margin-left: 0;
  margin-right: 10px;
}

.homwork__card .homwork__card--feedback .homwork__card--student .homwork__card--details > span {
  display: flex;
  margin-bottom: 7px;
}

@media only screen and (max-width: 577px) {
  .homwork__card .homwork__card--feedback .homwork__card--student .homwork__card--details > span {
    display: block;
  }
}

.homwork__card .homwork__card--feedback .homwork__card--student .homwork__card--details > span h4 {
  font-size: 14px;
  font-weight: normal;
  color: #868ea4;
  margin-right: 14px;
}

body.rtl .homwork__card .homwork__card--feedback .homwork__card--student .homwork__card--details > span h4 {
  margin-right: 0;
  margin-left: 14px;
}

@media only screen and (max-width: 577px) {
  .homwork__card .homwork__card--feedback .homwork__card--student .homwork__card--details > span h4 {
    font-size: 11px;
    margin: 0;
    margin-bottom: 3px;
  }
}

.homwork__card .homwork__card--feedback .homwork__card--student .homwork__card--details > span .assignment-status > img {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

body.rtl .homwork__card .homwork__card--feedback .homwork__card--student .homwork__card--details > span .assignment-status > img {
  margin-right: 0;
  margin-left: 7px;
}

.homwork__card .homwork__card--feedback .homwork__card--student .homwork__card--details > span .assignment-status > span {
  font-size: 9px;
  font-weight: bold;
  color: #11b74f;
}

.homwork__card .homwork__card--feedback .homwork__card--student .homwork__card--details > span .assignment-status.assignment-status--uncomplated > span {
  color: #c5c5c5;
}

.homwork__card .homwork__card--feedback .homwork__card--student .homwork__card--details p {
  font-size: 14px;
  font-weight: 600;
  color: #2c363f;
}

.homwork__card .homwork__card--feedback .homwork__card--student .homwork__card--details p span {
  font-weight: 300;
}

.homwork__card .homwork__card--footer {
  display: flex;
}

@media only screen and (max-width: 401px) {
  .homwork__card .homwork__card--footer {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 993px) {
  .homwork__card .homwork__card--footer {
    flex-direction: column-reverse;
  }
}

body.rtl .homwork__card .homwork__card--footer {
  margin-left: 0;
}

@media only screen and (max-width: 577px) {
  .homwork__card .homwork__card--footer {
    margin-left: 0;
    margin-right: 0;
  }
  body.rtl .homwork__card .homwork__card--footer {
    margin-left: 0;
    margin-right: 0;
  }
}

.homwork__card .homwork__card--footer .homwork__footer--details {
  padding-right: 14px;
  margin-right: 14px;
  border-right: 1px solid #c5c5c5;
}

@media only screen and (max-width: 993px) {
  .homwork__card .homwork__card--footer .homwork__footer--details.date_footer_card {
    border-right: none;
  }
}

body.rtl .homwork__card .homwork__card--footer .homwork__footer--details {
  padding-right: 0;
  margin-right: 0;
  padding-left: 14px;
  margin-left: 14px;
  border-right: none;
  border-left: 1px solid #c5c5c5;
}

@media only screen and (max-width: 577px) {
  .homwork__card .homwork__card--footer .homwork__footer--details {
    padding-right: 8px;
    margin-right: 8px;
  }
  body.rtl .homwork__card .homwork__card--footer .homwork__footer--details {
    padding-right: 0;
    margin-right: 0;
    padding-left: 8px;
    margin-left: 8px;
  }
}

.homwork__card .homwork__card--footer .homwork__footer--details:last-of-type {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
  border-left: none;
}

body.rtl .homwork__card .homwork__card--footer .homwork__footer--details:last-of-type {
  padding-right: 0;
  margin-right: 0;
  padding-left: 0;
  margin-left: 0;
  border-left: none;
}

.homwork__card .homwork__card--footer .homwork__footer--details img {
  margin-right: 10px;
}

body.rtl .homwork__card .homwork__card--footer .homwork__footer--details img {
  margin-right: 0;
  margin-left: 10px;
}

@media only screen and (max-width: 577px) {
  .homwork__card .homwork__card--footer .homwork__footer--details img {
    margin-right: 5px;
  }
  body.rtl .homwork__card .homwork__card--footer .homwork__footer--details img {
    margin-right: 0;
    margin-left: 5px;
  }
}

.homwork__card .homwork__card--footer .homwork__footer--details > span {
  font-size: 14px;
  font-weight: 500;
  color: #2c363f;
}

.homwork__card .homwork__card--footer .homwork__footer--details > span > span {
  font-size: 10px;
  color: #cacaca;
  margin-right: 6px;
}

body.rtl .homwork__card .homwork__card--footer .homwork__footer--details > span > span {
  margin-right: 0;
  margin-left: 6px;
}

.homwork__card .homwork__card--footer .homwork__footer--details > span > span.degree__num {
  color: #11b74f;
  font-size: 14px;
}

@media only screen and (max-width: 993px) {
  .homwork__card .homwork__card--footer .homwork__footer--details > span > span.degree__num {
    font-size: 10px;
  }
}

@media only screen and (max-width: 577px) {
  .homwork__card .homwork__card--footer .homwork__footer--details > span {
    font-size: 10px;
  }
  .homwork__card .homwork__card--footer .homwork__footer--details > span > span {
    font-size: 8px;
    color: #cacaca;
    margin-right: 6px;
  }
}

.assignments_title_status {
  margin: 5px 0px;
  display: flex;
  justify-content: space-between;
}

.assignments_title_status h4 {
  color: #12abe4;
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media only screen and (max-width: 362px) {
  .assignments_title_status h4 {
    max-width: 180px;
  }
}

.assignments_title_status .assignment-status {
  display: flex;
  align-items: center;
}

.assignments_title_status .assignment-status > img {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

body.rtl .assignments_title_status .assignment-status > img {
  margin-right: 0;
  margin-left: 7px;
}

.assignments_title_status .assignment-status > span {
  font-size: 10px;
  font-weight: bold;
  color: #11b74f;
}

.assignments_title_status .assignment-status.assignment-status--uncomplated > span {
  color: #c5c5c5;
}

.assignment_teacher_subject {
  display: flex;
  margin: 5px 0px;
}

@media only screen and (max-width: 993px) {
  .assignment_teacher_subject {
    flex-direction: column;
  }
}

.assignment_teacher_subject .assignment_subject_topic {
  display: flex;
  flex-basis: 30%;
}

@media only screen and (max-width: 1201px) {
  .assignment_teacher_subject .assignment_subject_topic {
    flex-basis: 40%;
  }
}

.assignment_teacher_subject .assignment_subject_topic p:first-child {
  font-weight: 600;
}

.assignment_teacher_subject .assignment_subject_topic p {
  color: #2c363f;
}

.assignment_teacher_subject .assignment_teacher_image {
  display: flex;
  margin: 0px 20px;
}

@media only screen and (max-width: 993px) {
  .assignment_teacher_subject .assignment_teacher_image {
    margin: 10px 0px;
  }
}

.assignment_teacher_subject .assignment_teacher_image p {
  color: #868ea4;
  font-size: 13px;
  font-weight: 500;
}

.assignment_teacher_subject .assignment_teacher_image img {
  border-radius: 50%;
  width: 21px;
  height: 21px;
  margin: 0px 5px;
}

.assignment_footer {
  margin: 5px 0px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 993px) {
  .assignment_footer .homwork__footer--details span {
    font-size: 11px;
  }
}

@media only screen and (max-width: 993px) {
  .assignment_footer .homwork__footer--details.date_footer_card {
    border-right: none;
  }
}

.responsive_assignments_footer {
  display: flex;
}

.schedule-teacher-wrapper {
  padding-top: 25px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  /* start times */
  /* end times */
}

.schedule-teacher-wrapper button {
  height: 30px;
  width: 100px;
  margin: 0 5px;
  background-color: gainsboro;
  font-variant: small-caps;
  font-size: 14px;
  border-radius: 6px;
  box-shadow: 2px 5px #5c5c5c;
}

.schedule-teacher-wrapper button:hover {
  background-color: #949494;
}

.schedule-teacher-wrapper button:active {
  transform: translateY(2px);
}

.schedule-teacher-wrapper .myCourses {
  margin-left: 10px;
}

.schedule-teacher-wrapper .titleStyle {
  color: #2c2e33;
  font-variant: small-caps;
  width: 1100px;
  text-align: center;
  margin-bottom: 10px;
}

.schedule-teacher-wrapper .sectionSelectors {
  margin-bottom: 20px;
  font-variant: small-caps;
  width: 1100px;
  text-align: center;
}

.schedule-teacher-wrapper .sectionTitle {
  margin-bottom: 20px;
  font-variant: small-caps;
  width: 1100px;
  text-align: center;
  font-size: 22px;
}

.schedule-teacher-wrapper .timetable {
  display: grid;
  grid-gap: 0 10px;
  grid-template-columns: 130px repeat(5, 140px);
  grid-template-rows: repeat(20, 67px);
  background: url(../../resources/images/schedule-bg.svg);
}

.schedule-teacher-wrapper .titleWrapper {
  display: grid;
  grid-column: 1 / 7;
  grid-gap: 10px;
  grid-template-columns: 130px repeat(5, 140px);
  justifytitleWrapper-items: center;
  background-color: #ffffff;
}

.schedule-teacher-wrapper .titleWrapper > p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule-teacher-wrapper .titleWrapper > p.active {
  color: #0793c7;
}

.schedule-teacher-wrapper .timeWrapper {
  display: grid;
  grid-row: 2 / 21;
  grid-gap: 10px;
  grid-template-rows: repeat(20, 58px);
  background-color: #ffffff;
}

.schedule-teacher-wrapper .timeWrapper > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.schedule-teacher-wrapper .timeWrapper > div time {
  font-size: 12px;
  color: #7a8390;
  font-weight: normal;
}

.schedule-teacher-wrapper .timeWrapper > div p {
  font-size: 12px;
  color: rgba(122, 131, 144, 0.55);
  font-weight: normal;
}

.schedule-teacher-wrapper p {
  margin: 0;
  padding: 0;
}

.schedule-teacher-wrapper .tableElement {
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 11px;
  font-weight: 600;
  color: #2c363f;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 10px;
}

.schedule-teacher-wrapper .tableElement.green {
  border: solid 1px #06d236;
}

.schedule-teacher-wrapper .tableElement.blue {
  border: solid 1px #0793c7;
}

.schedule-teacher-wrapper .tableElement.yellow {
  border: solid 1px #ffb300;
}

.schedule-teacher-wrapper .tableElement span:first-of-type {
  font-size: 10px;
  font-weight: normal;
  color: #91999c;
}

.schedule-teacher-wrapper .tableElement span:last-of-type {
  font-size: 10px;
  font-weight: 500;
  color: rgba(44, 54, 63, 0.68);
}

.schedule-teacher-wrapper .tableElement:hover {
  animation: pulse 0.3s linear 1;
  cursor: pointer;
}

@keyframes pulse {
  50% {
    transform: scale(0.97);
  }
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(1) {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(2) {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(3) {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(4) {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(5) {
  grid-column: 1 / 2;
  grid-row: 5 / 6;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(6) {
  grid-column: 1 / 2;
  grid-row: 6 / 7;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(7) {
  grid-column: 1 / 2;
  grid-row: 7 / 8;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(8) {
  grid-column: 1 / 2;
  grid-row: 8 / 9;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(9) {
  grid-column: 1 / 2;
  grid-row: 9 / 10;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(10) {
  grid-column: 1 / 2;
  grid-row: 10 / 11;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(11) {
  grid-column: 1 / 2;
  grid-row: 11 / 12;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(12) {
  grid-column: 1 / 2;
  grid-row: 12 / 13;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(13) {
  grid-column: 1 / 2;
  grid-row: 13 / 14;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(14) {
  grid-column: 1 / 2;
  grid-row: 14 / 15;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(15) {
  grid-column: 1 / 2;
  grid-row: 15 / 16;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(16) {
  grid-column: 1 / 2;
  grid-row: 16 / 17;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(17) {
  grid-column: 1 / 2;
  grid-row: 17 / 18;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(18) {
  grid-column: 1 / 2;
  grid-row: 18 / 19;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(19) {
  grid-column: 1 / 2;
  grid-row: 19 / 20;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(20) {
  grid-column: 1 / 2;
  grid-row: 20 / 21;
}

.schedule-teacher-wrapper .timeColumn {
  grid-column: 1 / 2;
}

.schedule-teacher-wrapper .sunday {
  grid-column: 2 / 3;
}

.schedule-teacher-wrapper .monday {
  grid-column: 3 / 4;
}

.schedule-teacher-wrapper .tuesday {
  grid-column: 4 / 5;
}

.schedule-teacher-wrapper .wednesday {
  grid-column: 5 / 6;
}

.schedule-teacher-wrapper .thursday {
  grid-column: 6 / 7;
}

.schedule-teacher-wrapper .eightThirty {
  grid-row-start: 2;
}

.schedule-teacher-wrapper .nineOclock {
  grid-row-start: 3;
}

.schedule-teacher-wrapper .nineThirty {
  grid-row-start: 4;
}

.schedule-teacher-wrapper .tenOclock {
  grid-row-start: 5;
}

.schedule-teacher-wrapper .tenThirty {
  grid-row-start: 6;
}

.schedule-teacher-wrapper .elevenOclock {
  grid-row-start: 7;
}

.schedule-teacher-wrapper .elevenThirty {
  grid-row-start: 8;
}

.schedule-teacher-wrapper .twelveOclock {
  grid-row-start: 9;
}

.schedule-teacher-wrapper .twelveThirty {
  grid-row-start: 10;
}

.schedule-teacher-wrapper .oneOclock {
  grid-row-start: 11;
}

.schedule-teacher-wrapper .oneThirty {
  grid-row-start: 12;
}

.schedule-teacher-wrapper .twoOclock {
  grid-row-start: 13;
}

.schedule-teacher-wrapper .twoThirty {
  grid-row-start: 14;
}

.schedule-teacher-wrapper .threeOclock {
  grid-row-start: 15;
}

.schedule-teacher-wrapper .threeThirty {
  grid-row-start: 16;
}

.schedule-teacher-wrapper .fourOclock {
  grid-row-start: 17;
}

.schedule-teacher-wrapper .fourThirty {
  grid-row-start: 18;
}

.schedule-teacher-wrapper .fiveOclock {
  grid-row-start: 19;
}

.schedule-teacher-wrapper .endNineThirty {
  grid-row-end: 4;
}

.schedule-teacher-wrapper .endTenOclock {
  grid-row-end: 5;
}

.schedule-teacher-wrapper .endTenThirty {
  grid-row-end: 6;
}

.schedule-teacher-wrapper .endElevenOclock {
  grid-row-end: 7;
}

.schedule-teacher-wrapper .endElevenThirty {
  grid-row-end: 8;
}

.schedule-teacher-wrapper .endTwelveOclock {
  grid-row-end: 9;
}

.schedule-teacher-wrapper .endTwelveThirty {
  grid-row-end: 10;
}

.schedule-teacher-wrapper .endOneOclock {
  grid-row-end: 11;
}

.schedule-teacher-wrapper .endOneThirty {
  grid-row-end: 12;
}

.schedule-teacher-wrapper .endTwoOclock {
  grid-row-end: 13;
}

.schedule-teacher-wrapper .endTwoThirty {
  grid-row-end: 14;
}

.schedule-teacher-wrapper .endThreeOclock {
  grid-row-end: 15;
}

.schedule-teacher-wrapper .endThreeThirty {
  grid-row-end: 16;
}

.schedule-teacher-wrapper .endFourOclock {
  grid-row-end: 17;
}

.schedule-teacher-wrapper .endFourThirty {
  grid-row-end: 18;
}

.schedule-teacher-wrapper .endFiveOclock {
  grid-row-end: 19;
}

.schedule-teacher-wrapper .endFiveThirty {
  grid-row-end: 20;
}

.schedule-teacher-wrapper .endSixOclock {
  grid-row-end: 21;
}

.tableElement {
  position: relative;
}

.tableElement .course__details {
  box-shadow: 0 3px 62px 0 rgba(0, 0, 0, 0.36);
  border: solid 1px #0793c7;
  background-color: #ffffff;
  width: 209px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 6px;
  padding: 14px;
  z-index: 999999999;
}

.tableElement .course__details .course__details--name {
  font-size: 16px;
  font-weight: 500;
  color: #2c363f;
  margin-bottom: 9px;
}

.tableElement .course__details > span {
  font-size: 12px;
  font-weight: normal;
  color: #91999c;
}

.tableElement .course__details .course__details--type {
  font-size: 14px;
  font-weight: 300;
  color: #0793c7;
  margin: 25px 0;
}

.tableElement .course__details .course__details--avatar {
  display: flex;
}

.tableElement .course__details .course__details--avatar span {
  width: 24px;
  font-size: 10px;
  height: 24px;
  border-radius: 50%;
  margin-right: 3px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableElement .course__details .course__details--avatar span:last-child {
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
}

.tableElement .course__details .meeting_start {
  display: block;
  border-radius: 4px;
  background-color: #0793c7;
  margin: 25px 13px 0;
  height: 35px;
  color: #ffffff;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.results {
  padding: 20px;
}

@media only screen and (max-width: 401px) {
  .results {
    padding: 5px;
  }
}

.results .tabs {
  display: flex;
  align-items: center;
}

.results .tab {
  font-size: 16px;
  line-height: 1.75;
  color: #767676;
  margin: 0 13px;
  cursor: pointer;
}

@media only screen and (max-width: 577px) {
  .results .tab {
    font-size: 12px;
  }
}

.results .active {
  color: #0793c7;
  border-bottom: 1px solid #0793c7;
}

.results .results__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 993px) {
  .results .results__header {
    padding: 0;
  }
}

.results .results__header .ant-btn > span {
  color: #767676 !important;
  font-size: 16px !important;
  margin: 0 5px !important;
}

.results .results__empty {
  text-align: center;
  display: none;
}

.results .results__empty .title {
  font-size: 24px;
  font-weight: 500;
  color: #868ea4;
}

.results .results__empty .subtitle {
  opacity: 0.75;
  font-size: 17px;
  font-weight: 300;
  color: #868ea4;
  letter-spacing: -0.24px;
}

.results .results__empty .empty__img {
  width: 50%;
  max-width: 350px;
}

.results .result__cards {
  display: flex;
  border: solid 1px #f4f4f4;
  border-radius: 6px;
  overflow: hidden;
  margin: 15px 0;
}

@media only screen and (max-width: 993px) {
  .results .result__cards {
    flex-direction: column;
  }
}

.results .result__cards .student__term {
  width: 106px;
  height: 92px;
  opacity: 0.87;
  border: solid 1px #f4f4f4;
  background-color: #4f6170;
  color: white;
  text-align: center;
  padding: 15px 17px;
}

@media only screen and (max-width: 993px) {
  .results .result__cards .student__term {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
  }
}

.results .result__cards .student__term p:first-of-type {
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid white;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 993px) {
  .results .result__cards .student__term p:first-of-type {
    border-bottom: none;
    border-right: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-right: 5px;
    padding-right: 5px;
  }
  body.rtl .results .result__cards .student__term p:first-of-type {
    border-left: 1px solid white;
    margin-right: 0;
    padding-right: 0;
    margin-left: 5px;
    padding-left: 5px;
    border-right: none;
  }
}

.results .result__cards .student__term p {
  font-size: 11px;
}

.results .result__cards .details-bar {
  display: flex;
  flex: 1 1;
  background: white;
  padding: 10px;
  justify-content: space-between;
}

@media only screen and (max-width: 993px) {
  .results .result__cards .details-bar {
    flex-direction: column;
  }
}

.results .result__cards .details-bar .details-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.results .result__cards .details-bar .result-holder {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;
  align-items: center;
}

.results .result__cards .details-bar .total-result {
  display: flex;
  width: 30%;
}

@media only screen and (max-width: 1025px) {
  .results .result__cards .details-bar .total-result {
    display: block;
    width: unset;
    margin-right: 15px;
  }
  body.rtl .results .result__cards .details-bar .total-result {
    margin-right: 0;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 993px) {
  .results .result__cards .details-bar .total-result {
    display: flex;
    width: 65%;
  }
}

@media only screen and (max-width: 401px) {
  .results .result__cards .details-bar .total-result {
    display: flex;
    width: 90%;
  }
}

.results .result__cards .details-bar .details-title {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 1.5;
  margin-right: 15px;
  margin-left: 0;
}

body.rtl .results .result__cards .details-bar .details-title {
  margin-right: 0;
  margin-left: 15px;
}

.results .result__cards .details-bar .details-subtitle {
  font-size: 14px;
  font-weight: 500;
  color: #2c363f;
  line-height: 1.71;
  border-right: solid 1px #f4f4f4;
  padding-right: 15px;
}

@media only screen and (max-width: 993px) {
  .results .result__cards .details-bar .details-subtitle {
    margin: 10px 0;
  }
}

body.rtl .results .result__cards .details-bar .details-subtitle {
  border-left: solid 1px #f4f4f4;
  border-right: none;
  padding-right: 0;
  padding-left: 15px;
}

.results .result__cards .details-bar .details-subtitle:nth-of-type(2) {
  margin: 0 15px;
}

@media only screen and (max-width: 993px) {
  .results .result__cards .details-bar .details-subtitle:nth-of-type(2) {
    margin: 10px 15px;
  }
}

.results .result__cards .details-bar button {
  align-self: center;
}

@media only screen and (max-width: 993px) {
  .results .result__cards .details-bar button {
    margin-top: 10px;
  }
}

.results .courses-tab__header {
  padding: 19px 10px 0px 10px;
}

.todolist_container {
  margin: 0px 20px;
}

@media only screen and (max-width: 641px) {
  .todolist_container {
    margin: 0px 10px 0px 10px;
  }
}

.student_todo_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.student_todo_header.todo {
  position: absolute;
  background: #f9f9fc;
  z-index: 10;
  width: 80%;
  top: 60px;
  margin-bottom: 20px;
  padding: 30px 0px 0px 0px;
}

@media only screen and (max-width: 1201px) {
  .student_todo_header.todo {
    padding: 10px 0px 0px 0px;
  }
}

.student_todo_header.online_session_header {
  flex-direction: column;
}

.student_todo_header.online_session_header p {
  margin: 5px 17px;
}

@media only screen and (max-width: 1201px) {
  .student_todo_header.online_session_header p {
    margin: unset;
  }
}

@media only screen and (max-width: 1201px) {
  .student_todo_header {
    flex-direction: column;
  }
}

body.rtl .student_todo_header {
  align-items: baseline;
}

.student_todo_header p {
  font-size: 24px;
  color: #222222;
}

.student_todo_header img {
  display: none;
}

@media only screen and (max-width: 1201px) {
  .student_todo_header img {
    display: block;
  }
}

.student_todo_header .todo_header_radio_group {
  margin: 0px 20px;
}

@media only screen and (max-width: 1201px) {
  .student_todo_header .todo_header_radio_group {
    margin: 0px 0px;
    margin-top: 10px;
  }
}

.student_todo_header .todo_header_radio_group label {
  margin: 0px 20px;
  font-weight: 400;
}

@media only screen and (max-width: 481px) {
  .student_todo_header .todo_header_radio_group label {
    margin: 0px 0px;
    font-size: 9px;
  }
}

.student_todo_header .todo_header_radio_group.online_checkbox label:first-child {
  margin-left: 0px;
}

body.rtl .student_todo_header .todo_header_radio_group.online_checkbox label:first-child {
  margin-right: 0px;
}

.filter_container {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  top: 75px;
  z-index: 11;
  /* @include mq("phone-wide"){
    margin-top: -75px;
} */
}

body.rtl .filter_container {
  left: 10px;
  right: unset;
}

.todo_header_radio_group_responsive {
  /*  display: none;
    @include mq('tablet-qc'){
        display: block;
    } */
}

.todo_header_radio_group_responsive label {
  margin: 0px;
  font-weight: 400;
  font-size: 11px;
}

.todolist_card_header {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: 1px #f0f0f1 solid;
  border-right: 1px #f0f0f1 solid;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 51px;
  margin: 10px;
}

.todolist_card_header ul {
  display: flex;
}

.todolist_card_header ul > li:nth-child(4) {
  flex-basis: 15%;
}

.todolist_card_header ul > li:nth-child(5) {
  flex-basis: 15%;
}

.todolist_card_header ul > li:last-child {
  flex-basis: 10%;
}

.todolist_card_header ul > li {
  flex-basis: 20%;
  display: inline-flex;
  margin: 10px;
  padding: 7px;
  border-right: 1px #f0f0f1 solid;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-height: 45px;
  max-width: 20%;
  /* body.rtl & {
            padding: 0;
        } */
}

.todolist_card_header ul > li:first-child {
  justify-content: unset;
}

.todolist_card_header ul > li:nth-child(4) {
  border-right: 1px #f0f0f1 solid;
  display: inline;
  margin-inline-start: 5px;
}

body.rtl .todolist_card_header ul > li:nth-child(4) {
  border-right: none;
}

.todolist_card_header ul > li:last-child {
  border-right: none;
}

body.rtl .todolist_card_header ul > li:last-child {
  border-right: 1px #f0f0f1 solid;
}

.todolist_card_header li > span {
  font-size: 12px;
  display: flex;
  font-weight: 400;
}

.todolist_card_header li > span p:first-child {
  flex-basis: 30%;
}

body.rtl .todolist_card_header li > span p:first-child {
  flex-basis: 50%;
}

.todolist_card_header li > span > p {
  font-size: 11px;
  color: #000;
}

.todolist_card_header li > span > p:last-child {
  font-weight: 600;
}

.todolist_card_header li > h6 {
  color: #0793c7;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  align-items: center;
}

.todolist_card_header li > h6::before {
  content: "";
  font-size: 16px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px 5px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.todolist_card_header li {
  color: #000;
}

.todolist_card_header .topic, .todolist_card_header .subject > p {
  color: #0793c7;
}

.todolist_card_header .subject > p:first-child {
  font-weight: 600;
}

.todolist_card_header .teacher {
  display: flex;
  justify-content: flex-start;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 35px;
}

@media only screen and (max-width: 1201px) {
  .todolist_card_header .teacher {
    flex-basis: 50%;
  }
}

.todolist_card_header .teacher img {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin: 0px 3px;
}

.todolist_card_header .question_mark_details {
  background-color: #0793c7;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .todolist_card_header:first-child{
   
 } */
.todolist_card_header.responsive_hide {
  margin-top: 80px;
}

@media only screen and (max-width: 1201px) {
  .todolist_card_header.responsive_hide {
    display: none;
  }
}

.todolist_card_item {
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: 1px #f0f0f1 solid;
  border-right: 1px #f0f0f1 solid;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 52px;
  margin: 10px;
}

.todolist_card_item ul {
  display: flex;
}

.todolist_card_item ul > li:nth-child(4) {
  flex-basis: 15%;
}

.todolist_card_item ul > li:nth-child(5) {
  flex-basis: 15%;
}

.todolist_card_item ul > li:last-child {
  flex-basis: 10%;
}

.todolist_card_item ul > li {
  flex-basis: 20%;
  display: inline-flex;
  margin: 3px;
  padding: 7px;
  border-right: 1px #f0f0f1 solid;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-height: 45px;
  max-width: 20%;
  /* body.rtl & {
            padding: 0;
        } */
}

.todolist_card_item ul > li:first-child {
  justify-content: unset;
}

.todolist_card_item ul > li:nth-child(4) {
  border-right: 1px #f0f0f1 solid;
  display: inline;
  margin-inline-start: 5px;
}

body.rtl .todolist_card_item ul > li:nth-child(4) {
  border-right: none;
}

.todolist_card_item ul > li:last-child {
  border-right: none;
}

body.rtl .todolist_card_item ul > li:last-child {
  border-right: 1px #f0f0f1 solid;
}

.todolist_card_item li > span {
  font-size: 12px;
  display: flex;
  font-weight: 400;
}

.todolist_card_item li > span p:first-child {
  flex-basis: 30%;
}

body.rtl .todolist_card_item li > span p:first-child {
  flex-basis: 50%;
}

.todolist_card_item li > span > p {
  font-size: 11px;
  color: #000;
}

.todolist_card_item li > span > p:last-child {
  font-weight: 600;
}

.todolist_card_item li > h6 {
  color: #0793c7;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  align-items: center;
}

.todolist_card_item li > h6::before {
  content: "";
  font-size: 16px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px 5px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.todolist_card_item li {
  color: #000;
}

.todolist_card_item .topic, .todolist_card_item .subject > p {
  color: #0793c7;
}

.todolist_card_item .subject > p:first-child {
  font-weight: 600;
}

.todolist_card_item .teacher {
  display: flex;
  justify-content: flex-start;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 35px;
}

@media only screen and (max-width: 1201px) {
  .todolist_card_item .teacher {
    flex-basis: 50%;
  }
}

.todolist_card_item .teacher img {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin: 0px 3px;
}

.todolist_card_item .question_mark_details {
  background-color: #0793c7;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.todolist_card_item_responsive {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: 1px #f0f0f1 solid;
  border-right: 1px #f0f0f1 solid;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 110px;
  margin: 10px;
  padding: 10px 20px;
}

.todolist_card_item_responsive ul {
  display: flex;
}

.todolist_card_item_responsive ul > li:nth-child(4) {
  flex-basis: 15%;
}

.todolist_card_item_responsive ul > li:nth-child(5) {
  flex-basis: 15%;
}

.todolist_card_item_responsive ul > li:last-child {
  flex-basis: 10%;
}

.todolist_card_item_responsive ul > li {
  flex-basis: 20%;
  display: inline-flex;
  margin: 3px;
  padding: 7px;
  border-right: 1px #f0f0f1 solid;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-height: 45px;
  max-width: 20%;
  /* body.rtl & {
            padding: 0;
        } */
}

.todolist_card_item_responsive ul > li:first-child {
  justify-content: unset;
}

.todolist_card_item_responsive ul > li:nth-child(4) {
  border-right: 1px #f0f0f1 solid;
  display: inline;
  margin-inline-start: 5px;
}

body.rtl .todolist_card_item_responsive ul > li:nth-child(4) {
  border-right: none;
}

.todolist_card_item_responsive ul > li:last-child {
  border-right: none;
}

body.rtl .todolist_card_item_responsive ul > li:last-child {
  border-right: 1px #f0f0f1 solid;
}

.todolist_card_item_responsive li > span {
  font-size: 12px;
  display: flex;
  font-weight: 400;
}

.todolist_card_item_responsive li > span p:first-child {
  flex-basis: 30%;
}

body.rtl .todolist_card_item_responsive li > span p:first-child {
  flex-basis: 50%;
}

.todolist_card_item_responsive li > span > p {
  font-size: 11px;
  color: #000;
}

.todolist_card_item_responsive li > span > p:last-child {
  font-weight: 600;
}

.todolist_card_item_responsive li > h6 {
  color: #0793c7;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  align-items: center;
}

.todolist_card_item_responsive li > h6::before {
  content: "";
  font-size: 16px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px 5px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.todolist_card_item_responsive li {
  color: #000;
}

.todolist_card_item_responsive .topic, .todolist_card_item_responsive .subject > p {
  color: #0793c7;
}

.todolist_card_item_responsive .subject > p:first-child {
  font-weight: 600;
}

.todolist_card_item_responsive .teacher {
  display: flex;
  justify-content: flex-start;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 35px;
}

@media only screen and (max-width: 1201px) {
  .todolist_card_item_responsive .teacher {
    flex-basis: 50%;
  }
}

.todolist_card_item_responsive .teacher img {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin: 0px 3px;
}

.todolist_card_item_responsive .question_mark_details {
  background-color: #0793c7;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 481px) {
  .todolist_card_item_responsive {
    padding: 10px;
  }
}

.todolist_card_item_responsive .responsive_title_teacher {
  display: flex;
  justify-content: space-between;
}

.todolist_card_item_responsive .responsive_title_teacher h6 {
  /*  @include mq('phone-wide'){
                flex-basis: 70%;
            } */
  color: #00678d;
  font-weight: 500;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 48%;
}

.todolist_card_item_responsive .responsive_title_teacher p {
  font-size: 12px;
}

.todolist_card_item_responsive .responsive_title_teacher p img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
}

body.rtl .todolist_card_item_responsive .responsive_title_teacher p img {
  margin-left: 5px;
}

.todolist_card_item_responsive .subject_teacher_row {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.todolist_card_item_responsive .subject_teacher_row p {
  font-size: 10px;
}

.todolist_card_item_responsive .subject_teacher_row .subject_topic {
  display: flex;
  flex-direction: column;
  color: #295396;
}

.todolist_card_item_responsive .subject_teacher_row .subject_topic p:first-child {
  font-weight: 600;
}

.todolist_card_item_responsive .subject_teacher_row .teacher p:last-child {
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media only screen and (max-width: 481px) {
  .todolist_card_item_responsive .subject_teacher_row .teacher p:last-child {
    width: 70px;
  }
}

.todolist_card_item_responsive .subject_teacher_row::before {
  content: "";
  font-size: 18px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px 10px 0px -31px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.todolist_card_item_responsive .time_status_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.todolist_card_item_responsive .time_status_row p {
  font-size: 12px;
}

.todolist_card_item_responsive .time_status_row .start_due_time {
  display: flex;
  align-items: center;
  margin: 0px 2px;
}

.todolist_card_item_responsive .time_status_row .start_due_time .time {
  display: flex;
  align-items: center;
  color: #000;
}

.todolist_card_item_responsive .time_status_row .start_due_time .time img {
  padding: 0px 1px;
}

.todolist_card_item_responsive .time_status_row .start_due_time .to_p_tag {
  padding: 0px 5px;
  color: #00678d;
}

.todolist_card_item_responsive .time_status_row .status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 481px) {
  .todolist_card_item_responsive {
    padding: 10px;
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 481px) and (max-width: 481px) {
  .todolist_card_item_responsive {
    padding: 10px;
  }
}

@media only screen and (max-width: 481px) {
  .todolist_card_item_responsive .responsive_title_teacher {
    display: flex;
    justify-content: space-between;
  }
  .todolist_card_item_responsive .responsive_title_teacher h6 {
    /*  @include mq('phone-wide'){
                flex-basis: 70%;
            } */
    color: #00678d;
    font-weight: 500;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 48%;
  }
  .todolist_card_item_responsive .responsive_title_teacher p {
    font-size: 12px;
  }
  .todolist_card_item_responsive .responsive_title_teacher p img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 5px;
  }
  body.rtl .todolist_card_item_responsive .responsive_title_teacher p img {
    margin-left: 5px;
  }
  .todolist_card_item_responsive .subject_teacher_row {
    display: flex;
    align-items: center;
    padding: 10px 0px;
  }
  .todolist_card_item_responsive .subject_teacher_row p {
    font-size: 10px;
  }
  .todolist_card_item_responsive .subject_teacher_row .subject_topic {
    display: flex;
    flex-direction: column;
    color: #295396;
  }
  .todolist_card_item_responsive .subject_teacher_row .subject_topic p:first-child {
    font-weight: 600;
  }
  .todolist_card_item_responsive .subject_teacher_row .teacher p:last-child {
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 481px) and (max-width: 481px) {
  .todolist_card_item_responsive .subject_teacher_row .teacher p:last-child {
    width: 70px;
  }
}

@media only screen and (max-width: 481px) {
  .todolist_card_item_responsive .subject_teacher_row::before {
    content: "";
    font-size: 18px;
    font-weight: 700;
    color: #69b6d5;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #69b6d5;
    margin: 0px 10px 0px -21px;
    vertical-align: middle;
    background-color: #fff;
    text-align: center;
    text-decoration: none !important;
  }
  .todolist_card_item_responsive .time_status_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .todolist_card_item_responsive .time_status_row p {
    font-size: 12px;
  }
  .todolist_card_item_responsive .time_status_row .start_due_time {
    display: flex;
    align-items: center;
    margin: 0px 2px;
  }
  .todolist_card_item_responsive .time_status_row .start_due_time .time {
    display: flex;
    align-items: center;
    color: #000;
  }
  .todolist_card_item_responsive .time_status_row .start_due_time .time img {
    padding: 0px 1px;
  }
  .todolist_card_item_responsive .time_status_row .start_due_time .to_p_tag {
    padding: 0px 5px;
    color: #00678d;
  }
  .todolist_card_item_responsive .time_status_row .status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

body.rtl .todolist_card_item_responsive {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: 1px #f0f0f1 solid;
  border-right: 1px #f0f0f1 solid;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: unset;
  margin: 10px;
  padding: 10px 20px;
}

body.rtl .todolist_card_item_responsive ul {
  display: flex;
}

body.rtl .todolist_card_item_responsive ul > li:nth-child(4) {
  flex-basis: 15%;
}

body.rtl .todolist_card_item_responsive ul > li:nth-child(5) {
  flex-basis: 15%;
}

body.rtl .todolist_card_item_responsive ul > li:last-child {
  flex-basis: 10%;
}

body.rtl .todolist_card_item_responsive ul > li {
  flex-basis: 20%;
  display: inline-flex;
  margin: 3px;
  padding: 7px;
  border-right: 1px #f0f0f1 solid;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-height: 45px;
  max-width: 20%;
  /* body.rtl & {
            padding: 0;
        } */
}

body.rtl .todolist_card_item_responsive ul > li:first-child {
  justify-content: unset;
}

body.rtl .todolist_card_item_responsive ul > li:nth-child(4) {
  border-right: 1px #f0f0f1 solid;
  display: inline;
  margin-inline-start: 5px;
}

body.rtl body.rtl .todolist_card_item_responsive ul > li:nth-child(4) {
  border-right: none;
}

body.rtl .todolist_card_item_responsive ul > li:last-child {
  border-right: none;
}

body.rtl body.rtl .todolist_card_item_responsive ul > li:last-child {
  border-right: 1px #f0f0f1 solid;
}

body.rtl .todolist_card_item_responsive li > span {
  font-size: 12px;
  display: flex;
  font-weight: 400;
}

body.rtl .todolist_card_item_responsive li > span p:first-child {
  flex-basis: 30%;
}

body.rtl body.rtl .todolist_card_item_responsive li > span p:first-child {
  flex-basis: 50%;
}

body.rtl .todolist_card_item_responsive li > span > p {
  font-size: 11px;
  color: #000;
}

body.rtl .todolist_card_item_responsive li > span > p:last-child {
  font-weight: 600;
}

body.rtl .todolist_card_item_responsive li > h6 {
  color: #0793c7;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  align-items: center;
}

body.rtl .todolist_card_item_responsive li > h6::before {
  content: "";
  font-size: 16px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px 5px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

body.rtl .todolist_card_item_responsive li {
  color: #000;
}

body.rtl .todolist_card_item_responsive .topic, body.rtl .todolist_card_item_responsive .subject > p {
  color: #0793c7;
}

body.rtl .todolist_card_item_responsive .subject > p:first-child {
  font-weight: 600;
}

body.rtl .todolist_card_item_responsive .teacher {
  display: flex;
  justify-content: flex-start;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 35px;
}

@media only screen and (max-width: 1201px) {
  body.rtl .todolist_card_item_responsive .teacher {
    flex-basis: 50%;
  }
}

body.rtl .todolist_card_item_responsive .teacher img {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin: 0px 3px;
}

body.rtl .todolist_card_item_responsive .question_mark_details {
  background-color: #0793c7;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 481px) {
  body.rtl .todolist_card_item_responsive {
    padding: 10px;
  }
}

body.rtl .todolist_card_item_responsive .responsive_title_teacher {
  display: flex;
  justify-content: space-between;
}

body.rtl .todolist_card_item_responsive .responsive_title_teacher h6 {
  /*  @include mq('phone-wide'){
                flex-basis: 70%;
            } */
  color: #00678d;
  font-weight: 500;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 48%;
}

body.rtl .todolist_card_item_responsive .responsive_title_teacher p {
  font-size: 12px;
}

body.rtl .todolist_card_item_responsive .responsive_title_teacher p img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
}

body.rtl body.rtl .todolist_card_item_responsive .responsive_title_teacher p img {
  margin-left: 5px;
}

body.rtl .todolist_card_item_responsive .subject_teacher_row {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

body.rtl .todolist_card_item_responsive .subject_teacher_row p {
  font-size: 10px;
}

body.rtl .todolist_card_item_responsive .subject_teacher_row .subject_topic {
  display: flex;
  flex-direction: column;
  color: #295396;
}

body.rtl .todolist_card_item_responsive .subject_teacher_row .subject_topic p:first-child {
  font-weight: 600;
}

body.rtl .todolist_card_item_responsive .subject_teacher_row .teacher p:last-child {
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media only screen and (max-width: 481px) {
  body.rtl .todolist_card_item_responsive .subject_teacher_row .teacher p:last-child {
    width: 70px;
  }
}

body.rtl .todolist_card_item_responsive .subject_teacher_row::before {
  content: "";
  font-size: 18px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px -31px 0px 10px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

body.rtl .todolist_card_item_responsive .time_status_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

body.rtl .todolist_card_item_responsive .time_status_row p {
  font-size: 12px;
}

body.rtl .todolist_card_item_responsive .time_status_row .start_due_time {
  display: flex;
  align-items: center;
  margin: 0px 2px;
}

body.rtl .todolist_card_item_responsive .time_status_row .start_due_time .time {
  display: flex;
  align-items: center;
  color: #000;
}

body.rtl .todolist_card_item_responsive .time_status_row .start_due_time .time img {
  padding: 0px 1px;
}

body.rtl .todolist_card_item_responsive .time_status_row .start_due_time .to_p_tag {
  padding: 0px 5px;
  color: #00678d;
}

body.rtl .todolist_card_item_responsive .time_status_row .status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 481px) {
  body.rtl .todolist_card_item_responsive {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 481px) and (max-width: 481px) {
  body.rtl .todolist_card_item_responsive {
    padding: 10px;
  }
}

@media only screen and (max-width: 481px) {
  body.rtl .todolist_card_item_responsive .responsive_title_teacher {
    display: flex;
    justify-content: space-between;
  }
  body.rtl .todolist_card_item_responsive .responsive_title_teacher h6 {
    /*  @include mq('phone-wide'){
                flex-basis: 70%;
            } */
    color: #00678d;
    font-weight: 500;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 48%;
  }
  body.rtl .todolist_card_item_responsive .responsive_title_teacher p {
    font-size: 12px;
  }
  body.rtl .todolist_card_item_responsive .responsive_title_teacher p img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 5px;
  }
  body.rtl body.rtl .todolist_card_item_responsive .responsive_title_teacher p img {
    margin-left: 5px;
  }
  body.rtl .todolist_card_item_responsive .subject_teacher_row {
    display: flex;
    align-items: center;
    padding: 10px 0px;
  }
  body.rtl .todolist_card_item_responsive .subject_teacher_row p {
    font-size: 10px;
  }
  body.rtl .todolist_card_item_responsive .subject_teacher_row .subject_topic {
    display: flex;
    flex-direction: column;
    color: #295396;
  }
  body.rtl .todolist_card_item_responsive .subject_teacher_row .subject_topic p:first-child {
    font-weight: 600;
  }
  body.rtl .todolist_card_item_responsive .subject_teacher_row .teacher p:last-child {
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 481px) and (max-width: 481px) {
  body.rtl .todolist_card_item_responsive .subject_teacher_row .teacher p:last-child {
    width: 70px;
  }
}

@media only screen and (max-width: 481px) {
  body.rtl .todolist_card_item_responsive .subject_teacher_row::before {
    content: "";
    font-size: 18px;
    font-weight: 700;
    color: #69b6d5;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #69b6d5;
    margin: 0px -21px 0px 10px;
    vertical-align: middle;
    background-color: #fff;
    text-align: center;
    text-decoration: none !important;
  }
  body.rtl .todolist_card_item_responsive .time_status_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  body.rtl .todolist_card_item_responsive .time_status_row p {
    font-size: 12px;
  }
  body.rtl .todolist_card_item_responsive .time_status_row .start_due_time {
    display: flex;
    align-items: center;
    margin: 0px 2px;
  }
  body.rtl .todolist_card_item_responsive .time_status_row .start_due_time .time {
    display: flex;
    align-items: center;
    color: #000;
  }
  body.rtl .todolist_card_item_responsive .time_status_row .start_due_time .time img {
    padding: 0px 1px;
  }
  body.rtl .todolist_card_item_responsive .time_status_row .start_due_time .to_p_tag {
    padding: 0px 5px;
    color: #00678d;
  }
  body.rtl .todolist_card_item_responsive .time_status_row .status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.todolist_card_item_inprogress_responsive {
  width: 100%;
  background-color: #11b74f;
  border-radius: 5px;
  border: 1px #f0f0f1 solid;
  border-right: 1px #f0f0f1 solid;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 85px;
  margin: 10px;
  padding: 10px 20px;
  padding: 10px 20px;
}

.todolist_card_item_inprogress_responsive ul {
  display: flex;
}

.todolist_card_item_inprogress_responsive ul > li:nth-child(4) {
  flex-basis: 15%;
}

.todolist_card_item_inprogress_responsive ul > li:nth-child(5) {
  flex-basis: 15%;
}

.todolist_card_item_inprogress_responsive ul > li:last-child {
  flex-basis: 10%;
}

.todolist_card_item_inprogress_responsive ul > li {
  flex-basis: 20%;
  display: inline-flex;
  margin: 3px;
  padding: 7px;
  border-right: 1px #f0f0f1 solid;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-height: 45px;
  max-width: 20%;
  /* body.rtl & {
            padding: 0;
        } */
}

.todolist_card_item_inprogress_responsive ul > li:first-child {
  justify-content: unset;
}

.todolist_card_item_inprogress_responsive ul > li:nth-child(4) {
  border-right: 1px #f0f0f1 solid;
  display: inline;
  margin-inline-start: 5px;
}

body.rtl .todolist_card_item_inprogress_responsive ul > li:nth-child(4) {
  border-right: none;
}

.todolist_card_item_inprogress_responsive ul > li:last-child {
  border-right: none;
}

body.rtl .todolist_card_item_inprogress_responsive ul > li:last-child {
  border-right: 1px #f0f0f1 solid;
}

.todolist_card_item_inprogress_responsive li > span {
  font-size: 12px;
  display: flex;
  font-weight: 400;
}

.todolist_card_item_inprogress_responsive li > span p:first-child {
  flex-basis: 30%;
}

body.rtl .todolist_card_item_inprogress_responsive li > span p:first-child {
  flex-basis: 50%;
}

.todolist_card_item_inprogress_responsive li > span > p {
  font-size: 11px;
  color: #fff;
}

.todolist_card_item_inprogress_responsive li > span > p:last-child {
  font-weight: 600;
}

.todolist_card_item_inprogress_responsive li > h6 {
  color: #fff;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  align-items: center;
}

.todolist_card_item_inprogress_responsive li > h6::before {
  content: "";
  font-size: 16px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px 5px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.todolist_card_item_inprogress_responsive li {
  color: #fff;
}

.todolist_card_item_inprogress_responsive .topic, .todolist_card_item_inprogress_responsive .subject > p {
  color: #fff;
}

.todolist_card_item_inprogress_responsive .subject > p:first-child {
  font-weight: 600;
}

.todolist_card_item_inprogress_responsive .teacher {
  display: flex;
  justify-content: flex-start;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 35px;
}

@media only screen and (max-width: 1201px) {
  .todolist_card_item_inprogress_responsive .teacher {
    flex-basis: 50%;
  }
}

.todolist_card_item_inprogress_responsive .teacher img {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin: 0px 3px;
}

.todolist_card_item_inprogress_responsive .question_mark_details {
  background-color: #0793c7;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 481px) {
  .todolist_card_item_inprogress_responsive {
    padding: 10px;
  }
}

.todolist_card_item_inprogress_responsive .responsive_title_teacher {
  display: flex;
  justify-content: space-between;
}

.todolist_card_item_inprogress_responsive .responsive_title_teacher h6 {
  /*  @include mq('phone-wide'){
                flex-basis: 70%;
            } */
  color: #00678d;
  font-weight: 500;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 48%;
}

.todolist_card_item_inprogress_responsive .responsive_title_teacher p {
  font-size: 12px;
}

.todolist_card_item_inprogress_responsive .responsive_title_teacher p img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
}

body.rtl .todolist_card_item_inprogress_responsive .responsive_title_teacher p img {
  margin-left: 5px;
}

.todolist_card_item_inprogress_responsive .subject_teacher_row {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.todolist_card_item_inprogress_responsive .subject_teacher_row p {
  font-size: 10px;
}

.todolist_card_item_inprogress_responsive .subject_teacher_row .subject_topic {
  display: flex;
  flex-direction: column;
  color: #295396;
}

.todolist_card_item_inprogress_responsive .subject_teacher_row .subject_topic p:first-child {
  font-weight: 600;
}

.todolist_card_item_inprogress_responsive .subject_teacher_row .teacher p:last-child {
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media only screen and (max-width: 481px) {
  .todolist_card_item_inprogress_responsive .subject_teacher_row .teacher p:last-child {
    width: 70px;
  }
}

.todolist_card_item_inprogress_responsive .subject_teacher_row::before {
  content: "";
  font-size: 16px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px 5px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.todolist_card_item_inprogress_responsive .time_status_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.todolist_card_item_inprogress_responsive .time_status_row p {
  font-size: 12px;
}

.todolist_card_item_inprogress_responsive .time_status_row .start_due_time {
  display: flex;
  align-items: center;
  margin: 0px 2px;
}

.todolist_card_item_inprogress_responsive .time_status_row .start_due_time .time {
  display: flex;
  align-items: center;
  color: #000;
}

.todolist_card_item_inprogress_responsive .time_status_row .start_due_time .time img {
  padding: 0px 1px;
}

.todolist_card_item_inprogress_responsive .time_status_row .start_due_time .to_p_tag {
  padding: 0px 5px;
  color: #00678d;
}

.todolist_card_item_inprogress_responsive .time_status_row .status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.todolist_card_item_done_responsive {
  text-decoration: line-through #cacaca;
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 5px;
  border: 1px #f0f0f1 solid;
  border-right: 1px #f0f0f1 solid;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 110px;
  margin: 10px;
  padding: 10px 20px;
}

.todolist_card_item_done_responsive ul {
  display: flex;
}

.todolist_card_item_done_responsive ul > li:nth-child(4) {
  flex-basis: 15%;
}

.todolist_card_item_done_responsive ul > li:nth-child(5) {
  flex-basis: 15%;
}

.todolist_card_item_done_responsive ul > li:last-child {
  flex-basis: 10%;
}

.todolist_card_item_done_responsive ul > li {
  flex-basis: 20%;
  display: inline-flex;
  margin: 3px;
  padding: 7px;
  border-right: 1px #f0f0f1 solid;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-height: 45px;
  max-width: 20%;
  /* body.rtl & {
            padding: 0;
        } */
}

.todolist_card_item_done_responsive ul > li:first-child {
  justify-content: unset;
}

.todolist_card_item_done_responsive ul > li:nth-child(4) {
  border-right: 1px #f0f0f1 solid;
  display: inline;
  margin-inline-start: 5px;
}

body.rtl .todolist_card_item_done_responsive ul > li:nth-child(4) {
  border-right: none;
}

.todolist_card_item_done_responsive ul > li:last-child {
  border-right: none;
}

body.rtl .todolist_card_item_done_responsive ul > li:last-child {
  border-right: 1px #f0f0f1 solid;
}

.todolist_card_item_done_responsive li > span {
  font-size: 12px;
  display: flex;
  font-weight: 400;
}

.todolist_card_item_done_responsive li > span p:first-child {
  flex-basis: 30%;
}

body.rtl .todolist_card_item_done_responsive li > span p:first-child {
  flex-basis: 50%;
}

.todolist_card_item_done_responsive li > span > p {
  font-size: 11px;
  color: #fff;
}

.todolist_card_item_done_responsive li > span > p:last-child {
  font-weight: 600;
}

.todolist_card_item_done_responsive li > h6 {
  color: #c8c8c8;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  align-items: center;
}

.todolist_card_item_done_responsive li > h6::before {
  content: "";
  font-size: 16px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px 5px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.todolist_card_item_done_responsive li {
  color: #fff;
}

.todolist_card_item_done_responsive .topic, .todolist_card_item_done_responsive .subject > p {
  color: #c8c8c8;
}

.todolist_card_item_done_responsive .subject > p:first-child {
  font-weight: 600;
}

.todolist_card_item_done_responsive .teacher {
  display: flex;
  justify-content: flex-start;
  color: #c8c8c8;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 35px;
}

@media only screen and (max-width: 1201px) {
  .todolist_card_item_done_responsive .teacher {
    flex-basis: 50%;
  }
}

.todolist_card_item_done_responsive .teacher img {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin: 0px 3px;
}

.todolist_card_item_done_responsive .question_mark_details {
  background-color: #0793c7;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 481px) {
  .todolist_card_item_done_responsive {
    padding: 10px;
  }
}

.todolist_card_item_done_responsive .responsive_title_teacher {
  display: flex;
  justify-content: space-between;
}

.todolist_card_item_done_responsive .responsive_title_teacher h6 {
  /*  @include mq('phone-wide'){
                flex-basis: 70%;
            } */
  color: #c8c8c8;
  font-weight: 500;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 48%;
}

.todolist_card_item_done_responsive .responsive_title_teacher p {
  font-size: 12px;
}

.todolist_card_item_done_responsive .responsive_title_teacher p img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
}

body.rtl .todolist_card_item_done_responsive .responsive_title_teacher p img {
  margin-left: 5px;
}

.todolist_card_item_done_responsive .subject_teacher_row {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.todolist_card_item_done_responsive .subject_teacher_row p {
  font-size: 10px;
}

.todolist_card_item_done_responsive .subject_teacher_row .subject_topic {
  display: flex;
  flex-direction: column;
  color: #c8c8c8;
}

.todolist_card_item_done_responsive .subject_teacher_row .subject_topic p:first-child {
  font-weight: 600;
}

.todolist_card_item_done_responsive .subject_teacher_row .teacher p:last-child {
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media only screen and (max-width: 481px) {
  .todolist_card_item_done_responsive .subject_teacher_row .teacher p:last-child {
    width: 70px;
  }
}

.todolist_card_item_done_responsive .subject_teacher_row::before {
  content: "✓";
  font-size: 18px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px 10px 0px -31px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.todolist_card_item_done_responsive .time_status_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.todolist_card_item_done_responsive .time_status_row p {
  font-size: 12px;
}

.todolist_card_item_done_responsive .time_status_row .start_due_time {
  display: flex;
  align-items: center;
  margin: 0px 2px;
}

.todolist_card_item_done_responsive .time_status_row .start_due_time .time {
  display: flex;
  align-items: center;
  color: #c8c8c8;
}

.todolist_card_item_done_responsive .time_status_row .start_due_time .time img {
  padding: 0px 1px;
}

.todolist_card_item_done_responsive .time_status_row .start_due_time .to_p_tag {
  padding: 0px 5px;
  color: #c8c8c8;
}

.todolist_card_item_done_responsive .time_status_row .status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 481px) {
  .todolist_card_item_done_responsive {
    padding: 10px;
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 481px) and (max-width: 481px) {
  .todolist_card_item_done_responsive {
    padding: 10px;
  }
}

@media only screen and (max-width: 481px) {
  .todolist_card_item_done_responsive .responsive_title_teacher {
    display: flex;
    justify-content: space-between;
  }
  .todolist_card_item_done_responsive .responsive_title_teacher h6 {
    /*  @include mq('phone-wide'){
                flex-basis: 70%;
            } */
    color: #c8c8c8;
    font-weight: 500;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 48%;
  }
  .todolist_card_item_done_responsive .responsive_title_teacher p {
    font-size: 12px;
  }
  .todolist_card_item_done_responsive .responsive_title_teacher p img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 5px;
  }
  body.rtl .todolist_card_item_done_responsive .responsive_title_teacher p img {
    margin-left: 5px;
  }
  .todolist_card_item_done_responsive .subject_teacher_row {
    display: flex;
    align-items: center;
    padding: 10px 0px;
  }
  .todolist_card_item_done_responsive .subject_teacher_row p {
    font-size: 10px;
  }
  .todolist_card_item_done_responsive .subject_teacher_row .subject_topic {
    display: flex;
    flex-direction: column;
    color: #c8c8c8;
  }
  .todolist_card_item_done_responsive .subject_teacher_row .subject_topic p:first-child {
    font-weight: 600;
  }
  .todolist_card_item_done_responsive .subject_teacher_row .teacher p:last-child {
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 481px) and (max-width: 481px) {
  .todolist_card_item_done_responsive .subject_teacher_row .teacher p:last-child {
    width: 70px;
  }
}

@media only screen and (max-width: 481px) {
  .todolist_card_item_done_responsive .subject_teacher_row::before {
    content: "✓";
    font-size: 18px;
    font-weight: 700;
    color: #69b6d5;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #69b6d5;
    margin: 0px 10px 0px -21px;
    vertical-align: middle;
    background-color: #fff;
    text-align: center;
    text-decoration: none !important;
  }
  .todolist_card_item_done_responsive .time_status_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .todolist_card_item_done_responsive .time_status_row p {
    font-size: 12px;
  }
  .todolist_card_item_done_responsive .time_status_row .start_due_time {
    display: flex;
    align-items: center;
    margin: 0px 2px;
  }
  .todolist_card_item_done_responsive .time_status_row .start_due_time .time {
    display: flex;
    align-items: center;
    color: #c8c8c8;
  }
  .todolist_card_item_done_responsive .time_status_row .start_due_time .time img {
    padding: 0px 1px;
  }
  .todolist_card_item_done_responsive .time_status_row .start_due_time .to_p_tag {
    padding: 0px 5px;
    color: #c8c8c8;
  }
  .todolist_card_item_done_responsive .time_status_row .status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

body.rtl .todolist_card_item_done_responsive {
  padding: 10px 20px;
}

@media only screen and (max-width: 481px) {
  body.rtl .todolist_card_item_done_responsive {
    padding: 10px;
  }
}

body.rtl .todolist_card_item_done_responsive .responsive_title_teacher {
  display: flex;
  justify-content: space-between;
}

body.rtl .todolist_card_item_done_responsive .responsive_title_teacher h6 {
  /*  @include mq('phone-wide'){
                flex-basis: 70%;
            } */
  color: #c8c8c8;
  font-weight: 500;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 48%;
}

body.rtl .todolist_card_item_done_responsive .responsive_title_teacher p {
  font-size: 12px;
}

body.rtl .todolist_card_item_done_responsive .responsive_title_teacher p img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
}

body.rtl body.rtl .todolist_card_item_done_responsive .responsive_title_teacher p img {
  margin-left: 5px;
}

body.rtl .todolist_card_item_done_responsive .subject_teacher_row {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

body.rtl .todolist_card_item_done_responsive .subject_teacher_row p {
  font-size: 10px;
}

body.rtl .todolist_card_item_done_responsive .subject_teacher_row .subject_topic {
  display: flex;
  flex-direction: column;
  color: #c8c8c8;
}

body.rtl .todolist_card_item_done_responsive .subject_teacher_row .subject_topic p:first-child {
  font-weight: 600;
}

body.rtl .todolist_card_item_done_responsive .subject_teacher_row .teacher p:last-child {
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media only screen and (max-width: 481px) {
  body.rtl .todolist_card_item_done_responsive .subject_teacher_row .teacher p:last-child {
    width: 70px;
  }
}

body.rtl .todolist_card_item_done_responsive .subject_teacher_row::before {
  content: "✓";
  font-size: 18px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px -31px 0px 10px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

body.rtl .todolist_card_item_done_responsive .time_status_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

body.rtl .todolist_card_item_done_responsive .time_status_row p {
  font-size: 12px;
}

body.rtl .todolist_card_item_done_responsive .time_status_row .start_due_time {
  display: flex;
  align-items: center;
  margin: 0px 2px;
}

body.rtl .todolist_card_item_done_responsive .time_status_row .start_due_time .time {
  display: flex;
  align-items: center;
  color: #c8c8c8;
}

body.rtl .todolist_card_item_done_responsive .time_status_row .start_due_time .time img {
  padding: 0px 1px;
}

body.rtl .todolist_card_item_done_responsive .time_status_row .start_due_time .to_p_tag {
  padding: 0px 5px;
  color: #c8c8c8;
}

body.rtl .todolist_card_item_done_responsive .time_status_row .status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 481px) {
  body.rtl .todolist_card_item_done_responsive {
    padding: 10px;
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 481px) and (max-width: 481px) {
  body.rtl .todolist_card_item_done_responsive {
    padding: 10px;
  }
}

@media only screen and (max-width: 481px) {
  body.rtl .todolist_card_item_done_responsive .responsive_title_teacher {
    display: flex;
    justify-content: space-between;
  }
  body.rtl .todolist_card_item_done_responsive .responsive_title_teacher h6 {
    /*  @include mq('phone-wide'){
                flex-basis: 70%;
            } */
    color: #c8c8c8;
    font-weight: 500;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 48%;
  }
  body.rtl .todolist_card_item_done_responsive .responsive_title_teacher p {
    font-size: 12px;
  }
  body.rtl .todolist_card_item_done_responsive .responsive_title_teacher p img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 5px;
  }
  body.rtl body.rtl .todolist_card_item_done_responsive .responsive_title_teacher p img {
    margin-left: 5px;
  }
  body.rtl .todolist_card_item_done_responsive .subject_teacher_row {
    display: flex;
    align-items: center;
    padding: 10px 0px;
  }
  body.rtl .todolist_card_item_done_responsive .subject_teacher_row p {
    font-size: 10px;
  }
  body.rtl .todolist_card_item_done_responsive .subject_teacher_row .subject_topic {
    display: flex;
    flex-direction: column;
    color: #c8c8c8;
  }
  body.rtl .todolist_card_item_done_responsive .subject_teacher_row .subject_topic p:first-child {
    font-weight: 600;
  }
  body.rtl .todolist_card_item_done_responsive .subject_teacher_row .teacher p:last-child {
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 481px) and (max-width: 481px) {
  body.rtl .todolist_card_item_done_responsive .subject_teacher_row .teacher p:last-child {
    width: 70px;
  }
}

@media only screen and (max-width: 481px) {
  body.rtl .todolist_card_item_done_responsive .subject_teacher_row::before {
    content: "✓";
    font-size: 18px;
    font-weight: 700;
    color: #69b6d5;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #69b6d5;
    margin: 0px -21px 0px 10px;
    vertical-align: middle;
    background-color: #fff;
    text-align: center;
    text-decoration: none !important;
  }
  body.rtl .todolist_card_item_done_responsive .time_status_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  body.rtl .todolist_card_item_done_responsive .time_status_row p {
    font-size: 12px;
  }
  body.rtl .todolist_card_item_done_responsive .time_status_row .start_due_time {
    display: flex;
    align-items: center;
    margin: 0px 2px;
  }
  body.rtl .todolist_card_item_done_responsive .time_status_row .start_due_time .time {
    display: flex;
    align-items: center;
    color: #c8c8c8;
  }
  body.rtl .todolist_card_item_done_responsive .time_status_row .start_due_time .time img {
    padding: 0px 1px;
  }
  body.rtl .todolist_card_item_done_responsive .time_status_row .start_due_time .to_p_tag {
    padding: 0px 5px;
    color: #c8c8c8;
  }
  body.rtl .todolist_card_item_done_responsive .time_status_row .status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.todolist_card_item_inprogress {
  width: 100%;
  background-color: #11b74f;
  border-radius: 5px;
  border: 1px #f0f0f1 solid;
  border-right: 1px #f0f0f1 solid;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 52px;
  margin: 10px;
}

.todolist_card_item_inprogress ul {
  display: flex;
}

.todolist_card_item_inprogress ul > li:nth-child(4) {
  flex-basis: 15%;
}

.todolist_card_item_inprogress ul > li:nth-child(5) {
  flex-basis: 15%;
}

.todolist_card_item_inprogress ul > li:last-child {
  flex-basis: 10%;
}

.todolist_card_item_inprogress ul > li {
  flex-basis: 20%;
  display: inline-flex;
  margin: 3px;
  padding: 7px;
  border-right: 1px #f0f0f1 solid;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-height: 45px;
  max-width: 20%;
  /* body.rtl & {
            padding: 0;
        } */
}

.todolist_card_item_inprogress ul > li:first-child {
  justify-content: unset;
}

.todolist_card_item_inprogress ul > li:nth-child(4) {
  border-right: 1px #f0f0f1 solid;
  display: inline;
  margin-inline-start: 5px;
}

body.rtl .todolist_card_item_inprogress ul > li:nth-child(4) {
  border-right: none;
}

.todolist_card_item_inprogress ul > li:last-child {
  border-right: none;
}

body.rtl .todolist_card_item_inprogress ul > li:last-child {
  border-right: 1px #f0f0f1 solid;
}

.todolist_card_item_inprogress li > span {
  font-size: 12px;
  display: flex;
  font-weight: 400;
}

.todolist_card_item_inprogress li > span p:first-child {
  flex-basis: 30%;
}

body.rtl .todolist_card_item_inprogress li > span p:first-child {
  flex-basis: 50%;
}

.todolist_card_item_inprogress li > span > p {
  font-size: 11px;
  color: #fff;
}

.todolist_card_item_inprogress li > span > p:last-child {
  font-weight: 600;
}

.todolist_card_item_inprogress li > h6 {
  color: #fff;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  align-items: center;
}

.todolist_card_item_inprogress li > h6::before {
  content: "";
  font-size: 16px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px 5px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.todolist_card_item_inprogress li {
  color: #fff;
}

.todolist_card_item_inprogress .topic, .todolist_card_item_inprogress .subject > p {
  color: #fff;
}

.todolist_card_item_inprogress .subject > p:first-child {
  font-weight: 600;
}

.todolist_card_item_inprogress .teacher {
  display: flex;
  justify-content: flex-start;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 35px;
}

@media only screen and (max-width: 1201px) {
  .todolist_card_item_inprogress .teacher {
    flex-basis: 50%;
  }
}

.todolist_card_item_inprogress .teacher img {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin: 0px 3px;
}

.todolist_card_item_inprogress .question_mark_details {
  background-color: #0793c7;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.todolist_card_item_done {
  cursor: pointer;
  text-decoration: line-through #cacaca;
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 5px;
  border: 1px #f0f0f1 solid;
  border-right: 1px #f0f0f1 solid;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 52px;
  margin: 10px;
}

.todolist_card_item_done ul {
  display: flex;
}

.todolist_card_item_done ul > li:nth-child(4) {
  flex-basis: 15%;
}

.todolist_card_item_done ul > li:nth-child(5) {
  flex-basis: 15%;
}

.todolist_card_item_done ul > li:last-child {
  flex-basis: 10%;
}

.todolist_card_item_done ul > li {
  flex-basis: 20%;
  display: inline-flex;
  margin: 3px;
  padding: 7px;
  border-right: 1px #f0f0f1 solid;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-height: 45px;
  max-width: 20%;
  /* body.rtl & {
            padding: 0;
        } */
}

.todolist_card_item_done ul > li:first-child {
  justify-content: unset;
}

.todolist_card_item_done ul > li:nth-child(4) {
  border-right: 1px #f0f0f1 solid;
  display: inline;
  margin-inline-start: 5px;
}

body.rtl .todolist_card_item_done ul > li:nth-child(4) {
  border-right: none;
}

.todolist_card_item_done ul > li:last-child {
  border-right: none;
}

body.rtl .todolist_card_item_done ul > li:last-child {
  border-right: 1px #f0f0f1 solid;
}

.todolist_card_item_done li > span {
  font-size: 12px;
  display: flex;
  font-weight: 400;
}

.todolist_card_item_done li > span p:first-child {
  flex-basis: 30%;
}

body.rtl .todolist_card_item_done li > span p:first-child {
  flex-basis: 50%;
}

.todolist_card_item_done li > span > p {
  font-size: 11px;
  color: #cacaca;
}

.todolist_card_item_done li > span > p:last-child {
  font-weight: 600;
}

.todolist_card_item_done li > h6 {
  color: #cacaca;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  align-items: center;
}

.todolist_card_item_done li > h6::before {
  content: "✓";
  font-size: 16px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px 5px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.todolist_card_item_done li {
  color: #cacaca;
}

.todolist_card_item_done .topic, .todolist_card_item_done .subject > p {
  color: #cacaca;
}

.todolist_card_item_done .subject > p:first-child {
  font-weight: 600;
}

.todolist_card_item_done .teacher {
  display: flex;
  justify-content: flex-start;
  color: #cacaca;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 35px;
}

@media only screen and (max-width: 1201px) {
  .todolist_card_item_done .teacher {
    flex-basis: 50%;
  }
}

.todolist_card_item_done .teacher img {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin: 0px 3px;
}

.todolist_card_item_done .question_mark_details {
  background-color: #0793c7;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inprogress_done_icon {
  background-color: #11b74f;
  padding: 10px;
  border-radius: 50%;
}

.todo_item_row {
  display: flex;
  align-items: center;
}

body.rtl .todo_item_row .play_img {
  transform: rotate(180deg);
}

.vertical_line {
  margin: 0px 17px;
  width: 1px;
  /* Line width */
  background-color: #EAEAEA;
  /* Line color */
  height: 100%;
  /* Override in-line if you want specific height. */
  text-align: center;
  /* Causes the line to float to left of content. */
  height: 85px;
}

/* .vertical_line:last-child{
    height: 42px;
} */
.todo_empty_state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 80vh;
  margin-top: 90px;
}

@media only screen and (max-width: 577px) {
  .todo_empty_state {
    margin: auto;
    margin-top: 100px;
  }
}

.todo_empty_state p {
  margin: 20px 0px;
}

.todo_empty_state img {
  max-width: 50%;
}

@media only screen and (max-width: 577px) {
  .todo_empty_state img {
    max-width: 100%;
  }
}

.todolist_card_items_holder {
  overflow-y: auto;
  height: calc(100vh - 201px);
}

@media only screen and (max-width: 1201px) {
  .todolist_card_items_holder {
    margin-top: 70px;
    height: calc(100vh - 140px);
    padding-bottom: 50px;
  }
}

.todo_completed_section {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 12px;
}

.todo_completed_section h5 {
  color: #2c363f;
  font-size: 20px;
}

.todolist_details_drawer {
  /*  body.rtl & {
		direction: ltr;
    }

    .ant-drawer-content-wrapper{
        box-shadow: 10px 10px 10px 10px #888;
        body.rtl & {
            direction: rtl;
            box-shadow: 5px 5px 5px 0px #888;
        }} */
}

.todolist_details_drawer .ant-drawer-content-wrapper {
  box-shadow: 0px -6px 10px 0px rgba(124, 124, 124, 0.15);
}

body.rtl .todolist_details_drawer .ant-drawer-content-wrapper {
  box-shadow: 0px -6px 10px 0px rgba(124, 124, 124, 0.15);
}

.todolist_details_drawer .details_btn {
  width: 300px;
  height: 48px;
  background-color: #0793c7;
  color: #fff;
  position: fixed;
  bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.todolist_details_drawer .ant-drawer-header {
  border: unset;
  padding: 25px 25px 0px;
}

.todolist_details_drawer .ant-drawer-body {
  padding: 25px !important;
}

.todolist_details_drawer .todo_details_drawer_title h5 {
  font-size: 23px;
  color: #0793c7;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.todolist_details_drawer .todo_details_drawer_title h5 span {
  margin: 5px;
  background-color: #f6f6f6;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.todolist_details_drawer .todo_details_drawer_title h5 span img {
  width: 20px;
  height: 20px;
}

.todolist_details_drawer .todo_details_drawer_title div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.todolist_details_drawer .todo_details_drawer_title div p {
  margin: 0px 45px;
}

.todolist_details_drawer .row_item_container {
  display: flex;
  justify-content: space-between;
}

.todolist_details_drawer .detail_item {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.todolist_details_drawer .detail_item p:first-child {
  color: #0793c7;
  font-size: 16px;
  font-weight: 600;
}

.todolist_details_drawer .detail_item p {
  font-size: 15px;
  color: #4a4a4a;
}

.todolist_details_drawer .detail_item article {
  height: 100px;
  overflow-y: auto;
}

.connect_container {
  display: flex;
  width: 100%;
  /* max-height: 100vh;
    overflow-y: auto; */
}

.connect_container .options_container {
  flex-basis: 34%;
}

@media only screen and (max-width: 769px) {
  .connect_container .options_container {
    flex-basis: 10%;
  }
}

.connect_container .options_container.right {
  flex-basis: 33%;
}

@media only screen and (max-width: 769px) {
  .connect_container .options_container.right {
    flex-basis: 45%;
  }
}

.connect_container .options_container.left {
  flex-basis: 33%;
}

@media only screen and (max-width: 769px) {
  .connect_container .options_container.left {
    flex-basis: 45%;
  }
}

.connect_container .options_container .option {
  color: #2a7d9c;
  margin: 0 5px 10px 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 769px) {
  .connect_container .options_container .option {
    margin: 0px;
    padding: 0px 0px 5px 0px;
  }
}

.connect_container .options_container .option span.left_dot_circle,
.connect_container .options_container .option .right_dot_circle {
  /*  width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #2a7d9c;
                cursor: pointer; */
}

.connect_container .options_container .option .option_actions {
  display: flex;
}

.connect_container .options_container .option .option_actions span.left_dot_circle,
.connect_container .options_container .option .option_actions .right_dot_circle {
  /*   width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: #2a7d9c;
                    cursor: pointer; */
}

.connect_container .options_container .option .option_actions span.delete_span {
  font-size: 18px;
  color: #ff0000;
  cursor: pointer;
  font-weight: 600;
}

.connect_container .inner-html {
  /* display: flex !important;
        align-items: center;
        justify-content: center; */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border: solid 1px #868ea4;
  border-radius: 6px;
  background-color: transparent;
  padding: 5px;
  max-height: 140px;
  width: 100%;
  min-height: 50px;
  /*  height: 140px;
        max-height: 140px;
        min-width: 260px;
        max-width: 100%; */
  /*         *{
            pointer-events: none; 
         } */
}

.connect_container .inner-html.MathJax {
  min-height: 140px;
}

@media only screen and (max-width: 769px) {
  .connect_container .inner-html {
    /*             min-width: 70px;
            max-width: 140px;
            min-height: 70px;
             */
    min-height: 50px;
    max-height: 100px;
    padding: 2px;
  }
  .connect_container .inner-html.MathJax {
    min-height: 100px;
  }
}

.connect_container .inner-html.SelectedClass {
  border: 2px solid #0793c7;
}

.connect_container .inner-html iframe {
  max-width: 180px;
  max-height: 125px;
  object-fit: cover;
}

@media only screen and (max-width: 769px) {
  .connect_container .inner-html iframe {
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
  }
}

.connect_container .inner-html figure {
  display: flex;
  justify-content: center;
  width: 100% !important;
}

.connect_container .inner-html img {
  max-width: 180px;
  max-height: 125px;
  object-fit: contain;
}

@media only screen and (max-width: 769px) {
  .connect_container .inner-html img {
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
  }
}

.connect_container .ck-editor__main > .ck-editor__editable {
  border: none;
  pointer-events: none;
}

.connect_container .ck-editor__main > .ck-editor__editable .ck-toolbar {
  border: none;
}

.connect_container .ck-math-widget img {
  pointer-events: none;
  max-height: unset;
  object-fit: unset;
  width: auto;
}

@media only screen and (max-width: 769px) {
  .connect_container .ck-math-widget img {
    max-width: unset;
    max-height: unset;
    min-width: unset;
    min-height: unset;
  }
}

.online_session_list_wrapper {
  margin: 10px 15px;
}

.online_session_card_header {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: 1px #f0f0f1 solid;
  border-right: 1px #f0f0f1 solid;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 75px;
  margin: 10px;
  border: solid 0.3px #0793c7;
}

.online_session_card_header ul {
  display: flex;
}

.online_session_card_header ul > li:nth-child(4) {
  flex-basis: 15%;
}

.online_session_card_header ul > li:nth-child(5) {
  flex-basis: 15%;
}

.online_session_card_header ul > li:last-child {
  flex-basis: 10%;
}

.online_session_card_header ul > li {
  flex-basis: 20%;
  display: inline-flex;
  margin: 10px;
  padding: 7px;
  border-right: 1px #f0f0f1 solid;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-height: 45px;
  max-width: 20%;
  /* body.rtl & {
            padding: 0;
        } */
}

.online_session_card_header ul > li:first-child {
  justify-content: unset;
}

.online_session_card_header ul > li:nth-child(4) {
  border-right: 1px #f0f0f1 solid;
  display: inline;
  margin-inline-start: 5px;
}

body.rtl .online_session_card_header ul > li:nth-child(4) {
  border-right: none;
}

.online_session_card_header ul > li:last-child {
  border-right: none;
}

body.rtl .online_session_card_header ul > li:last-child {
  border-right: 1px #f0f0f1 solid;
}

.online_session_card_header li > span {
  font-size: 12px;
  display: flex;
  font-weight: 400;
}

.online_session_card_header li > span p:first-child {
  flex-basis: 30%;
}

body.rtl .online_session_card_header li > span p:first-child {
  flex-basis: 50%;
}

.online_session_card_header li > span > p {
  font-size: 11px;
  color: #000;
}

.online_session_card_header li > span > p:last-child {
  font-weight: 600;
}

.online_session_card_header li > h6 {
  color: #0793c7;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  align-items: center;
}

.online_session_card_header li > h6::before {
  content: "";
  font-size: 16px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px 5px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.online_session_card_header li {
  color: #000;
}

.online_session_card_header .topic, .online_session_card_header .subject > p {
  color: #0793c7;
}

.online_session_card_header .subject > p:first-child {
  font-weight: 600;
}

.online_session_card_header .teacher {
  display: flex;
  justify-content: flex-start;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 35px;
}

@media only screen and (max-width: 1201px) {
  .online_session_card_header .teacher {
    flex-basis: 50%;
  }
}

.online_session_card_header .teacher img {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin: 0px 3px;
}

.online_session_card_header .question_mark_details {
  background-color: #0793c7;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.online_session_card_header .onlinesession_header_item {
  flex-basis: 16.66% !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
}

.online_session_card_header .onlinesession_header_item p {
  font-weight: bold;
  color: #000000;
  font-size: 12px;
}

.online_session_card_header .onlinesession_header_item .ant-input {
  height: 30px;
  margin-top: 2px;
  border: 1px solid #d2dade;
}

.online_session_card_header .onlinesession_header_item .ant-input::placeholder {
  color: #d2dade;
  font-size: 10px;
}

.online_session_card_header .onlinesession_header_item .ant-input-suffix .anticon {
  height: 5px;
  top: 23px;
}

.online_session_item_row {
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: 1px #f0f0f1 solid;
  border-right: 1px #f0f0f1 solid;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 45px;
  margin: 10px;
  margin: 5px 10px;
  border: solid 0.3px #0793c7;
}

.online_session_item_row ul {
  display: flex;
}

.online_session_item_row ul > li:nth-child(4) {
  flex-basis: 15%;
}

.online_session_item_row ul > li:nth-child(5) {
  flex-basis: 15%;
}

.online_session_item_row ul > li:last-child {
  flex-basis: 10%;
}

.online_session_item_row ul > li {
  flex-basis: 20%;
  display: inline-flex;
  margin: 3px;
  padding: 7px;
  border-right: 1px #f0f0f1 solid;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-height: 45px;
  max-width: 20%;
  /* body.rtl & {
            padding: 0;
        } */
}

.online_session_item_row ul > li:first-child {
  justify-content: unset;
}

.online_session_item_row ul > li:nth-child(4) {
  border-right: 1px #f0f0f1 solid;
  display: inline;
  margin-inline-start: 5px;
}

body.rtl .online_session_item_row ul > li:nth-child(4) {
  border-right: none;
}

.online_session_item_row ul > li:last-child {
  border-right: none;
}

body.rtl .online_session_item_row ul > li:last-child {
  border-right: 1px #f0f0f1 solid;
}

.online_session_item_row li > span {
  font-size: 12px;
  display: flex;
  font-weight: 400;
}

.online_session_item_row li > span p:first-child {
  flex-basis: 30%;
}

body.rtl .online_session_item_row li > span p:first-child {
  flex-basis: 50%;
}

.online_session_item_row li > span > p {
  font-size: 11px;
  color: #000;
}

.online_session_item_row li > span > p:last-child {
  font-weight: 600;
}

.online_session_item_row li > h6 {
  color: #0793c7;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  align-items: center;
}

.online_session_item_row li > h6::before {
  content: "";
  font-size: 16px;
  font-weight: 700;
  color: #69b6d5;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #69b6d5;
  margin: 0px 5px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.online_session_item_row li {
  color: #000;
}

.online_session_item_row .topic, .online_session_item_row .subject > p {
  color: #0793c7;
}

.online_session_item_row .subject > p:first-child {
  font-weight: 600;
}

.online_session_item_row .teacher {
  display: flex;
  justify-content: flex-start;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 35px;
}

@media only screen and (max-width: 1201px) {
  .online_session_item_row .teacher {
    flex-basis: 50%;
  }
}

.online_session_item_row .teacher img {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin: 0px 3px;
}

.online_session_item_row .question_mark_details {
  background-color: #0793c7;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.online_session_item_row li {
  font-size: 14px;
  font-weight: normal !important;
  flex-basis: 16.66%  !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.online_session_item_row .blue_item {
  color: #0793c7;
  font-weight: normal;
}

.online_session_item_row .teacher {
  display: flex;
  justify-content: flex-start;
  color: #000;
  font-size: 10px;
  font-weight: 400;
  padding: 0px 35px;
  justify-content: center;
  align-items: center;
}

.onlinesession_item_responsive {
  height: 107px;
  width: 100%;
  border: solid 0.5px #00adee;
  border-left: solid 5px  #00adee;
  border-radius: 5px;
  margin: 5px 2px;
  padding: 5px 10px;
}

.onlinesession_item_responsive p {
  color: #0793c7;
  font-weight: bold;
  margin: 3px 0px;
}

body.rtl .onlinesession_item_responsive p {
  text-align: right;
}

.onlinesession_item_responsive .teacher {
  color: #485159;
  font-weight: normal;
}

.onlinesession_item_responsive .teacher img {
  border-radius: 50%;
  margin-right: 5px;
}

body.rtl .onlinesession_item_responsive .teacher img {
  margin-left: 5px;
  margin-right: unset;
}

.onlinesession_item_responsive span {
  font-weight: bold;
  display: flex;
  color: #0793c7;
}

.onlinesession_item_responsive span p {
  font-weight: normal;
  margin: 0px 5px;
}

.onlinesession_item_responsive div {
  display: flex;
  justify-content: space-between;
}

.onlinesession_item_responsive div p {
  font-weight: normal;
  color: #485159;
}

.online_session_empty_state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.online_session_empty_state p {
  color: #222222;
  font-size: 21px;
  margin: 20px 0px;
}

@media only screen and (max-width: 577px) {
  .online_session_empty_state p {
    font-size: 15px;
  }
}

.online_session_empty_state img {
  max-width: 100%;
}

.time_remaining_status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.time_remaining_status p {
  font-size: 10px;
}

.online_sessions_list_pagination_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.online_sessions_list_pagination_container .ant-pagination-item, .online_sessions_list_pagination_container .ant-pagination-item-link {
  background-color: #fafafa;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  border: unset;
  color: #285294;
}

.online_sessions_list_pagination_container .ant-pagination-item-active {
  background-color: #ffffff;
  border: unset;
}

.online_sessions_list_container {
  height: calc(100vh - 270px);
}

@media only screen and (max-width: 1201px) {
  .online_sessions_list_container {
    height: calc(100vh - 225px);
    overflow-y: auto;
  }
}

.responsive_filters_container {
  position: absolute;
  top: 127px;
  background-color: #fff;
  width: 92%;
  margin: 8px 0 34px;
  padding: 1px 2.2px 51px 1.5px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #d2dade;
  height: 245px;
}

body.rtl .responsive_filters_container {
  height: 270px;
  top: 150px;
}

.responsive_filters_container p {
  font-size: 12px;
  font-weight: bold;
  margin: 0px 10px;
}

.responsive_filters_container .ant-row {
  margin: 10px 0px;
}

.responsive_filters_container .ant-input {
  height: 35px;
  margin: 0px 10px;
}

.responsive_filters_container .ant-input::placeholder {
  font-size: 8px;
}

.responsive_filters_container .ant-input-affix-wrapper {
  width: 90%;
  display: flex;
}

.responsive_filters_container .responsive_filters_actions_row {
  display: flex;
  justify-content: flex-end;
  margin: 10px 5px;
}

.responsive_filters_container .responsive_filters_actions_row button {
  margin: 0px 5px;
  background-color: #0793c7;
  color: white;
}

.responsive_filters_container .responsive_filters_actions_row button:first-child {
  color: #2c363f;
  background-color: white;
}

.online-session-container {
  height: calc(100vh - 60px);
}

.online-session-container .online-session-title {
  display: flex;
  align-items: center;
  padding: 16px 32px;
  cursor: pointer;
}

@media only screen and (max-width: 769px) {
  .online-session-container .online-session-title {
    padding: 16px;
  }
  .online-session-container .online-session-title h3 {
    font-size: 19px;
  }
}

.online-session-container .online-session-title h3 {
  font-weight: normal;
  margin: 0 12px;
}

.online-session-container .course-deatails {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
}

@media only screen and (max-width: 769px) {
  .online-session-container .course-deatails {
    margin-top: 0px;
  }
}

.online-session-container .course-deatails .course-deatails__content {
  margin-top: 15px;
}

body.rtl .online-session-container .course-deatails .course-deatails__content .first-part {
  padding-right: 0;
}

.online-session-container .course-deatails .course-deatails__content .first-part .pragraph-holder {
  margin-left: 0;
}

body.rtl .online-session-container .course-deatails .course-deatails__content .first-part .pragraph-holder {
  margin-right: 0;
}

.online-session-container .course-deatails .course-deatails__content .first-part .pragraph-holder .content-paragraph {
  word-break: break-all;
}

body.rtl .online-session-container .course-deatails .course-deatails__content .first-part .pragraph-holder .content-paragraph {
  text-align: right;
}

.online-session-container .course-deatails .course-deatails__content .first-part .pragraph-holder .icon-holder .online-indicator {
  display: flex;
  font-size: 12px;
  color: #ffbc08;
  align-items: center;
}

.online-session-container .course-deatails .course-deatails__content .first-part .pragraph-holder .icon-holder .online-indicator span {
  width: 12px;
  height: 12px;
  background: #ffbc08;
  border-radius: 100%;
  margin: 5px 5px 5px 0px;
}

.online-session-container .course-deatails .session-assets-holder .session-assets-emptystate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.online-session-container .course-deatails .session-assets-holder .session-assets-title {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}

.online-session-container .course-deatails .session-assets-holder img {
  margin-top: 5px;
}

body.rtl .online_sessions_range_picker .ant-calendar {
  width: unset;
}

.online_sessions_range_picker .ant-calendar-input-wrap {
  display: none;
}

@media only screen and (max-width: 1201px) {
  .online_sessions_range_picker .ant-calendar-range {
    width: 320px;
  }
}

@media only screen and (max-width: 1201px) {
  .online_sessions_range_picker .ant-calendar-range-left {
    float: unset;
  }
  body.rtl .online_sessions_range_picker .ant-calendar-range-left {
    float: unset;
  }
}

@media only screen and (max-width: 1201px) {
  .online_sessions_range_picker .ant-calendar-range-part {
    width: 100%;
  }
}

.online_session_select_status.ant-select {
  width: 90%;
  height: 30px !important;
  border: .5px solid #d2dade;
  border-radius: 5px;
  margin: 2px 10px;
}

@media only screen and (max-width: 1201px) {
  .online_session_select_status.ant-select {
    margin-top: unset;
  }
}

.online_session_select_status.ant-select::placeholder {
  font-size: 9px;
}

.online_session_select_status .ant-select-selection-selected-value {
  font-size: 10px;
  margin: -3px 10px;
}

.online_session_select_status .ant-select-selection__placeholder {
  font-size: 10px !important;
  margin: -13px 10px !important;
}

.ant-select-arrow {
  margin: -10px 0px;
}

.online_sessions_range_picker_input .ant-calendar-picker-input {
  height: 30px;
  width: 100%;
  font-size: 10px;
  padding-left: 0px;
}

@media only screen and (max-width: 1201px) {
  .online_sessions_range_picker_input .ant-calendar-picker-input {
    width: 90%;
  }
}

body.rtl .online_sessions_range_picker_input .ant-calendar-picker-input {
  padding-left: unset;
}

.online_sessions_range_picker_input .ant-calendar-picker-input .anticon {
  width: 4px;
}

.online_sessions_range_picker_input .ant-input-suffix .anticon {
  height: 5px;
  top: 23px;
}

.online_sessions_range_picker_input .ant-calendar-range-picker-input {
  margin: 2px 0px;
}

.online_sessions_range_picker_input .ant-calendar-range-picker-separator {
  margin: 10px 0px;
}

.online_sessions_range_picker_input .ant-calendar-picker {
  width: 95%;
}

body.rtl .online_sessions_range_picker_input .ant-calendar-picker {
  float: right;
}
